<template>
    <div>
        <form @submit.prevent="submit" id="tool-checkout-form" name="tool-checkout-form" :ref="'tool-checkout-form'">
            <div class="col-md-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h6 class="card-title">Check Out</h6>
                        <div class="form-group col-md-4">
                            <label for="supervisor_id">Crew Supervisor:<span class="required_asterisk">*</span></label>
                            <select-box name="supervisor_id" id="supervisor_id" :options="this.$props.supervisors" v-model="form.supervisor_id">
                                <option></option>
                            </select-box>
                        </div>
                        <h6 class="card-subtitle mb-2 mt-2 text-muted">Tools Available for Check Out</h6>
                        <div class="table-responsive pt-3">
                            <table class="table table-bordered table-hover data-table">
                                <thead>
                                <tr>
                                    <th>
                                        Check Out Tool
                                    </th>
                                    <th>
                                        Tool ID
                                    </th>
                                    <th>
                                        Category
                                    </th>
                                    <th>
                                        Tool Type
                                    </th>
                                    <th>
                                        Description
                                    </th>
                                    <th>
                                        Brand Name
                                    </th>
                                    <th>
                                        Model
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(item,index) in tool_in">
                                    <td>
                                        <button type="button" class="btn btn-primary btn-icon "  @click="$modal.show(index+'-tool-modal')">
                                            <log-in-icon size="1.5x" style="color: white"></log-in-icon>
                                        </button>
                                    </td>
                                    <td>
                                        {{item.tool_identifier}}
                                    </td>
                                    <td>
                                        {{item.category.name}}
                                    </td>
                                    <td v-if="item.type !== null ">
                                        {{item.type.name}}
                                    </td>
                                    <td v-else>

                                    </td>
                                    <td>
                                        {{item.description}}
                                    </td>
                                    <td>
                                        {{item.brand.name}}
                                    </td>
                                    <td>
                                        {{item.model}}
                                    </td>
                                    <checkout-modal :tool="item" :index="index" :tool_out="tool_out" :crews="crews"></checkout-modal>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h6 class="card-subtitle mb-2 text-muted">Tools to be checked out</h6>
                        <div class="table-responsive pt-3">
                            <table class="table table-bordered table-hover">
                                <thead>
                                <tr>
                                    <th>
                                        Remove from list
                                    </th>
                                    <th>
                                        Tool ID
                                    </th>
                                    <th>
                                        Serial Number
                                    </th>
                                    <th>
                                        Tool Type
                                    </th>
                                    <th>
                                        Description
                                    </th>
                                    <th>
                                        Brand Name
                                    </th>
                                    <th>
                                        Model
                                    </th>
                                    <th>
                                        Crew
                                    </th>
                                    <th>
                                        Comment
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(item,index) in tool_out">
                                    <td >
                                        <button type="button" class="btn btn-primary btn-icon "  v-on:click="removeFromOut(item.id,index)">
                                            <log-out-icon size="1.5x" style="color: white"></log-out-icon>
                                        </button>
                                    </td>
                                    <td>
                                        {{item.tool_number}}
                                    </td>
                                    <td>
                                        {{item.serial_code}}
                                    </td>
                                    <td v-if="item.tool.type !== null ">
                                        {{item.tool.type}}
                                    </td>
                                    <td v-else>

                                    </td>
                                    <td>
                                        {{item.tool.description}}
                                    </td>
                                    <td>
                                        {{item.tool.brand.name}}
                                    </td>
                                    <td>
                                        {{item.tool.model}}
                                    </td>
                                    <td>
                                        {{crews.find(crew=>crew.id == item.crew).name}}
                                    </td>
                                    <td>
                                        {{item.comment}}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="form-group mt-5">
                            <label for="check_out_date">Check Out Date<span class="required_asterisk">*</span></label>
                            <div class="input-group date datepicker dashboard-date mr-2 mb-2 mb-md-0 d-xl-flex">
                                <span class="input-group-addon bg-transparent"><i data-feather="calendar" class=" text-primary"></i></span>
                                <datePicker autocomplete="off" v-model="form.check_out_date" :config="datePickerOptions" name="check_out_date"></datePicker>
                            </div>
                        </div>

                        <div class="mt-5">
                            <button type="submit" class="btn btn-primary mr-2">Complete Check Out</button>
                            <!--                <a href="/tools" class="btn btn-light">Cancel</a>-->
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import datePicker from 'vue-bootstrap-datetimepicker';
    import 'jquery-validation'
    import Swal from "sweetalert2";
    import {LogInIcon, LogOutIcon} from 'vue-feather-icons'
    import moment from 'moment';


    export default {
        name: "CheckOutComponent",
        props: ['inventory_in','supervisors','crews'],
        components: {
            datePicker,
            LogInIcon,
            LogOutIcon
        },
        data(){
            return{

                tool_in: this.$props.inventory_in,
                tool_out:[],
                form:{
                    tools:[],
                    supervisor_id: "",
                    check_out_date: moment().format('MM/DD/YYYY'),
                },
                datePickerOptions:{
                    format: 'MM/DD/YYYY',
                    useCurrent: false,
                    maxDate: new Date()
                },
                moment: moment
            }

        },
        mounted() {
            $("form[name='tool-checkout-form']").validate({
                rules: {
                    supervisor_id: {
                        required: true,
                    },
                    check_in_date: {
                        required: true,
                    },
                },
                errorPlacement: function(label, element) {
                    if(['supervisor_id','check_in_date'].includes(element[0].name)){
                        label.addClass('mt-2 text-danger');
                        label.insertAfter(element.next());
                    }else{
                        label.addClass('mt-2 text-danger');
                        label.insertAfter(element);
                    }
                },
                highlight: function(element, errorClass) {
                    $(element).parent().addClass('has-danger')
                    $(element).addClass('form-control-danger')
                },
                unhighlight: function(element, errorClass, validClass) {
                    $(element).parent().removeClass('has-danger')
                    $(element).removeClass('form-control-danger')
                },
                submitHandler: function(form){
                    this.submit()
                }
            });
        },
        methods:{
            addModal(id,index){
                let comment = '';
                let crew ='';
                let crews = '';
                this.$props.crews.forEach(crew=>{
                    crews += '<option value="'+crew.id+'">'+crew.name+'</option>'
                })
                Swal.fire({
                    title: 'Tool to Check Out',
                    html:
                        '<label for="comment">Comment<span class="required_asterisk">*</span></label>'+
                        '<input id="comment" class="swal2-input">'+
                        '<label for="crew">Crew<span class="required_asterisk">*</span></label>'+
                        '<select id="crew" class="swal2-input">'+
                        crews+
                        '</select>'
                    ,
                    focusConfirm: false,
                    preConfirm: () => {
                        comment = document.getElementById('comment').value;
                        crew = document.getElementById('crew').value;

                        this.addToOut(id,index,comment,crew)
                    }
                });
            },
            addToOut(id,index,comment,crew){
                let toolToOut = this.tool_in.find(tool=> tool.id == id);

                toolToOut.comment = comment;
                toolToOut.crew = crew;
                this.tool_in.splice(index,1);

                this.tool_out.push(toolToOut);
            },
            removeFromOut(id,index){
                let toolToIn = this.tool_out.find(tool=> tool.id == id);

                this.tool_out.splice(index,1);
                toolToIn.comment = ''

                let parent_tool = this.tool_in.find(tool => tool.id == toolToIn.tool_id).items.push(toolToIn);
            },
            submit(){
                this.form.tools = this.tool_out;
                let attributes = this.form;

                if(this.form.supervisor_id !=='' && this.form.supervisor_id != null) {
                    axios.post('/toolsinventory/checkout/', attributes).then(response => {
                        location.reload()
                    })
                }
            },
            setSupervisor(){
                console.log(this.$refs)
            },
        }
    }
</script>

<style scoped>

</style>
