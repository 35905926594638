<template>
    <form @submit.prevent="submit" id="create-cycle-count-reconciliation-form" name="create-cycle-count-reconciliation-form" :ref="'edit-cycle-count-reconciliation-form'">
        <h6 class="card-subtitle mb-2 text-muted">Manual Reconciliations</h6>
        <div class="table-responsive pt-3">
            <table class="table table-bordered table-hover">
                <thead>
                <tr>
                    <th>
                        Warehouse
                    </th>
                    <th>
                        Zone
                    </th>
                    <th>
                        Location
                    </th>
                    <th>
                        SKU
                    </th>
                    <th>
                        Product
                    </th>
                    <th>
                        Description
                    </th>
                    <th v-if="is_admin === 1">
                        In Stock
                    </th>
                    <th>
                        Physical Count
                    </th>
                    <th v-if="is_admin === 1">
                        Variance
                    </th>
                    <th v-if="is_admin === 1">
                        Recount
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item,index) in $props.reconciliation_products">
                    <td>
                        {{warehouses.find(wh => wh.id === item.warehouse_id).name}}
                    </td>
                    <td v-if="zones.find(zn => zn.id === item.zone_id) != null">
                        {{zones.find(zn => zn.id === item.zone_id).name}}
                    </td>
                    <td v-else>

                    </td>
                    <td v-if="locations.find(ln => ln.id === item.location_id) != null">
                        {{locations.find(ln => ln.id === item.location_id).name}}
                    </td>
                    <td v-else>

                    </td>
                    <td>
                        {{products.find(prod => prod.id === item.product_id).sku}}
                    </td>
                    <td>
                        {{products.find(prod => prod.id === item.product_id).name}}
                    </td>
                    <td>
                        {{products.find(prod => prod.id === item.product_id).description}}
                    </td>
                    <td v-if="is_admin === 1">
                        {{reconciliation_products.find(prod => (prod.product_id == item.product_id) && (prod.zone_id == item.zone_id) && (prod.location_id === item.location_id)).original_inventory_quantity}}
                    </td>
                    <td>
                        {{item.inventory_count}}
                    </td>
                    <td v-if="is_admin === 1">
                        <p v-if="item.inventory_count < reconciliation_products.find(prod => (prod.product_id === item.product_id) && (prod.zone_id === item.zone_id) && (prod.location_id === item.location_id)).original_inventory_quantity">
                            {{'-'+Math.abs(reconciliation_products.find(prod => (prod.product_id === item.product_id) && (prod.zone_id === item.zone_id) && (prod.location_id === item.location_id)).original_inventory_quantity - item.inventory_count)}}
                        </p>
                        <p v-else-if="item.inventory_count > reconciliation_products.find(prod => (prod.product_id === item.product_id) && (prod.zone_id === item.zone_id) && (prod.location_id === item.location_id)).original_inventory_quantity">
                            {{'+'+Math.abs(reconciliation_products.find(prod => (prod.product_id === item.product_id) && (prod.zone_id === item.zone_id) && (prod.location_id === item.location_id)).original_inventory_quantity - item.inventory_count)}}
                        </p>
                        <p v-else>
                        </p>
                    </td>
                    <td v-if="is_admin === 1">
                        <input type="checkbox" class="form-control" name="recount_check" v-model="item.recount">
                    </td>
                </tr>
                </tbody>
            </table>
            <div>
                <button v-if="is_admin === 1" style="float: right" type="button" class="btn btn-primary mt-3 mb-3 ml-3" @click="submit_recount()">Recount</button>
                <button v-if="is_admin === 1" style="float: right" type="button" class="btn btn-primary mt-3 mb-3 ml-3" @click="submit_warning()" :disabled="disable_save">Approve</button>
            </div>
        </div>
    </form>
</template>
<script>
import Swal from "sweetalert2";
import 'jquery-validation';
import {LogInIcon, LogOutIcon, Trash2Icon} from 'vue-feather-icons';
import datePicker from 'vue-bootstrap-datetimepicker';
import axios from "axios";

export default {
    name: "PendingCycleCountReconciliation",
    props:{
        warehouses: Array,
        zones: Array,
        locations: Array,
        reconciliation:Object,
        reconciliation_products:Array,
        products:Array,
        is_admin:Number,
        products_submit:Array,
    },
    components:{
        LogOutIcon, LogInIcon, Trash2Icon, datePicker
    },
    data(){
        return{
            recount_check:'',
            list_of_products:[],
            form:{
                product_reconciliations: [],
                reconciliation_id:'',
                total_items:'',
            },
            recount:{
                reconciliation_id:this.$props.reconciliation.id,
                inventories_to_recount:[]
            },
            disable_save:false
        }
    },
    mounted() {
        this.list_of_products = this.$props.products;
        this.form.reconciliation_id = this.$props.reconciliation.id;
        // this.$props.products_submit.forEach(elem => {
        //     this.$props.reconciliation_products.forEach(prod=>{
        //         if((elem.product_id === prod.product_id) &&
        //             (elem.warehouse_id === prod.warehouse_id) &&
        //             (elem.zone_id === prod.zone_id) &&
        //             (elem.location_id === prod.location_id))
        //         {
        //             elem.reconciliation_qty = prod.inventory_count;
        //             elem.reconciliation_qty_list = prod.inventory_count;
        //             this.form.product_reconciliations.push(elem);
        //         }
        //     })
        // })
        this.$props.reconciliation_products.forEach(prod => {
            let elem = this.$props.products_submit.find(ps => ps.product_id == prod.product_id);
            elem.quantity = prod.original_inventory_quantity;
            elem.warehouse_id = prod.warehouse_id;
            elem.zone_id = prod.zone_id;
            elem.location_id = prod.location_id;
            elem.reconciliation_qty = prod.inventory_count;
            elem.reconciliation_qty_list = prod.inventory_count;
            console.log(elem);
            this.form.product_reconciliations.push(elem);
            console.log(this.form.product_reconciliations);
        })
    },

    methods:{
        submit_recount(){
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2500
            });
            let error = false;
            let recount_list_null = this.$props.reconciliation_products.filter(function (currentElement) {
                if (currentElement['recount'] === true ) {
                    return true;
                }
            });
            if(recount_list_null.length > 0){
                error = true;
                this.recount.inventories_to_recount = this.$props.reconciliation_products;
                let attributes = this.recount;
                axios.post('/cycle_count/recount_reconciliation',attributes).then(response =>{
                    window.location = response.data.redirect;
                }).catch(error_x =>{
                    Toast.fire({
                        icon: 'error',
                        title: 'There was an error.'
                    })
                });
            }else{
                Toast.fire({
                    icon: 'error',
                    title: 'Select at least one product to recount.'
                })
            }
        },

        submit_warning(){
            Swal.fire({
                title: 'Do you want to submit the reconciliation?',
                text: 'This action will add or delete inventory permanently.',
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: 'Submit',
            }).then((result) => {
                console.log(result.value === true);
                if(result.value === true){
                    this.submit_reconciliation()
                }
            })
        },

        submit_reconciliation(){
            this.disable_save = true;
            let attributes = this.form;
            axios.post('/cycle_count/submit_reconciliation_pending',attributes).then(response =>{
                window.location = response.data.redirect;
            }).catch(error =>{
                Swal.fire({
                    icon: 'error',
                    title: 'An error occurred during request.'
                })
            });
        },
    }
}
</script>

<style scoped>

</style>
