<template>
    <div>
        <form @submit.prevent="submit" id="receiving-form" name="receiving-form" :ref="'receiving-form'">
            <div class="col-md-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <div class="row justify-content-between">
                            <h3 class="card-title">Receiving PO</h3>
                        </div>
                        <div class="row" style="margin-bottom: 25px">
                            <div class="col-6">
                                <div style="margin-bottom: 20px ">
                                    <div class="row">
                                        <div class="col-lg-4 col-xl-3">
                                            <p class="mr-4 mb-2"><b>PO #:</b></p>
                                        </div>
                                        <div class="col-lg-4 col-xl-6 ml-2 ml-lg-0">
                                            <p class="mb-2">{{purchase_order.po_number}}</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-4 col-xl-3">
                                            <p class="mr-4 mb-2"><b>Supplier:</b></p>
                                        </div>
                                        <div class="col-lg-4 col-xl-6 ml-2 ml-lg-0">
                                            <p class="mb-2">{{purchase_order.supplier.name}}</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-4 col-xl-3">
                                            <p class="mr-4 mb-2"><b>Quote #:</b></p>
                                        </div>
                                        <div class="col-lg-4 col-xl-6 ml-2 ml-lg-0">
                                            <p class="mb-2">{{purchase_order.quote}}</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-4 col-xl-3">
                                            <p class="mr-4 mb-2"><b>Comments:</b></p>
                                        </div>
                                        <div class="col-lg-4 col-xl-6 ml-2 ml-lg-0">
                                            <p class="mb-2">{{purchase_order.comments}}</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-4 col-xl-3">
                                            <p class="mr-4 mb-2"><b>Documents:</b></p>
                                        </div>
                                        <div class="col-lg-8 col-xl-9 ml-2 ml-lg-0">
                                            <div v-for="(document,index) in purchase_order.documents">
                                                <p v-if="order.invoice_number !== ''">
                                                    <a v-if="(document.type === 'documentation' && (order.invoice_number !== null))" :href="'/orders/'+purchase_order.id+'/documents/'+document.id" target="_blank">{{order.invoice_number + '_(' + document.created_at.substring(0, 10) + ')_' + index}}</a>
                                                    <a v-else-if="document.type === 'documentation'" :href="'/orders/'+purchase_order.id+'/documents/'+document.id" target="_blank">{{'doc_(' + document.created_at.substring(0, 10) + ')_' + index}}</a>
                                                    <a v-else :href="'/orders/'+purchase_order.id+'/documents/'+document.id" target="_blank">{{document.type}}</a>
                                                </p>
                                                <p v-else>
                                                    <a v-if="document.type === 'documentation'" :href="'/orders/'+purchase_order.id+'/documents/'+document.id" target="_blank">{{'doc_(' + document.created_at.substring(0, 10) + ')_' + index}}</a>
                                                    <a v-else :href="'/orders/'+purchase_order.id+'/documents/'+document.id" target="_blank">{{document.type}}</a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div style="margin: 0; position: absolute; top: 50%; left: 50%; -ms-transform: translate(-50%, -50%); transform: translate(-50%, -50%);">
                                    <button type="button" class="btn btn-primary" v-on:click="scanItem(1)"><h3>Scan Item</h3></button>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12" style="margin-bottom: 25px">
                                <div v-for="(product,index) in this.form.products" :id="'accordion'+product.id" class="accordion" role="tablist">
                                    <div class="card" >
                                        <div class="card-header" role="tab" :id="'headingOne-' + product.id">
                                            <h3 class="mb-0">
                                                <a data-toggle="collapse" :href="'#collapseOne-'+ product.id" aria-expanded="false" :aria-controls="'collapseOne-'+ product.id">
                                                    <h3>{{product.sku}} ({{product.name}})</h3>
                                                </a>
                                            </h3>
                                        </div>
                                        <div :id="'collapseOne-'+ product.id" class="collapse" role="tabpanel" :aria-labelledby="'headingOne-' + product.id" :data-parent="'#accordion'+product.id">
                                            <div class="card-body">
                                                <div class="row mb-3">
                                                    <div class="col-md-6">
                                                        <h3 class="card-title">Order Details</h3>
                                                    </div>
                                                    <div class="col-md-6">
                                                    </div>
                                                    <div class="col-md-4 mr-1">
                                                        <div class="row" >
                                                            <div class="col-xl-5">
                                                                <p class="mr-4 mb-2"><b>UPC:</b></p>
                                                            </div>
                                                            <div class="col-xl-7 ml-2 ml-xl-0">
                                                                <p>{{product.upc}}</p>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-xl-5">
                                                                <p class="mr-4 mb-2"><b>Description:</b></p>
                                                            </div>
                                                            <div class=" col-xl-7 ml-2 ml-xl-0">
                                                                <p class="mb-2">{{product.description}}</p>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-xl-5">
                                                                <p class="mr-4 mb-2"><b>Order Qty.</b></p>
                                                            </div>
                                                            <div class="col-xl-7 ml-2 ml-xl-0">
                                                                <p class="mb-2">{{product.order_qty}}</p>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-xl-5">
                                                                <p class="mr-4 mb-2"><b>Remaining Qty.</b></p>
                                                            </div>
                                                            <div class="col-xl-7 ml-2 ml-xl-0">
                                                                <p class="mb-2">{{product.remaining_qty}}</p>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-xl-7 mt-4">
                                                                <div v-if="product.use_serials" class="alert alert-warning" role="alert">Scan S/N is required for this item.</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 mr-2">
                                                        <div class="row">
                                                            <div class="form-group">
                                                                <label for="order_qty"><b>Qty Received</b><span class="required_asterisk">*</span></label>
                                                                <div v-if="product.same_warehouse == false  && product.scan_serials == false">
                                                                    <p class="mb-2">{{product.warehouses_allocation.reduce((a, b) => a + (parseInt(b.qty) || 0), 0)}}</p>
                                                                </div>
                                                                <div v-else>
                                                                    <input type="number" oninput="validity.valid||(value='')" min="0" class="form-control" name="product" id="order_qty"  placeholder="####" v-model="product.qty_received">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-2">
                                                            <div class="form-check form-check-flat form-check-primary">
                                                                <label class="form-check-label">
                                                                    <input type="checkbox" class="form-check-input" v-model="product.same_warehouse" >
                                                                    Same Warehouse?
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <receiving-warehouse-allocation-component :index="index" :parent="product" :type="'product'" :zones="zones" :locations="locations"></receiving-warehouse-allocation-component>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">
                                    <h6 class="card-title">Additional Information</h6>
                                </div>
                                <div class="form-group">
                                    <label for="received_date">Date Received<span class="required_asterisk">*</span></label>
                                    <div class="input-group date datepicker dashboard-date mr-2 mb-2 mb-md-0 d-xl-flex">
                                        <span class="input-group-addon bg-transparent"><i data-feather="calendar" class=" text-primary"></i></span>
                                        <datePicker :disabled="!$props.is_admin" autocomplete="off" v-model="form.received_date" :config="datePickerOptions" name="received_date" :style="[!$props.is_admin ? {'background': '#D3D3D3'} : {'background': 'null'}]"></datePicker>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="invoice_number">Invoice Number<span class="required_asterisk">*</span></label>
                                            <input type="text" class="form-control" name="invoice_number" id="invoice_number" placeholder="####" v-model="form.invoice_number">
                                        </div>
                                        <div class="form-group">
                                            <label for="comments">Comments</label>
                                            <textarea class="form-control" id="comments" name="comments" placeholder="Comments" rows="5" v-model="form.comments"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="documents">Documents</label>
                                            <input type="file" multiple class="border" name="documents" id="documents" ref="documents">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button :disabled="submit_disabled" type="submit" class="btn btn-primary mr-2" v-on:click="disableButton()">Submit</button>
                        <button v-if="purchase_order.status == 'Active'" type="button" v-on:click="changes_tatus()" class="btn btn-danger mr-2">Close Active PO</button>
                        <a href="/orders" class="btn btn-light">Cancel</a>
                    </div>
                    <div v-if="receiving_serials.length > 0">
                        <div class="card-header">
                        </div>
                        <div class="card-body">
                            <div class="card-title"><p>Received products with serial numbers</p></div>
                            <div class="row">
                                <div class="table-responsive pt-3">
                                    <div>
                                        <table class="table table-bordered table-hover date-columns" id="new-projects">
                                            <thead>
                                            <tr>
                                                <th style="width: 10%;">
                                                    SKU
                                                </th>
                                                <th>
                                                    Product
                                                </th>
                                                <th>
                                                    Serial Number
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="(item,index) in receiving_serials">
                                                <td style="width: 10%;">
                                                    {{item.product_sku}}
                                                </td>
                                                <td style="width: 15%">
                                                    {{item.product_name}}
                                                </td>
                                                <td style="width: 15%">
                                                    {{item.serial_number}}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <receiving-scan-item-modal
            :index = 1
            :products = "form.products"
            :flag = "flag"
            :locations = "locations"
            :zones = "zones"
            :warehouses = "warehouses"
        ></receiving-scan-item-modal>
    </div>
</template>

<script>
import 'bootstrap-datepicker';
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import Swal from 'sweetalert2';
import { EditIcon} from 'vue-feather-icons';
import 'dropify';
import 'dropify/dist/css/dropify.min.css'
import moment from "moment";
import ReceivingScanItemModal from "./ReceivingScanItemModal";

export default {
    name: "PurchaseOrderReceiveForm",
    props:['order','warehouses','zones','locations', 'receiving_serials', 'is_admin'],
    components:{
        ReceivingScanItemModal,
        EditIcon,
        datePicker
    },
    mounted() {
        this.$modal.show('scan-item-modal-'+1);
        $('#documents').dropify();

        this.$props.order.products.forEach(product =>{

            let zone_options = [];
            let location_options = [];
            let zone_id = product[0]

            // if(product.warehouse_id !== "" && product.warehouse_id !== null){
            //
            //     zone_options = this.$props.warehouses.find(warehouse => warehouse.id == product.warehouse_id).zones
            //
            //     if(product.zones.length > 0){
            //         location_options = this.$props.warehouses.find(warehouse => warehouse.id == product.warehouse_id).zones.find(zone => zone.id == product.zones[0].id).locations
            //     }
            // }
            let receiving = this.$props.order.receivings.find( receiving => product.id == receiving.item_id);
            let remaining_qty = product.pivot.quantity;

            if(receiving){
                remaining_qty = product.pivot.quantity - receiving.qty_received;
            }

            this.form.products.push({
                id: product.id,
                name: product.name,
                sku: product.sku,
                upc: product.upc,
                description: product.description,
                order_qty: product.pivot.quantity,
                remaining_qty: remaining_qty,
                qty_received: "0",
                serial_codes: [],
                // warehouse_id: product.warehouse_id,
                // zone_id: product.zones.length > 0 ? product.zones[0].id : "",
                // location_id: product.locations.length > 0 ? product.locations[0].id:"",
                zones_options: zone_options,
                locations_options: location_options,
                category: product.category,
                description_slug: product.description_slug,
                product_identifier: product.product_identifier,
                warehouses_allocation:[],
                warehouses: product.warehouses,
                warehouse_id: 23,
                zone_id: "",
                location_id: "",
                scan_serials: product.use_serials, //true,
                same_warehouse:true,
                use_serials:product.use_serials
            })
        });
    },
    data(){
        return{
            form:{
                products: [],
                received_date: moment().format('MM/DD/YYYY'),
                invoice_number: (this.$props.order.invoice_number !== null) ? this.$props.order.invoice_number : '',
                comments: this.$props.order.comments,
                documents: []
            },
            purchase_order: this.$props.order,
            datePickerOptions:{
                format: 'MM/DD/YYYY',
                useCurrent: false,
                maxDate: new Date()
            },
            moment: moment,
            submit_disabled: false,
            flag:false,
        }
    },
    methods:{
        disableButton(){
            this.submit_disabled = true;
            this.submit();
        },
        submit(){
            let validSerials = true;
            let validQtyReceived = true;
            let diffQty = false;
            let validWarehouseAmount = true;
            let validWarehousefill = true;

            this.form.products.forEach(product=>{
                if(product.remaining_qty > 0){
                    if(product.same_warehouse == false && product.scan_serials == false) {
                        product.qty_received = product.warehouses_allocation.reduce((a, b) => a + (parseInt(b.qty,10) || ''), '')
                    }
                    if((product.use_serials) && (product.qty_received > 0) && (product.serial_codes.length < product.qty_received)){
                        this.submit_disabled = false;
                        validSerials = false;
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'The product '+product.name + ' (' + product.sku + ') does not have enough serials.',
                        });
                    }
                    if(product.serial_codes.length > 0 && product.qty_received !== product.serial_codes.length.toString()){
                        this.submit_disabled = false;
                        validSerials = false;
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'The product '+product.name + ' (' + product.sku + ') does not have enough serials.',
                        });
                    }else if(product.serial_codes.length > 1){
                        const yourArray = [];
                        product.serial_codes.forEach(sc=>{
                            yourArray.push(sc.sn);
                        })
                        const yourArrayWithoutDuplicates = [...new Set(yourArray)]
                        let duplicates = [...yourArray]
                        yourArrayWithoutDuplicates.forEach((item) => {
                            const i = duplicates.indexOf(item)
                            duplicates = duplicates
                                .slice(0, i)
                                .concat(duplicates.slice(i + 1, duplicates.length))
                        })
                        if(duplicates.length > 0){
                            validSerials = false;
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'The product '+product.name + ' (' + product.sku + ') has duplicated serials: '+ duplicates.toString(),
                            });
                        }
                    }
                    else if(product.qty_received === '' || product.qty_received === null){
                        this.submit_disabled = false;
                        validQtyReceived = false;
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Input the quantity received for the product '+product.name + ' (' + product.sku + ').',
                        });
                    }else if (product.qty_received != product.order_qty){
                        diffQty = true
                    }else{
                        let amount = 0;
                        if(product.same_warehouse == false){
                            product.warehouses_allocation.forEach(warehouse =>{
                                amount += parseInt(warehouse.qty,10);
                                if(warehouse.warehouse_id == "" || warehouse.warehouse_id == null){
                                    this.submit_disabled = false;
                                    validWarehousefill = false;
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Oops...',
                                        text: 'You must select a warehouse for every allocation.',
                                    });
                                }
                            })

                            if(product.scan_serials == false){
                                if(amount != product.qty_received){
                                    this.submit_disabled = false;
                                    validWarehouseAmount = false;
                                    Swal.fire({
                                        icon: 'error',
                                        title: 'Oops...',
                                        text: 'Warehouse Allocation quantities does not match with Qty. Received '+product.name + ' (' + product.sku + ').',
                                    });
                                }
                            }
                        }else if(product.same_warehouse){
                            if(product.warehouse_id == "" || product.warehouse_id == null){
                                this.submit_disabled = false;
                                validWarehousefill = false;
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: 'You must select a warehouse.',
                                });
                            }
                        }
                    }
                }
                if(product.same_warehouse == true) {
                    if (product.warehouse_id === '' || product.warehouse_id === null) {
                        this.submit_disabled = false;
                        validWarehousefill = false;
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'You must select a warehouse for ' + product.name + ' (' + product.sku + ').',
                        });
                    }
                }
            })

            if((this.form.comments === null || this.form.comments === '') &&  diffQty){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Comment is required if the received quantity is different from the ordered quantity',
                });
                this.submit_disabled = false;

            }
            else if(this.form.invoice_number === null || this.form.invoice_number === ''){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Invoice Number is required!',
                });
                this.submit_disabled = false;
            }
            else if(validSerials && validQtyReceived && validWarehouseAmount && validWarehousefill){
                let attributes = this.form
                let formData = new FormData();
                for(let i = 0; i < this.$refs.documents.files.length; i++){
                    console.log(this.$refs.documents.files[i]);
                    formData.append('files['+i+']',this.$refs.documents.files[i])
                }

                axios.post('/orders/' + this.purchase_order.id + '/receive/store',attributes).then(response => {
                    let id = response.data.message;
                    axios.post('/orders/'+id+'/documents',formData).then(document_response =>{
                        console.log(response);
                        location = document_response.data.message
                    });
                }).catch(error=>{
                    if(error.response.status === 403){
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'This PO was already added o the inventory',
                        });
                    }
                    this.submit_disabled = false;

                });
            }else{
                this.submit_disabled = false;
            }},
        loadZones(warehouse_id,product){
            product.zones_options = this.$props.warehouses.find(warehouse => warehouse.id == warehouse_id).zones
        },
        loadLocations(warehouse_id,zone_id,product){
            if(!!zone_id){
                product.location_options = this.$props.warehouses.find(warehouse => warehouse.id == warehouse_id).zones.find(zone => zone.id == zone_id).locations
            }
        },
        changes_tatus(){

            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false,
            });

            swalWithBootstrapButtons.fire({
                title: 'Are you sure?',
                text: "This will change the status of the PO to Received. If you have to submit inventory click CANCEL, then SUBMIT. If you want to close this active PO click YES.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonClass: 'ml-2',
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {

                    axios.patch('/orders/' + this.order.id + '/receive_status').then(response =>{
                        if(response.data.error == 404){
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: response.data.message,
                            });
                        }else{
                            location = response.data.message;
                        }
                    }).catch(error =>{
                        if(error.response.status === 403){
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'There was a problem.',
                            });
                        }
                    });
                }
            });
        },
        scanItem(index){
            this.flag = !this.flag;
            this.$modal.show('receiving-scan-item-modal-'+index);
        },
    }
};
</script>

<style scoped>

</style>
