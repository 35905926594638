<template>
    <div>
        <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
                <div class="card-body">
                    <h6 class="card-title">Edit BOM</h6>
                    <form @submit.prevent="submit">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="bom_identifier">BOM ID<span class="required_asterisk">*</span></label>
                                    <input type="text" class="form-control" name="bom_identifier" id="bom_identifier" placeholder="Bom ID" v-model="form.bom_identifier">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="battery_type">Battery Type<span class="required_asterisk">*</span></label>
                                    <dropbox name="battery_type" id="battery_type" :options="this.$props.battery_types" v-model="form.battery_type_id">
                                        <option></option>
                                    </dropbox>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="system_size">System Size<span class="required_asterisk">*</span></label>
                                    <input type="text" class="form-control" name="system_size" id="system_size" placeholder="####" v-model="form.system_size">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="battery_qty">Battery Qty<span class="required_asterisk">*</span></label>
                                    <input type="number" oninput="validity.valid||(value='')" min="0" :disabled="'disabled'" class="form-control" name="battery_qty" id="battery_qty" placeholder="" v-model="form.battery_qty">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <!--                            <div class="col-sm-6">-->
                            <!--                                <div class="form-group">-->
                            <!--                                    <label for="wh_product">Product Sold <span class="required_asterisk">*</span></label>-->
                            <!--                                    <dropbox name="wh_product" id="wh_product" :options="this.$props.wh_products" v-model="form.wh_product_id">-->
                            <!--                                        <option></option>-->
                            <!--                                    </dropbox>-->
                            <!--                                </div>-->
                            <!--                            </div>-->
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="description">Description</label>
                                    <input type="text" class="form-control" name="description" id="description" placeholder="" v-model="form.comment">
                                </div>
                            </div>
                        </div>
                        <br>

                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="category">Category<span class="required_asterisk">*</span></label>
                                    <dropbox name="category" id="category" :options="this.$props.categories" v-model="category_id"  @change="loadProducts()">
                                        <option></option>
                                    </dropbox>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="category">Product<span class="required_asterisk">*</span></label>
                                    <dropbox name="product" id="product" :options="this.product_options" v-model="product_id"  @change="loadWarehouses(), inventoryWarehouse()">
                                        <option></option>
                                    </dropbox>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="quantity">Quantity</label>
                                    <input type="number" oninput="validity.valid||(value='')" min="0" class="form-control" name="quantity" id="quantity" placeholder="" v-model="quantity">
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="category">Warehouse<span class="required_asterisk">*</span></label>
                                    <dropbox name="category" id="warehouse" :options="this.warehouse_options" v-model="warehousename" @change="inventoryWarehouse()">
                                        <option></option>
                                    </dropbox>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="zone">Zone<span class="required_asterisk">*</span></label>
                                    <dropbox name="zone" id="zone" :options="this.zone_options" v-model="zone">
                                        <option></option>
                                    </dropbox>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col-md-4">
                                <button type="button" class="btn btn-primary mr-2" v-on:click="addProduct">Add Product</button>
                            </div>
                        </div>
                        <div class="row col-md-12 mb-4">
                            <table class="table table-bordered table-hover date-columns" id="product-table">
                                <thead>
                                <tr>
                                    <th>
                                        Category
                                    </th>
                                    <th>
                                        Warehouse
                                    </th>
                                    <th>
                                        Zone
                                    </th>
                                    <th>
                                        Product ID
                                    </th>
                                    <th>
                                        Product
                                    </th>
                                    <th>
                                        Description
                                    </th>
                                    <th>
                                        Amount
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <template v-for="(category,index_category) in categories">
                                    <tr>
                                        <td>{{category.name}}</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr v-for="(product,index_product) in form.products.filter(item => item.category.id == category.id)">
                                        <td>
                                            <div class="row justify-content-center">
                                                <div style="cursor: pointer" class="mr-2" v-on:click="editProduct(product.id, product.pivot.warehouse)">
                                                    <edit-icon size="1.5x"></edit-icon>
                                                </div>
                                                <div style="cursor: pointer" v-on:click="deleteProduct(product)">
                                                    <trash2-icon size="1.5x"></trash2-icon>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <dropbox style="width: 100%" :options="product.warehouses" v-model="product.pivot.warehouse_id" v-on:change="updateWarehouse(product)">
                                                <option></option>
                                            </dropbox>

                                            <!--                                            <p v-if="product.pivot.warehouse != null">{{product.pivot.warehouse.name}}</p>-->
                                            <!--                                            <p v-for="(warehouse, index) in product.warehouses.filter(item => item.id == product.warehousename)">{{warehouse.name}}</p>-->
                                        </td>
                                        <td>
                                            <!--                                            <dropbox style="width: 100%" :options="product.zone_options != null ? product.zone_options : []" v-model="product.pivot.zone_id">-->
                                            <!--                                                <option></option>-->
                                            <!--                                            </dropbox>-->
                                            <select style="width: 100%"
                                                    v-on:click="loadZones2(product, product.pivot.warehouse_id)"
                                                    v-model="product.pivot.zone_id">
                                                <option></option>
                                                <option :value="null">None</option>
                                                <option v-for="zone in (product.zone_options!= null ? product.zone_options : [])" :key="zone.id" :value="zone.id">{{zone.name}}</option>
                                            </select>
                                        </td>
                                        <td>{{product.sku}}</td>
                                        <td>{{product.name}}</td>
                                        <td>{{product.description}}</td>
                                        <td>{{product.pivot.quantity}}</td>
                                    </tr>
                                </template>
                                </tbody>
                            </table>
                        </div>

                        <button type="submit" class="btn btn-primary mr-2">Submit</button>
                        <a href="/boms" class="btn btn-light">Cancel</a>
                    </form>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Swal from "sweetalert2";
import { EditIcon, Trash2Icon} from 'vue-feather-icons';

//WMS-Dropdown_Project
export default {
    name: "BomUpdateForm",
    props: ['products','categories','bom','battery_types', 'warehouses'],
    components:{
        EditIcon, Trash2Icon
    },
    beforeMount(){

    },
    mounted(){
        this.countBatteries();
        this.assignZones();
    },
    watch:{
        warehousename: {
            immediate: true,
            handler(newVal, oldVal){
                let warehouse = this.warehouse_options.find(warehouse => warehouse.id == newVal);

                if(warehouse != null){
                    this.zone_options = warehouse.zones;
                }
            }
        }
    },
    data(){
        return {
            form:{
                bom_identifier: this.$props.bom.bom_identifier,
                system_size: this.$props.bom.system_size,
                battery_type: this.$props.battery_types.find(bat => bat.id === this.$props.bom.battery_type.id).name,
                battery_type_id: this.$props.battery_types.find(bat => bat.id === this.$props.bom.battery_type.id).id,
                comment: this.$props.bom.comment,
                products: this.$props.bom.products,
                battery_qty: this.$props.bom.battery_qty,
            },
            battery:this.$props.battery_types.find(bat => bat.id === this.$props.bom.battery_type.id).id,
            product_options:[],
            category_id: "",
            product_id: "",
            quantity: "",
            warehousename:"",
            warehouse_id: "",
            warehouse_options:[],
            zone_options: [],
            zone: "",
            warehouseCount:"",
            newArray:Array(),
            duplicated_product:"",
            isDuplicated:false,
            warehouseError:false

        }
    },
    methods:{
        submit() {
            let battery_object = this.$props.battery_types.find(battery_type => battery_type.id == this.battery);
            this.form.battery_type = battery_object.name;

            this.newArray.splice(0);
            for(let i = 0; i < this.form.products.length; i++){
                console.log(this.form.products[i].pivot.warehouse_id);
                if(this.form.products[i].pivot.warehouse_id === null || this.form.products[i].pivot.warehouse_id === ''){
                    this.warehouseError = true;
                    break;
                }else{
                    this.warehouseError = false;
                }
            }
            if(this.warehouseError !== true) {
                this.form.products.forEach(product => {
                    this.newArray.push(product.id + '-' + product.pivot.warehouse_id.toString());
                })

                this.isDuplicated = false;
                this.findDuplicates();
                if (this.isDuplicated === true) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'A product is duplicated in the BOM list.',
                    });
                }else {
                    axios.patch("/boms/" + this.$props.bom.id, this.form).then(response => {
                        location = response.data.message;
                    })
                }
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'A product does not have selected warehouse.',
                });
            }
        },
        findDuplicates(){
            let duplicates = []
            const tempArray = [...this.newArray].sort()
            for (let i = 0; i < tempArray.length; i++) {
                if (tempArray[i + 1] === tempArray[i]) {
                    this.isDuplicated = true;
                    duplicates.push(tempArray[i])
                }
            }
            this.duplicated_product = duplicates;
        },
        assignZones(){

            this.form.products.forEach(product =>{

                // let warehouse = product.warehouses.find(warehouse => warehouse.id = product.pivot.warehouse_id);
                // product.zone = "";
                //
                // if(warehouse != null){
                //     let zone = warehouse.zones.find(warehouse_zone => warehouse_zone.id == product.pivot.zone_id);
                //     if(zone != null){
                //         // product.zonename = zone.name;
                //         product.zone = zone.id;
                //     }
                // }
                // console.log(product, product.pivot, warehouse);


            })
        },
        loadProducts(){
            if(this.category_id !== ""){
                this.product_options = this.$props.categories.find(category => category.id == this.category_id).products
            }
        },
        loadWarehouses(){
            if(this.category_id !== "" && this.product_id !== ""){
                this.warehouse_options = this.$props.products.find(product => product.id == this.product_id).warehouses
                this.warehousename = this.warehouse_options[0].id;
                this.zone_options = this.warehouse_options[0].zones;
                this.zone = this.warehouse_options[0].id;
                this.$nextTick(() => {
                    this.warehousename = this.warehouse_options[0].id;
                    this.zone_options = this.warehouse_options[0].zones;
                    this.zone = this.warehouse_options[0].id;
                });
            }
        },
        addProduct(){


            let sameProduct = this.form.products.find(product => product.id == this.product_id &&
                (product.warehousename == this.warehousename ||
                    product.pivot.warehousename == this.warehousename ||
                    product.pivot.warehouse == this.warehousename ||
                    product.pivot.warehouse_id == this.warehousename));
            if(sameProduct == null){
                if(this.product_id === ""){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Please select a product.',
                    });

                }else if(this.quantity == null || this.quantity === ''){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Please enter a order quantity greater than 0.',
                    });
                }else if(this.warehousename === ""){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Please select a warehouse.',
                    });
                    // }else if(this.warehouseCount === 0 || this.warehouseCount === "" || this.quantity > this.warehouseCount){
                    //     Swal.fire({
                    //         icon: 'error',
                    //         title: 'Oops...',
                    //         text: 'Warehouse does not contain enough of that product in inventory. Quantity available in this warehouse: '+this.warehouseCount,
                    //     });
                }else{
                    let vm = this;
                    let product = {...this.$props.products.find(product => product.id == this.product_id)};
                    product['pivot'] = {};
                    product.pivot['quantity'] = this.quantity;
                    console.log(product);
                    product.pivot['warehousename'] = this.warehousename;
                    product.pivot['warehouse'] = this.warehousename;
                    product.warehousename = this.warehousename;
                    product.pivot['warehouse_id'] = this.warehousename;
                    product.pivot['zone_id'] = this.zone;
                    product.zone_options = this.zone_options;

                    let zone = this.zone_options.find(zoneO => zoneO.id == this.zone);
                    if(zone != null){
                        product.zonename = zone.name;
                    }else{
                        product.zonename = "";
                    }

                    this.warehouse_id = this.warehousename;

                    if(product.category.name === "Battery"){
                        if(this.form.battery_qty != "" && this.form.battery_qty != null){
                            console.log(this.form.battery_qty)
                            this.form.battery_qty = parseInt(this.form.battery_qty,10)
                            this.form.battery_qty += parseInt(this.quantity,10);
                        }else{
                            this.form.battery_qty = parseInt(this.quantity);
                        }
                    }

                    this.form.products.push(product)
                    this.category_id = "";
                    this.product_id = "";
                    this.quantity = "";
                    this.warehousename="";
                    this.zone = "";
                }
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Product already added. You can edit it in the table'
                })
            }


        },
        editProduct(id, warehouse_id){
            let component = this;
            let product_edit = (this.form.products.find(product => product.id == id && (product.warehousename == warehouse_id || product.pivot['warehouse'] == warehouse_id)));

            Swal.fire({
                title: product_edit.name,
                html:
                    '<div class="form-group">' +
                    '<label for="product_quantity">Order Qty<span class="required_asterisk">*</span></label>'+
                    '<input type="number" oninput="validity.valid||(value=\'\')" min="0" id="product_quantity" class="swal2-input form-control" value="' + product_edit.pivot.quantity + '">'+
                    '</div>'+
                    '<div class="form-group">' +
                    '<label for="product_warehouse">Warehouse<span class="required_asterisk">*</span></label>'+
                    '<select name="warehouseChanger" id="warehouseChanger" class="swal2-input form-control" value=""></select>'+
                    '</div>',
                focusConfirm: false,
                preConfirm() {
                    component.form.battery_qty = parseInt(component.form.battery_qty,10);
                    component.form.battery_qty -= parseInt(product_edit.pivot.quantity,10);
                    product_edit.pivot.quantity = document.getElementById('product_quantity').value;
                    component.form.battery_qty += parseInt(product_edit.pivot.quantity,10);
                }
            })
        },
        deleteProduct(product){
            console.log(product);
            if(product.category.name === "Battery"){
                this.form.battery_qty = parseInt(this.form.battery_qty,10);
                this.form.battery_qty -= parseInt(product.pivot.quantity,10);
            }
            this.form.products.splice(this.form.products.indexOf(product),1);
        },
        countBatteries(){
            this.form.battery_qty = 0;
            this.form.products.forEach(pt => {
                if(pt.category.name === "Battery"){
                    this.form.battery_qty += parseInt(pt.pivot['quantity'],10);
                }
            })

        },
        inventoryWarehouse(){
            let ppp = this.product_id;
            let instance = this;
            axios.get('/api/warehouses/'+ ppp + '/' + this.warehousename).then(function(response) {
                instance.warehouseCount = response.data;
            }).catch(function(error){
                console.log(error);
            });
        },
        updateWarehouse(product){
            console.log("Update Before")
            let warehouse = this.$props.warehouses.find(w => w.id == product.pivot.warehouse_id);

            if(warehouse != null && product.zone_options != null){
                product.zone_options = [...warehouse.zones];
            }else{
                product.zone_options = [];
            }
            console.log("Update After", product.zone_options, warehouse)
        },
        loadZones2(product,whid){
            axios.get('/inventory/products/getZonesQuantity/' + product.id + '/' + whid + '/all_zones').then(function(response) {
                let zones_quantities = response.data;
                product.zone_options.forEach(zone => {
                    let zq = zones_quantities.find(zone_quantity => zone_quantity.id == zone.id);
                    if(zone.name_backup == null){
                        zone.name_backup = zone.name;
                    }
                    if(zq != null){
                        zone.name = zq.name;
                    }else{
                        zone.name = zone.name_backup + ' - ' + '0';
                    }
                });
                // prdid.zone_options = response.data;
            }).catch(function(error){
                console.log(error);
            });
        }
    }
}
</script>

<style scoped>

</style>
