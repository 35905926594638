<template>
    <div class="card">
        <div class="card-body">
            <h6 class="card-title">Inventory Bin to Bin</h6>

            <h6 class="card-subtitle mb-2 text-muted">Transfer Inventory</h6>

            <div class="table-responsive pt-3">
                <table class="table table-bordered table-hover data-table">
                    <thead>
                    <tr>
                        <th>
                            Product
                        </th>
                        <th>
                            SKU
                        </th>
                        <th>
                            Warehouse
                        </th>
                        <th>
                            Zone
                        </th>
                        <th>
                            Location
                        </th>
                        <th>
                            Quantity in Stock
                        </th>
                        <th>
                            Has Serial Numbers?
                        </th>
                        <th>
                            Transfer
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item,index) in this.$props.transfer_inventory">
                        <td>
                            {{item.product_name}}
                        </td>
                        <td>
                            {{item.product_sku}}
                        </td>
                        <td>
                            {{item.warehouse_name}}
                        </td>
                        <td>
                            {{item.zone_name}}
                        </td>
                        <td>
                            {{item.location_name}}
                        </td>
                        <td>
                            {{item.quantity}}
                        </td>
                        <td v-if="item.use_serials > 0">
                            Yes
                        </td>
                        <td v-if="item.use_serials <= 0">
                            No
                        </td>
                        <td v-if="item.use_serials > 0">
                            <button type="button" class="btn btn-primary mr-2" v-on:click="startTransfer(-1, item)"><log-out-icon size="1x" style="color: white"></log-out-icon></button>
                        </td>
                        <td v-if="item.use_serials <= 0">
                            <button type="button" class="btn btn-primary mr-2" v-on:click="startTransfer(0, item)"><log-out-icon size="1x" style="color: white"></log-out-icon></button>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <inventory-transfer-bin-to-bin-modal
                    :index = "0"
                    :item = "item_to_transfer"
                    :warehouses="warehouses"
                    :zones="zones"
                    :locations="locations"
                    :flag = "flag">
                </inventory-transfer-bin-to-bin-modal>
                <inventory-transfer-bin-to-bin-modal
                    :index = "-1"
                    :item = "item_to_transfer"
                    :warehouses="warehouses"
                    :zones="zones"
                    :locations="locations"
                    :flag = "flag">
                </inventory-transfer-bin-to-bin-modal>

            </div>
        </div>
    </div>
</template>
<script>
import Swal from "sweetalert2";
import 'jquery-validation';
import {LogInIcon, LogOutIcon} from 'vue-feather-icons';
import datePicker from 'vue-bootstrap-datetimepicker';
import InventoryTransferBinToBinModal from "./InventoryTransferBinToBinModal";
import axios from "axios";

export default {
    name: "BinToBinComponent",
    props:['transfer_inventory','warehouses', 'zones', 'locations', 'products'],
    components:{
        InventoryTransferBinToBinModal,
        LogOutIcon, LogInIcon, datePicker
    },

    data(){
        return{
            product_ids:[],
            item_to_transfer:Object,
            serial_numbers:[],
            flag:false,
            product_tt:''
        }
    },
    methods:{
        startTransfer(index, item) {
            this.item_to_transfer = item;

            let pid = item.product_id;
            let wh = item.warehouse_id;
            let zn = item.zone_id;
            let ln = item.location_id;

            if(item.use_serials) {
                // axios.get('/bintobin/get_serials/'+ pid + '/'+ wh + '/'+ zn + '/'+ ln).then(response => {
                //     this.serial_numbers = response.data;
                //     this.$forceUpdate();
                // }).catch(error =>{
                //     if(error.response.status === 403){
                //         Swal.fire({
                //             icon: 'error',
                //             title: 'Oops...',
                //             text: 'There was a problem.',
                //         });
                //     }
                // });
            }
            this.flag = !this.flag;
            this.$modal.show('inventory-transfer-bin-to-bin-modal-'+index);
        }
    }
}
</script>

<style scoped>

</style>
