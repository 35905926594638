<template>
    <modal :name="'scan-item-modal-'+index" :scrollable="true" height="auto" width="400px">
        <div class="modal-content border-bottom-0" id="myModal" ref="vuemodal">
            <div class="modal-header">
                <div>
                    <div class="row justify-content-between">
                        <h4 v-if="index === 'tool_scan'">Scan Tool</h4>
                        <h4 v-else class="modal-title">Scan Item</h4>
                    </div>
                </div>
            </div>
            <form @submit.prevent="submit" id="form" name="form" :ref="'form'">
                <div class="modal-body">
                    <div>
                        <input type="text" class="form-control" name="scan_item" id="scan_item" placeholder="XY-###" autofocus v-model="input_scan" v-on:keyup.enter="submit(input_scan, index)">
                        <div v-if="index !== 'tool_scan'">
                            <p v-if="index !== 'return_component'" style="color: red" :hidden="!input_empty">SKU or UPC not found in Purchase Order.</p>
                            <p style="color: red" :hidden="!already_in_list">Product Already added to the list of returns.</p>
                            <p v-if="index === 'return_component'" style="color: red" :hidden="!input_empty">SKU or UPC not found in Dispatched Inventory.</p>
                        </div>
                        <div v-else>
                            <p style="color: red" :hidden="!input_empty">Tool identifier or UPC not found in Purchase Order.</p>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light" @click="$modal.hide('scan-item-modal-'+index), clear_all()">Close</button>
                    <button type="button" class="btn btn-primary mr-2" @click="submit(input_scan, index)">Submit</button>
                </div>
                <receiving-details-modal
                    :product = "scanned_product"
                    :index = "index"
                    :locations = "locations"
                    :zones = "zones"
                    :flag2 = "flag2"
                ></receiving-details-modal>
            </form>
        </div>
        <scan-returns-modal
            :product = "scanned_product"
            :product_returns = "$props.product_returns"
            :flag_return = "flag_return"
            :parent="$props.parent"
            :index="$props.index"
            :returned_list="$props.returned_list"
            :dispatched_record="dispatched_record"
            :zones="zones"
            :locations="locations"
        >
        </scan-returns-modal>
    </modal>
</template>

<script>
import 'jquery-validation';
import ReceivingDetailsModal from "./ReceivingDetailsModal";
import ScanReturnsModal from "./ScanReturnsModal";
import Vue from "vue";

export default {
    name: "ScanItemModal",
    props: ['index', 'products','flag', 'warehouses', 'zones', 'locations', 'product_returns', 'parent', 'returned_list', 'dispatched_record'],
    components:{
        ReceivingDetailsModal,
        ScanReturnsModal
    },
    mounted(){
    },
    data(){
        return {
            input_scan:'',
            input_empty:false,
            scanned_product:'',
            flag2:false,
            flag_return:false,
            already_in_list:false,
            upc_array:Array
        }
    },
    methods:{
        submit(input_scan, index){
            if(typeof(index) !== 'undefined') {
                if (this.input_scan === '') {
                    this.input_empty = true;
                }
                if (index === 'return_component') {
                    if (this.$props.returned_list.length > 0) {
                        for (let a = 0; a < this.$props.returned_list.length; a++) {
                            let match = null;
                            if(this.$props.returned_list[a].upc != null){
                                this.upc_array = this.$props.returned_list[a].upc.split(",");
                                match = this.getMatchingResults(this.upc_array).length > 0;
                            }else{
                                match = this.input_scan === this.$props.returned_list[a].upc;
                            }
                            if (this.input_scan === this.$props.returned_list[a].sku || (match)){
                                this.already_in_list = true;
                                break;
                            }else{
                                this.already_in_list = false;
                            }
                        }
                    }
                    if (this.already_in_list === false) {
                        for (let i = 0; i < this.$props.products.length; i++) {
                            let match = null;
                            if(this.$props.products[i].upc != null){
                                this.upc_array = this.$props.products[i].upc.split(",");
                                match = this.getMatchingResults(this.upc_array).length > 0;
                            }else{
                                match = this.input_scan === this.$props.products[i].upc;
                            }
                            if (this.input_scan === this.$props.products[i].sku || (match)){
                                this.input_empty = false;
                                this.scanned_product = this.$props.products[i];
                                this.flag_return = !this.flag_return;
                                this.$props.parent.filter = this.input_scan;
                                this.$modal.show('scan-returns-modal');
                                break;
                            } else {
                                this.input_empty = true;
                            }
                        }
                    }
                }else if(index === 'tool_scan'){
                    for (let i = 0; i < this.$props.products.length; i++) {
                        let match = null;
                        if(this.$props.products[i].upc != null){
                            this.upc_array = this.$props.products[i].upc.split(",");
                            match = this.getMatchingResults(this.upc_array).length > 0;
                        }else{
                            match = this.input_scan === this.$props.products[i].upc;
                        }
                        if (this.input_scan === this.$props.products[i].tool_identifier || (match)){
                            this.input_empty = false;
                            this.scanned_product = this.$props.products[i];
                            this.flag2 = !this.flag2;
                            this.$modal.show('receiving-details-modal');
                            break;
                        } else {
                            this.input_empty = true;
                        }
                    }
                }else{
                    for (let i = 0; i < this.$props.products.length; i++) {
                        let match = null;
                        if(this.$props.products[i].upc != null){
                            this.upc_array = this.$props.products[i].upc.split(",");
                            match = this.getMatchingResults(this.upc_array).length > 0;
                        }else{
                            match = this.input_scan === this.$props.products[i].upc;
                        }
                        if (this.input_scan === this.$props.products[i].sku || (match)){
                            this.input_empty = false;
                            this.scanned_product = this.$props.products[i];
                            this.flag2 = !this.flag2;
                            this.$modal.show('receiving-details-modal');
                            break;
                        } else {
                            this.input_empty = true;
                        }
                    }
                }
            }
        },
        getMatchingResults(upc_array) {
            return upc_array.filter(d => this.input_scan.split(',').every(v => d.toLowerCase() === v.toLowerCase()));
        },
        clear_all(){
            console.log('Clear')
            this.input_scan = '';
            this.already_in_list = false;
            this.input_empty = false;
            setTimeout(function(){
                document.getElementById("scan_item").focus();
            },0)
        }
    },
    watch: {
        flag: function() {
            this.clear_all();
            this.input_empty = false;
        }
    }
}
</script>

<style scoped>

</style>
