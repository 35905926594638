<template>
    <div>
        <form @submit.prevent="submit" id="warehouse-form" name="warehouse-form" :ref="'warehouse-form'">
            <div class="row">
                <div class="col-md-6 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-body">
                            <h6 class="card-title">New Warehouse</h6>
                            <div class="form-group">
                                <label for="warehouse_id">Warehouse ID<span class="required_asterisk">*</span></label>
                                <input type="text" class="form-control" name="warehouse_id" id="warehouse_id" placeholder="Warehouse ID" value="id" v-model="form.warehouse_id">
                            </div>
                            <div class="form-group">
                                <label for="name">Warehouse Name<span class="required_asterisk">*</span></label>
                                <input type="text" class="form-control" name="name" id="name" placeholder="Warehouse Name" value="name" v-model="form.name">
                            </div>
                            <div class="form-group">
                                <label for="address">Address</label>
                                <input type="text" class="form-control" name="address" id="address" placeholder="Address" value="address" v-model="form.address">
                            </div>
                            <div class="form-group">
                                <label for="supervisor_id">Warehouse Supervisor</label>
                                <select-box name="supervisor_id" id="supervisor_id" :options="this.supervisors" v-model="form.supervisor_id">
                                    <option></option>
                                </select-box>
                            </div>
                            <div class="form-group">
                                <label for="description">Description</label>
                                <textarea class="form-control" id="description" name="description" placeholder="Description" rows="5" v-model="form.description">Description</textarea>
                            </div>
                            <br>
                            <br>
                            <div class="row align-items-center mb-2">
                                <h6 data-toggle="tooltip" data-placement="top" title="Click the plus sign to add doors" class="card-title mb-0">Add Doors</h6>
                                <div v-on:click="addDoor">
                                    <i class="link-icon ml-3" data-feather="plus-square" data-toggle="tooltip" data-placement="top" title="Click to add doors" style="cursor: pointer; width: 24px; height: 24px; fill: none; color:green"></i>
                                </div>

                            </div>

                            <div class="form-group">
                                <label for="doors">Doors</label>
                                <div class="row">
                                    <div v-for="(door,index) in form.doors" class="col-md-6 mb-3">
                                        <div class="align-items-center" style="display: flex; flex-wrap: wrap">
                                            <div class="mr-0 w-75">
                                                <input type="text" class="form-control" style="cursor: text;" v-model="door.name">
                                            </div>
                                            <div class="ml-1" style="height: 100%; cursor: pointer;" v-on:click="deleteDoor(index)">
                                                <x-circle-icon size="1.5x" style="color: red"></x-circle-icon>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <button type="submit" class="btn btn-primary mr-2">Submit</button>
                            <a href="/masterdata/warehouses" class="btn btn-light">Cancel</a>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="row align-items-center mb-3 justify-content-between">
                        <h6 class="card-title mb-0">Add Zones & Locations</h6>
                        <button type="button" v-on:click="addZone" class="btn btn-primary ml-2 mt-0">Add New Zone</button>
                    </div>
                    <div  style="overflow: auto; overflow-x: hidden; height: 80vh" :ref="'zonesDiv'">
                        <div v-for="(zone,index) in form.zones">
                            <div class="row card" :ref="'zoneInfo' + index" style="display: none">
                                <div class="card-body">
                                    <div v-on:click="hideZoneInfo(index)" class="row justify-content-end pr-3">
                                        <chevron-up-icon style="cursor: pointer" size="1.5x"></chevron-up-icon>
                                    </div>
                                    <div class="form-group">
                                        <label :for="'zone'+index">Zone</label>
                                        <input type="text" class="form-control" :name="'zone'+index" :id="'zone'+index" placeholder="Zone" v-model="zone.name">
                                    </div>
                                    <div v-for="(location,location_index) in zone.locations">
                                        <div class="form-group px-4">
                                            <label :for="'location'+location_index">Location {{location_index + 1}}</label>
                                            <div class="row align-items-center">
                                                <div class="col-sm-11">
                                                    <input type="text" class="form-control" :name="'location'+location_index" :id="'location'+location_index" placeholder="Location" v-model="location.name">
                                                </div>
                                                <div class="col-sm-1" v-on:click="deleteLocation(index,location_index)" style="cursor: pointer">
                                                    <x-circle-icon size="1.5x" style="color: red"></x-circle-icon>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <button type="button" v-on:click="addLocation(index)" class="btn btn-primary">Add New Location</button>
                                    <button type="button" v-on:click="deleteZone(index)" class="btn btn-danger">Delete Zone</button>
                                </div>
                            </div>
                            <div class="row" :ref="'zoneCard' + index">
                                <div style="cursor: pointer" v-on:click="showZoneInfo(index)" class="card w-100">
                                    <div class="card-body">
                                        <div class="row justify-content-between align-items-center px-3">
                                            <div>
                                                <div class="card-title">{{zone.name}}</div>
                                                <p>Locations: {{zone.locations.length}}</p>
                                            </div>
                                            <chevron-down-icon size="1.5x"></chevron-down-icon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <!--    profile="{{$profile->id}}"-->
</template>

<script>
    import 'jquery-validation'
    import { XCircleIcon, ChevronDownIcon, ChevronUpIcon} from 'vue-feather-icons';
    import {LogInIcon, LogOutIcon} from 'vue-feather-icons';
    import Swal from "sweetalert2";
    export default {
        name: "WarehouseCreateForm",
        components:{
            XCircleIcon, ChevronDownIcon, ChevronUpIcon
        },
        mounted(){
            $("form[name='warehouse-form']").validate({
                rules: {
                    warehouse_id: {
                        required: true,
                    },
                    name: {
                        required: true,
                        minlength: 3
                    },
                },
                errorPlacement: function(label, element) {
                    label.addClass('mt-2 text-danger');
                    label.insertAfter(element);
                },
                highlight: function(element, errorClass) {
                    $(element).parent().addClass('has-danger')
                    $(element).addClass('form-control-danger')
                },
                unhighlight: function(element, errorClass, validClass) {
                    $(element).parent().removeClass('has-danger')
                    $(element).removeClass('form-control-danger')
                },
                submitHandler: function(form){
                    this.submit()
                }
            });
        },
        props: ['supervisors'],
        data(){
            return {
                form:{
                    warehouse_id: "",
                    name: "",
                    address: "",
                    supervisor_id: "",
                    description: "",
                    zones: [],
                    doors: []
                },
            }
        },
        methods: {
            addZone(){
                this.form.zones.push({"name": 'Zone ' + (this.form.zones.length + 1), "locations": []});
            },
            deleteZone(index){
                let zone = this.form.zones[index];

                this.form.zones.splice(index,1);
            },
            addLocation(index){
                this.form.zones[index].locations.push({"name":""});
            },
            deleteLocation(zoneIndex,locationIndex){
                let zone = this.form.zones[zoneIndex];
                let location = this.form.zones[zoneIndex].locations[locationIndex];

                this.form.zones[zoneIndex].locations.splice(locationIndex,1);
            },
            addDoor(){
                this.form.doors.push({"name":""});
            },
            deleteDoor(index){
                this.form.doors.splice(index,1);
            },
            submit(){
                let attributes = this.form
                axios.post('/masterdata/warehouses/',attributes).then(response => {
                    if(response.status === 200){
                        location = response.data.message
                    }
                }).catch(error=>{
                    if(error.response){
                        console.log(error.response)
                        let validationErrors = [];

                        if(error.response.status === 422){
                            validationErrors = error.response.data.errors;
                            if(validationErrors['warehouse_id']){
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: validationErrors['warehouse_id'][0]
                                })
                            }
                        }else{
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: error,
                            });
                        }
                    }
                });
            },
            setSupervisor(){
                console.log(this.$refs)
            },
            showZoneInfo(index){
                let zonesDivChilds = this.$refs.zonesDiv.children;

                for (let i = 0; i < zonesDivChilds.length; i++){
                    zonesDivChilds[i].children[0].style.display = 'none';
                    zonesDivChilds[i].children[1].style.display = 'block';
                }

                this.$refs['zoneCard'+index][0].style.display = 'none';
                this.$refs['zoneInfo'+index][0].style.display = 'block';
            },
            hideZoneInfo(index){
                this.$refs['zoneCard'+index][0].style.display = 'block';
                this.$refs['zoneInfo'+index][0].style.display = 'none';
            }
        }
    }

</script>

<style scoped>

</style>
