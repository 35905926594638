<template>
    <div>
        <form @submit.prevent="submit" id="receiving-form" name="receiving-form" :ref="'receiving-form'">
            <div class="col-md-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <div class="row justify-content-between">
                            <h3 class="card-title">Receiving PO</h3>
                        </div>
                        <div class="row" style="margin-bottom: 25px">
                            <div class="col-6">
                                <div style="margin-bottom: 20px ">
                                    <div class="row">
                                        <div class="col-lg-4 col-xl-3">
                                            <p class="mr-4 mb-2"><b>PO #:</b></p>
                                        </div>
                                        <div class="col-lg-4 col-xl-6 ml-2 ml-lg-0">
                                            <p class="mb-2">{{purchase_order.po_number}}</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-4 col-xl-3">
                                            <p class="mr-4 mb-2"><b>Supplier:</b></p>
                                        </div>
                                        <div class="col-lg-4 col-xl-6 ml-2 ml-lg-0">
                                            <p class="mb-2">{{purchase_order.supplier.name}}</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-4 col-xl-3">
                                            <p class="mr-4 mb-2"><b>Quote #:</b></p>
                                        </div>
                                        <div class="col-lg-4 col-lg-2 ml-2 ml-lg-0">
                                            <p class="mb-2">{{purchase_order.quote}}</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-4 col-xl-3">
                                            <p class="mr-4 mb-2"><b>Comments:</b></p>
                                        </div>
                                        <div class="col-lg-4 col-xl-6 ml-2 ml-lg-0">
                                            <p class="mb-2">{{purchase_order.comments}}</p>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-lg-4 col-xl-3">
                                            <p class="mr-4 mb-2"><b>Documents:</b></p>
                                        </div>
                                        <div class="col-lg-8 col-xl-9 ml-2 ml-lg-0">
                                            <div v-for="(document,index) in purchase_order.documents">
                                                <p v-if="order.invoice_number !== ''">
                                                    <a v-if="(document.type === 'documentation' && (order.invoice_number !== null))" :href="'/orders/'+purchase_order.id+'/documents/'+document.id" target="_blank">{{order.invoice_number + '_(' + document.created_at.substring(0, 10) + ')_' + index}}</a>
                                                    <a v-else-if="document.type === 'documentation'" :href="'/orders/'+purchase_order.id+'/documents/'+document.id" target="_blank">{{'doc_(' + document.created_at.substring(0, 10) + ')_' + index}}</a>
                                                    <a v-else :href="'/orders/'+purchase_order.id+'/documents/'+document.id" target="_blank">{{document.type}}</a>
                                                </p>
                                                <p v-else>
                                                    <a v-if="document.type === 'documentation'" :href="'/orders/'+purchase_order.id+'/documents/'+document.id" target="_blank">{{'doc_(' + document.created_at.substring(0, 10) + ')_' + index}}</a>
                                                    <a v-else :href="'/orders/'+purchase_order.id+'/documents/'+document.id" target="_blank">{{document.type}}</a>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div style="margin: 0; position: absolute; top: 50%; left: 50%; -ms-transform: translate(-50%, -50%); transform: translate(-50%, -50%);">
                                    <button type="button" class="btn btn-primary" v-on:click="scanItem(tools_index)"><h3>Scan Item</h3></button>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12" style="margin-bottom: 25px">
                                <div v-for="(tool,index) in this.form.tools" :id="'accordion'+tool.id" class="accordion" role="tablist">
                                    <div class="card" >
                                        <div class="card-header" role="tab" :id="'headingOne-' + tool.id">
                                            <h3 class="mb-0">
                                                <a data-toggle="collapse" :href="'#collapseOne-'+ tool.id" aria-expanded="false" :aria-controls="'collapseOne-'+ tool.id">
                                                    <h3>{{tool.tool_identifier}} ({{tool.description}})</h3>
                                                </a>
                                            </h3>
                                        </div>
                                        <div :id="'collapseOne-'+ tool.id" class="collapse" role="tabpanel" :aria-labelledby="'headingOne-' + tool.id" :data-parent="'#accordion'+tool.id">
                                            <div class="card-body">
                                                <div class="row mb-3" >
                                                    <div class="col-md-6">
                                                        <h3 class="card-title">Order Details</h3>
                                                    </div>
                                                    <div class="col-md-6">
                                                    </div>
                                                    <div class="col-md-4 mr-1">
                                                        <div class="row">
                                                            <div class="col-xl-5">
                                                                <p class="mr-4 mb-2"><b>Category: </b></p>
                                                            </div>
                                                            <div class="col-xl-7 ml-2 ml-xl-0">
                                                                <p class="mb-2">{{tool.category.name}}</p>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-xl-5">
                                                                <p class="mr-4 mb-2"><b>Identifier: </b></p>
                                                            </div>
                                                            <div class=" col-xl-7 ml-2 ml-xl-0">
                                                                <p class="mb-2">{{tool.tool_identifier}}</p>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-xl-5">
                                                                <p class="mr-4 mb-2"><b>UPC: </b></p>
                                                            </div>
                                                            <div class=" col-xl-7 ml-2 ml-xl-0">
                                                                <p class="mb-2">{{tool.upc}}</p>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-xl-5">
                                                                <p class="mr-4 mb-2"><b>Brand Name:</b></p>
                                                            </div>
                                                            <div class="col-xl-7 ml-2 ml-xl-0">
                                                                <p class="mb-2">{{tool.brand.name}}</p>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-xl-5">
                                                                <p class="mr-4 mb-2"><b>Model:  </b></p>
                                                            </div>
                                                            <div class="col-xl-7 ml-2 ml-xl-0">
                                                                <p class="mb-2">{{tool.model}}</p>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-xl-5">
                                                                <p class="mr-4 mb-2"><b>Order Qty. </b></p>
                                                            </div>
                                                            <div class="col-xl-7 ml-2 ml-xl-0">
                                                                <p class="mb-2">{{tool.order_qty}}</p>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-xl-5">
                                                                <p class="mr-4 mb-2"><b>Remaining Qty. </b></p>
                                                            </div>
                                                            <div class="col-xl-7 ml-2 ml-xl-0">
                                                                <p class="mb-2">{{tool.remaining_qty}}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 mr-2">
                                                        <div class="row">
                                                            <div class="form-group">
                                                                <label for="order_qty">Qty Received<span class="required_asterisk">*</span></label>
                                                                <div v-if="tool.same_warehouse == false  && tool.scan_serials == false">
                                                                    <p class="mb-2">{{tool.warehouses_allocation.reduce((a, b) => a + (parseInt(b.qty) || 0), 0)}}</p>
                                                                </div>
                                                                <div v-else>
                                                                    <input type="number" oninput="validity.valid||(value='')" min="0" class="form-control" name="tool" id="order_qty" placeholder="####" v-model="tool.qty_received">
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-2">
                                                            <div class="form-check form-check-flat form-check-primary">
                                                                <label class="form-check-label">
                                                                    <input type="checkbox" class="form-check-input" v-model="tool.same_warehouse" >
                                                                    Same Warehouse?
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div class="row mb-2">
                                                            <div class="form-check form-check-flat form-check-primary">
                                                                <label class="form-check-label">
                                                                    <input type="checkbox" class="form-check-input" v-model="tool.scan_serials">
                                                                    Scan Serial Numbers?
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <receiving-warehouse-allocation-component :index="index" :parent="tool" :type="'tool'" :zones="zones" :locations="locations"></receiving-warehouse-allocation-component>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">
                                    <h6 class="card-title">Additional Information</h6>
                                </div>
                                <div class="form-group">
                                    <label for="received_date">Date Received<span class="required_asterisk">*</span></label>
                                    <div class="input-group date datepicker dashboard-date mr-2 mb-2 mb-md-0 d-xl-flex">
                                        <span class="input-group-addon bg-transparent"><i data-feather="calendar" class=" text-primary"></i></span>
                                        <datePicker :disabled="!$props.is_admin" autocomplete="off" v-model="form.received_date" :config="datePickerOptions" name="received_date" :style="[!$props.is_admin ? {'background': '#D3D3D3'} : {'background': 'null'}]"></datePicker>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="invoice_number">Invoice Number</label>
                                            <input type="text" class="form-control" name="invoice_number" id="invoice_number" placeholder="####" v-model="form.invoice_number">
                                        </div>
                                        <div class="form-group">
                                            <label for="comments">Comments</label>
                                            <textarea class="form-control" id="comments" name="comments" placeholder="Comments" rows="5" v-model="form.comments"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="form-group">
                                            <label for="documents">Documents</label>
                                            <input type="file" multiple  class="border" name="documents" id="documents" ref="documents">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button :disabled="submit_disabled" type="submit" class="btn btn-primary mr-2" v-on:click="disableButton()">Submit</button>
                        <button v-if="purchase_order.status == 'Active'" type="button" v-on:click="changes_tatus()" class="btn btn-danger mr-2">Close Active PO</button>
                        <a href="/orders" class="btn btn-light">Cancel</a>
                    </div>
                </div>
            </div>
        </form>
        <receiving-scan-tool-modal
            :index = "tools_index"
            :products = "form.tools"
            :flag = "flag"
            :locations = "locations"
            :zones = "zones"
            :warehouses = "warehouses"
        ></receiving-scan-tool-modal>
    </div>
</template>

<script>
import 'bootstrap-datepicker';
import datePicker from 'vue-bootstrap-datetimepicker';
import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
import Swal from 'sweetalert2';
import { EditIcon} from 'vue-feather-icons';
import 'dropify';
import 'dropify/dist/css/dropify.min.css'
import moment from 'moment';
import ReceivingScanToolModal from "./ReceivingScanToolModal";

export default {
    name: "POReceiveToolForm",
    props:['order','warehouses','zones','locations', 'is_admin'],
    components:{
        ReceivingScanToolModal,
        EditIcon,
        datePicker
    },
    mounted() {
        this.$modal.show('scan-item-modal-'+'tool_scan');

        $('#documents').dropify();
        this.$props.order.tools.forEach(tool =>{

            let zone_options = [];
            let locations_options = [];
            let zone_id = tool[0]

            // if(tool.warehouse_id !== "" && tool.warehouse_id !== null){
            //
            //     zone_options = this.$props.warehouses.find(warehouse => warehouse.id == tool.warehouse_id).zones
            //
            //     if(tool.zones.length > 0){
            //         location_options = this.$props.warehouses.find(warehouse => warehouse.id == tool.warehouse_id).zones.find(zone => zone.id == tool.zones[0].id).locations
            //     }
            // }
            let receiving = this.$props.order.receivings.find( receiving => tool.id == receiving.item_id);
            let remaining_qty = tool.pivot.quantity;

            if(receiving){
                remaining_qty = tool.pivot.quantity - receiving.qty_received;
            }

            this.form.tools.push({
                id: tool.id,
                category: tool.category,
                brand: tool.brand,
                model: tool.model,
                description: tool.description,
                upc: tool.upc,
                tool_identifier: tool.tool_identifier,
                order_qty: tool.pivot.quantity,
                remaining_qty: remaining_qty,
                qty_received: "0",
                serial_codes: [],
                zones_options: [],
                locations_options: [],
                name: tool.description,
                sku: tool.brand.name,
                warehouses_allocation:[],
                warehouses: tool.warehouses,
                warehouse_id:23,
                zone_id: "",
                location_id: "",
                scan_serials:true,
                same_warehouse:true,
                order_price: tool.pivot.price,
            })
        });
    },
    data(){
        return{
            form:{
                tools: [],
                received_date: moment().format('MM/DD/YYYY'),
                invoice_number: "",
                comments: this.$props.order.comments,
                documents: []
            },
            purchase_order: this.$props.order,
            datePickerOptions:{
                format: 'MM/DD/YYYY',
                useCurrent: false,
                maxDate: new Date()
            },
            moment: moment,
            submit_disabled: false,
            flag:false,
            tools_index: 'tool_scan',
        }
    },
    methods:{
        disableButton(){
            this.submit_disabled = true;
            this.submit();
        },
        submit(){
            let validSerials = true;
            let validQtyReceived = true;
            let diffQty = false;
            let validWarehouseAmount = true;
            let validWarehouseFill = true;

            this.form.tools.forEach(tool=>{
                if(tool.serial_codes.length > 0 && tool.qty_received !== tool.serial_codes.length.toString()){
                    this.submit_disabled = false;
                    validSerials = false;
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'The tool '+tool.model + ' (' + tool.brand + ') does not have enough serials.',
                    });
                }else if(tool.qty_received === '' || tool.qty_received === null){
                    this.submit_disabled = false;
                    validQtyReceived = false;
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Input the quantity received for the tool '+tool.model + ' (' + tool.brand + ').',
                    });
                }else if (tool.qty_received != tool.order_qty){
                    diffQty = true
                }else{
                    let amount = 0;
                    if(tool.same_warehouse == false && tool.scan_serials == false){
                        tool.warehouses_allocation.forEach(warehouse =>{
                            amount += parseInt(warehouse.qty,10);
                            if(warehouse.warehouse_id == "" || warehouse.warehouse_id == null){
                                this.submit_disabled = false;
                                validWarehouseFill = false;
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: 'You must select a warehouse for every allocation.',
                                });
                            }
                        })
                        if(amount != tool.qty_received){
                            this.submit_disabled = false;
                            validWarehouseAmount = false;
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'Warehouse Allocation quantities does not match with Qty. Received '+tool.description + '.',
                            });
                        }
                    }else{
                        if(tool.warehouse_id == "" || tool.warehouse_id == null){
                            this.submit_disabled = false;
                            validWarehouseFill = false;
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'You must select a warehouse.',
                            });
                        }
                    }
                }
            })
            if((this.form.comments === null || this.form.comments === '') &&  diffQty){
                this.submit_disabled = false;
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Comment is required if the received quantity is different from the ordered quantity',
                });
            }
            else if(validSerials && validQtyReceived && validWarehouseAmount && validWarehouseFill){
                let attributes = this.form
                let formData = new FormData();
                for(let i = 0; i < this.$refs.documents.files.length; i++){
                    console.log(this.$refs.documents.files[i]);
                    formData.append('files['+i+']',this.$refs.documents.files[i])
                }
                axios.post('/orders/' + this.purchase_order.id + '/receive/store',attributes).then(response => {
                    let id = response.data.message;
                    axios.post('/orders/'+id+'/documents',formData).then(response =>{
                        location = response.data.message
                    });
                }).catch(error=>{
                    if(error.response.status === 403){
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'This PO was already added o the inventory',
                        });
                    }
                });
            }
        },
        loadZones(warehouse_id,tool){
            tool.zones_options = this.$props.warehouses.find(warehouse => warehouse.id == warehouse_id).zones
        },
        loadLocations(warehouse_id,zone_id,tool){
            if(!!zone_id){
                tool.location_options = this.$props.warehouses.find(warehouse => warehouse.id == warehouse_id).zones.find(zone => zone.id == zone_id).locations
            }
        },
        changes_tatus(){
            let attributes = this.form;
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false,
            });

            swalWithBootstrapButtons.fire({
                title: 'Are you sure?',
                text: "This will change the status of the PO to Received. If you have to submit inventory click CANCEL, then SUBMIT. If you want to close this active PO click YES.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonClass: 'ml-2',
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {

                    axios.patch('/orders/' + this.order.id + '/receive_status', attributes).then(response =>{
                        if(response.data.error == 404){
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: response.data.message,
                            });
                        }else{
                            location = response.data.message;
                        }
                    }).catch(error =>{
                        if(error.response.status === 403){
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'There was a problem.',
                            });
                        }
                    });
                }
            });
        },
        scanItem(index){
            this.flag = !this.flag;
            this.$modal.show('receiving-scan-tool-modal-'+index);
        },
    }
}
</script>

<style scoped>

</style>
