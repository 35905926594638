<template>
    <div>
        <div v-if="parent.same_warehouse == true">
            <div class="row">
                <div class="form-group col-md-12">
                    <label for="warehouse_id">Warehouse<span class="required_asterisk">*</span></label>
                    <dropbox :name="index + 'warehouse_id'" :id="index + 'warehouse_id'" :options="parent.warehouses" v-model="parent.warehouse_id" @change="loadZones(parent.warehouse_id)">
                        <option></option>
                    </dropbox>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <label for="zone_id">Zone</label>
                    <dropbox :name="index + 'zone_id'" :id="index + 'zone_id'" :options="parent.zones_options" v-model="parent.zone_id" @change="loadLocations(parent.zone_id)">
                        <option></option>
                    </dropbox>
                </div>
            </div>
            <div class="row">
                <div class="form-group col-md-12">
                    <label for="location_id">Location</label>
                    <dropbox :name="index + 'location_id'" :id="index + 'location_id'" :options="parent.locations_options" v-model="parent.location_id" >
                        <option></option>
                    </dropbox>
                </div>
            </div>
            <div v-if="parent.scan_serials">
                <div class="row">
                    <div class="form-group">
                        <button type="button" class="btn btn-outline-primary" v-on:click="openSerialCodesModal(index)">Scan S/N</button>
                    </div>
                </div>
                <serial-codes-modal :product="parent" :index="index" :quantity="parent.qty_received" :wflag="wflag"></serial-codes-modal>
            </div>
        </div>
        <div v-else-if="parent.same_warehouse == false && parent.scan_serials == true">
            <button type="button" class="btn btn-primary mr-2" v-on:click="addWarehouseSerials()">Add Warehouse</button>
            <trash2-icon class="icon ml-4" style="color: red; cursor: pointer" @click="deleteWarehouseAllocation()"></trash2-icon>
            <div class="border-bottom mt-3" v-for="(item,warehouse_index) in parent.warehouses_allocation" :key="warehouse_index">
                <div class="form-group col-md-12 ml-0 pl-0">
                    <label :for="'warehouse_id'">Warehouse<span class="required_asterisk">*</span></label>
                    <dropbox :name="warehouse_index + 'warehouse_id'" :id="warehouse_index + 'warehouse_id'" :options="parent.warehouses" v-model="item.warehouse_id" @change="loadMultipleWarehouseZones(item)">
                        <option></option>
                    </dropbox>
                </div>
                <div class="form-group col-md-12 ml-0 pl-0">
                    <label :for="'zone_id'">Zone</label>
                    <dropbox :name="warehouse_index + 'zone_id'" :id="warehouse_index + 'zone_id'" :options="item.zones_options" v-model="item.zone_id" @change="loadMultipleWarehouseLocations(item)">
                        <option></option>
                    </dropbox>
                </div>
                <div class="form-group col-md-12 ml-0 pl-0">
                    <label :for="'location_id'">Location</label>
                    <dropbox :name="warehouse_index + 'location_id'" :id="warehouse_index + 'location_id'" :options="item.locations_options" v-model="item.location_id" >
                        <option></option>
                    </dropbox>
                </div>
                <div class="form-group">
                    <button type="button" class="btn btn-outline-primary" @click="$modal.show(warehouse_index+  '-' +parent.name +'-serial-codes-modal')">Scan S/N</button>
                </div>
                <div v-if="parent.scan_serials == true">
                    <warehouse-serial-codes-modal :product="parent" :serials_list="item.serials" :index="warehouse_index" :warehouse="item"></warehouse-serial-codes-modal>
                </div>
            </div>
        </div>
        <div v-else-if="parent.same_warehouse == false && parent.scan_serials == false">
            <button type="button" class="btn btn-primary mr-2" v-on:click="addWarehouse()">Add Warehouse</button>
            <trash2-icon class="icon ml-4" style="color: red; cursor: pointer" @click="deleteWarehouseAllocation()"></trash2-icon>
            <div class="border-bottom mt-3" v-for="(item,warehouse_index) in parent.warehouses_allocation" :key="warehouse_index">
                <div class="form-group col-md-12 ml-0 pl-0">
                    <label for="warehouse_id">Warehouse<span class="required_asterisk">*</span></label>
                    <dropbox :name="warehouse_index + 'warehouse_id'" :id="warehouse_index + 'warehouse_id'" :options="parent.warehouses" v-model="item.warehouse_id" @change="loadMultipleWarehouseZones(item)">
                        <option></option>
                    </dropbox>
                </div>
                <div class="form-group col-md-12 ml-0 pl-0">
                    <label for="zone_id">Zone</label>
                    <dropbox :name="warehouse_index + 'zone_id'" :id="warehouse_index + 'zone_id'" :options="item.zones_options" v-model="item.zone_id" @change="loadMultipleWarehouseLocations(item)">
                        <option></option>
                    </dropbox>
                </div>
                <div class="form-group col-md-12 ml-0 pl-0">
                    <label for="location_id">Location</label>
                    <dropbox :name="warehouse_index + 'location_id'" :id="warehouse_index + 'location_id'" :options="item.locations_options" v-model="item.location_id" >
                        <option></option>
                    </dropbox>
                </div>
                <div class="form-group">
                    <label>Qty. to Warehouse<span class="required_asterisk">*</span></label>
                    <input autocomplete="off" type="number" class="form-control" placeholder="####" v-model="item.qty">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {EditIcon, Trash2Icon} from 'vue-feather-icons';
export default {
    name: "ReceivingWarehouseAllocationComponent",
    props: ['parent','type','index','zones','locations'],
    components:{
        Trash2Icon
    },
    beforeMount() {
        this.loadZones(this.$props.parent.warehouse_id);
    },
    data(){
        return{
            wflag: false
        }
    },
    methods:{
        addWarehouse(){
            this.$forceUpdate();
            this.$props.parent.warehouses_allocation.push({
                'warehouse_id':'',
                'zone_id':'',
                'location_id':'',
                'qty':'',
                'zones_options':[],
                'locations_options':[]
            })
        },
        addWarehouseSerials(){
            this.$forceUpdate();
            this.$props.parent.warehouses_allocation.push({
                'warehouse_id':'',
                'zone_id':'',
                'location_id':'',
                'serials':[],
                'zones_options':[],
                'locations_options':[]
            })
        },
        loadZones(warehouse_id){
            this.$props.parent.zones_options = this.$props.zones.filter(zone => zone.warehouse_id == warehouse_id);
            this.$props.parent.zone_id = '';
            this.$props.parent.location_id = '';
        },
        loadLocations(zone_id){
            this.$props.parent.locations_options = this.$props.locations.filter(location => location.zone_id == zone_id);
            this.$props.parent.location_id = '';
        },
        loadMultipleWarehouseZones(warehouse){
            warehouse.zones_options = this.$props.zones.filter(zone => zone.warehouse_id == warehouse.warehouse_id);
            warehouse.zone_id = '';
            warehouse.location_id = '';
        },
        loadMultipleWarehouseLocations(warehouse){
            warehouse.locations_options = this.$props.locations.filter(location => location.zone_id == warehouse.zone_id);
            warehouse.location_id = '';
        },
        deleteWarehouseAllocation(){
            let warehouse_index = this.$props.parent.warehouses_allocation.length-1;
            this.$props.parent.serial_codes = this.$props.parent.serial_codes.reduce((p,c) => (c.warehouse_index !== warehouse_index && p.push(c),p),[]);
            this.$props.parent.warehouses_allocation.splice(warehouse_index, 1);
        },
        openSerialCodesModal(index){
            this.wflag = !this.wflag;
            this.$modal.show(index+'-serial-codes-modal');
        }
    }
}
</script>

<style scoped>

</style>
