<template>
    <div>
        <button type="button" class="btn btn-primary mr-2" v-on:click="$modal.show('scan-inventory-location-modal'); changeFlag()">Scan Location</button>

        <scan-inventory-location-modal
            :locations="$props.locations"
            :flag="flag">
        </scan-inventory-location-modal>
    </div>
</template>

<script>
import ScanInventoryLocationModal from "./ScanInventoryLocationModal";

export default {
    name: "ScanInventoryLocationButton",
    props: ['locations'],
    components:{
        ScanInventoryLocationModal
    },
    data(){
        return {
            input_scan_location:'',
            flag:false
        }
    },
    methods:{
        changeFlag(){
            this.flag = !this.flag;
        }
    }
}
</script>

<style scoped>

</style>
