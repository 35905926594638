<template>
    <div>
        <form @submit.prevent="submit" name="project">
            <div class="row justify-content-center">
                <div class="col-md-10 grid-margin stretch-card">
                    <div class="card">
                        <div class="card-header">
                            <div class="card-title"><p>Warehouse Information</p></div>
                        </div>
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="col-md-8 ml-0">
                                        <div class="form-group">
                                            <label for="supervisor">Crew Supervisor: <span class="required_asterisk">*</span></label>
                                            <select-box id="supervisor" name="supervisor" :options="supervisors" v-model="form.supervisor_id" ></select-box>
                                        </div>
                                        <div v-for="(zone,index) in form.zones" :key="index">
                                            <div class="form-group">
                                                <label :for="index + '-picker'"
                                                >{{zone.type == "Warehouse" ? "Warehouse: " + zone.name : "Warehouse: " + zone.warehouse.name + " | Zone: " + zone.name  }}<span class="required_asterisk">*</span></label>
                                                <select-user-multiple :id="index + '-picker'" :options="pickers" v-model="zone.pickers" ></select-user-multiple>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="priority">Priority<span class="required_asterisk">*</span></label>
                                        <dropbox id="priority" name="priority" :options="priorities" v-model="form.priority_id" ></dropbox>
                                    </div>
                                    <div class="form-group">
                                        <label for="dock">Waiting Dock</label>
                                        <dropbox-none id="dock" name="dock" :options="docks" v-model="form.waiting_dock_id" ></dropbox-none>
                                    </div>
                                    <div class="form-group">
                                        <label for="priority">Dispatch Door<span class="required_asterisk">*</span></label>
                                        <dropbox-none id="door" name="door" :options="doors" v-model="form.warehouse_door_id" ></dropbox-none>
                                    </div>
                                </div>
                            </div>
                            <div class="spinner-border text-primary my-3" v-if="submit_disabled" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                            <div class="row">
                                <div class="col-md-6">
                                    <button :disabled="submit_disabled" class="btn btn-primary">Submit</button>
                                    <a href="/projects" class="btn btn-light">Cancel</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
    import Swal from "sweetalert2";

    export default {
        name: "ProjectAssignmentForm",
        props: ['project','zones','supervisors','pickers','doors','priorities','docks','has_picking_completed','has_picking_saved'],
        data(){
            return{
                form:{
                    zones: Object.values(this.$props.zones),
                    priority_id: this.$props.project.priority_id,
                    warehouse_door_id: this.$props.project.warehouse_door_id,
                    waiting_dock_id: this.$props.project.waiting_dock_id,
                    supervisor_id: this.$props.project.supervisor_id
                },
                submit_disabled: false,
            }
        },
        mounted(){
            $("form[name='project']").validate({
                ignore: [],
                rules: {
                    priority: {
                        required: true,
                    },
                    supervisor:{
                        required: true
                    }
                },
                errorPlacement: function(label, element) {

                    if(['priority','supervisor_id'].includes(element[0].name)){
                        label.addClass('mt-2 text-danger');
                        label.insertAfter(element.next());
                    }else{
                        label.addClass('mt-2 text-danger');
                        label.insertAfter(element);
                    }
                },
                highlight: function(element, errorClass) {
                    $(element).parent().addClass('has-danger')
                    $(element).addClass('form-control-danger')
                },
                unhighlight: function(element, errorClass, validClass) {
                    $(element).parent().removeClass('has-danger')
                    $(element).removeClass('form-control-danger')
                },
                submitHandler: function(form){
                }
            });
        },
        methods:{
            submit(){
                this.submit_disabled = true;
                let meetsPickerValidation = true;
                let confirmDockandDoor = true;
                let priorityConfirm = true;

                this.form.zones.forEach(zone =>{
                    if(zone.pickers.length <= 0){
                        meetsPickerValidation = false;
                    }
                })

                if(this.form.waiting_dock_id == null && this.form.warehouse_door_id == null) {
                    confirmDockandDoor = false
                }
                if(this.form.waiting_dock_id == 'null' && this.form.warehouse_door_id == 'null') {
                    confirmDockandDoor = false
                }
                if(this.form.waiting_dock_id == 'null' && this.form.warehouse_door_id == null) {
                    confirmDockandDoor = false
                }
                if(this.form.waiting_dock_id == null && this.form.warehouse_door_id == 'null') {
                    confirmDockandDoor = false
                }
                if(this.form.priority == 'null') {
                    priorityConfirm = false
                }

                if(meetsPickerValidation && confirmDockandDoor){

                    if(this.$props.has_picking_completed){
                        const swalWithBootstrapButtons = Swal.mixin({
                            customClass: {
                                confirmButton: 'btn btn-success',
                                cancelButton: 'btn btn-danger'
                            },
                            buttonsStyling: false,
                        });

                        swalWithBootstrapButtons.fire({
                            title: 'Are you sure?',
                            text: "If any inventory was already picked will be returned to inventory and will have to be picked.",
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonClass: 'ml-2',
                            confirmButtonText: 'Yes',
                            cancelButtonText: 'Cancel',
                            reverseButtons: true
                        }).then((result) => {
                            if (result.value) {

                                axios.patch('/projects/' + this.$props.project.id + '/assignment',this.form).then(response =>{
                                    if(response.data.error == 404){
                                        Swal.fire({
                                            icon: 'error',
                                            title: 'Oops...',
                                            text: response.data.message,
                                        });
                                    }else{
                                        location = response.data.message;
                                    }
                                }).catch(error =>{
                                    if(error.response.status === 403){
                                        Swal.fire({
                                            icon: 'error',
                                            title: 'Oops...',
                                            text: 'There was a problem.',
                                        });
                                    }
                                });
                            }
                        });
                    }else if(this.$props.has_picking_saved){
                        const swalWithBootstrapButtons = Swal.mixin({
                            customClass: {
                                confirmButton: 'btn btn-success',
                                cancelButton: 'btn btn-danger'
                            },
                            buttonsStyling: false,
                        });

                        swalWithBootstrapButtons.fire({
                            title: 'Are you sure?',
                            text: "If any inventory was already saved at picking it will be returned to inventory and will have to be picked.",
                            icon: 'warning',
                            showCancelButton: true,
                            confirmButtonClass: 'ml-2',
                            confirmButtonText: 'Yes',
                            cancelButtonText: 'Cancel',
                            reverseButtons: true
                        }).then((result) => {
                            if (result.value) {

                                axios.patch('/projects/' + this.$props.project.id + '/assignment',this.form).then(response =>{
                                    if(response.data.error == 404){
                                        Swal.fire({
                                            icon: 'error',
                                            title: 'Oops...',
                                            text: response.data.message,
                                        });
                                    }else{
                                        location = response.data.message;
                                    }
                                }).catch(error =>{
                                    if(error.response.status === 403){
                                        Swal.fire({
                                            icon: 'error',
                                            title: 'Oops...',
                                            text: 'There was a problem.',
                                        });
                                    }
                                });
                            }
                        });
                    }else{
                        let vm = this;
                        axios.patch('/projects/' + this.$props.project.id + '/assignment',this.form).then(response =>{
                            if(response.data.error == 404){
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: response.data.message,
                                });

                                vm.submit_disabled = false;
                            }else{
                                location = response.data.message;

                            }
                        }).catch(error =>{
                            if(error.response.status === 403){
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: 'There was a problem.',
                                });
                            }

                            vm.submit_disabled = false;
                        });
                    }
                }else if(!meetsPickerValidation){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'You need to select at least one picker per zone.',
                    });
                }else if(!confirmDockandDoor){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'You must select a Dispatch door or Dock to continue',
                    });
                }else if(!priorityConfirm){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Select a priority',
                    });
                }
            }
        }
    }
</script>

<style scoped>

</style>
