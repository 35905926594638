<template>
    <modal :name="'phase2-select-location-modal'" height="auto" :scrollable="true">
        <div class="modal-content border-bottom-0 h-100">
            <div class="modal-header">
                <div>
                    <div class="row justify-content-between">
                        <h5 class="modal-title">Select Warehouse, Zone and Location</h5>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label for="warehouse">Warehouse<span class="required_asterisk">*</span></label>
                    <dropbox :options="warehouses" v-model="warehouse">
                        <option></option>
                    </dropbox>
                </div>
                <div class="form-group">
                    <label for="zone">Zone<span class="required_asterisk">*</span></label>
                    <dropbox :options="zone_options" v-model="zone">
                        <option></option>
                    </dropbox>
                </div>
                <div class="form-group">
                    <label for="location">Location<span class="required_asterisk">*</span></label>
                    <dropbox :options="location_options" v-model="location">
                        <option></option>
                    </dropbox>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light" @click="$modal.hide('phase2-select-location-modal')">Close</button>
                <button type="button" class="btn btn-primary mr-2" @click="submit">Submit</button>
            </div>
        </div>
    </modal>
</template>

<script>
import Swal from "sweetalert2";
export default {
    name: "Phase2SelectLocationModal",
    props: ['detail', 'warehouses', 'locations', 'zones', 'products'],
    watch:{
        warehouse: {
            immediate: true,
            deep: true,
            handler(newVal, oldVal){
                this.zone_options = this.zones.filter(z => z.warehouse_id == newVal);
            }
        },
        zone:{
            immediate: true,
            deep: true,
            handler(newVal, oldVal){
                this.location_options = this.locations.filter(l => l.zone_id == newVal);
            }
        }
    },
    data(){
        return{
            warehouse: "",
            warehouse_options:[],
            zone: "",
            zone_options: [],
            location: "",
            location_options: [],
            warehouse_object: {},
            zone_object: {}
        }
    },
    methods: {
        submit() {
            if (this.warehouse == null || this.zone == null || this.location == null || this.warehouse == '' || this.zone == '' || this.location == '') {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Please, select warehouse, zone and location.'
                });
            } else {
                let attributes = {
                    warehouse_id: this.warehouse,
                    zone_id: this.zone,
                    location_id: this.location,
                    detail: this.$props.detail
                }
                axios.post('/change_location_phase2', attributes).then(response => {
                    this.$props.detail.warehouse_id = this.warehouse;
                    this.$props.detail.zone_id = this.zone;
                    this.$props.detail.location_id = this.location;
                    this.$props.detail.warehouse = response.data.warehouse;
                    this.$props.detail.zone = response.data.zone;
                    this.$props.detail.location = response.data.location;
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'Location Updated'
                    });
                    this.$modal.hide('phase2-select-location-modal');
                }).catch(error => {
                    if (error.response) {
                        console.log(error.response)
                        let validationErrors = [];
                        if (error.response.status === 422) {
                            validationErrors = error.response.data.errors;
                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: error,
                            });
                        }
                    }
                });
            }
        }
    }
}
</script>

<style scoped>

</style>
