<template>
    <div class="card">
        <div class="card-body">
            <h6 class="card-title">Transfer Order Receiving</h6>
            <h6 class="card-subtitle mb-2 text-muted">Transfer Orders Pending to Receive</h6>
            <div class="table-responsive pt-3 mb-12">
                <table class="table table-bordered table-hover data-table">
                    <thead>
                    <tr>
                        <th>
                            Transfer Order Number
                        </th>
                        <th>
                            Picked By
                        </th>
                        <th>
                            Total Transfers
                        </th>
                        <th>
                            Picked Date
                        </th>
                        <th>
                            Status
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(transfer,index) in this.picked_transfer_orders">
                        <td>
                            <p v-on:click="edit_receiving(transfer.id)" style="color: blue; cursor: pointer">{{transfer.transfer_order_number}}</p>
                        </td>
                        <td>
                            {{users.find(user => user.id == transfer.picked_by).first_name + ' ' + users.find(user => user.id == transfer.picked_by).last_name}}
                        </td>
                        <td>
                            {{transfer_order_inventory.filter(toi => toi.transfer_order_id == transfer.id).length}}
                        </td>
                        <td>
                            {{transfer.picked_date}}
                        </td>
                        <td>
                            <p style="color: dodgerblue">{{transfer.status}}</p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="card-body">
            <h6 class="card-title">Transfer Order Receiving History</h6>
            <h6 class="card-subtitle mb-2 text-muted">Transfer Orders Completed</h6>
            <div class="table-responsive pt-3 mb-12">
                <table class="table table-bordered table-hover data-table">
                    <thead>
                    <tr>
                        <th>
                            Transfer Order Number
                        </th>
                        <th>
                            Received By
                        </th>
                        <th>
                            Total Transfers
                        </th>
                        <th>
                            Completed Date
                        </th>
                        <th>
                            Status
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(transfer,index) in this.completed_transfer_orders">
                        <td>
                            <p v-on:click="view_completed(transfer.id)" style="color: blue; cursor: pointer">{{transfer.transfer_order_number}}</p>
                        </td>
                        <td>
                            {{users.find(user => user.id == transfer.received_by).first_name + ' ' + users.find(user => user.id == transfer.received_by).last_name}}
                        </td>
                        <td>
                            {{transfer_order_inventory.filter(toi => toi.transfer_order_id == transfer.id).length}}
                        </td>
                        <td>
                            {{transfer.received_date}}
                        </td>
                        <td>
                            <p style="color: mediumseagreen">{{transfer.status}}</p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import 'jquery-validation';
import {LogInIcon, LogOutIcon} from 'vue-feather-icons';

export default {
    name: "ReceivingTransferOrderComponent",
    props:['warehouses', 'zones', 'locations', 'products', 'is_transfer', 'picked_transfer_orders', 'users', 'transfer_order_inventory', 'completed_transfer_orders'],
    components:{
        LogOutIcon, LogInIcon
    },
    data(){
        return{

        }
    },
    mounted() {
    },
    methods:{
        edit_receiving(transfer_id){
            window.location = "/edit_receiving_transfer_order/" + transfer_id;
        },
        view_completed(transfer_id){
            window.location = "/view_completed_transfer_order/" + transfer_id;
        }
    }
}
</script>

<style scoped>

</style>
