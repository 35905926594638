<template>
    <modal :name="'receiving-scan-item-modal-'+index" :scrollable="true" height="auto" width="400px">
        <div class="modal-content border-bottom-0" id="myModal" ref="vuemodal">
            <div class="modal-header">
                <div>
                    <div class="row justify-content-between">
                        <h4 v-if="modal_type === 'scan_item_tool'">Scan Tool</h4>
                        <h4 v-if="modal_type === 'scan_item_product'">Scan Item</h4>
                        <h4 v-if="modal_type === 'input_qty'">Receiving Details</h4>
                        <h4 v-if="modal_type === 'scan_location'">Scan Location</h4>
                        <h4 v-if="modal_type === 'scan_serial_codes'">Scan Serial Numbers</h4>
                    </div>
                </div>
            </div>
            <div v-if="modal_type === 'scan_item_product'">
                <form @submit.prevent="submit" id="form" name="form" :ref="'form'">
                    <div class="modal-body justify-content-between">
                        <div style="text-align: center; text-align-all: center; align-self: center; align-content: center; align-items: center">
                            <input type="text" class="form-control-lg text-center" name="scan_item" id="scan_item" placeholder="Scan Item" autofocus v-model="input_scan" v-on:keyup.enter="submit(input_scan, index)">
                            <div v-if="index !== 'tool_scan'">
                                <p v-if="index !== 'return_component'" style="color: red" :hidden="!input_empty">SKU or UPC not found in Purchase Order.</p>
                                <p style="color: red" :hidden="!already_in_list">Product Already added to the list of returns.</p>
                                <p v-if="index === 'return_component'" style="color: red" :hidden="!input_empty">SKU or UPC not found in Dispatched Inventory.</p>
                            </div>
                            <div v-else>
                                <p style="color: red" :hidden="!input_empty">Tool identifier or UPC not found in Purchase Order.</p>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" @click="$modal.hide('receiving-scan-item-modal-'+index), clear_all()">Close</button>
                        <button type="button" class="btn btn-primary mr-2" @click="submit(input_scan, index)">Submit</button>
                    </div>
                </form>
            </div>
            <div v-if="modal_type === 'input_qty'">
                <form @submit.prevent="submit_qty" id="form2" name="form2" :ref="'form2'">
                    <div class="modal-body">
                        <div style="margin-bottom: 20px">
                            <h5 style="color: #f89b24" v-if="index === 'tool_scan'">Tool Name: {{scanned_product.name}}</h5>
                            <h5 v-else style="color: #f89b24">{{scanned_product.sku}} ({{scanned_product.name}})</h5>
                            <p class="text"><b>Order Qty:</b> {{scanned_product.order_qty}} / <b>Remaining Qty:</b> {{scanned_product.remaining_qty}}</p>
                        </div>
                        <div style="text-align: center; text-align-all: center; align-self: center; align-content: center; align-items: center; margin-top: 4px">
                            <div class="col-md-12 justify-content-between">
                                <div class="row-md-12 justify-content-between" style="align-items: center">
                                    <input disabled autofocus oninput="validity.valid||(value='')" min="0" type="number" class="text-center form-control-lg mb-2" name="order_qty1" id="order_qty1"  placeholder="Qty. Received" v-model="qty_received">
                                </div>
                                <div class="row-md-12 justify-content-between" style="align-items: center">
                                    <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + 1;">1</button>
                                    <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + 2;">2</button>
                                    <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + 3;">3</button>
                                </div>
                                <div class="row-md-12 justify-content-between" style="align-items: center">
                                    <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '4';">4</button>
                                    <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '5';">5</button>
                                    <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '6';">6</button>
                                </div>
                                <div class="row-md-12 justify-content-between" style="align-items: center">
                                    <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '7';">7</button>
                                    <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '8';">8</button>
                                    <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '9';">9</button>
                                </div>
                                <div class="row-md-12 justify-content-between" style="align-items: center">
                                    <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=null">C</button>
                                    <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '0';">0</button>
                                    <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value.slice(0,-1);">&lt;</button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p v-if="qty_error" style="color: red">Please, input a valid quantity value.</p>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" @click="$modal.hide('receiving-scan-item-modal-'+index), clear_all()">Close</button>
                        <button type="button" class="btn btn-primary mr-2" v-on:click="submit_qty">Submit</button>
                    </div>
                </form>
            </div>
            <div v-if="modal_type === 'scan_location'">
                <form @submit.prevent="submit_location" id="form3" name="form3" :ref="'form3'">
                    <div class="modal-body justify-content-between">
                        <div style="margin-bottom: 20px">
                            <h5 style="color: #f89b24" v-if="index === 'tool_scan'">Tool Name: {{scanned_product.name}}</h5>
                            <h5 v-else style="color: #f89b24">{{scanned_product.sku}} ({{scanned_product.name}})</h5>
                        </div>
                        <div style="text-align: center; text-align-all: center; align-self: center; align-content: center; align-items: center; margin-top: 4px">
                            <div>
                                <input v-if="scanned_product.same_warehouse" type="text" class="form-control-lg text-center" name="scan_location" id="scan_location" placeholder="Location" autofocus v-model="input_scan_location" v-on:keyup.enter="submit_location(input_scan_location, index)">
                                <input v-if="!scanned_product.same_warehouse && (scanned_product.use_serials)" type="text" class="form-control-lg text-center" name="scan_location" id="scan_location" placeholder="Location" autofocus v-model="input_scan_location" v-on:keyup.enter="submit_location_dif_whs(input_scan_location, index)">
                                <input v-if="!scanned_product.same_warehouse && (!scanned_product.use_serials)" type="text" class="form-control-lg text-center" name="scan_location" id="scan_location" placeholder="Location" autofocus v-model="input_scan_location" v-on:keyup.enter="">
                                <p style="color: red" :hidden="!input_empty_location">Location not found for scanned item.</p>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" @click="$modal.hide('receiving-scan-item-modal-'+index), clear_all()">Close</button>
                        <button v-if="scanned_product.same_warehouse" type="button" class="btn btn-primary mr-2" @click="submit_location(input_scan_location, index)">Submit</button>
                        <button v-if="!scanned_product.same_warehouse && (scanned_product.use_serials)" type="button" class="btn btn-primary mr-2" @click="submit_location_dif_whs(input_scan_location, index)">Submit</button>
                        <button v-if="!scanned_product.same_warehouse && (!scanned_product.use_serials)" type="button" class="btn btn-primary mr-2" @click="submit_location_dif_whs(input_scan_location, index); next_item = false">Add Next Location</button>
                        <button v-if="!scanned_product.same_warehouse && (!scanned_product.use_serials)" type="button" class="btn btn-outline-primary mr-2" @click="submit_location_dif_whs(input_scan_location, index); next_item = true">Scan Next Item</button>
                    </div>
                </form>
            </div>
            <div v-if="modal_type === 'scan_serial_codes'">
                <div class="modal-header justify-content-between">
                    <div>
                        <div class="row">
                            <h5 class="modal-title" style="color: #f89b24">{{scanned_product.sku + ' ('+scanned_product.name+')'}}</h5>
                        </div>
                        <div class="row">
                            <p class="text-right"><b>Serials:</b> {{scanned_product.serial_codes.length}}/{{scanned_product.qty_received === '' ? '0' : scanned_product.qty_received}}</p>
                        </div>
                        <div class="row">
                            <p v-if="item_warehouse !== ''"><b>WHS: </b>{{item_warehouse}} / <b>ZN: </b>{{item_zone}} / <b>LOC: </b>{{item_location}}</p>
                        </div>
                        <div class="mt-3 row">
                            <h6 class="mr-2 p-2" v-on:click="changeScreen('input')" v-bind:class="{'modal-serial-selected': isInput}" style="cursor: pointer">Input Serial Number</h6>
                            <h6 class="p-2" v-on:click="changeScreen('list')" v-bind:class="{'modal-serial-selected':isList}" style="cursor: pointer">List of Serial Numbers</h6>
                        </div>
                    </div>
                </div>
                <form @submit.prevent="continueScan" id="form4" name="form4" :ref="'form4'">
                    <div class="modal-body">
                        <div class="form-group" v-if="screen == 'input'">
                            <div v-if="scanned_product.same_warehouse">
                                <textarea autofocus autocomplete="off" v-on:keydown.enter.prevent='addSerial' type="text" rows="4" class="form-control" name="serial_number" id="serial_number" placeholder="Input Serial Numbers" v-model="serial"></textarea>
                                <button type="button" class="btn btn-outline-primary mt-2" v-if="screen === 'input'" v-on:click="addSerial">Add S/N</button>
                            </div>
                            <div v-else-if="!scanned_product.same_warehouse">
                                <textarea autofocus autocomplete="off" v-on:keydown.enter.prevent='addSerial_dif_whs' type="text" rows="4" class="form-control" name="serial_number1" id="serial_number1" placeholder="Input Serial Numbers" v-model="serial"></textarea>
                                <button type="button" class="btn btn-outline-primary mt-2" v-if="screen === 'input'" v-on:click="addSerial_dif_whs">Add S/N</button>
                            </div>
                            <div class=" mt-4 alert alert-warning" role="alert">You can enter multiple serial numbers separated by comma.</div>
                        </div>
                        <div v-if="screen == 'list'">
                            <div class="table-responsive pt-3">
                                <table class="table table-bordered table-hover date-columns">
                                    <thead>
                                    <tr>
                                        <th>
                                            Serial Codes
                                        </th>
                                        <th>
                                            In Stock
                                        </th>
                                        <th>
                                            Action
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody v-if="scanned_product.same_warehouse">
                                    <tr v-for="(serial,index) in scanned_product.serial_codes">
                                        <td>{{serial.sn}}</td>
                                        <td>
                                            <div v-for="(valuer, i) in serialsInventoryCodes">
                                                <p v-if="serial.sn == valuer.serial_code">Yes</p>
                                            </div>
                                        </td>
                                        <td style="color: red"><p style="cursor: pointer;" v-on:click="deleteSerial(index)">Delete</p></td>
                                    </tr>
                                    </tbody>
                                    <tbody v-else-if="!scanned_product.same_warehouse">
                                    <tr  v-for="(serial,index) in scanned_product.serial_codes.filter(serial_item => serial_item.warehouse_index == (scanned_product.warehouses_allocation.length -1))">
                                        <td>{{serial.sn}}</td>
                                        <td>
                                            <div v-for="(valuer, i) in serialsInventoryCodes">
                                                <p v-if="serial.sn == valuer.serial_code">Yes</p>
                                            </div>
                                        </td>
                                        <td style="color: red"><p style="cursor: pointer;" v-on:click="deleteSerial_dif_whs(serial.sn)">Delete</p></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer" v-if="scanned_product.same_warehouse">
                        <p style="color: red" :hidden="!incomplete_serials">Please, input all received serial numbers for this item.</p>
                        <button type="button" class="btn btn-light" @click="$modal.hide('receiving-scan-item-modal-'+index), clear_all()">Close</button>
                        <button type="button" class="btn btn-primary mr-2" v-if="screen === 'input'" v-on:click="continueScan">Scan New Item</button>
                    </div>
                    <div class="modal-footer" v-else-if="!scanned_product.same_warehouse">
                        <p style="color: red" :hidden="!incomplete_serials">Please, input all received serial numbers for this item.</p>
                        <button type="button" class="btn btn-light" @click="$modal.hide('receiving-scan-item-modal-'+index), clear_all()">Close</button>
                        <button type="button" class="btn btn-outline-primary mr-2" v-if="screen === 'input' && (scanned_product.serial_codes.length < scanned_product.qty_received)" v-on:click="continueScan">Add Next Location</button>
                        <button type="button" class="btn btn-outline-primary mr-2" v-if="screen === 'input' && (scanned_product.serial_codes.length == scanned_product.qty_received)" v-on:click="continueScan">Scan Next Item</button>
                    </div>
                </form>
            </div>
        </div>
    </modal>
</template>

<script>
import 'jquery-validation';
import ReceivingDetailsModal from "./ReceivingDetailsModal";
import ScanReturnsModal from "./ScanReturnsModal";
import Vue from "vue";
import {trim} from "lodash/string";
import Swal from "sweetalert2";
import axios from "axios";

export default {
    name: "ReceivingScanItemModal",
    props: ['index', 'products','flag', 'warehouses', 'zones', 'locations', 'product_returns', 'parent', 'returned_list', 'dispatched_record'],
    components:{
        ReceivingDetailsModal,
        ScanReturnsModal
    },
    mounted(){
        this.modal_type = 'scan_item_product';
    },
    data(){
        return {
            input_scan:'',
            input_empty:false,
            scanned_product:'',
            flag2:false,
            flag_return:false,
            already_in_list:false,
            upc_array:Array,
            serial_index:'',
            flag1:false,
            qty_received:'',
            qty_error:false,
            input_scan_location:'',
            input_empty_location:false,
            scanned_location:'',
            is_scan_location:false,
            modal_type:'',
            item_warehouse:'',
            item_zone:'',
            item_location:'',
            readerSize: {width: 100, height: 100},
            readerTypes:
                [
                    'code_128_reader',
                    'ean_reader',
                    'ean_8_reader',
                    'code_39_reader',
                    'code_39_vin_reader',
                    'codabar_reader',
                    'upc_reader',
                    'upc_e_reader',
                    'i2of5_reader',
                    '2of5_reader',
                    'code_93_reader'
                ],
            screen:"input",
            isInput:true,
            isList:false,
            isScan:false,
            serial: "",
            serialsInventoryCodes:[],
            incomplete_serials:false,
            next_item:false,
        }
    },
    methods:{
        submit(input_scan, index){
            this.input_scan = trim(this.input_scan);
            if(typeof(index) !== 'undefined') {
                if (this.input_scan === '') {
                    this.input_empty = true;
                }
                for (let i = 0; i < this.$props.products.length; i++) {
                    let match = null;
                    if(this.$props.products[i].upc != null){
                        this.upc_array = this.$props.products[i].upc.split(",");
                        match = this.getMatchingResults(this.upc_array).length > 0;
                    }else{
                        match = this.input_scan === this.$props.products[i].upc;
                    }
                    if (this.input_scan.toLowerCase() === this.$props.products[i].sku.toLowerCase() || (match)){
                        this.input_empty = false;
                        this.scanned_product = this.$props.products[i];
                        this.serial_index = i;
                        this.serialsInventory();
                        const swalWithBootstrapButtons = Swal.mixin({
                            customClass: {
                                confirmButton: 'btn btn-success',
                                cancelButton: 'btn btn-danger'
                            },
                            buttonsStyling: false,
                        })
                        swalWithBootstrapButtons.fire({
                            title: 'Same Warehouse?',
                            text: "",
                            icon: 'question',
                            showCancelButton: true,
                            cancelButtonClass: 'ml-4',
                            confirmButtonText: 'Yes',
                            cancelButtonText: 'No',
                            reverseButtons: false
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.$props.products[i].same_warehouse = true;
                                this.modal_type = 'input_qty'
                            }else{
                                if(result.dismiss == "backdrop"){
                                    console.log('BACKDROP');
                                }else{
                                    this.$props.products[i].same_warehouse = false;
                                    if(this.scanned_product.use_serials){
                                        this.modal_type = 'input_qty'
                                    } else{
                                        this.modal_type = 'input_qty'
                                        this.addWarehouse();
                                    }
                                }
                            }
                        });
                        break;
                    } else {
                        this.input_empty = true;
                    }
                }
            }
        },
        submit_qty(){
            if (document.getElementById('order_qty1').value === '') {
                this.qty_error = true;
            } else{
                this.scanned_product.serial_codes.splice(0);
                this.incomplete_serials = false;
                this.scanned_product.qty_received = document.getElementById('order_qty1').value;
                this.qty_error = false;
                if(this.scanned_product.same_warehouse) {
                    this.modal_type = 'scan_location';
                    setTimeout(function () {
                        document.getElementById("scan_location").focus();
                    }, 0)
                }else{

                    if(this.scanned_product.use_serials){
                        this.modal_type = 'scan_location';
                        this.addWarehouseSerials();
                        setTimeout(function () {
                            document.getElementById("scan_location").focus();
                        }, 0)
                    }else{
                        this.scanned_product.warehouses_allocation[this.scanned_product.warehouses_allocation.length-1].qty = document.getElementById('order_qty1').value;
                        this.modal_type = 'scan_location';
                        setTimeout(function () {
                            document.getElementById("scan_location").focus();
                        }, 0)
                    }
                }
            }
        },
        submit_location(input_scan_location, index){
            if(this.input_scan_location === ''){
                this.input_empty_location = false;
            }
            if(typeof(index) !== 'undefined') {
                for (let i = 0; i < this.$props.locations.length; i++) {
                    if (this.input_scan_location.toLowerCase() === this.$props.locations[i].name.toLowerCase()) {
                        for (let j = 0; j < this.$props.zones.length; j++) {
                            if (this.$props.locations[i].zone_id === this.$props.zones[j].id) {
                                for (let k = 0; k < this.scanned_product.warehouses.length; k++) {
                                    if (this.$props.zones[j].warehouse_id === this.scanned_product.warehouses[k].id) {
                                        this.scanned_product.warehouse_id = this.scanned_product.warehouses[k].id;
                                        let zone_id = this.$props.zones[j].id;
                                        let location_id = this.$props.locations[i].id;
                                        this.select_zone(zone_id, location_id, index);
                                        this.input_empty_location = false;
                                        break;
                                    } else {
                                        this.input_empty_location = true;
                                    }
                                }
                                break;
                            } else {
                                this.input_empty_location = true;
                            }
                        }
                        break;
                    } else {
                        this.input_empty_location = true;
                    }
                }
            }
        },
        submit_location_dif_whs(input_scan_location, index){
            if(this.input_scan_location === ''){
                this.input_empty_location = false;
            }
            if(typeof(index) !== 'undefined') {
                for (let i = 0; i < this.$props.locations.length; i++) {
                    if (this.input_scan_location.toLowerCase() === this.$props.locations[i].name.toLowerCase()) {
                        for (let j = 0; j < this.$props.zones.length; j++) {
                            if (this.$props.locations[i].zone_id === this.$props.zones[j].id) {
                                for (let k = 0; k < this.scanned_product.warehouses.length; k++) {
                                    if (this.$props.zones[j].warehouse_id === this.scanned_product.warehouses[k].id) {
                                        this.scanned_product.warehouses_allocation[this.scanned_product.warehouses_allocation.length - 1].warehouse_id = this.scanned_product.warehouses[k].id;
                                        let zone_id = this.$props.zones[j].id;
                                        let location_id = this.$props.locations[i].id;
                                        this.select_zone_dif_whs(zone_id, location_id, index);
                                        this.input_empty_location = false;
                                        break;
                                    } else {
                                        this.input_empty_location = true;
                                    }
                                }
                                break;
                            } else {
                                this.input_empty_location = true;
                            }
                        }
                        break;
                    } else {
                        this.input_empty_location = true;
                    }
                }
            }
        },
        select_zone(zone_id, location_id, index){
            setTimeout(() =>  {
                this.scanned_product.zone_id = zone_id;
                this.select_location(location_id, index);
            }, 5);
        },
        select_zone_dif_whs(zone_id, location_id, index){
            setTimeout(() =>  {
                this.scanned_product.warehouses_allocation[this.scanned_product.warehouses_allocation.length -1].zone_id = zone_id;
                this.select_location_dif_whs(location_id, index);
            }, 5);
        },
        select_location(location_id, index){
            setTimeout(() =>  {
                this.scanned_product.location_id = location_id;
                if(this.scanned_product.use_serials) {
                    document.getElementById("serial_number").focus();
                }
            },5);
            this.input_scan_location = '';
            this.input_empty_location = false;
            if(this.scanned_product.use_serials){
                this.modal_type = 'scan_serial_codes';
            }else{
                this.continueScan();
            }
            this.$props.warehouses.find(wh=>{ if(wh.id == this.scanned_product.warehouse_id ){
                this.item_warehouse = wh.name;
            } });
            this.$props.zones.find(z=>{ if(z.id == this.scanned_product.zone_id ){
                this.item_zone = z.name;
            } });
            this.$props.locations.find(l=>{ if(l.id == location_id ){
                this.item_location = l.name;
            } });
        },
        select_location_dif_whs(location_id, index){
            setTimeout(() =>  {
                this.scanned_product.warehouses_allocation[this.scanned_product.warehouses_allocation.length -1].location_id = location_id;
                if(this.scanned_product.use_serials) {
                    document.getElementById("serial_number1").focus();
                }
            },5);
            this.input_scan_location = '';
            this.input_empty_location = false;
            if(this.scanned_product.use_serials){
                this.modal_type = 'scan_serial_codes';
            }else{

                if(this.next_item === false){
                    setTimeout(() =>  {
                        this.addWarehouse();
                    },10);
                    this.modal_type = 'input_qty'
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'center',
                        showConfirmButton: false,
                        timer: 1000
                    });
                    Toast.fire({
                        icon: 'success',
                        title: 'Scanned Successfully!'
                    })
                }else{
                    this.continueScan();
                }
            }
            this.$props.warehouses.find(wh=>{ if(wh.id == this.scanned_product.warehouses_allocation[this.scanned_product.warehouses_allocation.length -1].warehouse_id ){
                this.item_warehouse = wh.name;
            } });
            this.$props.zones.find(z=>{ if(z.id == this.scanned_product.warehouses_allocation[this.scanned_product.warehouses_allocation.length -1].zone_id ){
                this.item_zone = z.name;
            } });
            this.$props.locations.find(l=>{ if(l.id == location_id ){
                this.item_location = l.name;
            } });
        },
        getMatchingResults(upc_array) {
            return upc_array.filter(d => this.input_scan.split(',').every(v => d.toLowerCase() === v.toLowerCase()));
        },
        changeScreen(value){
            if(value === 'list'){
                this.screen = 'list';
                this.isInput = false;
                this.isList = true;
                this.isScan = false;
            }else if(value === 'input'){
                this.screen = 'input';
                this.isInput = true;
                this.isList = false;
                this.isScan = false;
            }
            setTimeout(() =>  {
                if(this.scanned_product.same_warehouse){
                    document.getElementById("serial_number").focus();
                }else{
                    document.getElementById("serial_number1").focus();
                }
            },1);
        },
        addSerialScan(data){
            let component = this;
            this.serial = data.codeResult.code;
            this.serial = this.serial.replaceAll('\n','').replaceAll('\t', '').replaceAll(' ', '');
            let repeated_serial = this.scanned_product.serial_codes.find(code => code === this.serial);
            if(this.scanned_product.qty_received === '' ||this.scanned_product.serial_codes.length >= parseInt(this.scanned_product.qty_received)){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'You have all the serial numbers for the product ' + component.scanned_product.name + '. If you need to add more increase the field "Quantity Received".',
                });
            }else if(repeated_serial != null){
            } else if((this.serial !== "" && this.serial !== null)){
                let serialObject = {sn: [...this.serial], warehouse_index: this.index}
                this.scanned_product.serial_codes.push(serialObject);
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 1000
                });
                Toast.fire({
                    icon: 'success',
                    title: 'Serial number '+ this.serial+ ' added to list.'
                })
            }
            this.serial = "";
        },
        addSerial(){
            let component = this;
            let serials = this.serial.replaceAll('\n','').replaceAll('\t', '').replaceAll(' ', '').split(',');
            serials.forEach(serial => {
                let repeated_serial = this.scanned_product.serial_codes.find(code => code === serial);
                if (this.scanned_product.qty_received === '' || this.scanned_product.serial_codes.length >= parseInt(this.scanned_product.qty_received)) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'You have all the serial numbers for the product ' + component.scanned_product.name + '. If you need to add more increase the field "Quantity Received".',
                    });
                } else if (repeated_serial != null) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Serial Number already inserted.',
                    });
                } else if ((serial !== "" && serial !== null)) {
                    let serialObject = {sn: serial, warehouse_index: this.index}
                    this.scanned_product.serial_codes.push(serialObject);
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'center',
                        showConfirmButton: false,
                        timer: 1000
                    });
                    Toast.fire({
                        icon: 'success',
                        title: 'Serial number added to list.'
                    })
                    setTimeout(() =>  {
                        document.getElementById("serial_number").focus();
                    },1);
                }
            })
            this.serial = "";
        },
        deleteSerial(index){
            this.$forceUpdate();
            this.scanned_product.serial_codes.splice(index,1);
        },
        serialsInventory(){
            let instance = this;
            axios.get('/api/serials/'+ this.scanned_product.id).then(function(response) {
                instance.serialsInventoryCodes = response.data;
            }).catch(function(error){
                console.log(error);
            });
        },
        continueScan(){
            const Toast = Swal.mixin({
                toast: true,
                position: 'center',
                showConfirmButton: false,
                timer: 1000
            });
            if(this.scanned_product.same_warehouse) {
                if (this.scanned_product.use_serials && (this.scanned_product.serial_codes.length < this.scanned_product.qty_received)) {
                    this.incomplete_serials = true;
                } else {
                    Toast.fire({
                        icon: 'success',
                        title: 'Scanned Successfully!'
                    })
                    this.modal_type = 'scan_item_product'
                    this.clear_all();
                    this.incomplete_serials = false;
                    this.input_empty = false;
                    this.qty_error = false;
                }
            }else{
                if(this.scanned_product.use_serials) {
                    if (this.scanned_product.serial_codes.length < this.scanned_product.qty_received) {
                        this.modal_type = 'scan_location';
                        this.addWarehouseSerials();
                        Toast.fire({
                            icon: 'success',
                            title: 'Location Added!'
                        })
                        setTimeout(function () {
                            document.getElementById("scan_location").focus();
                        }, 0)
                    } else {
                        Toast.fire({
                            icon: 'success',
                            title: 'Scanned Successfully!'
                        })
                        this.modal_type = 'scan_item_product'
                        this.clear_all();
                        this.incomplete_serials = false;
                        this.input_empty = false;
                        this.qty_error = false;
                    }
                }else{
                    Toast.fire({
                        icon: 'success',
                        title: 'Scanned Successfully!'
                    })
                    this.modal_type = 'scan_item_product'
                    this.clear_all();
                    this.incomplete_serials = false;
                    this.input_empty = false;
                    this.qty_error = false;
                }
            }
        },
        addWarehouse(){
            this.$forceUpdate();
            this.scanned_product.warehouses_allocation.push({
                'warehouse_id':'',
                'zone_id':'',
                'location_id':'',
                'qty':'',
                'zones_options':[],
                'locations_options':[]
            })
        },
        addWarehouseSerials(){
            this.$forceUpdate();
            this.scanned_product.warehouses_allocation.push({
                'warehouse_id':'',
                'zone_id':'',
                'location_id':'',
                'serials':[],
                'zones_options':[],
                'locations_options':[]
            })
        },
        clear_all(){
            console.log('Clear')
            this.input_scan = '';
            this.already_in_list = false;
            this.input_empty = false;
            this.input_scan_location = '';
            setTimeout(function(){
                document.getElementById("scan_item").focus();
            },0)
        },
        addSerial_dif_whs(){
            let component = this;
            let serials = this.serial.replaceAll('\n','').replaceAll('\t', '').replaceAll(' ', '').split(',');
            serials.forEach(serial => {
                let repeated_serial = this.scanned_product.serial_codes.find(code => code === serial);
                if (this.scanned_product.qty_received === '' || this.scanned_product.serial_codes.length >= parseInt(this.scanned_product.qty_received)) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'You have all the serial numbers for the product ' + component.scanned_product.name + '. If you need to add more increase the field "Quantity Received".',
                    });
                } else if (repeated_serial != null) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Serial Number already inserted.',
                    });
                } else if ((serial !== "" && serial !== null)) {
                    let serialObject = {sn: serial, warehouse_index: (this.scanned_product.warehouses_allocation.length -1)}
                    this.scanned_product.serial_codes.push(serialObject);
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'center',
                        showConfirmButton: false,
                        timer: 2500
                    });
                    Toast.fire({
                        icon: 'success',
                        title: 'Serial number added to list.'
                    })
                }
            })
            this.serial = "";
        },
        deleteSerial_dif_whs(serial_number){
            this.$forceUpdate();
            let index = this.scanned_product.serial_codes.findIndex(serial_item => serial_item.sn === serial_number);
            if(index !== -1){
                this.scanned_product.serial_codes.splice(index,1);
            }
        }
    },
    watch: {
        flag: function() {
            this.clear_all();
            this.modal_type = 'scan_item_product';
            this.input_empty = false;
            this.qty_error = false;
        }
    }
}
</script>

<style scoped>

</style>
