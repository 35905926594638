<template>
    <div>
        <button type="button" class="btn btn-primary mr-2" v-on:click="$modal.show('scan-return-project-modal'); changeFlag()">Scan Project</button>
        <scan-return-project-modal
            :projects="$props.projects"
            :flag="flag">
        </scan-return-project-modal>
    </div>
</template>

<script>
import ScanReturnProjectModal from "./ScanReturnProjectModal";

export default {
    name: "ScanReturnProjectButton",
    props: ['projects'],
    components:{
        ScanReturnProjectModal
    },
    data(){
        return {
            input_scan_sku:'',
            flag:false
        }
    },
    methods:{
        changeFlag(){
            this.flag = !this.flag;
        }
    }
}
</script>

<style scoped>

</style>
