<template>
    <modal :name="'scan-returns-modal'" :scrollable="true" height="auto" width="500px">
        <div class="container modal-content border-bottom-0 h-100">
            <div class="modal-header">
                <div>
                    <div class="row justify-content-between">
                        <h5 class="modal-title">Product to Return</h5>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <form @submit.prevent="submitReturn" id="form" name="form" :ref="'form'">
                    <div class="form-group">
                        <div class="table-responsive pt-3">
                            <table class="table table-bordered table-hover">
                                <tbody>
                                <tr>
                                    <td><h5>Product SKU</h5></td>
                                    <td>{{$props.product.sku}}</td>
                                </tr>
                                <tr>
                                    <td><h5>Product Name</h5></td>
                                    <td>{{$props.product.name}}</td>
                                </tr>
                                <tr>
                                    <td><h5>Dispatched Qty.</h5></td>
                                    <td >{{$props.product !== null && $props.product !== '' ? $props.product.pivot.quantity_picked:''}}</td>
                                </tr>
                                <tr v-if="$props.product.use_serials">
                                    <td><h5>Return Serials</h5></td>
                                    <select-record name="serial_numbers" id="serial_numbers" :options="$props.dispatched_record.filter(record => record.product_id == $props.product.id && (record.last_history.warehouse_id == null ? true : $props.product.pivot.warehouse_id == record.last_history.warehouse_id))" v-model="$props.product.serial_numbers"></select-record>
                                </tr>
                                <tr v-else>
                                    <td><h5>Return Qty.</h5></td>
                                    <td><input type="number" oninput="validity.valid||(value='')" min="1" :max="$props.product !== null && $props.product !== '' ? $props.product.pivot.quantity_picked:''" class="form-control" name="product_return_qty" id="product_return_qty"  placeholder="####" v-model="qty_returned"></td>
                                </tr>
                                </tbody>
                            </table>
                            <div class="row col-md-12" style="align-items:flex-end">
                                <div class="col-md-7">
                                    <div class="form-group">
                                        <label for="warehouse_select">Warehouse<span class="required_asterisk">*</span></label>
                                        <dropbox-code-block
                                            :name="'warehouse_select'"
                                            :id="'warehouse_select'"
                                            :options="$props.product.warehouses"
                                            v-model="warehouse_id"
                                            @change="loadZones(warehouse_id)"
                                        ><option></option>
                                        </dropbox-code-block>
                                    </div>
                                    <div class="form-group">
                                        <label for="zone_select">Zone</label>
                                        <dropbox-code-block
                                            :name="'zone_select'"
                                            :id="'zone_select'"
                                            :options="zones_show"
                                            v-model="zone_id"
                                            @change="loadLocations()"
                                            @setLocation="setZone"
                                        ><option></option>
                                        </dropbox-code-block>
                                    </div>
                                    <div class="form-group">
                                        <label for="location_select">Location</label>
                                        <dropbox-code-block
                                            :name="'location_select'"
                                            :id="'location_select'"
                                            :options="locations_show"
                                            v-model="location_id"
                                            @setLocation="setLocation"
                                        ><option></option>
                                        </dropbox-code-block>
                                    </div>
                                </div>
                                <div class="col-md-5">
                                    <button type="button" class="btn btn-primary" v-on:click="scanLocation()">Scan Location</button>
                                </div>
                            </div>
                            <p style="color: red" :hidden="!no_quantity">Please, input a quantity or select serials to return.</p>
                        </div>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light" @click="clearAll()">Close</button>
                <button type="button" class="btn btn-primary mr-2" @click="submitReturn()">Submit</button>
            </div>
            <scan-location-modal
                :index = "index"
                :product = "$props.product_returns[0]"
                :locations = "locations"
                :zones="zones"
                :flag1="return_flag"
                :parent="this"
            ></scan-location-modal>
        </div>
    </modal>
</template>

<script>
import Swal from "sweetalert2";

export default {
    name: "ScanReturnsModal",
    props: ['product', 'product_returns', 'flag_return', 'parent', 'index', 'returned_list', 'dispatched_record', 'zones', 'locations'],
    data(){
        return {
            qty_returned:'',
            warehouse_id: '',
            zone_id:'',
            location_id:'',
            zones_show:[],
            locations_show:[],
            no_quantity:false,
            return_flag:false,
            is_scan_location: false,
            tempWarehouse:'',
            tempZone:'',
            tempLocation:''
        }
    },
    mounted(){
        this.is_scan_location= false;
        this.$props.zones.forEach(zone=>{
            if (zone.warehouse_id == this.$props.product_returns[0].pivot.warehouse_id){
                this.zones_show.push(zone);
            }
        })
        if(this.$props.product_returns[0].zone_id !== null){
            this.$props.locations.forEach(location=>{
                if (location.zone_id == this.$props.product_returns[0].zone_id){
                    this.locations_show.push(location);
                }
            })
        }
    },
    methods: {
        submitReturn() {
            this.$props.product_returns[0].returned_qty = this.qty_returned;
            let item = this.$props.product_returns[0];
            if((item.use_serials === 0 && item.returned_qty !== '') || ((item.use_serials === 1 && item.serial_codes.length <= 0) )) {
                if (!this.$props.returned_list.includes(item)) {

                    item.warehouse_id_scan = this.warehouse_id;
                    item.zone_id_scan = this.zone_id;
                    item.location_id_scan = this.location_id;
                    this.$props.parent.change_qty(item, item.id)
                    this.$props.parent.returnProduct(item, 0, this.$props.index);
                    this.$props.parent.filter = '';
                    this.clearAll();
                } else {
                    this.clearAll();
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Product Already added to the list of returns.'
                    });
                }
            }else{
                this.no_quantity = true;
            }
        },
        clearAll(){
            this.$props.parent.filter = '';
            this.$modal.hide('scan-item-modal-'+this.$props.index);
            this.$modal.hide('scan-returns-modal');
        },
        loadZones(warehouse_id){
            if(this.is_scan_location === true){
                this.zones_show.splice(0);
                this.$props.zones.forEach((zone, index)=>{
                    if (zone.warehouse_id == warehouse_id){
                        this.zones_show.push(zone);
                    }
                })
                this.$forceUpdate();
            }else{
                this.zones_show.splice(0);
                this.locations_show.splice(0);
                this.zone_id = '';
                this.location_id = '';
                this.$props.zones.forEach(zone=>{
                    if (zone.warehouse_id == warehouse_id){
                        this.zones_show.push(zone);
                    }
                })
            }
            this.$forceUpdate();
        },
        loadLocations(){
            if(this.is_scan_location === true){
                this.locations_show.splice(0);
                this.$props.locations.forEach(location=>{
                    if (location.zone_id == this.zone_id){
                        this.locations_show.push(location);
                    }
                })
                this.is_scan_location = false;
                this.$forceUpdate();
            }
            else {
                this.locations_show.splice(0);
                this.location_id = '';
                this.$props.locations.forEach(location=>{
                    if (location.zone_id == this.zone_id){
                        this.locations_show.push(location);
                    }
                })
                this.$forceUpdate();
            }
        },
        scanLocation(){
            this.return_flag = !this.return_flag;
            this.$modal.show('scan-location-modal');
        },
        changeProductReturnLocation(warehouse_id, zone_id, location_id){
            this.is_scan_location = true;
            this.warehouse_id = warehouse_id;
            this.tempZone = zone_id;
            this.tempLocation = location_id;
            this.$modal.hide('scan-location-modal');
        },
        setZone(){
            if(this.tempZone !== ''){
                this.zone_id = this.tempZone;
            }
        },
        setLocation(){
            if(this.tempLocation !== ''){
                this.location_id = this.tempLocation;
            }
        }
    },
    watch: {
        flag_return: function() {
            let prd = this.$props.product.use_serials;
            setTimeout(function(){
                if(!prd){
                    document.getElementById("product_return_qty").focus();
                }else{
                    document.getElementById("serial_numbers").focus();
                }
            },0)
        }
    }
}
</script>

<style scoped>

</style>
