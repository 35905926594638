<template>
    <modal :name="'zone-location-change-modal'" height="auto" :scrollable="true">
        <div class="modal-content border-bottom-0 h-100">
            <div class="modal-header">
                <div>
                    <div class="row justify-content-between">
                        <h5 class="modal-title">Select Zona and Location</h5>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <label for="zone">Zone<span class="required_asterisk">*</span></label>
                    <dropbox :options="zone_options" v-model="zone">
                        <option></option>
                    </dropbox>
                </div>
                <div class="form-group">
                    <label for="location">Location<span class="required_asterisk">*</span></label>
                    <dropbox :options="location_options" v-model="location">
                        <option></option>
                    </dropbox>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light" @click="$modal.hide('zone-location-change-modal')">Close</button>
                <button type="button" class="btn btn-primary mr-2" @click="submit">Submit</button>
            </div>
        </div>
    </modal>
</template>

<script>
import Swal from "sweetalert2";

export default {
    name: "ChangeZoneLocationModal",
    props: ['detail', 'locations', 'zones', 'products'],
    watch:{
        detail: {
            immediate: true,
            deep: true,
            handler(newVal, oldVal){
                this.zone = newVal.zone_id
                this.location = newVal.location_id
            }
        },
        zones: {
            immediate: true,
            deep: true,
            handler(newVal, oldVal){
                this.zone_options = newVal;
                this.zone_object = this.zone_options.find(z => z.id == this.zone);
                this.location_options = this.zone_object.locations;
            }
        },
        zone: {
            immediate: true,
            handler(newVal, oldVal){
                this.zone_object = this.zone_options.find(z => z.id == newVal);
                this.location_options = this.zone_object.locations;
            }
        },
    },
    mounted() {

    },
    data(){
        return{
            zone: "",
            zone_options: [],
            location: "",
            location_options: [],
            zone_object: {}
        }
    },
    methods: {
        submit() {
            console.log(this.location);
            if (this.zone == null || this.location == null || this.zone == '' || this.location == '') {
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Please, select zone and location.'
                });
            } else {
                let attributes = {
                    zone_id: this.zone,
                    location_id: this.location,
                    detail: this.$props.detail
                }
                axios.post('/change_location', attributes).then(response => {
                    if (response.data.message == 'Changed') {
                        this.$props.detail.zone_id = this.zone;
                        this.$props.detail.location_id = this.location;
                        this.$props.detail.zone = response.data.zone;
                        this.$props.detail.location = response.data.location;

                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: 'Location Updated'
                        });

                        this.$modal.hide('zone-location-change-modal');
                    } else {
                        let product_index = this.$props.products.findIndex(product => product.id = this.$props.detail.product_id);

                        this.$props.products.splice(product_index, 1);

                        Swal.fire({
                            icon: 'success',
                            title: 'Success',
                            text: 'Location Updated'
                        });
                    }

                    this.$modal.hide('zone-location-change-modal');
                }).catch(error => {
                    if (error.response) {
                        console.log(error.response)
                        let validationErrors = [];

                        if (error.response.status === 422) {
                            validationErrors = error.response.data.errors;

                        } else {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: error,
                            });
                        }
                    }
                });
            }
        }
    }
}
</script>

<style scoped>

</style>

