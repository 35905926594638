<template>
    <modal name="cycle-reconciliation-edit-modal" height="auto" :scrollable="true">
        <div class="modal-content border-bottom-0 h-100">
            <div class="modal-header">
                <div>
                    <div class="row justify-content-between">
                        <h5 class="modal-title">Non-Inventory Products</h5>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-6">
                                <p>Select Product:</p>
                                <dropbox name="product_id" id="product_id" :options="no_inventory_products" v-model="form.new_product_id">
                                    <option></option>
                                </dropbox>
                            </div>
                            <div class="col-md-4">
                                <p>Quantity:<span class="required_asterisk">*</span></p>
                                <input type="number" class="form-control" :min="1" name="reconciliation_number" placeholder="##" v-model="form.quantity_to_add">
                            </div>
                        </div>


                        <p>Destination Warehouse:</p>
                        <dropbox name="warehouse_id" id="warehouse_id" :options="warehouses" v-model="form.new_warehouse_id" v-on:change="resetZoneLocation(form.new_warehouse_id)">
                            <option></option>
                        </dropbox>

                        <div class="mb-2 mt-2" v-if="form.new_warehouse_id !==''">
                            <p>Destination Zone</p>
                            <dropbox name="zone_id" id="zone_id" :options="zones" v-model="form.new_zone_id" v-on:change="changeLocation(form.new_zone_id)">
                                <option></option>
                            </dropbox>
                        </div>

                        <div class="mb-2 mt-2" v-if="form.new_zone_id !=='' && form.new_warehouse_id !==''">
                            <p>Destination Location</p>
                            <dropbox name="location_id" id="location_id" :options="locations" v-model="form.new_location_id">
                                <option></option>
                            </dropbox>
                        </div>


                    </div>
                </div>
            </div>
            {{products_to_reconcile}}
            <div class="modal-footer">
                <button type="button" class="btn btn-light" @click="$modal.hide('cycle-reconciliation-edit-modal')">Close</button>
                <button type="button" class="btn btn-primary mr-2" @click="addToIn()">Add Product</button>
            </div>
        </div>
    </modal>
</template>

<script>
import Swal from "sweetalert2";
export default {
    name: "CycleReconciliationEditModal",
    props: ['no_inventory_products', 'warehouses', 'zones', 'locations', 'products_to_reconcile', 'all_products'],

    data() {
        return {
            form: {
                new_product_id:'',
                quantity_to_add:'',
            },
            non_inv:[],
            zone_id: this.$props.zone,
            location_id: this.$props.location,
            flag:false
        }
    },
    methods: {
        addToIn() {
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2500
            });

            if( this.$props.products_to_reconcile.length > 0){
                this.$props.products_to_reconcile.forEach(elem => {
                    if(parseInt(elem.product_id) === parseInt(this.form.new_product_id)){
                        this.flag = true;
                        if(this.form.quantity_to_add === "0" || this.form.quantity_to_add === ""){
                            Toast.fire({
                                icon: 'error',
                                title: 'Product and quantity must be selected.'
                            })
                        }else{
                            elem.reconciliation_qty = this.form.quantity_to_add;
                            elem.reconciliation_qty_list = this.form.quantity_to_add;
                            Toast.fire({
                                icon: 'success',
                                title: 'Product quantity updated.'
                            })
                            this.$forceUpdate();
                        }
                    }else{
                        this.flag = false;
                    }
                })
                if(this.flag === false){
                    if(parseInt(this.$props.zone) === 0){
                        this.zone_id = null;
                    }
                    if(parseInt(this.$props.location) === 0){
                        this.location_id = null;
                    }
                    let prd = this.$props.all_products.filter(prd=> prd.id === parseInt(this.form.new_product_id));
                    let non_inv = {
                        warehouse_id: this.$props.warehouse,
                        zone_id: this.zone_id,
                        location_id: this.location_id,
                        name: prd[0].name,
                        product_id:prd[0].id,
                        quantity:0,
                        reconciliation_qty:this.form.quantity_to_add,
                        reconciliation_qty_list:this.form.quantity_to_add,
                        sku: prd[0].sku,
                        use_serials: prd[0].use_serials,
                        description: prd[0].description,
                    }
                    this.$props.products_to_reconcile.push(non_inv);
                    console.log(this.$props.products_to_reconcile);
                }
            }
            else if(this.form.new_product_id === 0 || this.form.quantity_to_add === "" || this.form.quantity_to_add === "0"){
                Toast.fire({
                    icon: 'error',
                    title: 'Product and quantity must be selected.'
                })
            }else{
                if(parseInt(this.$props.zone) === 0){
                    this.zone_id = null;
                }
                if(parseInt(this.$props.location) === 0){
                    this.location_id = null;
                }
                let prd = this.$props.all_products.filter(prd=> prd.id === parseInt(this.form.new_product_id));
                let non_inv = {
                    warehouse_id: this.$props.warehouse,
                    zone_id: this.zone_id,
                    location_id: this.location_id,
                    name: prd[0].name,
                    product_id:prd[0].id,
                    quantity:0,
                    reconciliation_qty:this.form.quantity_to_add,
                    reconciliation_qty_list:this.form.quantity_to_add,
                    sku: prd[0].sku,
                    use_serials: prd[0].use_serials,
                    description: prd[0].description,
                }
                this.$props.products_to_reconcile.push(non_inv);
                console.log(this.$props.products_to_reconcile);
            }
        }
    }
}
</script>

<style scoped>

</style>
