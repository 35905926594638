<template>
    <modal :name="'input-receiving-qty-modal'"  height="auto" width="400px">
        <div class="container modal-content border-bottom-0 h-100">
            <div class="modal-header">
                <div class="row justify-content-between">
                    <h4 class="modal-title">Receiving Details</h4>
                </div>
            </div>
            <div class="modal-body">
                <h5 v-if="index === 'tool_scan'">Tool Name: {{$props.product.name}}</h5>
                <h5 v-else>{{$props.product.sku}} / {{$props.product.name}}</h5>
                <div style="text-align: center; text-align-all: center; align-self: center; align-content: center; align-items: center; margin-top: 4px">
                    <div class="col-md-12 justify-content-between">
                        <div class="row-md-12 justify-content-between" style="align-items: center">
                            <input autofocus oninput="validity.valid||(value='')" min="0" type="number" class="text-center form-control-lg mb-2" name="product" id="order_qty1"  placeholder="Qty. Received" v-model="qty_received">
                        </div>
                        <div class="row-md-12 justify-content-between" style="align-items: center">
                            <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="console.log(document.getElementById('order_qty1').value); document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + 1;">1</button>
                            <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + 2;">2</button>
                            <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + 3;">3</button>
                        </div>
                        <div class="row-md-12 justify-content-between" style="align-items: center">
                            <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '4';">4</button>
                            <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '5';">5</button>
                            <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '6';">6</button>
                        </div>
                        <div class="row-md-12 justify-content-between" style="align-items: center">
                            <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '7';">7</button>
                            <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '8';">8</button>
                            <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '9';">9</button>
                        </div>
                        <div class="row-md-12 justify-content-between" style="align-items: center">
                            <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '0';">0</button>
                            <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value.slice(0,-1);">&lt;</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light" v-on:click="close()">Close</button>
                <button type="button" class="btn btn-primary mr-2" v-on:click="submit()">Submit</button>
            </div>
        </div>
    </modal>
</template>

<script>
import ScanLocationModal from "./ScanLocationModal";

export default {
    name: "InputReceivingQtyModal",
    props: ['product', 'warehouses', 'zones', 'locations', 'index', 'flag1', 'flag2', 'serial_index'],
    components:{
        ScanLocationModal
    },
    data(){
        return{
            qty_received:''
        }
    },
    mounted(){
        this.$props.flag1 = false
    },
    methods:{
        submit(){
            this.$props.product.qty_received = document.getElementById('order_qty1').value;
            this.scanLocation();
        },
        close(){
            this.$modal.hide('input-receiving-qty-modal');
            this.$modal.hide('scan-item-modal-'+this.$props.index);
            this.$modal.hide('1-serial-codes-modal');
        },
        scanLocation(){
            this.$props.flag1 = !this.$props.flag1;
            this.$modal.show('scan-location-modal');
        }
    },
    watch: {
        flag2: function() {
            setTimeout(function(){
                document.getElementById("order_qty1").focus();
            },0)
        }
    }
}
</script>

<style scoped>

</style>
