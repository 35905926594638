import 'jquery-validation'

$(function(){

    $("form[name='product-form']").validate({
        ignore: [],
        rules: {
            sku: {
                required: true,
            },
            name: {
                required: true,
                minlength: 3
            },
            description: {
                required: true
            },
            description_slug:{
                required: true,
                minlength: 2,
                maxlength:2,
            },
            category: {
                required: true
            },
            warehouse: {
                required: true
            },
            zone: {
                required: false
            },
            location: {
                required: false
            },
            min_qty_order: {
                required: true
            },
            reorder_frequency: {
                required: true
            },
            min_stock_qty: {
                required: true
            },
            avg_cost: {
                required: true
            },
            suppliers: {
                required: false
            }
        },
        errorPlacement: function(label, element) {

            if(['category','warehouse'].includes(element[0].name)){
                label.addClass('mt-2 text-danger');
                label.insertAfter(element.next());
            }else{
                label.addClass('mt-2 text-danger');
                label.insertAfter(element);
            }
        },
        highlight: function(element, errorClass) {
            $(element).parent().addClass('has-danger')
            $(element).addClass('form-control-danger')
        },
        unhighlight: function(element, errorClass, validClass) {
            $(element).parent().removeClass('has-danger')
            $(element).removeClass('form-control-danger')
        },
        submitHandler: function(form){
            form.submit();
        }
    });

    $("form[name='user-form']").validate({
        rules: {
            first_name: {
                required: true,
            },
            last_name: {
                required: true,
            },
            email: {
                required: true,
                email: true
            },
            "roles[]": {
                required: true
            },
        },
        errorPlacement: function(label, element) {
            if(['roles[]'].includes(element[0].name)){
                label.addClass('mt-2 text-danger');
                label.insertAfter(element.next());
            }else{
                label.addClass('mt-2 text-danger');
                label.insertAfter(element);
            }
        },
        highlight: function(element, errorClass) {
            $(element).parent().addClass('has-danger')
            $(element).addClass('form-control-danger')
        },
        unhighlight: function(element, errorClass, validClass) {
            $(element).parent().removeClass('has-danger')
            $(element).removeClass('form-control-danger')
        },
        submitHandler: function(form){
            form.submit();
        }
    });

    $("form[name='supplier-form']").validate({
        rules: {
            name: {
                required: true,
            },
            slug:{
                required: true,
                minlength: 2,
                maxlength: 2
            },
            email: {
                email: true
            },
        },
        errorPlacement: function(label, element) {
            label.addClass('mt-2 text-danger');
            label.insertAfter(element);
        },
        highlight: function(element, errorClass) {
            $(element).parent().addClass('has-danger')
            $(element).addClass('form-control-danger')
        },
        unhighlight: function(element, errorClass, validClass) {
            $(element).parent().removeClass('has-danger')
            $(element).removeClass('form-control-danger')
        },
        submitHandler: function(form){
            form.submit();
        }
    });

    $("form[name='project-installation-form']").validate({
        rules: {
            status: {
                required: true,
            },
        },
        errorPlacement: function(label, element) {
            label.addClass('mt-2 text-danger');
            label.insertAfter(element);
        },
        highlight: function(element, errorClass) {
            $(element).parent().addClass('has-danger')
            $(element).addClass('form-control-danger')
        },
        unhighlight: function(element, errorClass, validClass) {
            $(element).parent().removeClass('has-danger')
            $(element).removeClass('form-control-danger')
        },
        submitHandler: function(form){
            form.submit();
        }
    });

    $("form[name='project-form']").validate({
        rules: {
            project_number: {
                required: true,
            },
            client_name:{
                required: true,
                // minlength: 2,
                // maxlength: 2
            },
            address: {
                required: true
            },
            contact_phone_number1:{
                required: true,
            },
            financing_source_name:{
                required:true,
            },
            system_size:{
                required: true,
            },
            product_sold_id:{
                required:true,
            },
            battery_type:{
                required: true,
            },
            battery_qty:{
                required:true,
            },
            installation_scheduled_date:{
                required:true,
            },
            days_to_install:{
                required:true,
            },

        },
        errorPlacement: function(label, element) {
            label.addClass('mt-2 text-danger');
            label.insertAfter(element);
        },
        highlight: function(element, errorClass) {
            $(element).parent().addClass('has-danger')
            $(element).addClass('form-control-danger')
        },
        unhighlight: function(element, errorClass, validClass) {
            $(element).parent().removeClass('has-danger')
            $(element).removeClass('form-control-danger')
        },
        submitHandler: function(form){
            form.submit();
        }
    });

})

