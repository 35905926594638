<template>
    <select multiple>
        <slot></slot>
    </select>
</template>

<script>
export default {
    name: "SelectMultipleSerialBinToBin",
    props: ['options', 'value'],
    data() {
        return {
            optionsArray: []
        }
    },
    mounted() {
        var vm = this;

        this.$props.options.forEach(option =>{
            this.optionsArray.push(option)
        });

        $(this.$el)
            .select2({data: this.optionsArray, placeholder: "Select Serial Numbers", width: '100%'})
            .val(this.value)
            .trigger("change")
            .on("change", function () {
                vm.$emit("input", $(this).val());
            });
    },
    watch: {
        value: function (value) {
            // let select = $(this.$el).select2();
            // select.val(value).trigger("change");
            if ([...value].sort().join(",") !== [...$(this.$el).val()].sort().join(",")) {
                $(this.$el).val(value).trigger('change');
            }
        },
        options: function (options) {
            $(this.$el)
                .empty().select2({
                data: options
            })
        }
    }
}
</script>

<style scoped>

</style>
