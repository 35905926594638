<template>
    <div class="card">
        <div class="card-body">
            <h6 class="card-title">Select Product</h6>
            <form method="GET" id="form_b2b" name="form_b2b">
                <div class="mt-3">
                    <div class="form-group">
                        <div class="row col-md-8">
                            <div class="col-md-8">
                                <dropbox name="product_id" id="product_id" :options="products" v-model="selected_product" v-on:change="changeBinToBinProducts(selected_product)">
                                    <option></option>
                                </dropbox>
                            </div>
                            <div class="col-md-4" >
                                <scan-item-bin-2-bin-button
                                    :products = "products"
                                    :is_transfer = "is_transfer"
                                    :transfer_inventory = 'transfer_inventory'
                                    :parent = "this">
                                </scan-item-bin-2-bin-button>
                                <div v-if="loading" class="spinner-border text-primary ml-4 mt-2" role="status">
                                    <span class="visually-hidden"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="card-body">
            <h6 class="card-title">Create Transfer Order</h6>
            <h6 class="card-subtitle mb-2 text-muted">Transfer Inventory</h6>
            <div class="table-responsive pt-3 mb-12">
                <table class="table table-bordered table-hover data-table" id="odd" v-if="this.transfer_inventory.length > 0">
                    <thead>
                    <tr>
                        <th>
                            Product
                        </th>
                        <th>
                            SKU
                        </th>
                        <th>
                            Warehouse
                        </th>
                        <th>
                            Zone
                        </th>
                        <th>
                            Location
                        </th>
                        <th>
                            Quantity in Stock
                        </th>
                        <th>
                            Has Serial Numbers?
                        </th>
                        <th>
                            Transfer
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item,index) in this.transfer_inventory">
                        <td>
                            {{item.product_name}}
                        </td>
                        <td>
                            {{item.product_sku}}
                        </td>
                        <td>
                            {{item.warehouse_name}}
                        </td>
                        <td>
                            {{item.zone_name}}
                        </td>
                        <td>
                            {{item.location_name}}
                        </td>
                        <td>
                            {{item.quantity}}
                        </td>
                        <td v-if="item.use_serials > 0">
                            Yes
                        </td>
                        <td v-if="item.use_serials <= 0">
                            No
                        </td>
                        <td v-if="item.use_serials > 0">
                            <button type="button" class="btn btn-primary mr-2" v-on:click="startTransfer(-1, item)"><log-out-icon size="1x" style="color: white"></log-out-icon></button>
                        </td>
                        <td v-if="item.use_serials <= 0">
                            <button type="button" class="btn btn-primary mr-2" v-on:click="startTransfer(0, item)"><log-out-icon size="1x" style="color: white"></log-out-icon></button>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <inventory-transfer-bin-to-bin-modal
                    :index = "0"
                    :item = "item_to_transfer"
                    :warehouses="warehouses"
                    :zones="zones"
                    :locations="locations"
                    :flag = "flag"
                    :is_transfer="is_transfer"
                    :transfer_orders="transfer_orders">
                </inventory-transfer-bin-to-bin-modal>
                <inventory-transfer-bin-to-bin-modal
                    :index = "-1"
                    :item = "item_to_transfer"
                    :warehouses="warehouses"
                    :zones="zones"
                    :locations="locations"
                    :flag = "flag"
                    :is_transfer="is_transfer"
                    :transfer_orders="transfer_orders">
                </inventory-transfer-bin-to-bin-modal>
            </div>
        </div>
        <div class="card-body" v-if="this.transfer_orders.length > 0">
            <h6 class="card-subtitle mb-2 mt-4 text-muted">Orders to Transfer</h6>
            <div class="table-responsive pt-3">
                <table class="table table-bordered table-hover">
                    <thead>
                    <tr>
                        <th>
                            Product
                        </th>
                        <th>
                            SKU
                        </th>
                        <th>
                            From
                        </th>
                        <th>
                            To
                        </th>
                        <th>
                            Quantity
                        </th>
                        <th>
                            Action
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item,index) in this.transfer_orders">
                        <td>
                            {{products.find(prod => prod.id == item.product_id).name}}
                        </td>
                        <td>
                            {{products.find(prod => prod.id == item.product_id).sku}}
                        </td>
                        <td>
                            {{warehouses.find(wh => wh.id == item.old_warehouse_id) == null ? '' : warehouses.find(wh => wh.id == item.old_warehouse_id).name
                            + ' - ' + (zones.find(zn => zn.id == item.old_zone_id) == null ? '' : zones.find(zn => zn.id == item.old_zone_id).name)
                            + ' - ' + (locations.find(ln => ln.id == item.old_location_id) == null ? '' : locations.find(ln => ln.id == item.old_location_id).name)}}
                        </td>
                        <td>
                            {{warehouses.find(wh => wh.id == item.new_warehouse_id) == null ? '' : warehouses.find(wh => wh.id == item.new_warehouse_id).name
                            + ' - ' + (zones.find(zn => zn.id == item.new_zone_id) == null ? '' : zones.find(zn => zn.id == item.new_zone_id).name)
                            + ' - ' + (locations.find(ln => ln.id == item.new_location_id) == null ? '' : locations.find(ln => ln.id == item.new_location_id).name)}}
                        </td>
                        <td>
                            {{item.quantity_to_transfer}}
                        </td>
                        <td>
                            <button type="button" class="btn btn-outline-primary mr-2" v-on:click="removeFromTransfers(index)">Remove</button>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="mt-4">
                <button type="button" class="btn btn-primary mr-2" v-on:click="submit_transfer()">Submit Transfer</button>
                <div v-if="loading" class="spinner-border text-primary" role="status">
                    <span class="visually-hidden"></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Swal from "sweetalert2";
import 'jquery-validation';
import {LogInIcon, LogOutIcon} from 'vue-feather-icons';
import datePicker from 'vue-bootstrap-datetimepicker';
import InventoryTransferBinToBinModal from "./InventoryTransferBinToBinModal";
import axios from "axios";

export default {
    name: "TransferOrderComponent",
    props:['warehouses', 'zones', 'locations', 'products', 'is_transfer', 'transfer_inventory', 'history_transfer'],
    components:{
        InventoryTransferBinToBinModal,
        LogOutIcon, LogInIcon, datePicker
    },
    data(){
        return{
            product_ids:[],
            item_to_transfer:Object,
            serial_numbers:[],
            flag:false,
            product_tt:'',
            transfer_orders:[],
            transfer_inventory:[],
            selected_product:'',
            loading: false,
            transfer_number:''
        }
    },
    methods:{
        startTransfer(index, item) {
            this.item_to_transfer = item;
            let pid = item.product_id;
            let wh = item.warehouse_id;
            let zn = item.zone_id;
            let ln = item.location_id;
            if(item.use_serials) {
            }
            this.flag = !this.flag;
            this.$modal.show('inventory-transfer-bin-to-bin-modal-'+index);
        },
        removeFromTransfers(index){
            this.transfer_orders.splice(index, 1);
        },
        changeBinToBinProducts(selected_product_id){
            this.loading = true;
            axios.get('/getBinToBinProducts/' + selected_product_id,this.form).then(response => {
                this.transfer_inventory = response.data;
                this.loading = false;
                this.$forceUpdate();
            }).catch(error =>{
                this.loading = false;
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'There was a problem.',
                });
            });
        },
        check_unique_number(){
            this.transfer_number = 'Transfer-' + Math.ceil(Math.random()*10000);
            this.$props.history_transfer.forEach(order =>{
                if(this.transfer_number == order['transfer_order_number']){
                    this.transfer_number = this.check_unique_number();
                }
            })
        },
        submit_transfer(){
            this.loading = true;
            this.submit_create_transfer_order();
        },
        submit_create_transfer_order(){
            this.check_unique_number();
            let attributes =  {
                'transfer_orders' : this.transfer_orders,
                'transfer_number' : this.transfer_number
            };
            axios.post('/submit_create_transfer_order',attributes).then(response => {
                window.location = response.data.redirect;
                this.loading = false;
            }).catch(error =>{
                this.loading = false;
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'There was a problem.',
                });
            });
        },
    }
}
</script>

<style scoped>

</style>
