<template>
    <div>
        <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
                <div class="card-body">
                    <h6 class="card-title">Project#{{bom.project.project_number}}</h6>
                    <form @submit.prevent="submit">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="bom_identifier">BOM Assignment <span v-if="this.bom.assigned_bom_id!==null"> - {{assigned_bom_name}}</span></label>
                                    <dropbox-bom :options="boms" v-model="assigned_bom_id" v-on:change="updateBom">
                                        <option></option>
                                    </dropbox-bom>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="system_size">System Size<span class="required_asterisk">*</span></label>
                                    <input type="text" class="form-control" name="system_size" id="system_size" placeholder="####" v-model="form.system_size">
                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="bom_identifier">BOM ID<span class="required_asterisk">*</span></label>
                                    <input type="text" :disabled="'disabled'" class="form-control" name="bom_identifier" id="bom_identifier" placeholder="Bom ID" v-model="form.bom_identifier">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="battery_type">Battery Type<span class="required_asterisk">*</span></label>
                                    <dropbox name="battery_type" id="battery_type" :options="this.$props.battery_types" v-model="form.battery_type_id">
                                        <option></option>
                                    </dropbox>
                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <!--                            <div class="col-sm-6">-->
                            <!--                                <div class="form-group">-->
                            <!--                                    <label for="wh_product">Product Sold <span class="required_asterisk">*</span></label>-->
                            <!--                                    <dropbox name="wh_product" id="wh_product" :options="this.$props.wh_products" v-model="form.wh_product_id">-->
                            <!--                                        <option></option>-->
                            <!--                                    </dropbox>-->
                            <!--                                </div>-->
                            <!--                            </div>-->
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="description">Description</label>
                                    <input type="text" class="form-control" name="description" id="description" placeholder="" v-model="form.comment">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="battery_qty">Battery Qty<span class="required_asterisk">*</span></label>
                                    <input type="number" oninput="validity.valid||(value='')" min="0" :disabled="'disabled'" class="form-control" name="battery_qty" id="battery_qty" placeholder="" v-model="form.battery_qty">
                                </div>
                            </div>
                        </div>
                        <br>

                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="category">Category<span class="required_asterisk">*</span></label>
                                    <dropbox name="category" id="category" :options="this.$props.categories" v-model="category_id"  @change="loadProducts()">
                                        <option></option>
                                    </dropbox>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="category">Product<span class="required_asterisk">*</span></label>
                                    <dropbox name="product" id="product" :options="this.product_options" v-model="product_id"  @change="loadWarehouses(); inventoryWarehouse()"> <!---->
                                        <option></option>
                                    </dropbox>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="quantity">Quantity</label>
                                    <input type="number" oninput="validity.valid||(value='')" min="0" class="form-control" name="quantity" id="quantity" placeholder="" v-model="quantity">
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="category">Warehouse<span class="required_asterisk">*</span></label>
                                    <dropbox name="category" id="warehouse" :options="this.warehouse_options" v-model="warehousename" @change="inventoryWarehouse(); loadZones()">
                                        <option></option>
                                    </dropbox>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="zone">Zone<span class="required_asterisk">*</span></label>
                                    <dropbox name="zone" id="zone" :options="this.zone_options" v-model="zone">
                                        <option></option>
                                    </dropbox>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col-md-4">
                                <button type="button" class="btn btn-primary mr-2" v-on:click="addProduct">Add Product</button>
                            </div>
                        </div>
                        <div class="row col-md-12 mb-4">
                            <table class="table table-bordered table-hover date-columns" id="product-table">
                                <thead>
                                <tr>
                                    <th>
                                        Category
                                    </th>
                                    <th>
                                        Warehouse
                                    </th>
                                    <th>
                                        Zone
                                    </th>
                                    <th>
                                        Product ID
                                    </th>
                                    <th>
                                        Product
                                    </th>
                                    <th>
                                        Description
                                    </th>
                                    <th>
                                        Amount
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <template v-for="(category,index_category) in categories">
                                    <tr>
                                        <td>{{category.name}}</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr v-for="(product,index_product) in form.products.filter(item => item.category.id == category.id)">
                                        <td>
                                            <div class="row justify-content-center">
                                                <!-- <div style="cursor: pointer" class="mr-2" v-on:click="editProduct(product.id, product.pivot.warehouse)">
                                                    <edit-icon size="1.5x"></edit-icon>
                                                </div> -->
                                                <div style="cursor: pointer" v-on:click="deleteProduct(product)">
                                                    <trash2-icon size="1.5x"></trash2-icon>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            {{warehouses.find(w => w.id == product.pivot.warehouse_id) != null ? warehouses.find(w => w.id == product.pivot.warehouse_id).name : ''}}

                                            <select :id="'select_'+product.id" v-model="value" @click="inventoryInWarehouse(product.id)" @change="updateWarehouse(product.id, value), loadZones2(product, value)">
                                                <option :id="'option_'+product.id+'_'+warehouseOption.warehouse_id" v-for="warehouseOption in warehouesWithProductInventory" :key="warehouseOption.warehouse_id" :value="warehouseOption.warehouse_id">{{warehouseOption.name }} - {{ warehouseOption.quantity}}</option>


                                                <!-- <option :value="product.pivot.warehouse_id">{{warehouses.find(w => w.id == product.pivot.warehouse_id) != null ? warehouses.find(w => w.id == product.pivot.warehouse_id).name : ''}}</option>

                                                <option> :value="warehouseWithInvnetory.id" v-for="warehouesWithProductInventory in warehouseWithInvnetory">{{warehouseWithInvnetory.name}} - {{warehouseWithInvnetory.quantity}}</option>
                                             -->
                                            </select>


                                            <!-- <select :id="'select_'+product.id" v-model="value" @click="inventoryInWarehouse(product.id)" @change="updateWarehouse(product.id, value)">

                                                <option :id="'option_'+product.id+'_0_'+option.warehouse_id" v-for="option in product.warehouesWithProductInventory" :key="option.warehouse_id" :value="option.warehouse_id">{{option.name}} - {{option.quantity}}</option>


                                                <option :id="'option_'+product.id+'_'+warehouseOption.id" v-for="warehouseOption in warehouses" :key="warehouseOption.id" :value="warehouseOption.id">{{ warehouseOption.name }}</option>

                                            </select>
     -->

                                        </td>
                                        <td>
<!--                                            <div class="spinner-border text-primary my-3 position-absolute" v-if="product.loadingZones" role="status">-->
<!--                                                <span class="sr-only">Loading...</span>-->
<!--                                            </div>-->
                                            <select style="width: 100%"
                                                     v-on:click="loadZones2(product, product.pivot.warehouse_id)"
                                                     v-model="product.pivot.zone_id">
                                                <option></option>
                                                <option :value="null">None</option>
                                                <option v-for="zone in (product.zone_options!= null ? product.zone_options : [])" :key="zone.id" :value="zone.id">{{zone.name}}</option>
                                            </select>
                                        </td>
                                        <td>{{product.sku}}</td>
                                        <td>{{product.name}}</td>
                                        <td>{{product.description}}</td>
                                        <td>
                                            <input type="number" v-model="product.pivot.quantity">
                                        </td>
                                    </tr>
                                </template>
                                </tbody>
                            </table>
                        </div>

                        <div class="spinner-border text-primary my-3" v-if="submit_disabled" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <div class="row mt-4">
                            <button :disabled="submit_disabled" type="submit" class="btn btn-primary mr-2">Submit</button>
                            <a :href="'/projects/'+ bom.project_id + '/assignment' " class="btn btn-light">Cancel</a>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Swal from "sweetalert2";
import { EditIcon, Trash2Icon} from 'vue-feather-icons';

export default {
    name: "ProjectBomUpdate",
    props: ['products','categories','bom','wh_products','battery_types','warehouses','boms'],
    components:{
        EditIcon, Trash2Icon
    },
    beforeMount() {
        this.assignZones();
    },
    mounted(){
        this.countBatteries();
       this.assigned_bom_name = this.$props.boms.find(bom=>
            bom.id === this.bom.assigned_bom_id
        ).bom_identifier;
    },
    // watch:{
    //     warehousename: {
    //         immediate: true,
    //         handler(newVal, oldVal){
    //             let warehouse = this.warehouse_options.find(warehouse => warehouse.id == newVal);
    //
    //             if(warehouse != null){
    //                 this.zone_options = warehouse.zones;
    //             }
    //         }
    //     }
    // },
    data(){
        return {



            form:{
                bom_identifier: 'BOM#'+ this.$props.bom.project.project_number,
                system_size: this.$props.bom.system_size,
                battery_type: this.$props.battery_types.find(bat => bat.name === this.$props.bom.battery_type) != null ? this.$props.battery_types.find(bat => bat.id === this.$props.bom.battery_type.id).name : "",
                battery_type_id: this.$props.bom.battery_type_id,
                comment: this.$props.bom.comment,
                // wh_product_id: this.$props.bom.wh_product.id,
                products: this.$props.bom.products,
                //products: [], //this.$props.bom.products,
                battery_qty: '',
            },
            battery: this.$props.battery_types.find(bat => bat.name === this.$props.bom.battery_type) != null ? this.$props.battery_types.find(bat => bat.id === this.$props.bom.battery_type.id).id : "",

            product_options:[],
            category_id: "",
            product_id: "",
            quantity: "",
            assigned_bom_id: '',//this.bom.assigned_bom_id,
            old_bom_id: this.bom.assigned_bom_id,
            submit_disabled: false,
            warehouse_id: "",
            warehouse_options:[],
            zone_options:[],
            zone: "",
            warehousename:"",
            warehouseCount:"",
            warehouesWithProductInventory: [],
            loadedZones:[],
            assigned_bom_name:'',
        }
    },
    methods:{

        submit(){

            this.submit_disabled = true;

            axios.patch("/projects/" + this.bom.project_id + "/bom/",this.form).then(response =>{
                location = response.data.message;
            }).catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'There was a problem.',
                });

                this.submit_disabled = false;
            });
        },
        assignZones(){
            this.form.products.forEach(product =>{

                let warehouse = product.warehouses.find(warehouse => warehouse.id = product.pivot.warehouse_id);
                product.zone = "";

                if(warehouse != null){
                    let zone = warehouse.zones.find(warehouse_zone => warehouse_zone.id == product.pivot.zone_id);
                    // product.zone_options = warehouse.zones;
                    if(zone != null){
                        product.zonename = zone.name;
                        product.zone = zone.id;
                    }
                }
            })
        },
        loadProducts(){
            if(this.category_id !== ""){
                this.product_options = this.$props.categories.find(category => category.id == this.category_id).products
            }
        },
        loadWarehouses(){
            if(this.category_id !== "" && this.product_id !== ""){
                this.warehouse_options = this.$props.products.find(product => product.id == this.product_id).warehouses
                this.warehousename = this.warehouse_options[0].id;
                this.zone_options = this.warehouse_options[0].zones;
                this.zone = this.warehouse_options[0].id;
                this.$nextTick(() => {
                    this.warehousename = this.warehouse_options[0].id;
                    this.zone_options = this.warehouse_options[0].zones;
                    this.zone = this.warehouse_options[0].id;
                });
            }
        },
        addProduct(){


            let sameProduct = this.form.products.find(product => product.id == this.product_id &&
                (product.warehousename == this.warehousename ||
                    product.pivot.warehousename == this.warehousename ||
                    product.pivot.warehouse == this.warehousename ||
                    product.pivot.warehouse_id == this.warehousename));
            if(sameProduct == null){
                if(this.product_id === ""){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Please select a product.',
                    });

                }else if(this.quantity == null || this.quantity === ''){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Please enter a order quantity greater than 0.',
                    });
                    // }else if(this.warehouseCount === 0 || this.warehouseCount === "" || this.quantity > this.warehouseCount){
                    //     Swal.fire({
                    //         icon: 'error',
                    //         title: 'Oops...',
                    //         text: 'Warehouse does not contain enough of that product in inventory. Quantity available in this warehouse: '+this.warehouseCount,
                    //     });
                }else if(this.warehousename === ""){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Please select a warehouse.',
                    });
                }else{
                    let vm = this;
                    let product = {...this.$props.products.find(product => product.id == this.product_id)};
                    product['pivot'] = {};
                    product.pivot['quantity'] = this.quantity;
                    product.pivot['warehousename'] = this.warehousename;
                    product.pivot['warehouse'] = this.warehousename;
                    product.pivot['warehouse_id'] = this.warehousename;
                    product.pivot['zone_id'] = this.zone;
                    product.zone_options = this.zone_options;

                    let zone = this.zone_options.find(zoneO => zoneO.id == this.zone);
                    if(zone != null){
                        product.zonename = zone.name;
                    }else{
                        product.zonename = "";
                    }

                    this.warehouse_id = this.warehousename;




                    this.form.products.push(product)
                    this.category_id = "";
                    this.product_id = "";
                    this.quantity = "";
                    this.warehousename = "";
                    this.zone = "";

                    if(product.category.name === "Battery"){
                        this.countBatteries();
                    }
                }
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Product already added. You can edit it in the table'
                })
            }


        },
        editProduct(id, warehouse_id){
            let component = this;
            let product_edit = (this.form.products.find(product => product.id == id && (product.warehousename == warehouse_id || product.pivot['warehouse'] == warehouse_id)));
            //console.log(product_edit.id);
            //console.log(this.warehouses);
            //resultAPI = inventoryInWarehouse(product_edit.id);
            //console.log(resultAPI);
            //let warehouseSelection = this.warehouses;
            Swal.fire({
                title: product_edit.name,
                html:
                    '<label for="product_quantity">Order <span class="required_asterisk">*</span></label>'+
                    '<input type="number" oninput="validity.valid||(value=\'\')" min="0" id="product_quantity" class="swal2-input" value="' + product_edit.pivot.quantity + '"><br>',
                focusConfirm: false,
                preConfirm() {
                    component.form.battery_qty = parseInt(component.form.battery_qty,10);
                    component.form.battery_qty -= parseInt(product_edit.pivot.quantity,10);
                    product_edit.pivot.quantity = document.getElementById('product_quantity').value;
                    component.form.battery_qty += parseInt(product_edit.pivot.quantity,10);
                }
            })
        },
        deleteProduct(product){
            this.form.products.splice(this.form.products.indexOf(product),1);
            this.countBatteries();
        },
        countBatteries(){
            this.form.battery_qty = 0;
            this.form.products.forEach(pt => {
                if(pt.category.name === "Battery"){
                    this.form.battery_qty += parseInt(pt.pivot['quantity'],10);
                }
            })

        },
        updateBom(){
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false,
            })

            let bom_to_change = this.$props.boms.find(bom => bom.id == this.assigned_bom_id);

            swalWithBootstrapButtons.fire({
                title: 'Are you sure?',
                text: "BOM for this project will be changed to " + bom_to_change.bom_identifier  +  ". If any inventory was already picked will be returned to inventory and will have to be picked again.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonClass: 'ml-2',
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {

                    let attributes = {'assigned_bom_id':this.assigned_bom_id};

                    console.log(attributes);

                    axios.patch("/projects/" + this.bom.project_id + "/bom/assigned_bom",attributes).then(response =>{
                        location.reload();
                    })
                }else if(result.dismiss === Swal.DismissReason.cancel){
                    location.reload()
                }
            });
        },
        inventoryWarehouse(){
            let ppp = this.product_id;
            let instance = this;
            axios.get('/api/warehouses/'+ ppp + '/' + this.warehousename).then(function(response) {
                instance.warehouseCount = response.data;
            }).catch(function(error){
                console.log(error);
            });
        },
        inventoryInWarehouse(product_id){

            let productID = product_id;
            let instance = this;
            //console.log(productID);

            axios.get('/inventory/products/getProductWarehouses/'+productID).then(function(response) {

                //console.log(response.data);
                //console.log({'ThisItem: ' : instance.warehouesWithProductInventory});
                instance.warehouesWithProductInventory = response.data;
                //console.log(instance.warehouesWithProductInventory);

            }).catch(function(error){
                console.log(error);
            });
        },

        updateWarehouse(product_id, warehouseOption_warehouseId){
            let product_edit = (this.form.products.find(product => product.id == product_id));
            //console.log({ 'old' : product_edit.pivot.warehouse_id});
            //console.log({ 'wH' : warehouseOption_warehouseId});
            let warehouse = this.warehouesWithProductInventory.find(w => w.id == warehouseOption_warehouseId);

            if(warehouse != null){
                product_edit.pivot.zone_options = warehouse.zones;
            }

            product_edit.pivot.warehouse_id = warehouseOption_warehouseId;
            //.log({ 'new' : product_edit.pivot.warehouse_id});

        },
        loadZones(){
            let warehouse = this.warehouse_options.find(warehouse => warehouse.id == this.warehousename);

            if(warehouse != null){
                this.zone_options = warehouse.zones;
            }
        },
        loadZones2(prdid,whid){
            // let product_edit = (this.form.products.find(product => product.id === prdid));
            // let warehouse = this.$props.warehouses.find(warehouse => warehouse.id === whid);
            // this.$props.bom.products.find(product => product.id === prdid).zone_options.splice(0);
            prdid.loadingZones = true;

            axios.get('/inventory/products/getZonesQuantity/' + prdid.id + '/' + whid).then(function(response) {

                console.log(response.data);
                prdid.zone_options = response.data;
                prdid.loadingZones = false;


            }).catch(function(error){
                console.log(error);
                prdid.loadingZones = false;
            });

            // prdid.zone_options =

            // product_edit.warehouses.forEach(product => {
            //             product.zones.forEach(zone => {
            //                 this.$props.bom.products.find(product => product.id === prdid).zone_options.push(zone);
            //             })
            //     }
            // )
        }
    }
}
</script>

<style scoped>

</style>
