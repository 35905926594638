<template>
    <div>
        <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
                <div class="card-body">
                    <h6 class="card-title">Add New BOM</h6>
                    <form @submit.prevent="submit">
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="bom_identifier">BOM Assignment</label>
                                    <dropbox-bom name="battery_type" id="bom_assignment" :options="boms" v-model="assigned_bom_id" v-on:change="updateBom">
                                        <option></option>
                                    </dropbox-bom>
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="system_size">System Size<span class="required_asterisk">*</span></label>
                                    <input type="text" class="form-control" name="system_size" id="system_size" placeholder="####" v-model="form.system_size">
                                </div>
                            </div>


                        </div>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="bom_identifier">BOM ID<span class="required_asterisk">*</span></label>
                                    <input type="text" class="form-control" name="bom_identifier" id="bom_identifier" placeholder="Bom ID" v-model="form.bom_identifier">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="battery_type">Battery Type<span class="required_asterisk">*</span></label>
                                    <dropbox name="battery_type" id="battery_type" :options="this.$props.battery_types" v-model="form.battery_type_id">
                                        <option></option>
                                    </dropbox>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <!--                            <div class="col-sm-6">-->
                            <!--                                <div class="form-group">-->
                            <!--                                    <label for="wh_product">Product Sold <span class="required_asterisk">*</span></label>-->
                            <!--                                    <dropbox name="wh_product" id="wh_product" :options="this.$props.wh_products" v-model="form.wh_product_id">-->
                            <!--                                        <option></option>-->
                            <!--                                    </dropbox>-->
                            <!--                                </div>-->
                            <!--                            </div>-->
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="description">Description</label>
                                    <input type="text" class="form-control" name="description" id="description" placeholder="" v-model="form.comment">
                                </div>
                            </div>
                            <div class="col-sm-6">
                                <div class="form-group">
                                    <label for="battery_qty">Battery Qty<span class="required_asterisk">*</span></label>
                                    <input type="text" :disabled="'disabled'" class="form-control" name="battery_qty" id="battery_qty" placeholder="" v-model="form.battery_qty">
                                </div>
                            </div>

                        </div>
                        <br>

                        <div class="row">
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="category">Category<span class="required_asterisk">*</span></label>
                                    <dropbox name="category" id="category" :options="this.$props.categories" v-model="category_id"  @change="loadProducts()">
                                        <option></option>
                                    </dropbox>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="category">Product<span class="required_asterisk">*</span></label>
                                    <dropbox name="product" id="product" :options="this.product_options" v-model="product_id"  @change="loadWarehouses(), inventoryWarehouse()"> <!---->
                                        <option></option>
                                    </dropbox>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="quantity">Quantity</label>
                                    <input type="number" oninput="validity.valid||(value='')" min="0" class="form-control" name="quantity" id="quantity" placeholder="" v-model="quantity">
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="category">Warehouse<span class="required_asterisk">*</span></label>
                                    <dropbox name="category" id="warehouse" :options="this.warehouse_options" v-model="warehousename" @change = "inventoryWarehouse()" >
                                        <option></option>
                                    </dropbox>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="form-group">
                                    <label for="zone">Zone<span class="required_asterisk">*</span></label>
                                    <dropbox name="zone" id="zone" :options="this.zone_options" v-model="zone">
                                        <option></option>
                                    </dropbox>
                                </div>
                            </div>
                        </div>
                        <div class="row mb-4">
                            <div class="col-md-4">
                                <button type="button" class="btn btn-primary mr-2" v-on:click="addProduct">Add Product</button>
                            </div>
                        </div>
                        <div class="row col-md-12 mb-4">
                            <table class="table table-bordered table-hover date-columns" id="product-table">
                                <thead>
                                <tr>
                                    <th>
                                        Category
                                    </th>
                                    <th>
                                        Warehouse
                                    </th>
                                    <th>
                                        Zone
                                    </th>
                                    <th>
                                        Product ID
                                    </th>
                                    <th>
                                        Product
                                    </th>
                                    <th>
                                        Description
                                    </th>
                                    <th>
                                        Amount
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <template v-for="(category,index_category) in categories">
                                    <tr>
                                        <td>{{category.name}}</td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                    </tr>
                                    <tr v-for="(product,index_product) in form.products.filter(item => item.category.id == category.id)">
                                        <td>
                                            <div class="row justify-content-center">
                                                <div style="cursor: pointer" class="mr-2" v-on:click="editProduct(product.id, product.warehousename)">
                                                    <edit-icon size="1.5x"></edit-icon>
                                                </div>
                                                <div style="cursor: pointer" v-on:click="deleteProduct(product)">
                                                    <trash2-icon size="1.5x"></trash2-icon>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div v-for="(warehouse, index) in product.warehouses.filter(item => item.id == product.warehousename)">
                                                <p>{{warehouse.name}}</p>
                                            </div>
                                        </td>
                                        <td>
                                            {{product.zonename}}
                                        </td>
                                        <td>{{product.sku}}</td>
                                        <td>{{product.name}}</td>
                                        <td>{{product.description}}</td>
                                        <td>{{product.quantity}}</td>
                                    </tr>
                                </template>
                                </tbody>
                            </table>
                        </div>

                        <div class="spinner-border text-primary my-3" v-if="submit_disabled" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                        <button :disabled="submit_disabled" type="submit" class="btn btn-primary mr-2">Submit</button>
                        <a href="/boms" class="btn btn-light">Cancel</a>
                    </form>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import Swal from "sweetalert2";
import { EditIcon, Trash2Icon} from 'vue-feather-icons';

export default {
    name: "BomCreateForm",
    props: ['products','categories','battery_types','boms', 'warehouses', 'zones'],
    components:{
        EditIcon, Trash2Icon
    },
    watch:{
        warehousename: {
            immediate: true,
            handler(newVal, oldVal){
                let warehouse = this.warehouse_options.find(warehouse => warehouse.id == newVal);

                if(warehouse != null){
                    this.zone_options = warehouse.zones;
                }
            }
        },
    },
    data(){
        return {
            form:{
                bom_identifier: "",
                system_size: "",
                battery_type: "",
                batter_type_id:"",
                comment: "",
                products: [],
                battery_qty: "",
                warehouses: [],
            },
            battery:"",
            product_options:[],
            category_id: "",
            product_id: "",
            quantity: "",
            submit_disabled: false,
            assigned_bom_id: "",
            warehouse_id: "",
            warehouse_options:[],
            zone_options: [],
            zone: "",
            warehousename:"",
            warehouseCount:""
        }
    },
    methods:{
        submit(){
            this.submit_disabled = true;

            axios.post("/boms",this.form).then(response =>{
                location = response.data.message;
            }).catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'There was a problem.',
                });

                this.submit_disabled = false;
            })
        },
        loadProducts(){
            if(this.category_id !== ""){
                this.product_options = this.$props.categories.find(category => category.id == this.category_id).products
            }
        },
        loadWarehouses(){
            if(this.category_id !== "" && this.product_id !== ""){
                this.warehouse_options = this.$props.products.find(product => product.id == this.product_id).warehouses
                this.warehousename = this.warehouse_options[0].id;
                this.zone_options = this.warehouse_options[0].zones;
                this.zone = this.warehouse_options[0].id;
                this.$nextTick(() => {
                    this.warehousename = this.warehouse_options[0].id;
                    this.zone_options = this.warehouse_options[0].zones;
                    this.zone = this.warehouse_options[0].id;
                });
            }
        },
        addProduct(){
            this.inventoryWarehouse();
            let sameProduct = (this.form.products.find(product => product.id == this.product_id && product.warehousename == this.warehousename) ); //<---
            if(sameProduct == null){
                if(this.product_id === ""){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Please select a product.',
                    });

                }else if(this.quantity == null || this.quantity === ''){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Please enter a order quantity greater than 0.',
                    });
                }else if(this.warehousename === ""){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Please select a warehouse.',
                    });
                // }else if(this.warehouseCount === 0 || this.warehouseCount === "" || this.quantity > this.warehouseCount){
                //     Swal.fire({
                //         icon: 'error',
                //         title: 'Oops...',
                //         text: 'Warehouse does not contain enough of that product in inventory. Quantity available in this warehouse: '+this.warehouseCount,
                //     });
                }else{
                    let product = {...this.$props.products.find(product => product.id == this.product_id)}/* && this.$props.products.*/;
                    // let bom_details = this.$props.boms
                    console.log(product);
                    product.quantity = this.quantity;
                    product.warehousename = this.warehousename;
                    product.warehouse = this.warehousename;
                    product.warehouse_id = this.warehouse_id;
                    product.warehouse_id = this.warehousename;
                    product.zone = this.zone;

                    let zone = this.zone_options.find(zoneO => zoneO.id == this.zone);
                    if(zone != null){
                        product.zonename = zone.name;
                    }else{
                        product.zonename = "";
                    }

                    this.warehouse_id = this.warehousename;



                    if(product.category.name === "Battery"){
                        if(this.form.battery_qty != "" && this.form.battery_qty != null){
                            console.log(this.form.battery_qty)
                            this.form.battery_qty = parseInt(this.form.battery_qty,10)
                            this.form.battery_qty += parseInt(this.quantity,10);
                        }else{
                            this.form.battery_qty = parseInt(this.quantity);
                        }
                    }

                    this.form.products.push(product)
                    this.category_id = "";
                    this.product_id = "";
                    this.quantity = "";
                    this.warehousename = "";
                    this.zone = "";
                }
            }else{
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Product already added. You can edit it in the table'
                })
            }
        },
        editProduct(id, warehouse_id){
            let component = this;
            let product_edit = (this.form.products.find(product => product.id == id && product.warehousename == warehouse_id));

            Swal.fire({
                title: product_edit.name,
                html:
                    '<label for="product_quantity">Order Qty<span class="required_asterisk">*</span></label>'+
                    '<input type="number"  oninput="validity.valid||(value=\'\')" min="0" id="product_quantity" class="swal2-input" value="' + product_edit.quantity + '">',
                focusConfirm: false,
                preConfirm() {
                    component.form.battery_qty = parseInt(component.form.battery_qty,10);
                    component.form.battery_qty -= parseInt(product_edit.quantity,10);
                    product_edit.quantity = document.getElementById('product_quantity').value;
                    component.form.battery_qty += parseInt(product_edit.quantity,10);
                }
            })
        },
        deleteProduct(product){
            console.log(product);
            if(product.category.name === "Battery"){
                this.form.battery_qty = parseInt(this.form.battery_qty,10);
                this.form.battery_qty -= parseInt(product.quantity,10);
            }
            this.form.products.splice(this.form.products.indexOf(product),1);
        },
        updateBom(){
            console.log('updeteando')
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false,
            })

            let bom_to_change = this.$props.boms.find(bom => bom.id === this.assigned_bom_id);

            swalWithBootstrapButtons.fire({
                title: 'Are you sure?',
                text: "BOM products will reset to the selected BOM.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonClass: 'ml-2',
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {

                    let attributes = {'assigned_bom_id':this.assigned_bom_id};

                    axios.get("/boms/"+this.assigned_bom_id+"/products",attributes).then(response =>{
                        console.log(response.data)

                        response.data.products.forEach(product =>{
                            product.quantity = product.pivot.quantity;
                            //-------
                            product.warehouses = product.warehouses;
                            product.warehousename = product.pivot.warehouse_id;
                            product.warehouse = product.pivot.warehouse_id;
                            product.warehouse_id = product.pivot.warehouse_id;
                            product.zone = product.pivot.zone_id;
                            this.warehouse_id = product.pivot.warehouse_id;
                            let zone = product.pivot.zone_id;
                            if(zone != null){
                                product.zonename = this.$props.zones.find(zone => zone.id === product.pivot.zone_id).name;
                                product.zone = product.pivot.zone_id;
                                product.zone_id = product.pivot.zone_id;
                            }else{
                                product.zone = "";
                                product.zone_id = "";
                                product.zonename = "";
                            }
                            //------
                            this.form.products.push(product)
                        })
                        this.form.products = response.data.products;
                        this.countBatteries();

                    })
                }else if(result.dismiss === Swal.DismissReason.cancel){
                    // location.reload()
                }
            });
        },
        countBatteries(){
            this.form.battery_qty = 0;
            this.form.products.forEach(pt => {
                if(pt.category.name === "Battery"){
                    this.form.battery_qty += parseInt(pt.pivot['quantity'],10);
                }
            })

        },
        inventoryWarehouse(){
            let ppp = this.product_id;
            let instance = this;
            axios.get('/api/warehouses/'+ ppp + '/' + this.warehousename).then(function(response) {
                instance.warehouseCount = response.data;
            }).catch(function(error){
                console.log(error);
            });
        }
    }
}
</script>

<style scoped>

</style>
