<template>
    <modal :name="'scan-cycle-count-item-modal'" :scrollable="true" height="auto" width="400px">
        <div v-if="modal_type == 'scan_location'" class="modal-content border-bottom-0" id="myModal" ref="vuemodal">
            <div class="modal-header">
                <div>
                    <div class="row justify-content-between">
                        <h4 class="modal-title">Scan Location</h4>
                    </div>
                </div>
            </div>
            <form @submit.prevent="submitLocation" id="form" name="form" :ref="'form'">
                <div class="modal-body">
                    <div>
                        <div style="text-align: center; text-align-all: center; align-self: center; align-content: center; align-items: center">
                            <input type="text" class="form-control-lg text-center" name="scan_location" id="scan_location" placeholder="Location" autofocus v-model="input_scan_location" v-on:keyup.enter="submitLocation(input_scan_location)">
                            <p style="color: red" :hidden="!input_empty_location">Location not found in system.</p>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light" @click="$modal.hide('scan-cycle-count-item-modal'), clear_all()">Close</button>
                    <button type="button" class="btn btn-primary mr-2" @click="submitLocation(input_scan_location)">Submit</button>
                </div>
            </form>
        </div>
        <div v-else-if="modal_type == 'scan_product'" class="modal-content border-bottom-0" id="scan_product" ref="vuemodal">
            <div class="modal-header">
                <div>
                    <div class="row justify-content-between">
                        <h4 class="modal-title">Scan Item</h4>
                    </div>
                </div>
            </div>
            <form @submit.prevent="submit_scan_product" id="scan_product_form" name="form" :ref="'scan_product'">
                <div class="modal-body justify-content-between">
                    <div style="text-align: center; text-align-all: center; align-self: center; align-content: center; align-items: center">
                        <input type="text" class="form-control-lg text-center" name="scan_item" id="scan_item" placeholder="Scan Item" autofocus v-model="input_scan" v-on:keyup.enter="submit_scan_product(input_scan)">
                        <div>
                            <p style="color: red" :hidden="!input_empty">SKU or UPC not found in the system.</p>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light" @click="$modal.hide('scan-cycle-count-item-modal'), clear_all()">Close</button>
                    <button type="button" class="btn btn-primary mr-2" @click="submit_scan_product(input_scan)">Submit</button>
                </div>
            </form>
        </div>
        <div v-if="modal_type === 'input_qty'">
            <div class="modal-header">
                <div>
                    <div class="row justify-content-between">
                        <h4 class="modal-title">Reconciliation Quantity</h4>
                    </div>
                </div>
            </div>
            <form @submit.prevent="submit_qty" id="form2" name="form2" :ref="'form2'">
                <div class="modal-body">
                    <div style="margin-bottom: 20px">
                        <h5 style="color: #f89b24">{{scanned_product.sku}} ({{scanned_product.name}})</h5>
                        <p class="text"><b>WHS:</b> {{warehouse_name}} / <b>ZN:</b> {{zone_name}} / <b>LOC:</b> {{location_name}}</p>
                    </div>
                    <div style="text-align: center; text-align-all: center; align-self: center; align-content: center; align-items: center; margin-top: 4px">
                        <div class="col-md-12 justify-content-between">
                            <div class="row-md-12 justify-content-between" style="align-items: center">
                                <input disabled autofocus oninput="validity.valid||(value='')" min="0" type="number" class="text-center form-control-lg mb-2" name="order_qty1" id="order_qty1"  placeholder="Input Qty." v-model="qty_reconciliation">
                            </div>
                            <div class="row-md-12 justify-content-between" style="align-items: center">
                                <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + 1;">1</button>
                                <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + 2;">2</button>
                                <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + 3;">3</button>
                            </div>
                            <div class="row-md-12 justify-content-between" style="align-items: center">
                                <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '4';">4</button>
                                <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '5';">5</button>
                                <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '6';">6</button>
                            </div>
                            <div class="row-md-12 justify-content-between" style="align-items: center">
                                <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '7';">7</button>
                                <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '8';">8</button>
                                <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '9';">9</button>
                            </div>
                            <div class="row-md-12 justify-content-between" style="align-items: center">
                                <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=null">C</button>
                                <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '0';">0</button>
                                <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value.slice(0,-1);">&lt;</button>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p v-if="qty_error" style="color: red">Please, input a valid quantity value.</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light" @click="$modal.hide('scan-cycle-count-item-modal'), clear_all()">Close</button>
                    <button type="button" class="btn btn-primary mr-2" v-on:click="submit_qty">Submit</button>
                </div>
            </form>
        </div>
    </modal>
</template>

<script>
import {trim} from "lodash/string";

export default {
    name: "ScanCycleCountItemModal",
    props: [
        'product',
        'warehouses',
        'zones',
        'locations',
        'index',
        'flagscan',
        'scan_flag',
        'new_warehouse_id',
        'new_zone_id',
        'new_location_id',
        'parent',
        'backup_warehouses',
        'backup_zones',
        'backup_locations',
        'return_flag',
        'path',
        'list_of_products',
        'scan_filtered_products',
        'inventory_products',
        'qty_to_add'
    ],
    mounted(){
        this.modal_type = 'scan_product';
    },
    data(){
        return {
            input_scan_location:'',
            input_empty_location:false,
            scanned_location:'',
            modal_type:'',
            scanned_product:'',
            input_scan:'',
            input_empty:false,
            qty_reconciliation:'',
            qty_error:false,
            warehouse_name:'',
            zone_name:'',
            location_name:''
        }
    },
    methods: {
        submitLocation(input_scan_location){
            if(this.input_scan_location === ''){
                this.input_empty_location = false;
            }
            for (let i = 0; i < this.$props.locations.length; i++) {
                if (this.input_scan_location.toLowerCase() === this.$props.locations[i].name.toLowerCase()) {
                    for (let j = 0; j < this.$props.zones.length; j++) {
                        if (this.$props.locations[i].zone_id === this.$props.zones[j].id) {
                            for (let k = 0; k < this.$props.warehouses.length; k++) {
                                if (this.$props.zones[j].warehouse_id === this.$props.warehouses[k].id) {
                                    let warehouse_id = this.$props.warehouses[k].id;
                                    let zone_id = this.$props.zones[j].id;
                                    let location_id = this.$props.locations[i].id;
                                    this.warehouse_name = this.$props.warehouses[k].name;
                                    this.zone_name = this.$props.zones[j].name;
                                    this.location_name = this.$props.locations[i].name;
                                    this.$props.parent.form.new_warehouse_id = warehouse_id;
                                    this.cycle_select_zone(zone_id, location_id);
                                    this.input_empty_location = false;
                                    break;
                                } else {
                                    this.input_empty_location = true;
                                }
                            }
                            break;
                        } else {
                            this.input_empty_location = true;
                        }
                    }
                    break;
                } else {
                    this.input_empty_location = true;
                }
            }
        },
        clear_all() {
            this.input_scan_location = '';
            this.input_scan = '';
            this.input_empty = false;
            this.modal_type = 'scan_product';
            setTimeout(function(){
                document.getElementById("scan_item").focus();
            },0)
        },
        cycle_select_zone(zone_id, location_id){
            setTimeout(() =>  {
                this.$props.parent.form.new_zone_id = zone_id;
                this.cycle_select_location(location_id);
            }, 200);
        },
        cycle_select_location(location_id){
            setTimeout(() => {
                this.$props.parent.form.new_location_id = location_id;
                this.modal_type = 'input_qty';
            }, 200);
        },
        cycle_count_search(){
            setTimeout(() => {
                this.$props.parent.getData(this.$props.path + '?' + this.$props.parent.getFilter());
                this.$props.parent.getScanProducts();
                this.$modal.hide('scan-cycle-count-item-modal');
            }, 200);
        },
        submit_scan_product(input_scan){
            this.input_scan = trim(this.input_scan);
            if (this.input_scan === '') {
                this.input_empty = true;
            }
            for (let i = 0; i < this.$props.inventory_products.length; i++) {
                let match = null;
                if(this.$props.inventory_products[i].upc != null){
                    this.upc_array = this.$props.inventory_products[i].upc.split(",");
                    match = this.getMatchingResults(this.upc_array).length > 0;
                }else{
                    match = this.input_scan === this.$props.inventory_products[i].upc;
                }
                if (this.input_scan.toLowerCase() === this.$props.inventory_products[i].sku.toLowerCase() || (match)){
                    this.input_empty = false;
                    this.scanned_product = this.$props.inventory_products[i];
                    setTimeout(() => {
                        this.$props.product = this.$props.inventory_products[i].id;
                        this.$props.parent.form.new_product_id = this.$props.inventory_products[i].id;
                    }, 200);
                    this.modal_type = 'scan_location'
                    setTimeout(function(){
                        document.getElementById("scan_location").focus();
                    },10)
                    break;
                } else {
                    this.input_empty = true;
                }
            }
        },
        getMatchingResults(upc_array) {
            return upc_array.filter(d => this.input_scan.split(',').every(v => d.toLowerCase() === v.toLowerCase()));
        },
        submit_qty(){
            if (document.getElementById('order_qty1').value === '') {
                this.qty_error = true;
            } else{
                this.qty_error = false;
                this.qty_to_add = document.getElementById('order_qty1').value;
                this.$props.parent.form.quantity_to_add = this.qty_to_add;
                this.$props.parent.addToIn();
            }
        }
    },
    watch: {
        flagscan: function() {
            this.clear_all();
            this.input_empty_location = false;
        }
    }
}
</script>

<style scoped>

</style>
