<template>
    <div class="col-md-6 grid-margin stretch-card">
        <div class="card">
            <div class="card-body">
                <form @submit.prevent="add" id="form" name="form" :ref="'form'">

                    <h6 class="card-title">{{name}}</h6>
                    <div class="table-responsive pt-3 mb-3">
                        <table class="table table-bordered table-hover date-columns">
                            <thead>
                            <tr>
                                <th>
                                    Name
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item,index) in dropdown.items">
                                <td>{{item.name}}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="form-group">
                        <label for="product_category_name">{{name}} Name<span class="required_asterisk">*</span></label>
                        <input type="text" class="form-control" name="product_category_name" id="product_category_name" placeholder="Name" value="id" v-model="dropdown.name">
                    </div>

                    <button type="submit" class="btn btn-primary mr-2">Add</button>
                </form>

            </div>
        </div>
    </div>
</template>

<script>
    import Swal from "sweetalert2";

    export default {
        name: "DropdownConfigComponent",
        props: ['name','dropdown','path'],
        methods:{
            add(){
                let component = this;
                let repeated_name = this.$props.dropdown.items.find(item => item.name == this.$props.dropdown.name);

                if(repeated_name != null){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: component.$props.name + ' name already in use.',
                    });
                }else{
                    if(this.$props.dropdown.name == "" || this.$props.dropdown.slug == ""){
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Please be sure to fill all the fields.',
                        });
                    }else{

                        let attributes = {dropdown:{
                                name: this.$props.dropdown.name
                        }}

                        axios.post('/admin/' + this.$props.path,attributes).then(response =>{
                            if(response.status){
                                this.$props.dropdown.items.push({name:this.$props.dropdown.name,slug:this.$props.dropdown.slug});
                                this.$props.dropdown.name = "";
                            }else{
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: 'There was a problem.',
                                });
                            }
                        });
                    }
                }
            },
        }
    }
</script>

<style scoped>

</style>
