<template>
    <div>

        <div class="row">
            <div class="col-md-6 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <form @submit.prevent="addProduct" id="form" name="form" :ref="'form'">

                            <h6 class="card-title">Product Category</h6>
                            <div class="table-responsive pt-3 mb-3">
                                <table class="table table-bordered table-hover date-columns">
                                    <thead>
                                    <tr>
                                        <th>
                                            Name
                                        </th>
                                        <th>
                                            Abbreviation
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(item,index) in product_category.items">
                                        <td>{{item.name}}</td>
                                        <td>{{item.slug}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="form-group">
                                <label for="product_category_name">Product Category Name<span class="required_asterisk">*</span></label>
                                <input type="text" class="form-control" name="product_category_name" id="product_category_name" placeholder="Name" value="id" v-model="product_category.name">
                            </div>
                            <div class="form-group">
                                <label for="product_category_slug">Product Category Abbreviation<span class="required_asterisk">*</span></label>
                                <input type="text" class="form-control" name="product_category_slug" id="product_category_slug" placeholder="Slug" value="id" v-model="product_category.slug">
                            </div>

                            <button type="submit" class="btn btn-primary mr-2">Add</button>
                        </form>

                    </div>
                </div>
            </div>
            <dropdown-config-component :name="'Roles'" :dropdown="role" :path="'role'"></dropdown-config-component>
        </div>
        <div class="row">
            <dropdown-config-component :name="'Tool Category'" :dropdown="tool_category" :path="'tool_category'"></dropdown-config-component>
            <dropdown-config-component :name="'Tool Brand'" :dropdown="tool_brand" :path="'tool_brand'"></dropdown-config-component>
            <dropdown-config-component :name="'Tool Type'" :dropdown="tool_type" :path="'tool_type'"></dropdown-config-component>
        </div>
        <div class="row">
            <dropdown-config-component :name="'Product Sold'" :dropdown="product_sold" :path="'product_sold'"></dropdown-config-component>
            <dropdown-config-component :name="'Inventory Quality Check'" :dropdown="quality_check" :path="'quality_check'"></dropdown-config-component>
        </div>
        <div class="row">
            <dropdown-config-component :name="'Tool Quality Check'" :dropdown="tool_quality_check" :path="'tool_quality_check'"></dropdown-config-component>
            <dropdown-config-component :name="'Battery Type'" :dropdown="battery_type" :path="'battery_type'"></dropdown-config-component>
        </div>
        <div class="row">
            <dropdown-config-component :name="'Tool Write Off Reason'" :dropdown="tool_reason" :path="'tool_writeoff_reason'"></dropdown-config-component>
            <dropdown-config-component :name="'Product Write Off Reason'" :dropdown="product_reason" :path="'product_writeoff_reason'"></dropdown-config-component>
        </div>
        <div class="row">
            <dropdown-config-component :name="'Project Priority'" :dropdown="priority" :path="'priority'"></dropdown-config-component>
            <dropdown-config-component :name="'Waiting Dock'" :dropdown="waiting_dock" :path="'waiting_dock'"></dropdown-config-component>
        </div>
    </div>
</template>

<script>
    import Swal from "sweetalert2";

    export default {
        name: "AdminComponent",
        props:[
            'product_categories',
            'roles',
            'tool_categories',
            'tool_brands',
            'product_solds',
            'quality_checks',
            'tool_quality_checks',
            'battery_types',
            'priorities',
            'waiting_docks',
            'tool_reasons',
            'product_reasons',
            'tool_types'
        ],
        mounted() {
        },
        data(){
            return{
                product_category:{
                    items:this.$props.product_categories,
                    text:"",
                    slug:""
                },
                role:{
                    items:this.$props.roles,
                    text:"",
                },
                tool_category:{
                    items:this.$props.tool_categories,
                    text:"",
                },
                tool_brand:{
                    items:this.$props.tool_brands,
                    name:"",
                },
                tool_type:{
                    items:this.$props.tool_types,
                    name:"",
                },
                product_sold:{
                    items:this.$props.product_solds,
                    name:"",
                },
                quality_check:{
                    items:this.$props.quality_checks,
                    name:"",
                },
                tool_quality_check:{
                    items:this.$props.tool_quality_checks,
                    name:"",
                },
                battery_type:{
                    items:this.$props.battery_types,
                    name:"",
                },
                priority:{
                    items:this.$props.priorities,
                    name:"",
                },
                waiting_dock:{
                    items:this.$props.waiting_docks,
                    name:"",
                },
                tool_reason:{
                    items:this.$props.tool_reasons,
                    name:"",
                },
                product_reason:{
                    items:this.$props.product_reasons,
                    name:"",
                },

            }
        },
        methods:{
            addProduct(){
                let component = this;
                let repeated_name = this.product_category.items.find(item => item.name == this.product_category.name);
                let repeated_slug = this.product_category.items.find(item => item.slug == this.product_category.slug);

                if(repeated_name != null){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: component.product_category.name + ' name already in use.',
                    });
                }else if(repeated_slug != null){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: component.product_category.slug + ' abbreviation already in use.',
                    });
                }else{
                    if(this.product_category.name == "" || this.product_category.slug == ""){
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Please be sure to fill all the fields.',
                        });
                    }else{

                        let attributes = {dropdown:{
                                name: this.product_category.name,
                                slug: this.product_category.slug
                            }}

                        axios.post('/admin/product_category',attributes).then(response =>{
                            if(response.status){
                                this.product_category.items.push({name:this.product_category.name,slug:this.product_category.slug});
                                this.product_category.name = "";
                                this.product_category.slug = "";
                            }else{
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: 'There was a problem.',
                                });
                            }
                        });
                    }
                }
            },
        }
    }
</script>

<style scoped>

</style>
