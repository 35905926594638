$(function(){

    $('#warehouse_product').on('change',function(e){
        let warehouse_id = e.target.value;

        axios.get('/masterdata/products_warehouse_zones/' + warehouse_id)
            .then(response => {
                $('#zone_product').empty();


                $('#zone_product').append('<option value="null">None</option>')
                response.data['zones'].forEach(zone =>
                    $('#zone_product').append('<option value="'+zone.id+'">'+zone.name+'</option>')
                )

                $('#location_product').empty();

                $('#location_product').append('<option value="null">None</option>')
            });
    })

    $('#zone_product').on('change',function(e){
        let zone_id = e.target.value;

        if(zone_id != null && zone_id !== 'null'){
            axios.get('/masterdata/products_warehouse_locations/' + zone_id)
                .then(response => {
                    $('#location_product').empty();

                    $('#location_product').append('<option value="null">None</option>')
                    if(response.data['locations']){
                        response.data['locations'].forEach(location =>
                            $('#location_product').append('<option value="'+location.id+'">'+location.name+'</option>')
                        )
                    }
                });
        }else{
            $('#location_product').empty();

            $('#location_product').append('<option value="null">None</option>')
        }
    })
})
