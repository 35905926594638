<template>
    <select multiple>
        <slot></slot>
    </select>
</template>

<script>
export default {
    name: "CheckOutToolMultiSelector",
    props: ['options', 'value'],
    data() {
        return {
            optionsArray: []
        }
    },
    mounted() {
        let vm = this;
        this.$props.options.forEach(option =>{
            let option_name = ''
            if(option.serial_code != null && option.serial_code != ''){
                option_name = option.tool_number + ' / ' + option.serial_code
            }else{
                option_name = option.tool_number
            }
            this.optionsArray.push({id:option.id,text:option_name})
        });

        $(this.$el)
            .select2({data: this.optionsArray, placeholder: "Search or select Serial or Tool Numbers", width: '100%'})
            .val(this.value)
            .trigger("change")
            .on("change", function () {
                vm.$emit("input", $(this).val());
            });
    },
    watch: {
        value: function (value) {

            if ([...value].sort().join(",") !== [...$(this.$el).val()].sort().join(",")) {
                $(this.$el).val(value).trigger('change');
            }
        },
        options: function (options) {
            $(this.$el)
                .empty().select2({
                data: options
            })
        }
    }
}
</script>

<style scoped>

</style>
