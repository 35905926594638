<template>
    <div class="row mb-2 justify-content-center align-items-center">
<!--        <div class="col-md-4">-->
            <a @click="disableBOM()">disable</a>
<!--        </div>-->
    </div>
</template>

<script>
import Swal from 'sweetalert2';
import moment from "moment";

export default {
    name: "DisableBomModal",
    props: {
        bom: Object,
    },
    mounted() {
    },
    data(){
        return{
            form:{

            },
            submit_disabled : false,
            moment: moment
        }
    },
    watch: {
        form:{
            handler (val){
                this.submit();
            },
            deep:true
        },
    },
    methods: {
        disableBOM(){
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false,
            });

            swalWithBootstrapButtons.fire({
                title: 'Are you sure?',
                text: "This will disable the selected BOM from the list permanently.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonClass: 'ml-2',
                confirmButtonText: 'Yes',
                cancelButtonText: 'Cancel',
                reverseButtons: true
            }).then((result) => {
                if (result.value) {
                    axios.patch('/boms/' + this.$props.bom.id + '/disable', this.$props.bom).then(response => {
                        if (response.data.error === 404) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: response.data.message,
                            });
                            this.submit_disabled = false;
                        } else {
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: 'Selected BOM was disabled.',
                                showConfirmButton: false,
                                timer: 3000,
                                toast:true
                            })
                            location.reload();
                        }
                    }).catch(error => {
                        if (error.response.status === 403) {
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'There was a problem.',
                            });
                        }
                        this.submit_disabled = false;
                    });
                }else if(result.dismiss === Swal.DismissReason.cancel){
                    swalWithBootstrapButtons.close();
                }
            });
        }
    }
}
</script>

<style scoped>

</style>

