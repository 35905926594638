<template>
    <div class="row mb-2 justify-content-end align-items-center">
        <div class="col-md-4">
            <label for="received_date">Installation Start Date<span class="required_asterisk">*</span></label>
        </div>
        <div class="col-md-6">
            <div class="input-group date datepicker dashboard-date mr-2 mb-2 mb-md-0 d-xl-flex">
                <span class="input-group-addon bg-transparent"><i data-feather="calendar" class=" text-primary"></i></span>
                <datePicker autocomplete="off" v-model="form.installation_scheduled_date" :config="datePickerOptions" name="received_date"></datePicker>
            </div>
        </div>
    </div>
</template>

<script>
    import Swal from 'sweetalert2';
    import moment from "moment";
    import datePicker from 'vue-bootstrap-datetimepicker';


    export default {
        name: "ChangeProjectDateModal",
        components:{
            datePicker
        },
        props: {
            project: Object,
        },
        mounted() {
        },
        data(){
            return{
                form:{
                    installation_scheduled_date: moment(this.$props.project.installation_scheduled_date).format('MM/DD/YYYY')
                },
                submit_disabled : false,
                datePickerOptions:{
                    format: 'MM/DD/YYYY',
                    useCurrent: false,
                },
                moment: moment
            }

        },
        watch: {
            form:{
                handler (val){
                    this.submit();
                },
                deep:true
            },
        },
        methods: {
            submit() {
                const swalWithBootstrapButtons = Swal.mixin({
                    customClass: {
                        confirmButton: 'btn btn-success',
                        cancelButton: 'btn btn-danger'
                    },
                    buttonsStyling: false,
                });

                swalWithBootstrapButtons.fire({
                    title: 'Are you sure?',
                    text: "This will change the Installation Start Date of the project.",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonClass: 'ml-2',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Cancel',
                    reverseButtons: true
                }).then((result) => {
                    if (result.value) {

                        axios.patch('/projects/' + this.$props.project.id + '/update_date', this.form).then(response => {
                            if (response.data.error == 404) {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: response.data.message,
                                });
                                this.submit_disabled = false;
                            } else {
                                location = response.data.message;
                                Swal.fire({
                                    position: 'top-end',
                                    icon: 'success',
                                    title: 'Installation Start Date Updated.',
                                    showConfirmButton: false,
                                    timer: 3000,
                                    toast:true
                                })
                            }
                        }).catch(error => {
                            if (error.response.status === 403) {
                                Swal.fire({
                                    icon: 'error',
                                    title: 'Oops...',
                                    text: 'There was a problem.',
                                });
                            }
                            this.submit_disabled = false;
                        });
                    }else if(result.dismiss === Swal.DismissReason.cancel){
                        location.reload()
                    }
                });

            }
        }
    }
</script>

<style scoped>

</style>

