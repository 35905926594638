<template>
    <select>
        <slot></slot>
    </select>
</template>

<script>
    export default {
        name: "SelectBox",
        props: ['options','value'],
        data(){
            return {
                optionsArray:[]
            }
        },
        mounted() {
            let vm = this;

            this.$props.options.forEach(option =>
                this.optionsArray.push({id:option.id,text:option.first_name + ' ' + option.last_name })
            );

            $(this.$el)
                .select2({data: this.optionsArray, placeholder: "Select one"})
                .val(this.value)
                .trigger("change")
                .on("change",function(){
                    vm.$emit("input",this.value);
                });
        },
        watch: {
            value: function (value) {
                let select = $(this.$el).select2();
                select.val(value).trigger("change");
                this.$emit('change',value);
            },
            options: function(options){
                let vm = this;
                this.optionsArray.splice(0,this.optionsArray.length);
                options.forEach(option =>
                    this.optionsArray.push({id:option.id,text:option.first_name + ' ' + option.last_name })
                );

                $(this.$el).empty()
                    .select2({data: this.optionsArray, placeholder: "Select one"})
                    .val(this.value)
                    .trigger("change")
                    .on("change",function(){
                        vm.$emit("input",this.value);
                    });
            }
        }
    }
</script>

<style scoped>

</style>
