<template>
    <modal :name="'scan-inventory-location-modal'" :scrollable="true" height="auto" width="400px">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Scan Location</h4>
                </div>
                <form @submit.prevent="submit" method="GET" id="scan_form" name="scan_form">
                    <div class="modal-body">
                        <input type="text" class="form-control" name="location" id="location" placeholder="Location" autofocus v-model="input_scan_location" v-on:keyup.enter="submit(input_scan_location)">
                        <p style="color: red" :hidden="!input_empty">Location not found.</p>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" @click="$modal.hide('scan-inventory-location-modal')">Close</button>
                        <button type="button" class="btn btn-primary mr-2" @click="submit(input_scan_location)">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    name: "ScanInventoryLocationModal",
    props: ['locations', 'flag'],
    data(){
        return {
            input_scan_location:'',
            input_empty:false,
            scanned_location:'',
            flag2:false
        }
    },
    methods: {
        submit(input_scan_location) {
            if(this.input_scan_location === ''){
                this.input_empty = true;
                console.log(this.input_empty);
            }
            for(let i = 0; i < this.$props.locations.length; i++){
                if (this.input_scan_location === this.$props.locations[i].name){
                    this.input_empty = false;
                    this.scanned_location = this.$props.locations[i];
                    this.flag2 = !this.flag2;

                    if ($('#inventory-select-location-table').is('table')) {
                        $('#inventory-select-location-table').DataTable().destroy()
                        $('#inventory-select-location-table').DataTable({
                            "aLengthMenu": [
                                [10, 30, 50, -1],
                                [10, 30, 50, "All"]
                            ],
                            "iDisplayLength": 10,
                            "language": {
                                search: ""
                            },
                            processing: true,
                            serverSide: true,
                            ajax: '/inventory_select_location_list/'+this.scanned_location.id,
                            columns: [
                                {data: 'name', name: 'name', orderable: false, searchable:false},
                                {data: 'sku', name: 'sku', orderable: false, searchable:false},
                                {data: 'description', name: 'description', orderable: false, searchable:false},
                                {data:'warehouse', name:'warehouse', orderable: false, searchable:false},
                                {data:'zone', name:'zone', orderable: false, searchable:false},
                                {data: 'quantity', name: 'quantity'},
                            ],
                            search: {
                                "regex": true
                            }
                        });
                        $('#inventory-select-location-table').each(function () {
                            var datatable = $(this);
                            // SEARCH - Add the placeholder for Search and Turn this into in-line form control
                            var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
                            search_input.attr('placeholder', 'Search');
                            search_input.removeClass('form-control-sm');
                            // LENGTH - Inline-Form control
                            var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
                            length_sel.removeClass('form-control-sm');
                        });
                    }
                    this.$modal.hide('scan-inventory-location-modal');
                    break;
                }else{
                    this.input_empty = true;
                }
            }
        },
        clear_all(){
            console.log('Clear')
            this.input_scan_location = '';
            this.input_empty = false;
            setTimeout(function(){
                document.getElementById("location").focus();
            },0)
        }
    },
    watch: {
        flag: function() {
            this.clear_all();
            this.input_empty = false;
        }
    }
}
</script>

<style scoped>

</style>
