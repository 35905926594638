<template>
    <modal :name="'scan-location-modal'" :scrollable="true" height="auto" width="400px">
        <div class="modal-content border-bottom-0" id="myModal" ref="vuemodal">
            <div class="modal-header">
                <div>
                    <div class="row justify-content-between">
                        <h4 class="modal-title">Scan Location</h4>
                    </div>
                </div>
            </div>
            <form @submit.prevent="submit" id="form" name="form" :ref="'form'">
                <div class="modal-body">
                    <div>
                        <div style="text-align: center; text-align-all: center; align-self: center; align-content: center; align-items: center">
                            <input type="text" class="form-control-lg text-center" name="scan_location" id="scan_location" placeholder="Location" autofocus v-model="input_scan_location" v-on:keyup.enter="submit(input_scan_location, index)">
                            <p style="color: red" :hidden="!input_empty_location">Location not found for scanned product.</p>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light" @click="$modal.hide('scan-location-modal'), clear_all()">Close</button>
                    <button type="button" class="btn btn-primary mr-2" @click="submit(input_scan_location, index)">Submit</button>
                </div>
            </form>
        </div>
    </modal>
</template>

<script>
export default {
    name: "ScanLocationModal",
    props: [
        'product',
        'warehouses',
        'zones',
        'locations',
        'index',
        'flag1',
        'scan_flag',
        'new_warehouse_id',
        'new_zone_id',
        'new_location_id',
        'parent',
        'backup_warehouses',
        'backup_zones',
        'backup_locations',
        'return_flag'
    ],
    mounted(){

    },
    data(){
        return {
            input_scan_location:'',
            input_empty_location:false,
            scanned_location:''
        }
    },
    methods: {
        submit(input_scan_location, index){
            if(this.input_scan_location === ''){
                this.input_empty_location = false;
            }
            if(typeof(index) !== 'undefined') {
                if (index !== 2) {
                    if(index === 'return_component'){
                        for (let i = 0; i < this.$props.locations.length; i++) {
                            if (this.input_scan_location.toLowerCase() === this.$props.locations[i].name.toLowerCase()) {
                                for (let j = 0; j < this.$props.zones.length; j++) {
                                    if (this.$props.locations[i].zone_id === this.$props.zones[j].id) {
                                        for (let k = 0; k < this.$props.product.warehouses.length; k++) {
                                            if (this.$props.zones[j].warehouse_id === this.$props.product.warehouses[k].id) {
                                                let warehouse_id = this.$props.product.warehouses[k].id;
                                                let zone_id = this.$props.zones[j].id;
                                                let location_id = this.$props.locations[i].id;
                                                this.$props.parent.changeProductReturnLocation(warehouse_id, zone_id, location_id);
                                                this.input_empty_location = false;
                                                break;
                                            } else {
                                                this.input_empty_location = true;
                                            }
                                        }
                                        break;
                                    } else {
                                        this.input_empty_location = true;
                                    }
                                }
                                break;
                            } else {
                                this.input_empty_location = true;
                            }
                        }
                    }else {
                        for (let i = 0; i < this.$props.locations.length; i++) {
                            if (this.input_scan_location.toLowerCase() === this.$props.locations[i].name.toLowerCase()) {
                                for (let j = 0; j < this.$props.zones.length; j++) {
                                    if (this.$props.locations[i].zone_id === this.$props.zones[j].id) {
                                        for (let k = 0; k < this.$props.product.warehouses.length; k++) {
                                            if (this.$props.zones[j].warehouse_id === this.$props.product.warehouses[k].id) {
                                                this.$props.product.warehouse_id = this.$props.product.warehouses[k].id;
                                                let zone_id = this.$props.zones[j].id;
                                                let location_id = this.$props.locations[i].id;
                                                this.select_zone(zone_id, location_id, index);
                                                this.input_empty_location = false;
                                                break;
                                            } else {
                                                this.input_empty_location = true;
                                            }
                                        }
                                        break;
                                    } else {
                                        this.input_empty_location = true;
                                    }
                                }
                                break;
                            } else {
                                this.input_empty_location = true;
                            }
                        }
                    }
                } else {
                    for (let i = 0; i < this.$props.backup_locations.length; i++) {
                        if (this.input_scan_location.toLowerCase() === this.$props.backup_locations[i].name.toLowerCase()) {
                            for (let j = 0; j < this.$props.backup_zones.length; j++) {
                                if (this.$props.backup_locations[i].zone_id === this.$props.backup_zones[j].id) {
                                    for (let k = 0; k < this.$props.backup_warehouses.length; k++) {
                                        if (this.$props.backup_zones[j].warehouse_id === this.$props.backup_warehouses[k].id) {
                                            let warehouse_id = this.$props.backup_warehouses[k].id;
                                            let zone_id = this.$props.backup_zones[j].id;
                                            let location_id = this.$props.backup_locations[i].id;
                                            this.select_warehouse(warehouse_id, zone_id, location_id, index);
                                            this.input_empty_location = false;
                                            break;
                                        } else {
                                            this.input_empty_location = true;
                                        }
                                    }
                                    break;
                                } else {
                                    this.input_empty_location = true;
                                }
                            }
                            break;
                        } else {
                            this.input_empty_location = true;
                        }
                    }
                }
            }
        },
        select_warehouse(warehouse_id,zone_id, location_id, index){
            this.$props.scan_flag = true;
            setTimeout(() =>  {
                this.$props.parent.form.new_warehouse_id = warehouse_id;
                this.select_zone(zone_id,location_id, index);
            }, 5);
        },
        select_zone(zone_id, location_id, index){
            if(index!==2){
                setTimeout(() =>  {
                    this.$props.product.zone_id = zone_id;
                    this.select_location(location_id, index);
                }, 5);
            }else{
                setTimeout(() =>  {
                    this.$props.parent.form.new_zone_id = zone_id;
                    this.$props.parent.changeLocation(zone_id);
                    this.select_location(location_id, index);
                }, 350);
            }
        },
        select_location(location_id, index){
            if(index !==2){
                setTimeout(() =>  {
                    this.$props.product.location_id = location_id;
                },5);
            }else{
                setTimeout(() =>  {
                    this.$props.parent.form.new_location_id = location_id;
                }, 500);
            }
            this.input_scan_location = '';
            this.input_empty_location = false;
            this.$modal.hide('scan-location-modal');
        },
        clear_all() {
            console.log('Clear Location')
            this.input_scan_location = '';
            setTimeout(function () {
                document.getElementById("scan_location").focus();
            }, 0)
        }
    },
    watch: {
        flag1: function() {
            this.clear_all();
            this.input_empty_location = false;
        }
    }
}
</script>

<style scoped>

</style>
