<template style="border-style: solid; border-color: red">
    <modal :name="'return-scan-item-modal-'+index" :scrollable="true" height="auto" width="400px" v-on:close="clear_all">
        <div class="modal-content border-bottom-0" id="myModal" ref="vuemodal">
            <div class="modal-header">
                <div>
                    <div class="row justify-content-between">
                        <h4 v-if="modal_type === 'scan_item_product'" class="modal-title">Scan Item to Return</h4>
                        <h4 v-if="modal_type === 'input_qty'" class="modal-title">Input Qty. to Return</h4>
                        <h4 v-if="modal_type === 'select_quality'" class="modal-title">Select quality of product</h4>
                        <h4 v-if="modal_type === 'scan_location'" class="modal-title">Scan Location to Return</h4>
                        <h4 v-if="modal_type === 'input_serials'" class="modal-title">Scan Serial Numbers to Return</h4>
                    </div>
                </div>
            </div>
            <div v-if="modal_type === 'scan_item_product'">
                <form @submit.prevent="submit" id="form" name="form" :ref="'form'">
                    <div class="modal-body justify-content-between">
                        <div style="text-align: center; text-align-all: center; align-self: center; align-content: center; align-items: center">
                            <input type="text" class="form-control-lg text-center" name="scan_item" id="scan_item" placeholder="Scan Item" autofocus v-model="input_scan" v-on:keyup.enter="submit(input_scan, index)">
                            <p style="color: red" :hidden="!already_in_list">Product Already added to the list of returns.</p>
                            <p style="color: red" :hidden="!input_empty">SKU or UPC not found in Dispatched Inventory.</p>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" @click="$modal.hide('return-scan-item-modal-'+index), clear_all()">Close</button>
                        <button type="button" class="btn btn-primary mr-2" @click="submit(input_scan, index)">Submit</button>
                    </div>
                </form>
            </div>
            <div v-if="modal_type === 'input_qty'">
                <form @submit.prevent="submit_qty" id="form2" name="form2" :ref="'form2'">
                    <div class="modal-body">
                        <div style="margin-bottom: 20px">
                            <h5 style="color: #f89b24">{{scanned_product.sku}} ({{scanned_product.name}})</h5>
                            <p><b>Dispatched Qty.: </b>{{scanned_product.pivot.quantity_picked}}</p>
                        </div>
                        <div style="text-align: center; text-align-all: center; align-self: center; align-content: center; align-items: center; margin-top: 4px">
                            <div class="col-md-12 justify-content-between">
                                <div class="row-md-12 justify-content-between" style="align-items: center">
                                    <input disabled autofocus oninput="validity.valid||(value='')" min="0" type="number" class="text-center form-control-lg mb-2" name="order_qty1" id="order_qty1"  placeholder="Qty. to Return" v-model="qty_returned">
                                </div>
                                <div class="row-md-12 justify-content-between" style="align-items: center">
                                    <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + 1;">1</button>
                                    <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + 2;">2</button>
                                    <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + 3;">3</button>
                                </div>
                                <div class="row-md-12 justify-content-between" style="align-items: center">
                                    <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '4';">4</button>
                                    <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '5';">5</button>
                                    <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '6';">6</button>
                                </div>
                                <div class="row-md-12 justify-content-between" style="align-items: center">
                                    <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '7';">7</button>
                                    <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '8';">8</button>
                                    <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '9';">9</button>
                                </div>
                                <div class="row-md-12 justify-content-between" style="align-items: center">
                                    <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=null">C</button>
                                    <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '0';">0</button>
                                    <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value.slice(0,-1);">&lt;</button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p v-if="quantity_error" style="color: red">Please, input a valid quantity value.</p>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" @click="$modal.hide('return-scan-item-modal-'+index), clear_all()">Close</button>
                        <button type="button" class="btn btn-primary mr-2" v-on:click="submit_qty">Submit</button>
                    </div>
                </form>
            </div>
            <div v-if="modal_type === 'select_quality'">
                <form @submit.prevent="submit_quality" id="form3" name="form3" :ref="'form3'">
                    <div class="modal-body">
                        <div style="margin-bottom: 20px">
                            <h5 style="color: #f89b24">{{scanned_product.sku}} ({{scanned_product.name}})</h5>
                            <p><b>Qty. to Return: </b>{{scanned_product.returned_qty}}</p>
                        </div>
                        <div style="margin-top: 4px; margin-left: 6px">
                            <div class="col-md-12">
                                <label class="container">Good
                                    <input type="radio" checked name="radio" :value="$props.quality.find(quality => quality.slug == 'good').id" v-model="quality_picked">
                                    <span class="checkmark"></span>
                                </label>
                                <label class="container">RMA
                                    <input type="radio" name="radio" :value="$props.quality.find(quality => quality.slug == 'rma').id" v-model="quality_picked">
                                    <span class="checkmark"></span>
                                </label>
                                <label class="container">Damaged
                                    <input type="radio" name="radio" :value="$props.quality.find(quality => quality.slug == 'damaged').id" v-model="quality_picked">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                        <div>
                            <p v-if="quality_error" style="color: red">Please, select a quality value for the returned product.</p>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" @click="$modal.hide('return-scan-item-modal-'+index), clear_all()">Close</button>
                        <button type="button" class="btn btn-primary mr-2" v-on:click="submit_quality">Submit</button>
                    </div>
                </form>
            </div>
            <div v-if="modal_type === 'input_serials'">
                <div class="modal-header justify-content-between">
                    <div>
                        <div class="row">
                            <h5 class="modal-title" style="color: #f89b24">{{scanned_product.sku + ' ('+scanned_product.name+')'}}</h5>
                        </div>
                        <div class="row">
                            <p class="text-right"><b>Return Serials:</b> {{scanned_product.serial_numbers.length}} / {{scanned_product.pivot.quantity_picked}}</p>
                        </div>
                        <div class="mt-3 row">
                            <h6 class="mr-2 p-2" v-on:click="changeScreen('input')" v-bind:class="{'modal-serial-selected': isInput}" style="cursor: pointer">Scan Serial Numbers</h6>
                            <h6 class="p-2" v-on:click="changeScreen('list')" v-bind:class="{'modal-serial-selected':isList}" style="cursor: pointer">Item Serial Numbers</h6>
                        </div>
                    </div>
                </div>
                <form @submit.prevent="submit_serials" id="form4" name="form4" :ref="'form4'">
                    <div class="modal-body">
                        <div class="form-group" v-if="screen == 'input'">
                            <textarea v-on:keydown.enter.prevent='inputSerial' autofocus autocomplete="off" type="text" rows="4" class="form-control" name="serial_number" id="serial_number" placeholder="Input Serial Numbers" v-model="serial_number_scanned"></textarea>
                            <button type="button" class="btn btn-outline-primary mt-2" v-if="screen === 'input'" @click="inputSerial()">Add S/N</button>
                            <button type="button" class="btn btn-primary mt-2 ml-2" v-if="screen === 'input'" @click="clearSerialInput()">Clear</button>
                        </div>
                        <div v-if="screen == 'list'">
                            <div class="table-responsive pt-3">
                                <table class="table table-bordered table-hover date-columns">
                                    <thead>
                                    <tr>
                                        <th>
                                            Serial Numbers
                                        </th>
                                        <th>
                                            Action
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(serial,index) in $props.dispatched_record.filter(record => record.product_id == scanned_product.id && (record.last_history.warehouse_id == null ? true :scanned_product.pivot.warehouse_id == record.last_history.warehouse_id))">
                                        <td>{{serial.serial_number}}</td>
                                        <td v-if="typeof(scanned_product.serial_numbers.find(s => s == serial.id)) == 'undefined'" style="color: mediumseagreen"><p style="cursor: pointer;" @click="selectSerial(serial)">Select</p></td>
                                        <td v-else style="color: red"><p style="cursor: pointer;" @click="removeSerial(serial)">Remove</p></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" @click="$modal.hide('return-scan-item-modal-'+index), clear_all()">Close</button>
                        <button type="button" class="btn btn-primary mr-2" v-on:click="submit_serials()">Submit</button>
                    </div>
                </form>
            </div>
            <div v-if="modal_type === 'scan_location'">
                <form @submit.prevent="submit_location" id="form5" name="form5" :ref="'form5'">
                    <div class="modal-body justify-content-between">
                        <div style="margin-bottom: 20px">
                            <h5 style="color: #f89b24">{{scanned_product.sku}} ({{scanned_product.name}})</h5>
                            <p><b>Qty. to Return: </b>{{scanned_product.returned_qty}}</p>
                        </div>
                        <div style="text-align: center; text-align-all: center; align-self: center; align-content: center; align-items: center; margin-top: 4px">
                            <div>
                                <input type="text" class="form-control-lg text-center" name="scan_location" id="scan_location" placeholder="Location" v-model="input_scan_location" v-on:keyup.enter="submit_location(input_scan_location)">
                                <p style="color: red" :hidden="!input_empty_location">Please, input a valid location.</p>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" @click="$modal.hide('return-scan-item-modal-'+index), clear_all()">Close</button>
                        <button type="button" class="btn btn-primary mr-2" @click="submit_location(input_scan_location)">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </modal>
</template>

<script>
import ReceivingDetailsModal from "./ReceivingDetailsModal";
import ScanReturnsModal from "./ScanReturnsModal";
import Swal from "sweetalert2";

export default {
    name: "ReturnScanItemModal",
    props: ['index', 'products','flag', 'warehouses', 'zones', 'locations', 'product_returns', 'parent', 'returned_list', 'dispatched_record', 'quality'],
    components:{
        ReceivingDetailsModal,
        ScanReturnsModal
    },
    mounted(){
        this.modal_type = 'scan_item_product';
    },
    data(){
        return {
            input_scan:'',
            input_empty:false,
            scanned_product:'',
            flag2:false,
            flag_return:false,
            already_in_list:false,
            upc_array:Array,
            modal_type:'',
            qty_returned:'',
            quantity_error:false,
            product_quality:'',
            quality_picked:'',
            quality_error:false,
            product_index:'',
            input_empty_location:false,
            scanned_location:'',
            input_scan_location:'',
            screen:"input",
            isInput:true,
            isList:false,
            isScan:false,
            serial_number_scanned:'',
            serials_error: false
        }
    },
    methods:{
        submit(input_scan, index){
            if(typeof(index) !== 'undefined') {
                if (this.input_scan === '') {
                    this.input_empty = true;
                }
                if (index === 'return_component') {
                    if (this.$props.returned_list.length > 0) {
                        for (let a = 0; a < this.$props.returned_list.length; a++) {
                            let match = null;
                            if(this.$props.returned_list[a].upc != null){
                                this.upc_array = this.$props.returned_list[a].upc.split(",");
                                match = this.getMatchingResults(this.upc_array).length > 0;
                            }else{
                                match = this.input_scan === this.$props.returned_list[a].upc;
                            }
                            if (this.input_scan.toLowerCase() === this.$props.returned_list[a].sku.toLowerCase() || (match)){
                                this.already_in_list = true;
                                break;
                            }else{
                                this.already_in_list = false;
                            }
                        }
                    }
                    if (this.already_in_list === false) {
                        for (let i = 0; i < this.$props.products.length; i++) {
                            let match = null;
                            if(this.$props.products[i].upc != null){
                                this.upc_array = this.$props.products[i].upc.split(",");
                                match = this.getMatchingResults(this.upc_array).length > 0;
                            }else{
                                match = this.input_scan === this.$props.products[i].upc;
                            }
                            if (this.input_scan.toLowerCase() === this.$props.products[i].sku.toLowerCase() || (match)){
                                this.input_empty = false;
                                this.scanned_product = this.$props.products[i];
                                this.flag_return = !this.flag_return;
                                this.$props.parent.filter = this.input_scan;
                                this.product_index = i;
                                if(!this.scanned_product.use_serials){
                                    this.modal_type = 'input_qty';
                                }else{
                                    this.modal_type = 'input_serials';
                                    setTimeout(() =>  {
                                        document.getElementById("serial_number").focus();
                                    },1);
                                }
                                break;
                            } else {
                                this.input_empty = true;
                            }
                        }
                    }
                }
            }
        },
        getMatchingResults(upc_array) {
            return upc_array.filter(d => this.input_scan.split(',').every(v => d.toLowerCase() === v.toLowerCase()));
        },
        submit_qty(){
            this.qty_returned = document.getElementById('order_qty1').value;
            if(this.qty_returned === '' || this.qty_returned < 1 || this.qty_returned > this.scanned_product.pivot.quantity_picked){
                this.quantity_error = true;
            }else{
                this.$props.product_returns[0].returned_qty = this.qty_returned;
                this.scanned_product.returned_qty = this.qty_returned;
                this.modal_type = 'select_quality'
                this.quantity_error = false;
            }
        },
        submit_quality(){
            if(this.quality_picked != ''){
                this.$props.product_returns[0].quality_check = this.quality_picked;
                this.scanned_product.quality_check = this.quality_picked;
                if(this.quality_picked == this.$props.quality.find(quality => quality.slug == 'good').id){
                    const swalWithBootstrapButtons = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn btn-success',
                            cancelButton: 'btn btn-danger'
                        },
                        buttonsStyling: false,
                    })
                    swalWithBootstrapButtons.fire({
                        title: 'Return to this location?',
                        text: "North Material",
                        icon: 'question',
                        showCancelButton: true,
                        cancelButtonClass: 'ml-4',
                        confirmButtonText: 'Yes',
                        cancelButtonText: 'No',
                        reverseButtons: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.scanned_product.warehouse_id_scan = this.$props.warehouses.find(w => w.name == 'Sabana Seca').id;
                            this.scanned_product.zone_id_scan = this.$props.zones.find(z => z.name == 'NORTH Material').id;
                            this.scanned_product.location_id_scan = this.$props.locations.find(l => l.name == 'Material').id;
                            this.$props.parent.returnProduct(this.scanned_product, 0, 'return_component');
                            // this.$modal.hide('return-scan-item-modal-' + this.$props.index);
                            const Toast = Swal.mixin({
                                toast: true,
                                position: 'center',
                                showConfirmButton: false,
                                timer: 1000
                            });
                            Toast.fire({
                                icon: 'success',
                                title: 'Scanned Successfully!'
                            })
                            this.clear_all();
                            this.modal_type = 'scan_item_product';
                        }else{
                            if(result.dismiss == "backdrop"){
                                console.log('BACKDROP');
                            }else{
                                this.modal_type = 'scan_location'
                                setTimeout(function () {
                                    document.getElementById("scan_location").focus();
                                }, 350)
                            }
                        }
                    });
                }else{
                    this.scanned_product.warehouse_id_scan = this.$props.warehouses.find(w => w.name == 'Sabana Seca').id;
                    this.scanned_product.zone_id_scan = this.$props.zones.find(z => z.name == 'NORTH Material').id;
                    this.scanned_product.location_id_scan = this.$props.locations.find(l => l.name == 'Material').id;
                    this.$props.parent.returnProduct(this.scanned_product, 0, 'return_component');
                    // this.$modal.hide('return-scan-item-modal-' + this.$props.index);
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'center',
                        showConfirmButton: false,
                        timer: 1000
                    });
                    Toast.fire({
                        icon: 'success',
                        title: 'Scanned Successfully!'
                    })
                    this.clear_all();
                    this.modal_type = 'scan_item_product';
                }
            }else{
                this.quality_error = true;
            }
        },
        submit_location(){
            if(this.input_scan_location == ''){
                this.input_empty_location = true;
            }else{
                for (let i = 0; i < this.$props.locations.length; i++) {
                    if (this.input_scan_location === this.$props.locations[i].name) {
                        for (let j = 0; j < this.$props.zones.length; j++) {
                            if (this.$props.locations[i].zone_id === this.$props.zones[j].id) {
                                for (let k = 0; k <  this.scanned_product.warehouses.length; k++) {
                                    if (this.$props.zones[j].warehouse_id ===  this.scanned_product.warehouses[k].id) {
                                        let warehouse_id =  this.scanned_product.warehouses[k].id;
                                        let zone_id = this.$props.zones[j].id;
                                        let location_id = this.$props.locations[i].id;
                                        if (!this.$props.returned_list.includes(this.scanned_product)) {
                                            this.scanned_product.warehouse_id_scan = warehouse_id;
                                            this.scanned_product.zone_id_scan = zone_id;
                                            this.scanned_product.location_id_scan = location_id;
                                            this.$props.parent.change_qty(this.scanned_product, this.scanned_product.id)
                                            this.$props.parent.returnProduct(this.scanned_product, 0, 'return_component');
                                            this.$props.parent.filter = '';
                                            this.input_empty_location = false;
                                            const Toast = Swal.mixin({
                                                toast: true,
                                                position: 'center',
                                                showConfirmButton: false,
                                                timer: 1000
                                            });
                                            Toast.fire({
                                                icon: 'success',
                                                title: 'Scanned Successfully!'
                                            })
                                            this.clear_all();
                                            this.modal_type = 'scan_item_product';
                                        }
                                        break;
                                    } else {
                                        this.input_empty_location = true;
                                    }
                                }
                                break;
                            } else {
                                this.input_empty_location = true;
                            }
                        }
                        break;
                    } else {
                        this.input_empty_location = true;
                    }
                }
            }
        },
        submit_serials(){
            if(this.scanned_product.serial_numbers.length < 1){
                this.serials_error = true;
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'center',
                    showConfirmButton: false,
                    timer: 2000
                });
                Toast.fire({
                    icon: 'error',
                    title: 'Please, scan at least one S/N.'
                })
            }else{
                this.scanned_product.returned_qty = this.scanned_product.serial_numbers.length;
                this.modal_type = 'select_quality'
                this.serials_error = false;
                this.quantity_error = false;
            }
        },
        changeScreen(value){
            if(value === 'list'){
                this.screen = 'list';
                this.isInput = false;
                this.isList = true;
                this.isScan = false;
            }else if(value === 'input'){
                this.screen = 'input';
                this.isInput = true;
                this.isList = false;
                this.isScan = false;
            }
            setTimeout(() =>  {
                document.getElementById("serial_number").focus();
            },1);
        },
        inputSerial(){
            let serials = this.serial_number_scanned.replaceAll('\n','').replaceAll('\t', '').replaceAll(' ', '').split(',');
            serials.forEach(serial => {
                let exist_serial = this.$props.dispatched_record.find(code => code.serial_number === serial);
                if(typeof(exist_serial) !== 'undefined'){
                    this.scanned_product.serial_numbers.push(exist_serial.id);
                    this.serial_number_scanned = '';
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'center',
                        showConfirmButton: false,
                        timer: 1000
                    });
                    Toast.fire({
                        icon: 'success',
                        title: 'Scanned Successfully!'
                    })
                }
            });
        },
        clearSerialInput(){
            this.serial_number_scanned = '';
            setTimeout(() =>  {
                document.getElementById("serial_number").focus();
            },1);
        },
        selectSerial(serial){
            this.scanned_product.serial_numbers.push(serial.id);
        },
        removeSerial(serial){
            this.scanned_product.serial_numbers.splice(this.scanned_product.serial_numbers.findIndex(s => s == serial.id),1);
        },
        clear_all(){
            console.log('Clear')
            this.$props.parent.filter = '';
            this.serial_number_scanned = '';
            this.input_scan = '';
            this.already_in_list = false;
            this.input_empty = false;
            this.quantity_error = false;
            this.qty_returned = '';
            setTimeout(function(){
                document.getElementById("scan_item").focus();
            },0)
        }
    },
    watch: {
        flag: function() {
            this.clear_all();
            this.modal_type = 'scan_item_product';
            this.input_empty = false;
        }
    }
}
</script>

<style scoped>
.container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
/* Hide the browser's default radio button */
.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
}
/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
    background-color: #ccc;
}
.container input:checked ~ .checkmark {
    background-color: #F89B24FF;
}
/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}
/* Show the indicator (dot/circle) when checked */
.container input:checked ~ .checkmark:after {
    display: block;
}
/* Style the indicator (dot/circle) */
.container .checkmark:after {
    top: 9px;
    left: 9px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}
</style>
