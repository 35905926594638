$(function() {
    'use strict';

    $(function() {
        if($('#new-projects').is('table')){

            $('#new-projects').DataTable({
                "aLengthMenu": [
                    [10, 30, 50, -1],
                    [10, 30, 50, "All"]
                ],
                "iDisplayLength": 10,
                "language": {
                    search: ""
                }
            });
            $('#new-projects').each(function() {
                var datatable = $(this);
                // SEARCH - Add the placeholder for Search and Turn this into in-line form control
                var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
                search_input.attr('placeholder', 'Search');
                search_input.removeClass('form-control-sm');
                // LENGTH - Inline-Form control
                var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
                length_sel.removeClass('form-control-sm');
            });
        }
    });

    $(function() {
        if($('#new-projects-table').is('table')){

            $('#new-projects-table').DataTable({
                "aLengthMenu": [
                    [10, 30, 50, -1],
                    [10, 30, 50, "All"]
                ],
                "iDisplayLength": 10,
                "language": {
                    search: ""
                },
                "columnDefs": [
                    { "type": "date", "targets": 2 }
                ]
            });
            $('#new-projects-table').each(function() {
                var datatable = $(this);
                // SEARCH - Add the placeholder for Search and Turn this into in-line form control
                var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
                search_input.attr('placeholder', 'Search');
                search_input.removeClass('form-control-sm');
                // LENGTH - Inline-Form control
                var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
                length_sel.removeClass('form-control-sm');
            });
        }
    });

    $(function() {
        if($('#history-po-table').is('table')){

            $('#history-po-table').DataTable({
                "aLengthMenu": [
                    [10, 30, 50, -1],
                    [10, 30, 50, "All"]
                ],
                "iDisplayLength": 10,
                "language": {
                    search: ""
                },
                "columnDefs": [
                    { "type": "date", "targets": 3 }
                ]
            });
            $('#history-po-table').each(function() {
                var datatable = $(this);
                // SEARCH - Add the placeholder for Search and Turn this into in-line form control
                var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
                search_input.attr('placeholder', 'Search');
                search_input.removeClass('form-control-sm');
                // LENGTH - Inline-Form control
                var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
                length_sel.removeClass('form-control-sm');
            });
        }
    });

    // $(function() {
    //     if($('#inventory-table').is('table')){
    //
    //         $('#inventory-table').DataTable({
    //             "aLengthMenu": [
    //                 [10, 30, 50, -1],
    //                 [10, 30, 50, "All"]
    //             ],
    //             "iDisplayLength": 10,
    //             "language": {
    //                 search: ""
    //             }
    //         });
    //         $('#inventory-table').each(function() {
    //             var datatable = $(this);
    //             // SEARCH - Add the placeholder for Search and Turn this into in-line form control
    //             var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
    //             search_input.attr('placeholder', 'Search');
    //             search_input.removeClass('form-control-sm');
    //             // LENGTH - Inline-Form control
    //             var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
    //             length_sel.removeClass('form-control-sm');
    //         });
    //     }
    // });

    //----

    $(function() {
        if($('#pending-po-table').is('table')){

            $('#pending-po-table').DataTable({
                "aLengthMenu": [
                    [10, 30, 50, -1],
                    [10, 30, 50, "All"]
                ],
                "iDisplayLength": 10,
                "language": {
                    search: ""
                },
                "columnDefs": [
                    {
                        "type": "date",
                        "targets": [6,7]
                    }
                ]
            });
            $('#pending-po-table').each(function() {
                var datatable = $(this);
                // SEARCH - Add the placeholder for Search and Turn this into in-line form control
                var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
                search_input.attr('placeholder', 'Search');
                search_input.removeClass('form-control-sm');
                // LENGTH - Inline-Form control
                var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
                length_sel.removeClass('form-control-sm');
            });
        }
    });

    // $(function() {
    //     if($('#projects-pending-assignments-table').is('table')){
    //
    //         $('#projects-pending-assignments-table').DataTable({
    //             "aLengthMenu": [
    //                 [10, 30, 50, -1],
    //                 [10, 30, 50, "All"]
    //             ],
    //             "iDisplayLength": 10,
    //             "language": {
    //                 search: ""
    //             },
    //             "columnDefs": [
    //                 {
    //                     "type": "date",
    //                     "targets": 2
    //                 }
    //             ]
    //         });
    //         $('#projects-pending-assignments-table').each(function() {
    //             var datatable = $(this);
    //             // SEARCH - Add the placeholder for Search and Turn this into in-line form control
    //             var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
    //             search_input.attr('placeholder', 'Search');
    //             search_input.removeClass('form-control-sm');
    //             // LENGTH - Inline-Form control
    //             var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
    //             length_sel.removeClass('form-control-sm');
    //         });
    //     }
    // });

    $(function() {
        if($('#damaged-tools-write-off-history-table').is('table')){

            $('#damaged-tools-write-off-history-table').DataTable({
                "aLengthMenu": [
                    [10, 30, 50, -1],
                    [10, 30, 50, "All"]
                ],
                "iDisplayLength": 10,
                "language": {
                    search: ""
                },
                "columnDefs": [
                    {
                        "type": "date",
                        "targets": 7
                    }
                ]
            });
            $('#damaged-tools-write-off-history-table').each(function() {
                var datatable = $(this);
                // SEARCH - Add the placeholder for Search and Turn this into in-line form control
                var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
                search_input.attr('placeholder', 'Search');
                search_input.removeClass('form-control-sm');
                // LENGTH - Inline-Form control
                var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
                length_sel.removeClass('form-control-sm');
            });
        }
    });

    $(function() {
        if($('#warranty-claim-table').is('table')){

            $('#warranty-claim-table').DataTable({
                "aLengthMenu": [
                    [10, 30, 50, -1],
                    [10, 30, 50, "All"]
                ],
                "iDisplayLength": 10,
                "language": {
                    search: ""
                },
                "columnDefs": [
                    {
                        "type": "date",
                        "targets": 10
                    }
                ]
            });
            $('#warranty-claim-table').each(function() {
                var datatable = $(this);
                // SEARCH - Add the placeholder for Search and Turn this into in-line form control
                var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
                search_input.attr('placeholder', 'Search');
                search_input.removeClass('form-control-sm');
                // LENGTH - Inline-Form control
                var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
                length_sel.removeClass('form-control-sm');
            });
        }
    });

    $(function() {
        if($('#warranty-replacement-history-table').is('table')){

            $('#warranty-replacement-history-table').DataTable({
                "aLengthMenu": [
                    [10, 30, 50, -1],
                    [10, 30, 50, "All"]
                ],
                "iDisplayLength": 10,
                "language": {
                    search: ""
                },
                "columnDefs": [
                    {
                        "type": "date",
                        "targets": 7
                    }
                ]
            });
            $('#warranty-replacement-history-table').each(function() {
                var datatable = $(this);
                // SEARCH - Add the placeholder for Search and Turn this into in-line form control
                var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
                search_input.attr('placeholder', 'Search');
                search_input.removeClass('form-control-sm');
                // LENGTH - Inline-Form control
                var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
                length_sel.removeClass('form-control-sm');
            });
        }
    });

    // $(function() {
    //     if($('#projects-dispatched-table').is('table')){
    //
    //         $('#projects-dispatched-table').DataTable({
    //             "aLengthMenu": [
    //                 [10, 30, 50, -1],
    //                 [10, 30, 50, "All"]
    //             ],
    //             "iDisplayLength": 10,
    //             "language": {
    //                 search: ""
    //             },
    //             "columnDefs": [
    //                 {
    //                     "type": "date",
    //                     "targets": 2
    //                 }
    //             ]
    //         });
    //         $('#projects-dispatched-table').each(function() {
    //             var datatable = $(this);
    //             // SEARCH - Add the placeholder for Search and Turn this into in-line form control
    //             var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
    //             search_input.attr('placeholder', 'Search');
    //             search_input.removeClass('form-control-sm');
    //             // LENGTH - Inline-Form control
    //             var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
    //             length_sel.removeClass('form-control-sm');
    //         });
    //     }
    // });

    $(function() {
        if($('#damaged-tools-details-table').is('table')){

            $('#damaged-tools-details-table').DataTable({
                "aLengthMenu": [
                    [10, 30, 50, -1],
                    [10, 30, 50, "All"]
                ],
                "iDisplayLength": 10,
                "language": {
                    search: ""
                },
                "columnDefs": [
                    {
                        "type": "date",
                        "targets": 5
                    }
                ]
            });
            $('#damaged-tools-details-table').each(function() {
                var datatable = $(this);
                // SEARCH - Add the placeholder for Search and Turn this into in-line form control
                var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
                search_input.attr('placeholder', 'Search');
                search_input.removeClass('form-control-sm');
                // LENGTH - Inline-Form control
                var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
                length_sel.removeClass('form-control-sm');
            });
        }
    });

    $(function() {
        if($('#rma-replacement-history-table').is('table')){

            $('#rma-replacement-history-table').DataTable({
                "aLengthMenu": [
                    [10, 30, 50, -1],
                    [10, 30, 50, "All"]
                ],
                "iDisplayLength": 10,
                "language": {
                    search: ""
                },
                "columnDefs": [
                    {
                        "type": "date",
                        "targets": 7
                    }
                ]
            });
            $('#rma-replacement-history-table').each(function() {
                var datatable = $(this);
                // SEARCH - Add the placeholder for Search and Turn this into in-line form control
                var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
                search_input.attr('placeholder', 'Search');
                search_input.removeClass('form-control-sm');
                // LENGTH - Inline-Form control
                var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
                length_sel.removeClass('form-control-sm');
            });
        }
    });

    // $(function() {
    //     if($('#pending-pickings-table').is('table')){
    //
    //         $('#pending-pickings-table').DataTable({
    //             "aLengthMenu": [
    //                 [10, 30, 50, -1],
    //                 [10, 30, 50, "All"]
    //             ],
    //             "iDisplayLength": 10,
    //             "language": {
    //                 search: ""
    //             },
    //             "columnDefs": [
    //                 {
    //                     "type": "date",
    //                     "targets": 4
    //                 }
    //             ]
    //         });
    //         $('#pending-pickings-table').each(function() {
    //             var datatable = $(this);
    //             // SEARCH - Add the placeholder for Search and Turn this into in-line form control
    //             var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
    //             search_input.attr('placeholder', 'Search');
    //             search_input.removeClass('form-control-sm');
    //             // LENGTH - Inline-Form control
    //             var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
    //             length_sel.removeClass('form-control-sm');
    //         });
    //     }
    // });

    // $(function() {
    //     if($('#all-projects-table').is('table')){
    //
    //         $('#all-projects-table').DataTable({
    //             "aLengthMenu": [
    //                 [10, 30, 50, -1],
    //                 [10, 30, 50, "All"]
    //             ],
    //             "iDisplayLength": 10,
    //             "language": {
    //                 search: ""
    //             },
    //             "columnDefs": [
    //                 {
    //                     "type": "date",
    //                     "targets": 2
    //                 }
    //             ]
    //         });
    //         $('#all-projects-table').each(function() {
    //             var datatable = $(this);
    //             // SEARCH - Add the placeholder for Search and Turn this into in-line form control
    //             var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
    //             search_input.attr('placeholder', 'Search');
    //             search_input.removeClass('form-control-sm');
    //             // LENGTH - Inline-Form control
    //             var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
    //             length_sel.removeClass('form-control-sm');
    //         });
    //     }
    // });

    $(function() {
        if($('#inbound-details-table').is('table')){

            $('#inbound-details-table').DataTable({
                "aLengthMenu": [
                    [10, 30, 50, -1],
                    [10, 30, 50, "All"]
                ],
                "iDisplayLength": 10,
                "language": {
                    search: ""
                },
                "columnDefs": [
                    {
                        "type": "date",
                        "targets": [0,4]
                    }
                ]
            });
            $('#inbound-details-table').each(function() {
                var datatable = $(this);
                // SEARCH - Add the placeholder for Search and Turn this into in-line form control
                var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
                search_input.attr('placeholder', 'Search');
                search_input.removeClass('form-control-sm');
                // LENGTH - Inline-Form control
                var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
                length_sel.removeClass('form-control-sm');
            });
        }
    });

    $(function() {
        if($('#outbound-details-table').is('table')){

            $('#outbound-details-table').DataTable({
                "aLengthMenu": [
                    [10, 30, 50, -1],
                    [10, 30, 50, "All"]
                ],
                "iDisplayLength": 10,
                "language": {
                    search: ""
                },
                "columnDefs": [
                    {
                        "type": "date",
                        "targets": 3
                    }
                ]
            });
            $('#outbound-details-table').each(function() {
                var datatable = $(this);
                // SEARCH - Add the placeholder for Search and Turn this into in-line form control
                var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
                search_input.attr('placeholder', 'Search');
                search_input.removeClass('form-control-sm');
                // LENGTH - Inline-Form control
                var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
                length_sel.removeClass('form-control-sm');
            });
        }
    });

    $(function() {
        if($('#damaged-write-off-history-table').is('table')){

            $('#damaged-write-off-history-table').DataTable({
                "aLengthMenu": [
                    [10, 30, 50, -1],
                    [10, 30, 50, "All"]
                ],
                "iDisplayLength": 10,
                "language": {
                    search: ""
                },
                "columnDefs": [
                    {
                        "type": "date",
                        "targets": 5
                    }
                ]
            });
            $('#damaged-write-off-history-table').each(function() {
                var datatable = $(this);
                // SEARCH - Add the placeholder for Search and Turn this into in-line form control
                var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
                search_input.attr('placeholder', 'Search');
                search_input.removeClass('form-control-sm');
                // LENGTH - Inline-Form control
                var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
                length_sel.removeClass('form-control-sm');
            });
        }
    });
    //----


    if($('.data-table').length > 0){

        $('.data-table').DataTable({
            "aLengthMenu": [
                [10, 30, 50, -1],
                [10, 30, 50, "All"]
            ],
            "iDisplayLength": 10,
            "language": {
                search: ""
            }
        });
        $('.data-table').each(function() {
            var datatable = $(this);
            // SEARCH - Add the placeholder for Search and Turn this into in-line form control
            var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
            search_input.attr('placeholder', 'Search');
            search_input.removeClass('form-control-sm');
            // LENGTH - Inline-Form control
            var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
            length_sel.removeClass('form-control-sm');
        });
    }

    //---
    if($('.data-table-2').length > 0){

        $('.data-table-2').DataTable({
            order: [[1, 'asc']],
            "columnDefs": [ {
                "targets": [0],
                "searchable":false,
                "orderable": false,
            } ],
            "aLengthMenu": [
                [10, 30, 50, -1],
                [10, 30, 50, "All"]
            ],
            "iDisplayLength": 10,
            "language": {
                search: ""
            }
        });
        $('.data-table-2').each(function() {
            var datatable = $(this);
            // SEARCH - Add the placeholder for Search and Turn this into in-line form control
            var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
            search_input.attr('placeholder', 'Search');
            search_input.removeClass('form-control-sm');
            // LENGTH - Inline-Form control
            var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
            length_sel.removeClass('form-control-sm');
        });
    }
    //---

    let inventory_serials_table = $('#ist').val();


    if($('.inventory-serials-table').length > 0) {
        $('.inventory-serials-table').DataTable({
            "aLengthMenu": [
                [10, 30, 50, -1],
                [10, 30, 50, "All"]
            ],
            "iDisplayLength": 10,
            "language": {
                search: ""
            },
            processing: true,
            serverSide: true,
            ajax: '/inventory/products/' + inventory_serials_table + '/inventory_list',
            columns: [
                {data: 'sku', name: 'sku'},
                {data: 'serial_code', name: 'serial_code'},
                {data: 'warehouse', name: 'warehouse'},
                {data: 'zone', name: 'zone'},
                {data: 'location', name: 'location'},
                {data: 'po_number', name: 'po_number'},
                {data: 'po_date', name: 'po_date'}
            ]
        });

        $('.inventory-serials-table').each(function () {
            var datatable = $(this);
            // SEARCH - Add the placeholder for Search and Turn this into in-line form control
            var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
            search_input.attr('placeholder', 'Search');
            search_input.removeClass('form-control-sm');
            // LENGTH - Inline-Form control
            var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
            length_sel.removeClass('form-control-sm');
        });
    }

    $(function() {
        console.log('Here Inventory');
        if ($('#inventory-table').is('table')) {
            $('#inventory-table').DataTable({
                "aLengthMenu": [
                    [10, 30, 50, -1],
                    [10, 30, 50, "All"]
                ],
                "iDisplayLength": 10,
                "language": {
                    search: ""
                },
                processing: true,
                serverSide: true,
                ajax: '/inventory_list',
                columns: [
                    {data: 'product.sku', name: 'product.sku'},
                    {data: 'product.name', name: 'product.name'},
                    {data: 'product.description', name: 'product.description'},
                    {data: 'quantity', name: 'quantity'},
                    {data: 'product.min_qty_order', name: 'product.min_qty_order'},
                    {data: 'status', name: 'status', orderable: false, searchable:false},
                    {data: 'po_status', name: 'po_status', orderable: false, searchable:false},
                    {data: 'po_qty', name: 'po_qty', orderable: false, searchable:false}
                ],
                search: {
                    "regex": true
                }
            });

            $('#inventory-table').each(function () {
                var datatable = $(this);
                // SEARCH - Add the placeholder for Search and Turn this into in-line form control
                var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
                search_input.attr('placeholder', 'Search');
                search_input.removeClass('form-control-sm');
                // LENGTH - Inline-Form control
                var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
                length_sel.removeClass('form-control-sm');
            });

        }
    });

    $(function() {
        console.log('Here Inventory');
        if ($('#inventory-by-location-table').is('table')) {
            $('#inventory-by-location-table').DataTable({
                "aLengthMenu": [
                    [10, 30, 50, -1],
                    [10, 30, 50, "All"]
                ],
                "iDisplayLength": 10,
                "language": {
                    search: ""
                },
                processing: true,
                serverSide: true,
                ajax: '/inventory_by_location_list',
                columns: [
                    {data: 'sku', name: 'sku'},
                    {data: 'name', name: 'name'},
                    {data: 'description', name: 'description'},
                    {data: 'qty', name: 'qty', orderable: false, searchable:false},
                    {data: 'min_qty_order', name: 'min_qty_order'},
                    {data:'warehouses', name:'warehouses', orderable: false, searchable:false},
                    {data:'zones', name:'zones', orderable: false, searchable:false},
                    // {data:'locations', name:'locations', orderable: false, searchable:false},
                    // {data: 'status', name: 'status', orderable: false, searchable:false},
                    // {data: 'po_status', name: 'po_status', orderable: false, searchable:false},
                    // {data: 'po_qty', name: 'po_qty', orderable: false, searchable:false}
                ],
                search: {
                    "regex": true
                }
            });

            $('#inventory-by-location-table').each(function () {
                var datatable = $(this);
                // SEARCH - Add the placeholder for Search and Turn this into in-line form control
                var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
                search_input.attr('placeholder', 'Search');
                search_input.removeClass('form-control-sm');
                // LENGTH - Inline-Form control
                var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
                length_sel.removeClass('form-control-sm');
            });
        }
    });

    // $(function() {
    //     let location_id = $('#ist3').val();
    //
    //     if ($('#inventory-select-location-table').is('table')) {
    //         $('#inventory-select-location-table').DataTable({
    //             "aLengthMenu": [
    //                 [10, 30, 50, -1],
    //                 [10, 30, 50, "All"]
    //             ],
    //             "iDisplayLength": 10,
    //             "language": {
    //                 search: ""
    //             },
    //             processing: true,
    //             serverSide: true,
    //             ajax: '/inventory_select_location_list/'+location_id,
    //             columns: [
    //                 {data: 'sku', name: 'sku'},
    //                 {data: 'name', name: 'name'},
    //                 {data: 'description', name: 'description'},
    //                 {data: 'qty', name: 'qty', orderable: false, searchable:false},
    //                 {data: 'min_qty_order', name: 'min_qty_order'},
    //                 {data:'warehouses', name:'warehouses', orderable: false, searchable:false},
    //                 {data:'zones', name:'zones', orderable: false, searchable:false},
    //             ],
    //             search: {
    //                 "regex": true
    //             }
    //         });
    //
    //         $('#inventory-select-location-table').each(function () {
    //             var datatable = $(this);
    //             // SEARCH - Add the placeholder for Search and Turn this into in-line form control
    //             var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
    //             search_input.attr('placeholder', 'Search');
    //             search_input.removeClass('form-control-sm');
    //             // LENGTH - Inline-Form control
    //             var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
    //             length_sel.removeClass('form-control-sm');
    //         });
    //     }
    // });

    $(function() {
        if($('#projects-dispatched-table').is('table')){

            $('#projects-dispatched-table').DataTable({
                "aLengthMenu": [
                    [10, 30, 50, -1],
                    [10, 30, 50, "All"]
                ],
                "iDisplayLength": 10,
                "language": {
                    search: ""
                },
                processing: true,
                serverSide: true,
                ajax: '/accounting_projects_dispatched',
                columns: [
                    {data: 'project_number', name: 'project_number'},
                    {data: 'deal_name', name: 'deal_name'},
                    {data: 'installation_scheduled_date', name: 'installation_scheduled_date'},
                    {data: 'supervisor', name: 'supervisor'},
                    {data: 'assigned_crew', name: 'assigned_crew'},
                    {data: 'status', name: 'status'},
                ],
                search: {
                    "regex": true
                },
                "columnDefs": [
                    {
                        "type": "date",
                        "targets": 2
                    }
                ]
            });
            $('#projects-dispatched-table').each(function() {
                var datatable = $(this);
                // SEARCH - Add the placeholder for Search and Turn this into in-line form control
                var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
                search_input.attr('placeholder', 'Search');
                search_input.removeClass('form-control-sm');
                // LENGTH - Inline-Form control
                var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
                length_sel.removeClass('form-control-sm');
            });
        }
    });

    $(function() {
        //WMS-Optimize
        if($('#projects-pending-assignments-table').is('table')){

            $('#projects-pending-assignments-table').DataTable({
                "aLengthMenu": [
                    [10, 30, 50, -1],
                    [10, 30, 50, "All"]
                ],
                "iDisplayLength": 10,
                "language": {
                    search: ""
                },
                processing: true,
                serverSide: true,
                ajax: '/projects_pending_assignments',
                columns: [
                    {data:'start_assignment', name: 'start_assignment'},
                    {data: 'project_number', name: 'project_number'},
                    {data: 'installation_scheduled_date', name: 'installation_scheduled_date'},
                    {data: 'client_name', name: 'client_name'},
                    {data: 'battery_type', name: 'battery_type'},
                    {data: 'system_size', name: 'system_size'},

                ]
            });
            $('#projects-pending-assignments-table').each(function() {
                var datatable = $(this);
                // SEARCH - Add the placeholder for Search and Turn this into in-line form control
                var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
                search_input.attr('placeholder', 'Search');
                search_input.removeClass('form-control-sm');
                // LENGTH - Inline-Form control
                var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
                length_sel.removeClass('form-control-sm');
            });
        }
    });

    $(function() {
        if($('#project-assigned-projects-table').is('table')){

            $('#project-assigned-projects-table').DataTable({
                "aLengthMenu": [
                    [10, 30, 50, -1],
                    [10, 30, 50, "All"]
                ],
                "iDisplayLength": 10,
                "language": {
                    search: ""
                },
                processing: true,
                serverSide: true,
                ajax: '/projects_assigned_projects',
                columns: [
                    {data: 'view_edit', name: 'view_edit'},
                    {data: 'project_number', name: 'project_number'},
                    {data: 'installation_scheduled_date', name: 'installation_scheduled_date'},
                    {data: 'pickers', name: 'pickers'},
                    {data: 'supervisor', name: 'supervisor'},
                    {data: 'asigned_crew', name: 'asigned_crew'},
                    {data: 'status', name: 'status'}
                ]
            });
            $('#project-assigned-projects-table').each(function() {
                var datatable = $(this);
                // SEARCH - Add the placeholder for Search and Turn this into in-line form control
                var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
                search_input.attr('placeholder', 'Search');
                search_input.removeClass('form-control-sm');
                // LENGTH - Inline-Form control
                var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
                length_sel.removeClass('form-control-sm');
            });
        }
    });

    $(function() {
        if($('#pending-pickings-table').is('table')){

            $('#pending-pickings-table').DataTable({
                "aLengthMenu": [
                    [10, 30, 50, -1],
                    [10, 30, 50, "All"]
                ],
                "iDisplayLength": 10,
                "language": {
                    search: ""
                },
                processing: true,
                serverSide: true,
                ajax: '/project_pending_pickings',
                columns: [
                    {data: 'start_picking', name: 'start_picking'},
                    {data: 'project_number', name: 'project_number'},
                    {data: 'warehouse_name', name: 'warehouse_name'},
                    {data: 'zone_name', name: 'zone_name'},
                    {data: 'picker', name: 'picker'},
                    {data: 'installation_scheduled_date', name: 'installation_scheduled_date'},
                    {data: 'priority', name: 'priority'},
                    {data: 'warehouse_door', name: 'warehouse_door'},
                    {data: 'picking_status', name: 'picking_status'}
                ]
            });
            $('#pending-pickings-table').each(function() {
                var datatable = $(this);
                // SEARCH - Add the placeholder for Search and Turn this into in-line form control
                var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
                search_input.attr('placeholder', 'Search');
                search_input.removeClass('form-control-sm');
                // LENGTH - Inline-Form control
                var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
                length_sel.removeClass('form-control-sm');
            });
        }
    });

    $(function() {
        if($('#pickings-completed-table').is('table')){
            $('#pickings-completed-table').DataTable({
                "aLengthMenu": [
                    [10, 30, 50, -1],
                    [10, 30, 50, "All"]
                ],
                "iDisplayLength": 10,
                "language": {
                    search: ""
                },
                processing: true,
                serverSide: true,
                ajax: '/project_pickings_completed',
                columns: [
                    {data: 'project_number', name: 'project_number'},
                    {data: 'warehouse_name', name: 'warehouse_name'},
                    {data: 'zone_name', name: 'zone_name'},
                    {data: 'picker', name: 'picker'},
                    {data: 'priority', name: 'priority'},
                    {data: 'warehouse_door', name: 'warehouse_door'},
                    {data: 'picking_status', name: 'picking_status'}
                ]
            });
            $('#pickings-completed-table').each(function() {
                var datatable = $(this);
                // SEARCH - Add the placeholder for Search and Turn this into in-line form control
                var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
                search_input.attr('placeholder', 'Search');
                search_input.removeClass('form-control-sm');
                // LENGTH - Inline-Form control
                var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
                length_sel.removeClass('form-control-sm');
            });
        }
    });

    $(function() {
        if($('#projects-open-installations-table').is('table')){
            $('#projects-open-installations-table').DataTable({
                "aLengthMenu": [
                    [10, 30, 50, -1],
                    [10, 30, 50, "All"]
                ],
                "iDisplayLength": 10,
                "language": {
                    search: ""
                },
                processing: true,
                serverSide: true,
                ajax: '/projects_open_installations',
                columns: [
                    {data:'manage_projects', name: 'manage_projects'},
                    {data: 'project_number', name: 'project_number'},
                    {data: 'installation_scheduled_date', name: 'installation_scheduled_date'},
                    {data: 'supervisor', name: 'supervisor'},
                    {data: 'asigned_crew', name: 'asigned_crew'},
                    {data: 'priority', name: 'priority'},
                    {data: 'warehouse_door', name: 'warehouse_door'},
                    {data: 'status', name: 'status'}
                ]
            });
            $('#projects-open-installations-table').each(function() {
                var datatable = $(this);
                // SEARCH - Add the placeholder for Search and Turn this into in-line form control
                var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
                search_input.attr('placeholder', 'Search');
                search_input.removeClass('form-control-sm');
                // LENGTH - Inline-Form control
                var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
                length_sel.removeClass('form-control-sm');
            });
        }
    });

    $(function() {
        if($('#returns-open-installations-table').is('table')){
            $('#returns-open-installations-table').DataTable({
                "aLengthMenu": [
                    [10, 30, 50, -1],
                    [10, 30, 50, "All"]
                ],
                "iDisplayLength": 10,
                "language": {
                    search: ""
                },
                processing: true,
                serverSide: true,
                ajax: '/returns_open_installations',
                columns: [
                    {data:'manage_projects', name: 'manage_projects'},
                    {data: 'project_number', name: 'project_number'},
                    {data: 'installation_scheduled_date', name: 'installation_scheduled_date'},
                    {data: 'supervisor', name: 'supervisor'},
                    {data: 'asigned_crew', name: 'asigned_crew'},
                    {data: 'priority', name: 'priority'},
                    {data: 'warehouse_door', name: 'warehouse_door'},
                    {data: 'status', name: 'status'}
                ]
            });
            $('#returns-open-installations-table').each(function() {
                var datatable = $(this);
                // SEARCH - Add the placeholder for Search and Turn this into in-line form control
                var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
                search_input.attr('placeholder', 'Search');
                search_input.removeClass('form-control-sm');
                // LENGTH - Inline-Form control
                var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
                length_sel.removeClass('form-control-sm');
            });
        }
    });

    $(function() {
        if($('#all-projects-table').is('table')){

            $('#all-projects-table').DataTable({
                "aLengthMenu": [
                    [10, 30, 50, -1],
                    [10, 30, 50, "All"]
                ],
                "iDisplayLength": 10,
                "language": {
                    search: ""
                },
                processing: true,
                serverSide: true,
                ajax: '/projects_installations_completed',
                columns: [
                    {data:'project_details', name: 'project_details'},
                    {data: 'project_number', name: 'project_number'},
                    {data: 'installation_scheduled_date', name: 'installation_scheduled_date'},
                    {data: 'completed_date', name: 'completed_date'},
                    {data: 'supervisor', name: 'supervisor'},
                    {data: 'asigned_crew', name: 'asigned_crew'},
                    {data: 'status', name: 'status'}
                ],
                "columnDefs": [
                    {
                        "type": "date",
                        "targets": 2
                    }
                ]
            });
            $('#all-projects-table').each(function() {
                var datatable = $(this);
                // SEARCH - Add the placeholder for Search and Turn this into in-line form control
                var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
                search_input.attr('placeholder', 'Search');
                search_input.removeClass('form-control-sm');
                // LENGTH - Inline-Form control
                var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
                length_sel.removeClass('form-control-sm');
            });
        }
    });

    if($('.data-table-cycle-count').length > 0){

        $('.data-table-cycle-count').DataTable({
            "aLengthMenu": [
                [5, 10, 30, -1],
                [5, 10, 30, "All"]
            ],
            "iDisplayLength": 5,
            "language": {
                search: ""
            }
        });
        $('.data-table-cycle-count').each(function() {
            var datatable = $(this);
            // SEARCH - Add the placeholder for Search and Turn this into in-line form control
            var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
            search_input.attr('placeholder', 'Search');
            search_input.removeClass('form-control-sm');
            // LENGTH - Inline-Form control
            var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
            length_sel.removeClass('form-control-sm');
        });
    }


    // $(function() {
    //     let date = $('#ist').val();
    //
    //     if($('#accounting-inventory-reconciliation').is('table')){
    //
    //         $('#accounting-inventory-reconciliation').DataTable({
    //             "aLengthMenu": [
    //                 [10, 30, 50, -1],
    //                 [10, 30, 50, "All"]
    //             ],
    //             "iDisplayLength": 10,
    //             "language": {
    //                 search: ""
    //             },
    //             processing: true,
    //             serverSide: true,
    //             ajax: '/accounting_inventory_reconciliation/'+date,
    //             columns: [
    //                 {data: 'action', name: 'action'},
    //                 {data:'sku', name: 'sku'},
    //                 {data: 'name', name: 'name'},
    //
    //                 {data: 'qty_received', name: 'qty_received'},
    //                 {data: 'dispatched', name: 'dispatched'},
    //                 {data: 'in_stock', name: 'in_stock'},
    //                 {data: 'physical_inventory', name: 'physical_inventory'},
    //                 {data: 'physical_variance', name: 'physical_variance'},
    //                 {data: 'warehouse_comments', name: 'warehouse_comments'},
    //                 {data: 'books_inventory', name: 'books_inventory'},
    //                 {data: 'books_variance', name: 'books_variance'},
    //                 {data: 'accounting_comments', name: 'accounting_comments'},
    //                 {data: 'adjustment_amount', name: 'adjustment_amount'},
    //                 {data: 'adjustment_reason', name: 'adjustment_reason'},
    //                 {data: 'adjustment_by', name: 'adjustment_by'}
    //             ],
    //             "columnDefs": [
    //                 {
    //                     "type": "date",
    //                     "targets": 2
    //                 }
    //             ]
    //         });
    //         $('#accounting-inventory-reconciliation').each(function() {
    //             var datatable = $(this);
    //             // SEARCH - Add the placeholder for Search and Turn this into in-line form control
    //             var search_input = datatable.closest('.dataTables_wrapper').find('div[id$=_filter] input');
    //             search_input.attr('placeholder', 'Search');
    //             search_input.removeClass('form-control-sm');
    //             // LENGTH - Inline-Form control
    //             var length_sel = datatable.closest('.dataTables_wrapper').find('div[id$=_length] select');
    //             length_sel.removeClass('form-control-sm');
    //         });
    //     }
    // });
});
