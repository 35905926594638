<template>
    <modal :name="'bin-to-bin-serial-codes-modal'" height="auto" :scrollable="true">
        <div class="modal-content border-bottom-0">
            <div class="modal-header">
                <div>
                    <div class="row justify-content-between">
                        <h5 class="modal-title">{{product.product_name + ' ('+product.product_sku+')'}}</h5>
                        <p class="text-right">Serials: {{serials.length}}/{{product.quantity === '' ? '0' : product.quantity}}</p>
                    </div>
                    <div class="mt-4 row">
                        <h6 class="mr-2 p-2" v-on:click="changeScreen('input')" v-bind:class="{'modal-serial-selected': isInput}" style="cursor: pointer">Input Serial Number</h6>
                        <h6 class="p-2" v-on:click="changeScreen('list')" v-bind:class="{'modal-serial-selected':isList}" style="cursor: pointer">List of Serial Numbers</h6>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div class="form-group" v-if="screen == 'input'">
                    <textarea autofocus autocomplete="off" v-on:keydown.enter.prevent='addSerial' type="text" rows="5" class="form-control" name="serial_number" id="serial_number" placeholder="Input Serial Numbers" v-model="serial"></textarea>
                    <div class=" mt-4 alert alert-warning" role="alert">You can enter multiple serial numbers separated by comma (e.g: 1234, 5678)</div>
                </div>
                <div v-if="screen == 'list'">
                    <form @submit.prevent>
                        <div v-on:submit.prevent class="d-flex justify-content-end">
                            <div class="row align-items-end">
                                <div></div>
                                <div class="form-group mr-2">
                                    <input type="text" class="form-control" id="filter" name="filter" placeholder="Search" v-model="filter">
                                </div>
                                <div class="form-group">
                                    <button type="button" class="btn btn-primary" v-on:click="getData(path + '?' + getFilter())">Filter</button>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-12 overflow-auto">
                                <table class="table table-bordered table-hover products-table">
                                    <thead>
                                    <tr>
                                        <th>Select</th>
                                        <th>SKU</th>
                                        <th>Serial Number</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(product,index) in filtered_serials">
                                        <td class="text-center">
                                            <span v-if="!inSerials(product)" class="cursor-pointer" style="color: blue" v-on:click="selectProduct(product)">Select</span>
                                            <span v-else class="cursor-pointer" style="color: red" v-on:click="removeProduct(product)">Remove</span>
                                        </td>
                                        <td>{{product.sku}}</td>
                                        <td>{{product.serial_code}}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row justify-content-between mt-3">
                            <div></div>
                            <div>
                                <nav>
                                    <ul class="pagination">
                                        <li :class="prevButtonClass">
                                            <span v-if="prev_page_url == '' || prev_page_url == null" class="page-link">{{'<'}}</span>
                                            <button type="button" v-else class="page-link" v-on:click="getData(prev_page_url)">{{'<'}}</button>
                                        </li>
                                        <li v-for="(button,index) in page_buttons" :class="button.class">
                                            <span v-if="button.isActualPage" class="page-link">{{button.pageNumber}}</span>
                                            <span v-else-if="button.disabledButton" class="page-link">...</span>
                                            <button type="button" v-else class="page-link" v-on:click="getData(button.path)">{{button.pageNumber}}</button>
                                        </li>
                                        <li :class="nextButtonClass">
                                            <span v-if="next_page_url == '' || next_page_url == null" class="page-link">{{'>'}}</span>
                                            <button type="button" v-else class="page-link" v-on:click="getData(next_page_url)">{{'>'}}</button>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light" @click="$modal.hide('bin-to-bin-serial-codes-modal')">Close</button>
                <button type="button" class="btn btn-primary mr-2" v-if="screen === 'input'" v-on:click="addSerial">Add Serial Number</button>
            </div>
        </div>
    </modal>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
import {ChevronsRightIcon} from 'vue-feather-icons';
import VueQuagga from "vue-quaggajs";

export default {
    name: "BinToBinSerialCodesModal",
    props:
        {
            product:Object,
            index:Number,
            quantity:Number,
            serials:Array,
            product_id:Number,
            warehouse_id:Number,
            zone_id:Number,
            location_id:Number,
            removeSerial:Function
        },
    components:{
        VueQuagga,
        ChevronsRightIcon
    },
    beforeMount() {
        if(this.$props.serials == null){
            this.$props.serials = [];
        }
    },
    mounted() {
        if(this.$props.location_id == null){
            this.$props.location_id = null;
        }
        this.getData(this.path + '?' + this.getFilter());
        this.getData2(this.path2 + '?' + this.getFilter());
    },
    data(){
        return{
            readerSize: {
                width: 100,
                height: 100
            },
            filtered_serials: [],
            all_serials:[],
            current_page: 1,
            first_page_url: "",
            from: 1,
            last_page: 1,
            last_page_url: "",
            next_page_url: "",
            path: "/inventory_serials_search_filter",
            path2: "/inventory_serials_search_filter2",
            per_page: 1,
            prev_page_url: "",
            to: 1,
            total: 1,
            filter: this.$props.text,
            paginate: 10,
            prevButtonClass: 'page-item ' + (this.prev_page_url !== null && this.prev_page_url !== '' ? '' : 'disabled'),
            nextButtonClass: 'page-item ' + (this.next_page_url !== null && this.next_page_url !== '' ? '' : 'disabled'),
            page_buttons: [],
            readerTypes:['code_128_reader',
                'ean_reader',
                'ean_8_reader',
                'code_39_reader',
                'code_39_vin_reader',
                'codabar_reader',
                'upc_reader',
                'upc_e_reader',
                'i2of5_reader',
                '2of5_reader',
                'code_93_reader'],
            detecteds: [],
            screen:"input",
            isInput: true,
            isList: false,
            isScan: false,
            serial: "",
            serialsInventoryCodes:[]
        }
    },
    methods:{
        selectProduct(item){
            if(!this.inSerials(item)){
                this.$props.serials.push(item);
            }
        },
        removeProduct(item){
            this.$props.removeSerial( item)
        },
        inSerials(item){
            let listItem = this.$props.serials.find(s => s.id === item.id);
            return listItem != null;
        },
        getData(path){
            axios.get(path).then(response => {
                this.setProductsPaginationData(response.data)
            })
        },
        getData2(path){
            axios.get(path).then(response => {
                this.all_serials = response.data.inventory_serials.data;
            })
        },
        resetFilter(){
            this.filter = this.$props.text;
            this.getData(this.path + '?' + this.getFilter());
        },
        setProductsPaginationData(data){
            let filterPath = this.getFilter();
            if(filterPath !== ''){
                filterPath = filterPath + '&';
            }
            this.filtered_serials = data.inventory_serials.data;
            this.current_page = data.inventory_serials.current_page;
            this.first_page_url = data.inventory_serials.first_page_url;
            this.from = data.inventory_serials.from;
            this.last_page = data.inventory_serials.last_page;
            this.last_page_url = data.inventory_serials.last_page_url;
            this.next_page_url = data.inventory_serials.next_page_url == null ? null : this.path + '?' + filterPath + 'page=' + (this.current_page + 1);
            this.per_page = data.inventory_serials.per_page;
            this.prev_page_url = data.inventory_serials.prev_page_url == null ? null : this.path + '?' + filterPath + "page=" + (this.current_page - 1);
            this.to = data.inventory_serials.to;
            this.total = data.inventory_serials.total;
            this.filter = data.filter;
            this.paginate = data.paginate;
            this.prevButtonClass = 'page-item ' + (this.prev_page_url !== null && this.prev_page_url !== '' ? '' : 'disabled');
            this.nextButtonClass = 'page-item ' + (this.next_page_url !== null && this.next_page_url !== '' ? '' : 'disabled');
            this.setButtons();
        },
        getFilter(){
            if(this.$props.location_id == null){
                this.$props.location_id = null;
            }
            let query = 'product_id=' + this.$props.product_id;
            query = query + '&warehouse_id=' + this.$props.warehouse_id??null;
            query = query + '&zone_id=' + this.$props.zone_id??null;
            query = query + '&location_id=' + this.$props.location_id??null;
            query = query + (this.filter != null && this.filter !== '' ? '&filter=' + this.filter : '');
            return query;
        },
        setButtons(){
            this.page_buttons = [];
            if(this.last_page > 13){
                if(this.current_page < 8){
                    this.setLeftSideButtons();
                }else if(this.last_page - this.current_page < 7){
                    this.setRightSideButtons();
                }else{
                    this.setMiddleButtons();
                }
            }else{
                this.setNormalButtons();
            }
        },
        setNormalButtons(){
            for(let i = 1; i <= this.last_page; i++){
                let button = this.createButton(i);
                this.page_buttons.push(button);
            }
        },
        setLeftSideButtons(){
            for(let i = 1; i <= 10; i++){
                let button = this.createButton(i)
                this.page_buttons.push(button)
            }
            let button = this.createButton(0, false);
            this.page_buttons.push(button);
            button = this.createButton(this.last_page - 1)
            this.page_buttons.push(button);
            button = this.createButton(this.last_page);
            this.page_buttons.push(button);
        },
        setRightSideButtons(){
            let button = this.createButton(1);
            this.page_buttons.push(button);
            button = this.createButton(2);
            this.page_buttons.push(button);
            button = this.createButton(0, false);
            this.page_buttons.push(button);
            for(let i = this.last_page - 9; i <= this.last_page; i++){
                button = this.createButton(i);
                this.page_buttons.push(button);
            }
        },
        setMiddleButtons(){
            let button = this.createButton(1);
            this.page_buttons.push(button);
            button = this.createButton(2);
            this.page_buttons.push(button);
            button = this.createButton(0, false);
            this.page_buttons.push(button);
            for(let i = this.current_page - 3; i <= this.current_page + 3; i++){
                button = this.createButton(i);
                this.page_buttons.push(button);
            }
            button = this.createButton(0, false);
            this.page_buttons.push(button);
            button = this.createButton(this.last_page - 1)
            this.page_buttons.push(button);
            button = this.createButton(this.last_page);
            this.page_buttons.push(button);
        },
        createButton(pageNumber, normalButton=true){
            let filterPath = this.getFilter();
            if(filterPath !== ''){
                filterPath = filterPath + '&';
            }
            return {
                'path': this.path + '?' + filterPath + 'page=' + pageNumber,
                'pageNumber': pageNumber,
                'isActualPage': this.current_page == pageNumber,
                'class': 'page-item' + (this.current_page == pageNumber ? ' active':'') + (!normalButton ? ' disabled' : ''),
                'disabledButton': !normalButton
            }
        },
        logIt (data) {
            console.log('detected', data.codeResult.code)
        },
        changeScreen(value){
            if(value === 'list'){
                this.screen = 'list';
                this.isInput = false;
                this.isList = true;
                this.isScan = false;
            }else if(value === 'scan'){
                this.screen = 'scan';
                this.isInput = false;
                this.isList = false;
                this.isScan = true;
            }else if(value === 'input'){
                this.screen = 'input';
                this.isInput = true;
                this.isList = false;
                this.isScan = false;
            }
            this.filter = '';
            this.getData(this.path + '?' + this.getFilter());
        },
        addSerial(){
            let component = this;
            let serials = this.serial.split(',');
            serials.forEach(serial => {
                this.all_serials.forEach(
                    item =>{
                        if(serial == item.serial_code){
                            this.selectProduct(item);
                        }
                    }
                )
            })
            this.serial = "";
        }
    },
}
</script>

<style scoped>

</style>
