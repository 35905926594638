<template>
    <div>
        <form @submit.prevent="submit" id="tool-checkin-form" name="tool-checkin-form" :ref="'tool-checkin-form'">
            <div class="col-md-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h6 class="card-title">Check In</h6>
                        <label for="crew_id">Crew:</label>
                        <div class="form-group col-md-12" style="display: flex">

                            <div class="col-md-4">
                                <dropbox name="crew_id" id="crew_id" :options="this.$props.crews" v-model="form.crew_id" @change="loadTools(form.crew_id)">
                                    <option></option>
                                </dropbox>
                            </div>

                            <div style="margin-left: auto; margin-right: 0; display: flex">
                                <a class="btn btn-primary mt-6 mr-2" @click="searchTools(scanned_serial)">Scan</a>
                                <input type="text" class="form-control mt-6" id="tool_serial_number" name="tool_serial_number" autocomplete="off" placeholder="Insert S/N or Tool #" v-model="scanned_serial">
                            </div>

                        </div>

                        <h6 class="card-subtitle mb-2 mt-2 text-muted">Tools Available for Check In </h6>
                        <div class="table-responsive pt-3">
                            <table class="table table-bordered table-hover">
                                <thead>
                                <tr>
                                    <th>
                                        Add to check in list
                                    </th>
                                    <th>
                                        Tool Number
                                    </th>
                                    <th>
                                        Serial Number
                                    </th>
                                    <th>
                                        Category
                                    </th>
                                    <th>
                                        Tool Type
                                    </th>
                                    <th>
                                        Description
                                    </th>
                                    <th>
                                        Brand Name
                                    </th>
                                    <th>
                                        Model
                                    </th>
                                    <th>
                                        Check out Date
                                    </th>
                                    <th>
                                        Crew Supervisor
                                    </th>
                                    <th>
                                        Comment
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(item,index) in this.tool_out">
                                    <td>
                                        <button type="button" class="btn btn-primary btn-icon "  v-on:click="addModal(item.id,index)">
                                            <log-out-icon size="1.5x" style="color: white"></log-out-icon>
                                        </button>
                                    </td>
                                    <td>
                                        {{item.tool_number}}
                                    </td>
                                    <td>
                                        {{item.serial_code}}
                                    </td>
                                    <td>
                                        {{item.tool.category.name}}
                                    </td>
                                    <td v-if="item.tool.type !== null">
                                        {{item.tool.type.name}}
                                    </td>
                                    <td v-else>

                                    </td>
                                    <td>
                                        {{item.tool.description}}
                                    </td>
                                    <td>
                                        {{item.tool.brand.name}}
                                    </td>
                                    <td>
                                        {{item.tool.model}}
                                    </td>
                                    <td>
                                        <!--                                        {{item.histories ? moment(item.histories[item.histories.length - 1].check_out_date).format('DD-MM-YYYY'):''}}-->
                                        {{item.histories ? moment(item.histories[item.histories.length - 1].check_out_date).format('l'):''}}

                                    </td>
                                    <td>
                                        {{item.supervisor.first_name + ' ' + item.supervisor.last_name}}
                                    </td>
                                    <td>
                                        {{item.histories ? item.histories[item.histories.length - 1].comment : ''}}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-12 mt-2 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h6 class="card-subtitle mb-2 text-muted">Tools to be check in</h6>
                        <div class="table-responsive pt-3">
                            <table class="table table-bordered table-hover">
                                <thead>
                                <tr>
                                    <th>
                                        Remove from list
                                    </th>
                                    <th>
                                        Tool Number
                                    </th>
                                    <th>
                                        Serial Number
                                    </th>
                                    <th>
                                        Tool Type
                                    </th>
                                    <th>
                                        Description
                                    </th>
                                    <th>
                                        Brand Name
                                    </th>
                                    <th>
                                        Model
                                    </th>
                                    <th>
                                        Check out date
                                    </th>
                                    <th>
                                        Crew
                                    </th>
                                    <th>
                                        Comment
                                    </th>
                                    <th>
                                        Quality Check
                                    </th>
                                    <th>
                                        Return Reason
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="(item,index) in tool_in">
                                    <td>
                                        <button type="button" class="btn btn-primary btn-icon "  v-on:click="removeFromIn(item.id,index)">
                                            <log-in-icon size="1.5x" style="color: white"></log-in-icon>
                                        </button>
                                        <button type="button" class="btn btn-primary btn-icon "  v-on:click="editInfo(item.id,index)">
                                            <edit-icon size="1.5x" style="color: white"></edit-icon>
                                        </button>
                                    </td>
                                    <td>
                                        {{item.tool_number}}
                                    </td>
                                    <td>
                                        {{item.serial_code}}
                                    </td>
                                    <td v-if="item.tool.type !== null">
                                        {{item.tool.type.name}}
                                    </td>
                                    <td v-else>

                                    </td>
                                    <td>
                                        {{item.tool.description}}
                                    </td>
                                    <td>
                                        {{item.tool.brand.name}}
                                    </td>
                                    <td>
                                        {{item.tool.model}}
                                    </td>
                                    <td>
                                        <!--                                        {{item.histories ? moment(item.histories[item.histories.length - 1].check_out_date).format('DD-MM-YYYY'):''}}-->
                                        {{item.histories ? moment(item.histories[item.histories.length - 1].check_out_date).format('l'):''}}

                                    </td>
                                    <td>
                                        {{item.histories ? item.histories[item.histories.length - 1].crew.name : ''}}
                                    </td>
                                    <td>
                                        {{item.comment}}
                                    </td>
                                    <td>
                                        {{quality_checks.find(quality=>quality.id == item.quality_check).name}}
                                    </td>
                                    <td >
                                        {{item.return_reason}}
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="form-group mt-5">
                            <label for="check_in_date">Check In Date<span class="required_asterisk">*</span></label>
                            <div class="input-group date datepicker dashboard-date mr-2 mb-2 mb-md-0 d-xl-flex">
                                <span class="input-group-addon bg-transparent"><i data-feather="calendar" class=" text-primary"></i></span>
                                <datePicker autocomplete="off" v-model="form.check_in_date" :config="datePickerOptions" name="check_out_date"></datePicker>
                            </div>
                        </div>
                        <div class="mt-5">
                            <button type="submit" class="btn btn-primary mr-2">Complete Check In</button>
                            <!--                            <a href="/tools" class="btn btn-light">Cancel</a>-->
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</template>

<script>
import Swal from "sweetalert2";
import 'jquery-validation';
import {EditIcon, LogInIcon, LogOutIcon} from 'vue-feather-icons';
import datePicker from 'vue-bootstrap-datetimepicker';
import moment from 'moment';

export default {
    name: "CheckInComponent",
    props: ['crews','quality_checks'],
    components:{
        LogOutIcon, LogInIcon, EditIcon,
        datePicker
    },
    data(){
        return{
            tool_in: [],
            // tool_out: this.$props.first_supervisor != null ? this.$props.supervisors.find(supervisor => supervisor.id == this.$props.first_supervisor['id']).tools.filter(tool => tool.in_use == 1) : [],
            tool_out: [],
            form:{
                tools:[],
                // supervisor_id: this.$props.first_supervisor != null ? this.$props.first_supervisor['id'] : "",
                check_in_date: moment().format('MM/DD/YYYY'),
                crew_id:''
            },
            datePickerOptions:{
                format: 'MM/DD/YYYY',
                useCurrent: false,
                minDate: new Date()
            },
            scanned_serial:'',
            moment: moment
        }
    },
    mounted() {
        $("form[name='tool-checkin-form']").validate({
            rules: {
                crew_id: {
                    required: false,
                },
                check_in_date: {
                    required: true,
                },
            },
            errorPlacement: function(label, element) {
                if(['check_in_date','crew_id'].includes(element[0].name)){
                    label.addClass('mt-2 text-danger');
                    label.insertAfter(element.next());
                }else{
                    label.addClass('mt-2 text-danger');
                    label.insertAfter(element);
                }
            },
            highlight: function(element, errorClass) {
                $(element).parent().addClass('has-danger')
                $(element).addClass('form-control-danger')
            },
            unhighlight: function(element, errorClass, validClass) {
                $(element).parent().removeClass('has-danger')
                $(element).removeClass('form-control-danger')
            },
            submitHandler: function(form){
                this.submit()
            }
        });
    },
    methods:{
        editInfo(id,index){
            let return_reason = '';
            let quality_check = '';
            let comment = '';
            let qualities = '';
            let toolToIn = this.tool_in.find(tool=> tool.id == id);

            this.$props.quality_checks.forEach(quality=>{
                qualities += '<option value="'+quality.id+'">'+quality.name+'</option>'
            })

            Swal.fire({
                title: 'Tool to Check In',
                html:
                    '<label for="quality_check">Quality Check</label>'+
                    '<select id="quality_check" class="swal2-input">'+
                    qualities+
                    '</select>'+
                    '<label for="return_reason">Return Reason</label>'+
                    '<input id="return_reason" class="swal2-input">'+
                    '<label for="comment">Comment</label>'+
                    '<input id="comment" class="swal2-input">'
                ,
                focusConfirm: false,
                preConfirm: () => {
                    return_reason = document.getElementById('return_reason').value;
                    quality_check = document.getElementById('quality_check').value;
                    comment = document.getElementById('comment').value;

                    toolToIn.return_reason = return_reason;
                    toolToIn.quality_check = quality_check;
                    toolToIn.comment = comment;
                    this.$forceUpdate();
                }
            });
        },
        searchTools(serial){
            if(serial === ''){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Input serial or tool number cannot be null.',
                });
            }else if(this.form.crew_id === ''){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Crew must be selected.',
                });
            }else {
                console.log(serial)
                axios.get('/search_tools_by_serial/' + serial + '/' + this.form.crew_id +'/tools').then(response => {
                    // console.log('Zoonic:', response.data.tools[0]['id']);
                    if(response.data.tools.length === 0){
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Scanned tool does not exist.',
                        });
                    }else {
                        let i = this.tool_out.findIndex(tool => tool.id === response.data.tools[0]['id']);

                        this.scanned_serial = '';
                        let return_reason = '';
                        let quality_check = 1;
                        let comment = '';
                        this.addToIn(response.data.tools[0]['id'],i,quality_check,return_reason,comment);
                    }
                });
            }
        },
        submit(){
            if(this.tool_in.length <= 0){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Tools list to be check-in is empty.',
                });
            }else {
                this.form.tools = this.tool_in;
                let attributes = this.form;
                if (this.form.crew_id !== '' && this.form.crew_id != null) {
                    axios.post('/toolsinventory/checkin/', attributes).then(response => {
                        location.reload();
                    });
                }
            }
        },
        addModal(id,index){
            let return_reason = '';
            let quality_check = '';
            let comment = '';

            let qualities = '';

            this.$props.quality_checks.forEach(quality=>{
                qualities += '<option value="'+quality.id+'">'+quality.name+'</option>'
            })

            Swal.fire({
                title: 'Tool to Check In',
                html:

                    '<label for="quality_check">Quality Check<span class="required_asterisk">*</span></label>'+
                    '<select id="quality_check" class="swal2-input">'+
                    qualities+
                    '</select>'+
                    '<label for="return_reason">Return Reason<span class="required_asterisk">*</span></label>'+
                    '<input id="return_reason" class="swal2-input">'+
                    '<label for="comment">Comment<span class="required_asterisk">*</span></label>'+
                    '<input id="comment" class="swal2-input">'

                ,
                focusConfirm: false,
                preConfirm: () => {
                    return_reason = document.getElementById('return_reason').value;
                    quality_check = document.getElementById('quality_check').value;
                    comment = document.getElementById('comment').value;
                    console.log('Quatu: ',quality_check);
                    this.addToIn(id,index,quality_check,return_reason,comment)
                }
            });
        },
        addToIn(id,index,quality_check,return_reason,comment){

            console.log('entré bien');
            let toolToIn = this.tool_out.find(tool=> tool.id == id);

            toolToIn.return_reason = return_reason;
            toolToIn.quality_check = quality_check;
            toolToIn.comment = comment;
            this.tool_out.splice(index,1);

            this.tool_in.push(toolToIn);
        },
        removeFromIn(id,index){
            let toolToOut = this.tool_in.find(tool=> tool.id == id);

            this.tool_in.splice(index,1);
            toolToOut.return_reason = ''
            toolToOut.comment = ''
            this.tool_out.push(toolToOut);
        },
        loadTools(crew_id){
            // if(this.scanned_serial !== ''){

            // }else {
                axios.get('/crews/' + this.form.crew_id + '/tools').then(response => {
                    this.tool_in = [];
                    this.tool_out = [];
                    this.tool_out = response.data.tools;
                });
            // }
        }
    }
}
</script>

<style scoped>

</style>
