<template>
    <modal :name="'scan-return-project-modal'" :scrollable="true" height="auto" width="400px">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Scan Project Number</h4>
                </div>
                <form @submit.prevent="submit" method="GET" id="scan_form" name="scan_form">
                    <div class="modal-body justify-content-between">
                        <div style="text-align: center; text-align-all: center; align-self: center; align-content: center; align-items: center; margin-top: 4px">
                            <input type="text" class="form-control-lg text-center" name="project_number" id="project_number" placeholder="Project Number" autofocus v-model="input_project_number" v-on:keyup.enter="submit(input_project_number)">
                            <p style="color: red" :hidden="!input_empty">Project Number not found.</p>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" @click="$modal.hide('scan-return-project-modal')">Close</button>
                        <button type="button" class="btn btn-primary mr-2" @click="submit(input_project_number)">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </modal>
</template>

<script>
export default {
    name: "ScanReturnProjectModal",
    props: ['projects', 'flag'],
    data(){
        return {
            input_project_number:'',
            input_empty:false,
            scanned_project:'',
            flag2:false
        }
    },
    methods: {
        submit(input_project_number) {
            if(this.input_project_number == ''){
                this.input_empty = true;
                console.log(this.input_empty);
            }
            for(let i = 0; i < this.$props.projects.length; i++){
                if(this.input_project_number == this.$props.projects[i].project_number){
                    this.input_empty = false;
                    this.scanned_project = this.$props.projects[i];
                    this.flag2 = !this.flag2;
                    window.location.href = '/projects/'+this.$props.projects[i].project_id+'/project_return';
                    break;
                }else{
                    this.input_empty = true;
                }
            }
        },
        clear_all(){
            console.log('Clear')
            this.input_project_number = '';
            this.input_empty = false;
            setTimeout(function(){
                document.getElementById("project_number").focus();
            },0)
        }
    },
    watch: {
        flag: function() {
            this.clear_all();
            this.input_empty = false;
        }
    }
}
</script>

<style scoped>

</style>
