import { render, staticRenderFns } from "./WarehouseAllocationComponent.vue?vue&type=template&id=59225196&scoped=true&"
import script from "./WarehouseAllocationComponent.vue?vue&type=script&lang=js&"
export * from "./WarehouseAllocationComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59225196",
  null
  
)

export default component.exports