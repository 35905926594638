/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */

import Multiselect from "vue-multiselect";

global.$ = global.jQuery = require('jquery');


require('./bootstrap');

require('./forms-validation');
require('./product-warehouses');
require('./data-tables');
require('./tools-movement');

import 'select2';
import VoerroTagsInput from '@voerro/vue-tagsinput';
import Swal from 'sweetalert2';

// require('jquery-tags-input/src/jquery.tagsinput.css')

$(function(){
    if ($(".select-box").length) {
        $(".select-box").select2({
            placeholder: "Select one",
        });
    }
});

$(function() {
    'use strict';

    if($('.date-picker').length) {
        var date = new Date();
        var today = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        $('.date-picker').datepicker({
            format: "mm/dd/yyyy",
            todayHighlight: true,
            autoclose: true
        });
    }
});
$(function() {
    'use strict';

    if($('.date-picker-limit').length) {
        var date = new Date();
        var today = new Date(date.getFullYear(), date.getMonth(), date.getDate());
        var tomorrow = new Date(today)
        tomorrow.setDate(tomorrow.getDate() + 1)
        $('.date-picker-limit').datepicker({
            format: "mm/dd/yyyy",
            todayHighlight: true,
            autoclose: true,
            startDate: today,
        });
    }
});


//Todo:Poner en file aparte
import SignaturePad from "signature_pad";
$(function () {
    let wrapper = document.getElementById("signature-pad"),
        clearButton = wrapper.querySelector("[data-action=clear]"),
        saveButton = wrapper.querySelector("[data-action=save]"),
        canvas = wrapper.querySelector("canvas"),
        signaturePad;


    // Adjust canvas coordinate space taking into account pixel ratio,
    // to make it look crisp on mobile devices.
    // This also causes canvas to be cleared.
    window.resizeCanvas = function () {
        var ratio =  window.devicePixelRatio || 1;
        canvas.width = canvas.offsetWidth * ratio;
        canvas.height = canvas.offsetHeight * ratio;
        canvas.getContext("2d").scale(ratio, ratio);
    }

    resizeCanvas();

    signaturePad = new SignaturePad(canvas);

    clearButton.addEventListener("click", function(event) {
        signaturePad.clear();
    });

    saveButton.addEventListener("click", function(event) {
        event.preventDefault();

        if (signaturePad.isEmpty()) {
            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Please provide a signature first.',
                showConfirmButton: false,
                timer: 3000,
                toast:true
            })
        } else {
            var dataUrl = signaturePad.toDataURL();
            var image_data = dataUrl.replace(/^data:image\/(png|jpg);base64,/, "");
            let project_id = document.getElementById("project_id");

            let attributes = {
                'image_data': image_data
            }

            axios.post("/projects/"+ project_id.value+"/installation/signatures",attributes).then(response =>{
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Signature was saved.',
                    showConfirmButton: false,
                    timer: 3000,
                    toast:true
                })
            });

        }
    });
});

window.Vue = require('vue');
import VModal from 'vue-js-modal'
import {CheckSquareIcon,InfoIcon,EditIcon,SearchIcon,ArrowUpIcon,ArrowDownIcon,ClipboardIcon, TrashIcon, Trash2Icon, AlertCircleIcon} from "vue-feather-icons";

Vue.use(VModal)

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/TextEditor.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('project-assignment-form', require('./components/ProjectAssignmentForm').default);
Vue.component('select-user-multiple', require('./components/SelectUserMultiple').default);
Vue.component('warehouse-create-form', require('./components/WarehouseCreateForm.vue').default);
Vue.component('purchase-order-create-form', require('./components/PurchaseOrderCreateForm').default);
Vue.component('purchase-order-edit-form', require('./components/PurchaseOrderEditForm').default);
Vue.component('po-receive-product-form', require('./components/PurchaseOrderReceiveProductForm').default);
Vue.component('po-receive-tool-form', require('./components/POReceiveToolForm').default);
Vue.component('warehouse-edit-form', require('./components/WarehouseEditForm.vue').default);
Vue.component('select-box',require('./components/SelectBox').default)
Vue.component('select-sku',require('./components/SelectSKU').default)
Vue.component('select-tool-model',require('./components/SelectToolModel').default)
Vue.component('dropbox',require('./components/Dropbox').default)
Vue.component('dropbox-reconciliation',require('./components/DropboxReconciliation').default)
Vue.component('dropbox-code-block',require('./components/DropboxCodeBlock').default)
Vue.component('dropbox-none',require('./components/DropboxNone').default)
Vue.component('serial-codes-modal',require('./components/SerialCodesModal').default)
Vue.component('dispatch-serial-codes-modal',require('./components/DispatchSerialCodesModal').default)
Vue.component('bin-to-bin-serial-codes-modal',require('./components/BinToBinSerialCodesModal').default)
Vue.component('transfer-orders-serial-codes-modal',require('./components/TransferOrdersSerialCodesModal').default)
Vue.component('checkout-component',require('./components/CheckOutComponent').default)
Vue.component('checkin-component',require('./components/CheckInComponent').default)
Vue.component('bom-create-form',require('./components/BomCreateForm').default)
Vue.component('bom-update-form',require('./components/BomUpdateForm').default)
Vue.component('project-return-component',require('./components/ProjectReturnComponent').default)
Vue.component('bin-to-bin-component',require('./components/BinToBinComponent').default)
Vue.component('transfer-order-component',require('./components/TransferOrderComponent').default)
Vue.component('picking-transfer-order-component',require('./components/PickingTransferOrderComponent').default)
Vue.component('receiving-transfer-order-component',require('./components/ReceivingTransferOrderComponent').default)
Vue.component('edit-picking-transfer-order-component',require('./components/EditPickingTransferOrderComponent').default)
Vue.component('edit-receiving-transfer-order-component',require('./components/EditReceivingTransferOrderComponent').default)
Vue.component('edit-completed-transfer-order-component',require('./components/EditCompletedTransferOrderComponent').default)
Vue.component('create-cycle-count-reconciliation',require('./components/CreateCycleCountReconciliation').default)
Vue.component('edit-cycle-count-reconciliation',require('./components/EditCycleCountReconciliation').default)
Vue.component('pending-cycle-count-reconciliation',require('./components/PendingCycleCountReconciliation').default)
Vue.component('project-picking',require('./components/ProjectPickingComponent').default)
Vue.component('project-bom-update',require('./components/ProjectBomUpdate').default)
Vue.component('damaged-tool-component',require('./components/DamagedToolComponent').default)
Vue.component('damaged-product-component',require('./components/DamagedProductComponent').default)
Vue.component('warranty-tool-component',require('./components/WarrantyToolComponent').default)
Vue.component('rma-product-component',require('./components/RmaProductComponent').default)
Vue.component('admin-component',require('./components/AdminComponent').default)
Vue.component('dropdown-config-component',require('./components/DropdownConfigComponent').default)
Vue.component('checkout-modal',require('./components/CheckoutModal').default)
Vue.component('tool-inventory-box',require('./components/ToolInventoryBox').default)
Vue.component('checkout-tool-inventory-box',require('./components/CheckOutToolMultiSelector').default)
Vue.component('project-return-modal',require('./components/ProjectReturnModal').default)
Vue.component('cycle-reconciliation-modal',require('./components/CycleReconciliationModal').default)
Vue.component('cycle-reconciliation-edit-modal',require('./components/CycleReconciliationEditModal').default)
Vue.component('select-record',require('./components/SelectSerialRecordMultiple').default)
Vue.component('select-serial-bin-to-bin',require('./components/SelectSerialBinToBin').default)
Vue.component('warehouse-allocation-component',require('./components/WarehouseAllocationComponent').default)
Vue.component('receiving-warehouse-allocation-component',require('./components/ReceivingWarehouseAllocationComponent').default)
Vue.component('warehouse-serial-codes-modal',require('./components/MultipleWarehouseSerialCodesModal').default)
Vue.component('change-zone-location-modal',require('./components/ChangeZoneLocationModal').default)
Vue.component('inventory-adjustment-component',require('./components/InventoryAdjustmentComponent').default)
Vue.component('project-installation-component',require('./components/ProjectInstallationComponent').default)
Vue.component('installation-edit-component',require('./components/InstallationEditComponent').default)
Vue.component('dispatch-products-modal',require('./components/DispatchProductsModal').default)
Vue.component('change-project-date-modal',require('./components/ChangeProjectDateModal').default)
Vue.component('disable-bom-modal',require('./components/DisableBomModal').default)
Vue.component('edit-project-installation-component',require('./components/EditProjectInstallationComponent').default)
Vue.component('dropbox-bom',require('./components/DropboxBom').default)
Vue.component('tags-input',VoerroTagsInput);
Vue.component('check-square-icon',CheckSquareIcon);
Vue.component('info-icon',InfoIcon);
Vue.component('edit-icon',EditIcon);
Vue.component('trash-icon',TrashIcon);
Vue.component('trash2-icon',Trash2Icon);
Vue.component('alert-icon',AlertCircleIcon);
Vue.component('search-icon',SearchIcon);
Vue.component('arrow-up-icon',ArrowUpIcon);
Vue.component('arrow-down-icon',ArrowDownIcon);
Vue.component('clipboard-icon',ClipboardIcon);
Vue.component('inventory-transfer-bin-to-bin-modal', require('./components/InventoryTransferBinToBinModal').default);
Vue.component('inventory-serials-selection-modal', require('./components/InventorySerialsSelectionModal').default);
Vue.component('scan-item-modal', require('./components/ScanItemModal').default);
Vue.component('receiving-scan-item-modal', require('./components/ReceivingScanItemModal').default);
Vue.component('receiving-scan-tool-modal', require('./components/ReceivingScanToolModal').default);
Vue.component('return-scan-item-modal', require('./components/ReturnScanItemModal').default);
Vue.component('scan-item-bin-2-bin-button',require('./components/ScanItemBin2BinButton').default)
Vue.component('scan-return-project-button',require('./components/ScanReturnProjectButton').default)
Vue.component('scan-inventory-location-button',require('./components/ScanInventoryLocationButton').default)
Vue.component('scan-cycle-count-modal',require('./components/ScanCycleCountModal').default)
Vue.component('scan-cycle-count-item-modal',require('./components/ScanCycleCountItemModal').default)
Vue.component('scan-item-bin-2-bin-modal',require('./components/ScanItemBin2BinModal').default)
Vue.component('scan-return-project-modal',require('./components/ScanReturnProjectModal').default)
Vue.component('scan-inventory-location-modal',require('./components/ScanInventoryLocationModal').default)
Vue.component('receiving-details-modal', require('./components/ReceivingDetailsModal').default);
Vue.component('input-receiving-qty-modal', require('./components/InputReceivingQtyModal').default);
Vue.component('scan-location-modal', require('./components/ScanLocationModal').default);
Vue.component('scan-returns-modal', require('./components/ScanReturnsModal').default);
Vue.component('phase2-select-location-modal', require('./components/Phase2SelectLocationModal').default);
Vue.component('multiselect', Multiselect)

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});


