<template>
    <div v-if="$props.project.status == 'Dispatched'">
        <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
                <div class="card-header">
                    <div class="card-title">
                        <p>Dispatch List</p>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="table-responsive pt-3">
                            <div>
                                <table class="table table-bordered table-hover date-columns data-table-2">
                                    <thead>
                                    <tr>
                                        <th>
                                            Phase 2
                                        </th>
                                        <th style="width: 10%;">
                                            Product ID
                                        </th>
                                        <th>
                                            Category
                                        </th>
                                        <th>
                                            Product
                                        </th>
                                        <th>
                                            Description
                                        </th>
                                        <th style="width: 10%;">
                                            Amount
                                        </th>
                                        <th>
                                            Initial Dispatch
                                        </th>
                                        <th>
                                            Additional Dispatched
                                        </th>
                                        <th>
                                            Additional Date
                                        </th>
                                        <th>
                                            Last Return Amount
                                        </th>
                                        <th>
                                            Returns Date
                                        </th>
                                        <th>
                                            Final Dispatch
                                        </th>
                                        <th>
                                            Comments
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(product,index) in this.$props.products">
                                        <td v-if="product.quantity_picked > 0" style="text-align: center">
                                            <input type="checkbox" class="form-control" name="phase_2" disabled="true">
                                        </td>
                                        <td v-else style="text-align: center">
                                            <input type="checkbox" class="form-control" v-model="product.phase_2">
                                        </td>
                                        <td style="width: 10%;">
                                            {{ product.sku }}
                                        </td>
                                        <td>
                                            {{ product.category }}
                                        </td>
                                        <td style="width: 15%">
                                            {{ product.product_name }}
                                        </td>
                                        <td style="width: 15%">
                                            {{ product.description }}
                                        </td>
                                        <td style="width: 10%;">
                                            {{ product.quantity }}
                                        </td>
                                        <td style="width: 10%;">
                                            {{ product.initial_dispatch }}
                                        </td>
                                        <td v-if="product.additional_amount === '0'">
                                        </td>
                                        <td v-else-if="product.additional_amount != null || product.additional_amount != ''" style="width: 10%">
                                            {{product.additional_amount}}
                                        </td>
                                        <td v-else>
                                        </td>
                                        <td v-if="product.additional_date != null || product.additional_date != ''" style="width: 10%;">
                                            {{moment(product.additional_date).format('MM/DD/YYYY')}}
                                        </td>
                                        <td v-else>
                                        </td>
                                        <td v-if="product.returns_amount != null || product.returns_amount != ''" style="width: 10%;">
                                            {{product.returns_amount}}
                                        </td>
                                        <td v-else-if="inventory_return.filter(ir => ir.product_id == product.id) != null || inventory_return.filter(ir => ir.product_id == product.id) != ''">
                                            {{inventory_return.filter(ir => ir.product_id == product.id)[0].returned_quantity}}
                                        </td>
                                        <td v-else>
                                        </td>
                                        <td v-if="product.returns_date != null || product.returns_date != ''" style="width: 10%">
                                            {{moment(product.returns_date).format('MM/DD/YYYY')}}
                                        </td>
                                        <td v-else-if="inventory_return.filter(ir => ir.product_id == product.id) != null || inventory_return.filter(ir => ir.product_id == product.id) != ''">
                                            {{moment(inventory_return.filter(ir => ir.product_id == product.id)[0].created_at).format('MM/DD/YYYY')}}
                                        </td>
                                        <td v-else>
                                        </td>
                                        <td style="width: 10%;">
                                            {{product.quantity_picked}}
                                        </td>
                                        <td style="width: 10%;">
                                            {{product.comments}}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <a href="/projects/installations" class="btn btn-light">Back</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row col-md-12 grid-margin stretch-card">
            <div class="card">
                <div class="card-header">
                    <div class="card-title">
                        <p>Products with serial number scanned</p>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="table-responsive pt-3">
                            <div>
                                <table class="table table-bordered table-hover date-columns">
                                    <thead>
                                    <tr>
                                        <th style="width: 10%;">
                                            SKU
                                        </th>
                                        <th>
                                            Product
                                        </th>
                                        <th>
                                            Serial Number
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(product,index) in this.$props.products_with_serial">
                                        <td style="width: 10%;">
                                            {{ product.sku }}
                                        </td>
                                        <td style="width: 15%">
                                            {{ $props.products.find(p => p.id == product.product_id).product_name }}
                                        </td>
                                        <td style="width: 15%">
                                            {{ product.serial_number }}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
                <div class="card-header">
                    <div class="card-title"><p>Project Status</p></div>
                </div>
                <div class="card-body">
                    <div>
                        <form @submit.prevent="submit" name="project-installation-form">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="form-group">
                                        <label for="status">Completed?<span class="required_asterisk">*</span></label>
                                        <div class="row ml-2">
                                            <div class="form-check form-check-inline">
                                                <label for="status" class="form-check-label">
                                                    <input type="radio" class="form-check-input" name="status" value="Completed" :checked="form.status == 'Completed'" v-model="form.status">
                                                    Yes
                                                </label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <label for="status" class="form-check-label">
                                                    <input type="radio" class="form-check-input" name="status" value="Dispatched" :checked="form.status == 'Dispatched'" v-model="form.status">
                                                    No
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mb-2 my-4"  v-if="form.status == 'Completed'">
                                        <h6>I hereby confirm that the project was successfully dispatched.</h6>
                                        <div class="m-signature-pad my-4" style="border: 2px dashed rgb(204, 204, 204);">
                                            <VueSignaturePad width="498px" height="158px" ref="signaturePad" class="signature-pad--body"/>
                                        </div>
                                        <div>
                                            <button type="button" class="btn btn-sm btn-secondary" @click="clearSignature">Clear</button>
                                            <button type="button" class="btn btn-sm btn-primary" @click="saveSignature">Save</button>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label for="comments">Comments</label>
                                        <textarea class="form-control" id="comments" name="comments" placeholder="Description" v-model="form.comments" rows="5"></textarea>
                                    </div>
                                    <button :disabled="submit_disabled" type="submit" class="btn btn-primary mr-2" v-on:click="disableButton()">Submit</button>
                                    <a href="/projects/installations" class="btn btn-light">Cancel</a>
                                    <div v-if="submit_disabled" class="spinner-border text-primary ml-4" role="status">
                                        <span class="visually-hidden"></span>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else-if="$props.project.status == 'In Progress' || $props.project.status == 'Completed'">
        <div class="row col-md-12 grid-margin stretch-card">
            <div class="col-md-6 stretch-card">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title"><p>Completion Acknowledgment</p></div>
                    </div>
                    <div class="card-body">
                        <div class="row mb-2">
                            <h6>I hereby confirm that the project was successfully dispatched.</h6>
                        </div>
                        <div class="row">
                            <div v-if="signatures.filter(s => s.type == 'crew_supervisor').length > 0" class="col-md-12">
                                <img v-bind:src="'/projects/'+ $props.project.id +'/installation/signatures/'+ signatures.filter(s => s.type == 'crew_supervisor')[0].id" alt="Signature">
                                <h6>{{user[0].first_name + ' ' + user[0].last_name + ' ' + user[0].second_last_name}}</h6>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title"><p>Project Comments</p></div>
                    </div>
                    <div class="card-body">
                        <div v-if="project.in_progress_comments != null || project.in_progress_comments != ''">
                            <pre class="bg-white overflow-hidden">{{project.in_progress_comments}}</pre>
                        </div>
                        <pre class="bg-white overflow-hidden">{{project.completed_comments}}</pre>
                        <div class="form-group">
                            <label for="comments">Comments</label>
                            <textarea class="form-control" id="comments1" name="comments" placeholder="Comments" rows="5" v-model="comment_update"></textarea>
                        </div>
                        <button class="btn btn-primary" v-on:click="updateComment()">Add Comments</button>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="$props.project.status == 'Completed'" class="row col-md-12 grid-margin stretch-card">
            <div class="card">
                <div class="card-header">
                    <div class="card-title"><p>Project Status</p></div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <h6>Project Status</h6>
                                <p>{{project.status}}</p>
                            </div>
                            <div class="form-group">
                                <h6>Date Completed</h6>
                                <p>{{project.completed_date}}</p>
                            </div>
                            <div class="form-group">
                                <h6>Returns</h6>
                                <div v-if="inventory_returns > 0">
                                    YES
                                </div>
                                <div v-else>
                                    NO
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div v-else-if="$props.project.status == 'Phase 2'">
        <div class="col-md-12 grid-margin stretch-card">
            <div class="card">
                <div class="card-header">
                    <div class="card-title">
                        <p>Phase 2 Picking List</p>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="table-responsive pt-3">
                            <div>
                                <table class="table table-bordered table-hover date-columns data-table">
                                    <thead>
                                    <tr>
                                        <th style="width: 10%">
                                            SKU
                                        </th>
                                        <th style="width: 10%">
                                            Product
                                        </th>
                                        <th style="width: 10%">
                                            Description
                                        </th>
                                        <th style="width: 10%">
                                            Location
                                        </th>
                                        <th style="width: 5%">
                                            Amount
                                        </th>
                                        <th style="width: 5%">
                                            Quantity
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(product,index) in this.form.phase2_products">
                                        <td style="width: 10%">
                                            {{ product.sku }}
                                        </td>
                                        <td style="width: 10%">
                                            {{ product.name }}
                                        </td>
                                        <td style="width: 10%">
                                            {{ product.description }}
                                        </td>
                                        <td v-if="product['warehouse_id'] !== null" style="width: 10%; color:lightseagreen" class="cursor-pointer" v-on:click="openChangeZoneLocationModal(product)">
                                            {{product['warehouse'] + ' - ' + product['zone'] + ' - ' + product['location'] }}
                                        </td>
                                        <td v-else-if="product['warehouse_id'] == null || product['warehouse_id'] == ''" style="width: 10%; color:grey" class="cursor-pointer" v-on:click="openChangeZoneLocationModal(product)">
                                            Select Location
                                        </td>
                                        <td style="width: 5%">
                                            {{ product.quantity }}
                                        </td>
                                        <td style="width: 5%">
                                            <input type="number" oninput="validity.valid||(value='')" min="0" class="form-control" name="quantity"  placeholder="####" v-model="product.quantity_picked">
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <a href="/projects/installations" class="btn btn-light">Back</a>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3" v-if="form.phase2_products.filter(product => product.use_serials).length > 0">
            <h6>Serial Numbers</h6>
        </div>
        <div class="row mt-2" v-for="(product,index) in form.phase2_products.filter(product => product.use_serials)" :key="index">
            <p class="col-md-12">{{product.name }}</p>
            <div class="col-md-12">
                <p type="button" style="color: #007bff" @click="$modal.show(index+'-serial-codes-modal') ; $forceUpdate()">Add or Edit Codes</p>
                <serial-codes-modal :ref="index+'serial-code-modal'" :product="product" :index="index" v-bind:quantity="product.quantity_picked"></serial-codes-modal>
            </div>
        </div>
        <div class="spinner-border text-primary my-3" v-if="flag_loading" role="status">
            <span class="sr-only">Loading...</span>
        </div>
        <div class="row mt-4">
            <button :disabled="submit_disabled" type="button" class="btn btn-primary mr-2" @click="submitPhase2">Submit Phase 2</button>
            <a href="/projects/installations" class="btn btn-light">Cancel</a>
            <div v-if="submit_disabled" class="spinner-border text-primary ml-4" role="status">
                <span class="visually-hidden"></span>
            </div>
        </div>
        <phase2-select-location-modal :detail="detail" :warehouses="warehouses" :zones="zones" :locations="locations" :products="form.phase2_products"></phase2-select-location-modal>
    </div>
</template>

<script>
import moment from 'moment';
import Swal from "sweetalert2";
import datePicker from 'vue-bootstrap-datetimepicker';
import VueSignaturePad from 'vue-signature-pad';
Vue.use(VueSignaturePad);
export default {
    name: "InstallationEditComponent",
    props: ['project','products', 'inventory_return', 'products_with_serial', 'signatures', 'user', 'inventory_returns', 'picking_products', 'warehouses', 'zones', 'locations', 'pbomd'],
    components:{
        datePicker,
    },
    beforeMount(){
        this.form.details = this.$props.project.bom.products.filter(detail => detail.pivot.phase_2 === 1);
        this.form.details.forEach(detail =>{
            this.form.phase2_products.push({
                type: 'Detail',
                id: this.$props.pbomd.filter(pbd => pbd.product_id == detail.id)[0].id,
                parent_id: this.$props.pbomd.filter(pbd => pbd.product_id == detail.id)[0].id,
                sku: detail.sku,
                upc: detail.upc,
                name: detail.name,
                description: detail.description,
                warehouse:null,
                zone: null,
                warehouse_id:null,
                zone_id: null,
                location: null,
                location_id: null,
                quantity: detail.pivot.quantity,
                quantity_picked: '0',
                serial_codes: [],
                use_serials: detail.use_serials,
                comments: '',
                product_id: detail.id
            })
        });
    },
    mounted() {
    },
    data() {
        return {
            form:{
                date_completed:'',
                comments: '',
                returns: this.$props.project.returns ? 'yes' : 'no',
                status: this.$props.project.status,
                products: this.$props.products,
                phase2_products:[],
                details: [],
            },
            datePickerOptions:{
                format: 'MM/DD/YYYY',
                useCurrent: false,
            },
            submit_disabled: false,
            moment: moment,
            comment_update:'',
            flag_loading: false,
            detail:''
        }
    },
    methods:{
        disableButton(){
            this.submit_disabled = true;
            this.submit();
        },
        submit(){
            axios.post('/projects/' + this.$props.project.id + '/installation',this.form).then(response => {
                if(response.data.error == 404){
                    this.submit_disabled = false;

                    console.log("Submit 404", response.data.message);

                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: response.data.message,
                    });
                    return false;
                }else{
                    location = response.data.message;
                }
            }).catch(error =>{
                this.submit_disabled = false;
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'There was a problem.',
                });
            });
        },
        submitPhase2(){
            let haveQuantity = true;
            let products = [];
            let serials_success = true;
            let location_success = true;
            let bad_serials_list = '';
            let bad_location_list = '';
            this.submit_disabled = true;
            this.flag_loading = true;

            this.form.phase2_products.forEach(phase2_product => {
                if(phase2_product.quantity_picked == null || phase2_product.quantity_picked == ""){
                    haveQuantity = false;
                }
                if(phase2_product.quantity == 0){
                    haveQuantity = true;
                }
                if(phase2_product.warehouse_id == null || phase2_product.warehouse_id == '' || phase2_product.zone_id == null || phase2_product.zone_id == '' || phase2_product.location_id == null || phase2_product.location_id == ''){
                    location_success = false;
                    bad_location_list += '(' + phase2_product.name + '), ';
                }
                if(phase2_product.quantity_picked != "0" && phase2_product.use_serials) {
                    if (phase2_product.quantity_picked != phase2_product.serial_codes.length) {
                        serials_success = false;
                        bad_serials_list += '(' + phase2_product.name + '), ';
                    }
                }
            });
            if(!haveQuantity){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Input the quantity for all the products.',
                });
                this.flag_loading = false;
                this.submit_disabled = false;
            }else if(!location_success){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Please, select the location for all the products: ' + bad_location_list,
                });
                this.flag_loading = false;
                this.submit_disabled = false;
            }else if(!serials_success){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Please scan products with Serial Numbers to complete Picking.' + bad_serials_list,
                });
                this.flag_loading = false;
                this.submit_disabled = false;

            }else{
                axios.post('/projects/'+this.$props.project.id+'/pickings/phase2/store',this.form).then(response => {
                    if(response.data.error == 404){
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: response.data.message,
                        });
                        this.flag_loading = false;
                        this.submit_disabled = false;
                    }else{
                        location = response.data.message;
                    }
                }).catch(error =>{
                    if(error.response.status === 403){
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'There was a problem.',
                        });
                    }
                    this.flag_loading = false;
                    this.submit_disabled = false;
                });
            }
        },
        clearSignature(){
            this.$refs.signaturePad.undoSignature();
        },
        saveSignature(){
            const {isEmpty,data} = this.$refs.signaturePad.saveSignature();

            let attributes = {
                'image_data': data
            }
            axios.post("/projects/"+ this.$props.project.id +"/installation/signatures",attributes).then(response =>{
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Signature was saved.',
                    showConfirmButton: false,
                    timer: 3000,
                    toast:true
                })
            });
        },
        updateComment(){
            let attributes = {
                'comments': this.comment_update
            };
            let project_path = "/projects/" + this.project.id;
            axios.post(project_path + '/update_comments'+ '?_method=PATCH', attributes).then(response => {
                if(response.data.error == 404){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: response.data.message,
                    });
                }else{
                    location = project_path + '/installation';
                }
            }).catch(error =>{
                this.submit_disabled = false;
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'There was a problem.',
                });
            });
        },
        openChangeZoneLocationModal(product){
            this.detail = product;
            this.$forceUpdate();
            let vm = this;

            axios.get('/inventory/products/' + product.product_id + '/warehouses/getWarehousesZonesAndLocations').then(response =>{
                vm.$modal.show('phase2-select-location-modal');
                console.log(response.data.warehouses)
                vm.warehouses = response.data.warehouses;
            });
        },
    }
}
</script>

<style scoped>

</style>
