<template>
    <modal :name="'picking-scan-item-modal'" :scrollable="true" height="auto" width="400px">
        <div class="modal-content border-bottom-0" id="myModal" ref="vuemodal">
            <div class="modal-header">
                <div>
                    <div class="row justify-content-between">
                        <h4 v-if="modal_type === 'scan_item'" class="modal-title">Scan Picking Item</h4>
                        <h4 v-if="modal_type === 'input_qty'" class="modal-title">Input Picking Quantity</h4>
                        <h4 v-if="modal_type === 'scan_location'">Scan Location</h4>
                        <h4 v-if="modal_type === 'scan_serial_codes'">Scan Serial Numbers</h4>
                    </div>
                </div>
            </div>
            <div v-if="modal_type === 'scan_item'">
                <form @submit.prevent="submitScan" id="scan_form" name="scan_form" :ref="'scan_form'">
                    <div class="modal-body justify-content-between">
                        <div style="text-align: center; text-align-all: center; align-self: center; align-content: center; align-items: center">
                            <input type="text" class="form-control-lg text-center" name="scan_item_picking" id="scan_item_picking" placeholder="Scan Item" autofocus v-model="input_scan">
                            <p style="color: red" :hidden="!input_empty">SKU or UPC not found.</p>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" @click="$modal.hide('scan-item-modal-7'), clear_all()">Close</button>
                        <button type="button" class="btn btn-primary mr-2" @click="submitScan()">Submit</button>
                    </div>
                </form>
            </div>
            <div v-if="modal_type === 'input_qty'">
                <form @submit.prevent="submit_qty" id="form2" name="form2" :ref="'form2'">
                    <div class="modal-body">
                        <div style="margin-bottom: 20px">
                            <h5 style="color: #f89b24">{{scanned_product.sku}} ({{scanned_product.name}})</h5>
                            <p class="text"><b>Product Amount:</b> {{scanned_product.quantity}}</p>
                        </div>
                        <div style="text-align: center; text-align-all: center; align-self: center; align-content: center; align-items: center; margin-top: 4px">
                            <div class="col-md-12 justify-content-between">
                                <div class="row-md-12 justify-content-between" style="align-items: center">
                                    <input disabled autofocus oninput="validity.valid||(value='')" min="0" type="number" class="text-center form-control-lg mb-2" name="order_qty1" id="order_qty1"  placeholder="Qty. Picked" v-model="qty_picked">
                                </div>
                                <div class="row-md-12 justify-content-between" style="align-items: center">
                                    <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + 1;">1</button>
                                    <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + 2;">2</button>
                                    <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + 3;">3</button>
                                </div>
                                <div class="row-md-12 justify-content-between" style="align-items: center">
                                    <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '4';">4</button>
                                    <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '5';">5</button>
                                    <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '6';">6</button>
                                </div>
                                <div class="row-md-12 justify-content-between" style="align-items: center">
                                    <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '7';">7</button>
                                    <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '8';">8</button>
                                    <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '9';">9</button>
                                </div>
                                <div class="row-md-12 justify-content-between" style="align-items: center">
                                    <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=null">C</button>
                                    <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value + '0';">0</button>
                                    <button style="border-color: #f89b24; margin: 2px" type="button" class="btn-lg btn-outline-secondary py-3 px-4" onclick="document.getElementById('order_qty1').value=document.getElementById('order_qty1').value.slice(0,-1);">&lt;</button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p v-if="qty_error" style="color: red">Please, input a valid quantity value.</p>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-light" @click="$modal.hide('picking-scan-item-modal'), clear_all()">Close</button>
                        <button type="button" class="btn btn-primary mr-2" v-on:click="submit_qty">Submit</button>
                    </div>
                </form>
            </div>
            <div v-if="modal_type === 'scan_location'">
                <div class="modal-header justify-content-between">
                    <div>
                        <div style="margin-bottom: 20px">
                            <h5 style="color: #f89b24">{{scanned_product.sku}} ({{scanned_product.name}})</h5>
                        </div>
                        <div class="mt-3 ml-5 row">
                            <h6 class="mr-2 p-2" v-on:click="changeScreenLocation('scan')" v-bind:class="{'modal-serial-selected': isScan}" style="cursor: pointer">Scan Location</h6>
                            <h6 class="p-2" v-on:click="changeScreenLocation('select')" v-bind:class="{'modal-serial-selected':isSelect}" style="cursor: pointer">Select Location</h6>
                        </div>
                    </div>
                </div>
                <form @submit.prevent="submit_location" id="form3" name="form3" :ref="'form3'">
                    <div class="form-group" v-if="screen == 'scan'">
                        <div class="modal-body justify-content-between">

                            <div style="text-align: center; text-align-all: center; align-self: center; align-content: center; align-items: center; margin-top: 4px">
                                <div>
                                    <input type="text" class="form-control-lg text-center" name="scan_location" id="scan_location" placeholder="Location" autofocus v-model="input_scan_location" v-on:keyup.enter="submit_location(input_scan_location)">
                                    <p style="color: red" :hidden="!input_empty_location">Location not valid for selected warehouse.</p>
                                    <p style="color: red" :hidden="!wrong_location">Please, input a valid location.</p>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <div class=" mt-4 alert alert-warning" role="alert">Also, you can select the zone and location in the other tab.</div>
                            <button type="button" class="btn btn-light" @click="$modal.hide('picking-scan-item-modal'), clear_all()">Close</button>
                            <button type="button" class="btn btn-primary mr-2" @click="submit_location(input_scan_location)">Submit</button>
                        </div>
                    </div>
                    <div v-if="screen == 'select'">
                        <div class="modal-body justify-content-between">

                            <div class="form-group">
                                <label for="zone">Zone<span class="required_asterisk">*</span></label>
                                <dropbox :options="scanned_product_zones" v-model="zone" @change="setLocationOptions()">
                                    <option></option>
                                </dropbox>
                            </div>
                            <div class="form-group">
                                <label for="location">Location<span class="required_asterisk">*</span></label>
                                <dropbox :options="location_options" v-model="location">
                                    <option></option>
                                </dropbox>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-light" @click="$modal.hide('picking-scan-item-modal'), clear_all()">Close</button>
                            <button type="button" class="btn btn-primary mr-2" @click="submit_select_location()">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
            <div v-if="modal_type === 'scan_serial_codes'">
                <div class="modal-header justify-content-between">
                    <div>
                        <div class="row">
                            <h5 class="modal-title" style="color: #f89b24">{{scanned_product.sku}} ({{scanned_product.name}})</h5>
                        </div>
                        <div class="row">
                            <p class="text-right"><b>Serials:</b> {{scanned_product.serial_codes.length}}/{{scanned_product.quantity_picked}}</p>
                        </div>
                        <div class="row">
                            <p><b>ZN: </b>{{this.scanned_product.zone}} / <b>LOC: </b>{{this.scanned_product.location}}</p>
                        </div>
                        <div class="mt-3 row">
                            <h6 class="mr-2 p-2" v-on:click="changeScreen('input')" v-bind:class="{'modal-serial-selected': isInput}" style="cursor: pointer">Input Serial Number</h6>
                            <h6 class="p-2" v-on:click="changeScreen('list')" v-bind:class="{'modal-serial-selected':isList}" style="cursor: pointer">List of Serial Numbers</h6>
                        </div>
                    </div>
                </div>
                <form @submit.prevent="continueScan" id="form4" name="form4" :ref="'form4'">
                    <div class="modal-body">
                        <div class="form-group" v-if="screen == 'input'">
                            <textarea autofocus autocomplete="off" v-on:keydown.enter.prevent='addSerial' type="text" rows="4" class="form-control" name="serial_number" id="serial_number" placeholder="Input Serial Numbers" v-model="serial"></textarea>
                            <button type="button" class="btn btn-outline-primary mt-2" v-if="screen === 'input'" v-on:click="addSerial">Add S/N</button>
                            <div class=" mt-4 alert alert-warning" role="alert">You can enter multiple serial numbers separated by comma.</div>
                        </div>
                        <div v-if="screen == 'list'">
                            <div class="table-responsive pt-3">
                                <table class="table table-bordered table-hover date-columns">
                                    <thead>
                                    <tr>
                                        <th>
                                            Serial Codes
                                        </th>
                                        <th>
                                            In Stock
                                        </th>
                                        <th>
                                            Action
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="(serial,index) in scanned_product.serial_codes">
                                        <td>{{serial.sn}}</td>
                                        <td>
                                            <div v-for="(valuer, i) in serialsInventoryCodes">
                                                <p v-if="serial.sn == valuer.serial_code">Yes</p>
                                            </div>
                                        </td>
                                        <td style="color: red"><p style="cursor: pointer;" v-on:click="deleteSerial(index)">Delete</p></td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <p style="color: red" :hidden="!incomplete_serials">Please, input all received serial numbers for this item.</p>
                        <button type="button" class="btn btn-light" @click="$modal.hide('picking-scan-item-modal'), clear_all()">Close</button>
                        <button type="button" class="btn btn-primary mr-2" v-if="screen === 'input'" v-on:click="continueScan">Scan New Item</button>
                    </div>
                </form>
            </div>
        </div>
    </modal>
</template>

<script>
import {trim} from "lodash/string";
import Swal from "sweetalert2";

export default {
    name: "PickingScanItemModal",
    props: ['filter_sku_input', 'parent', 'filteredRows', 'real_filter', 'products', 'flag', 'picking', 'warehouses', 'zones', 'locations', 'scan_warehouses', 'scan_locations', 'scan_zones', 'detail'],
    mounted() {
        this.modal_type = 'scan_item';
    },
    data(){
        return{
            input_empty:false,
            input_scan:'',
            modal_type:'',
            scanned_product:'',
            qty_picked:'',
            qty_error:'',
            input_scan_location:'',
            input_empty_location:false,
            wrong_location:false,
            scanned_product_zones:[],
            zone: "",
            zone_options: [],
            location: "",
            location_options: [],
            zone_object: {},
            serial: "",
            screen:"scan",
            isScan:true,
            isSelect:false,
            isInput:true,
            isList:false,
            serialsInventoryCodes:[],
            incomplete_serials:false
        }
    },
    methods:{
        submitScan(){
            this.input_scan = trim(this.input_scan);
            if (this.input_scan === '') {
                this.input_empty = true;
            }
            for (let i = 0; i < this.$props.products.length; i++) {
                let match = null;
                if(this.$props.products[i].upc != null){
                    this.upc_array = this.$props.products[i].upc.split(",");
                    match = this.getMatchingResults(this.upc_array).length > 0;
                }else {
                    match = this.input_scan === this.$props.products[i].upc;
                }
                if (this.input_scan.toLowerCase() === this.$props.products[i].sku.toLowerCase() || (match)){
                    this.input_empty = false;
                    this.scanned_product = this.$props.products[i];
                    this.serial_index = i;
                    this.modal_type = 'input_qty'
                    this.serialsInventory();
                    break;
                } else {
                    this.input_empty = true;
                }
            }
        },
        getMatchingResults(upc_array) {
            return upc_array.filter(d => this.input_scan.split(',').every(v => d.toLowerCase() === v.toLowerCase()));
        },
        submit_qty(){
            let order_qty = document.getElementById('order_qty1').value;
            if (order_qty === '') {
                this.qty_error = true;
            } else {
                this.incomplete_serials = false;
                this.scanned_product.quantity_picked = order_qty;
                this.qty_error = false;
                if(this.scanned_product.quantity != order_qty){
                    if(order_qty == 0){
                        this.scanned_product.comments = "not dispatched";
                    }else{
                        this.scanned_product.comments = "dispatched";
                    }
                }else{
                    this.scanned_product.comments = "";
                }
                if(order_qty > 0){
                    this.modal_type = 'scan_location';
                    this.getZoneLocation();
                    setTimeout(() => {document.getElementById("scan_location").focus()},1);
                }else if(order_qty == 0){
                    Swal.fire({
                        position: "top-right",
                        icon: 'success',
                        title: 'Quantity updated!',
                        showConfirmButton: false,
                        timer: 1500,
                        toast:true
                    })
                    this.clear_all();
                    this.modal_type = 'scan_item';
                    setTimeout(() => {document.getElementById("scan_item_picking").focus()},1);
                }
            }
        },
        submit_location(){
            if(this.input_scan_location === ''){
                this.input_empty_location = false;
            }
            this.selectZone();
        },
        selectZone(){
            for (let i = 0; i < this.$props.scan_locations.length; i++) {
                if (this.input_scan_location.toLowerCase() === this.$props.scan_locations[i].name.toLowerCase()) {
                    for (let j = 0; j < this.$props.scan_zones.length; j++) {
                        if (this.$props.scan_locations[i].zone_id === this.$props.scan_zones[j].id) {
                            if (this.$props.scan_zones[j].warehouse_id === this.$props.picking.warehouse_id) {

                                let zone_id = this.$props.scan_zones[j].id;
                                let location_id = this.$props.scan_locations[i].id;
                                let detail = this.scanned_product;
                                let spz = this.scanned_product_zones.find(z => z.id == zone_id);
                                let spl = null;

                                if(spz == null || spz == ''){
                                    spl = null;
                                }else {
                                    spl = spz.locations.find(l => l.id == location_id);
                                }
                                if(spl == null || spl == ''){
                                    this.wrong_location = false;
                                    this.input_empty_location = true;
                                }else{
                                    this.wrong_location = false;
                                    this.input_empty_location = false;
                                    let zone_name = this.$props.scan_zones[j].name;
                                    let location_name = this.$props.scan_locations[i].name;
                                    this.changeLocationBOM(zone_id, location_id, detail, zone_name, location_name);
                                    break;
                                }
                                break;
                            } else {
                                this.wrong_location = false;
                                this.input_empty_location = true;
                            }
                        } else {
                            this.wrong_location = false;
                            this.input_empty_location = true;
                        }
                    }
                    break;
                } else {
                    this.wrong_location = true;
                    this.input_empty_location = false;
                }
            }
        },
        getZoneLocation(){
            axios.get('/inventory/products/' + this.scanned_product.product_id + '/warehouses/' + this.$props.picking.warehouse_id + '/getZonesAndLocations').then(response =>{
                this.scanned_product_zones = response.data.zones
            });
        },
        changeLocationBOM(zone_id, location_id, detail, zone_name, location_name){
            let attributes = {
                zone_id: zone_id,
                location_id: location_id,
                detail: detail
            }
            axios.post('/change_location',attributes).then(response => {
                this.scanned_product.location_id = location_id;
                this.scanned_product.zone_id = zone_id;
                this.scanned_product.zone = zone_name;
                this.scanned_product.location = location_name;

                Swal.fire({
                    position: "top-right",
                    icon: 'success',
                    title: 'Location updated!',
                    showConfirmButton: false,
                    timer: 1500,
                    toast:true
                })
                if(this.scanned_product.use_serials){
                    this.screen = 'input';
                    this.modal_type = 'scan_serial_codes'
                    setTimeout(() => {document.getElementById("serial_number").focus()},1);
                }else{
                    this.clear_all();
                    this.modal_type = 'scan_item';
                    setTimeout(() => {document.getElementById("scan_item_picking").focus()},1);
                }
            })
        },
        setLocationOptions(){
            this.zone_object = this.scanned_product_zones.find(z => z.id == this.zone);
            this.location_options = this.zone_object.locations;
        },
        changeScreenLocation(value){
            if(value === 'scan'){
                this.screen = 'scan';
                this.isScan = true;
                this.isSelect =false;
            }else if(value === 'select'){
                this.screen = 'select';
                this.isScan = false;
                this.isSelect =true;
            }
            setTimeout(() => {document.getElementById("scan_location").focus()},1);
        },
        changeScreen(value){
            if(value === 'list'){
                this.screen = 'list';
                this.isInput = false;
                this.isList = true;
            }else if(value === 'input'){
                this.screen = 'input';
                this.isInput = true;
                this.isList = false;
            }
            setTimeout(() => {document.getElementById("serial_number").focus()},1);
        },
        submit_select_location(){
            if(this.zone == null || this.zone == '' || this.location == null || this.location == ''){
                Swal.fire({
                    icon: 'error',
                    title: 'Zone and Location must be selected.',
                    showConfirmButton: true,
                })
            }else {
                let zone_id = this.zone;
                let location_id = this.location;
                let detail = this.scanned_product;
                let zone_name = this.$props.scan_zones.find(z => z.id == zone_id).name;
                let location_name = this.$props.scan_locations.find(l => l.id == location_id).name;
                this.changeLocationBOM(zone_id, location_id, detail, zone_name, location_name);
            }
        },
        addSerial(){
            let component = this;
            let serials = this.serial.replaceAll('\n','').replaceAll('\t', '').replaceAll(' ', '').split(',');
            serials.forEach(serial => {
                let repeated_serial = this.scanned_product.serial_codes.find(code => code === serial);
                if (this.scanned_product.serial_codes.length >= parseInt(this.scanned_product.quantity_picked)) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'You have all the serial numbers for the product ' + component.scanned_product.name + '. If you need to add more increase the picked quantity.',
                    });
                } else if (repeated_serial != null) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Serial Number already inserted.',
                    });
                } else if ((serial !== "" && serial !== null)) {
                    let serialObject = {sn: serial, warehouse_index: this.index}
                    this.scanned_product.serial_codes.push(serialObject);
                    const Toast = Swal.mixin({
                        toast: true,
                        position: 'center',
                        showConfirmButton: false,
                        timer: 1500
                    });
                    Toast.fire({
                        icon: 'success',
                        title: 'Serial number added to list.'
                    })
                    setTimeout(() =>  {document.getElementById("serial_number").focus()},1);
                }
            })
            this.serial = "";
        },
        deleteSerial(index){
            this.$forceUpdate();
            this.scanned_product.serial_codes.splice(index,1);
        },
        continueScan(){
            const Toast = Swal.mixin({
                toast: true,
                position: 'center',
                showConfirmButton: false,
                timer: 1500
            });
            if (this.scanned_product.serial_codes.length < this.scanned_product.quantity_picked) {
                this.incomplete_serials = true;
            } else {
                Toast.fire({
                    icon: 'success',
                    title: 'Scanned Successfully!'
                })
                this.modal_type = 'scan_item';
                setTimeout(() =>  {document.getElementById("scan_item_picking").focus()},1);
                this.clear_all();
            }
        },
        serialsInventory(){
            let instance = this;
            axios.get('/api/serials/'+ this.scanned_product.product_id).then(function(response) {
                instance.serialsInventoryCodes = response.data;
            }).catch(function(error){
                console.log(error);
            });
        },
        clear_all(){
            console.log('Clear')
            this.input_scan = '';
            this.input_scan_location = '';
            this.input_empty = false;
            this.input_empty_location = false;
            this.wrong_location = false;
            this.screen = 'scan';
            this.isScan = true;
            this.isSelect = false;
            this.isInput = true;
            this.isList = false;
            this.incomplete_serials = false;
        }
    },
    watch: {
        flag: function() {
            this.clear_all();
            this.modal_type = 'scan_item';
            this.input_empty = false;
            this.qty_error = false;
        }
    }
}
</script>

<style scoped>

</style>
