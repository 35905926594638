<template>

    <modal :name="'receiving-details-modal'" height="auto" width="400px">
        <div class="container modal-content border-bottom-0 h-100">
            <div class="modal-header">
                <div class="row justify-content-between">
                    <h4 class="modal-title">Receiving Details</h4>
                </div>
            </div>
            <div class="modal-body">
                <h6 v-if="index === 'tool_scan'">Tool Name: {{$props.product.name}}</h6>
                <h6 v-else>Product Name: {{$props.product.name}}</h6>
                <div class="col-md-12">
                    <div class="row mt-2 align-items-center">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="form-group col-md-12 ml-0 pl-0">
                                    <label for="order_qty">Qty. Received<span class="required_asterisk">*</span></label>
                                    <input autofocus type="number" oninput="validity.valid||(value='')" min="0" class="form-control" name="product" id="order_qty1"  placeholder="####" v-model="product.qty_received">
                                </div>
                            </div>
                            <warehouse-allocation-component :index="index" :parent="product" :type="'product'" :zones="zones" :locations="locations"></warehouse-allocation-component>
                        </div>
                        <div class="col-md-6">
                            <button type="button" class="btn btn-primary ml-2" v-on:click="scanLocation()">Scan Location</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light" v-on:click="close()">Close</button>
                <button type="button" class="btn btn-primary mr-2" v-on:click="submit()">Submit</button>
            </div>

            <scan-location-modal
                :index = "index"
                :product = "product"
                :locations = "locations"
                :zones="zones"
                :flag1="flag1">
            </scan-location-modal>

        </div>
    </modal>
</template>

<script>
import ScanLocationModal from "./ScanLocationModal";

export default {
    name: "ReceivingDetailsModal",
    props: ['product', 'warehouses', 'zones', 'locations', 'index', 'flag2'],
    components:{
        ScanLocationModal
    },
    data(){
        return{
            flag1: false
        }
    },
    mounted(){

    },
    methods:{
        submit(){
            this.$modal.hide('receiving-details-modal');
            this.$modal.hide('scan-item-modal-'+this.$props.index);
            this.$modal.hide('1-serial-codes-modal');
        },
        close(){
            this.$modal.hide('receiving-details-modal');
            this.$modal.hide('scan-item-modal-'+this.$props.index);
            this.$modal.hide('1-serial-codes-modal');
        },
        scanLocation(){
            this.flag1 = !this.flag1;
            this.$modal.show('scan-location-modal');
        }
    },
    watch: {
        flag2: function() {
            setTimeout(function(){
                document.getElementById("order_qty1").focus();
            },0)
        }
    }
}
</script>

<style scoped>

</style>
