<template>
    <div class="col-md-10 grid-margin stretch-card">
        <div class="card">
            <form @submit.prevent="submit" id="product-return-form" name="product-return-form" :ref="'product-return-form'">

                <div class="card-body">
                    <h6 class="card-title">Returns</h6>

                    <h6 class="card-subtitle mb-2 text-muted">Dispatched Inventory</h6>
                    <div class="table-responsive pt-3" id="app">
                        <form id="search" style="float: right; margin-bottom:10px">
                            <div class="mb-2" style="float: right">
                                <input type="search" v-model="filter" placeholder="Search" class="form-control" onkeypress="return event.keyCode != 13;">
                            </div>
                            <div class="mb-2 mt-1" style="float: right">
                                <button type="button" class="btn btn-primary mr-2" v-on:click="scanProduct()">Scan Product</button>
                            </div>
                        </form>
                        <table class="table table-bordered table-hover" :ref="'table'">
                            <thead>
                            <tr>
                                <th>
                                    Add to list
                                </th>
                                <th @click="sort('sku')" onmouseover="this.style.color='orange'" onmouseout="this.style.color=''" style="cursor:pointer">
                                    Product ID
                                </th>
                                <th @click="sort('name')" onmouseover="this.style.color='orange'" onmouseout="this.style.color=''" style="cursor:pointer">
                                    Product
                                </th>
                                <th @click="sort('warehouses')" onmouseover="this.style.color='orange'" onmouseout="this.style.color=''" style="cursor:pointer">
                                    Warehouse Name
                                </th>
                                <!--                                    <th>-->
                                <!--                                        Location-->
                                <!--                                    </th>-->
                                <th @click="sort('quantity_picked')" onmouseover="this.style.color='orange'" onmouseout="this.style.color=''" style="cursor:pointer">
                                    Dispatched Qty.
                                </th>
                                <th>
                                    Return Qty.
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <!--                            <tr v-for="(item,index) in this.$props.dispatched_inventory" :key="item.id + '-' + item.pivot.warehouse_id + '-' + !(product_returns.some(product => product.id === item.id && product.pivot.warehouse_id === item.pivot.warehouse_id))" v-if="!(product_returns.some(product => product.id === item.id && product.pivot.warehouse_id === item.pivot.warehouse_id))">-->
                            <tr v-for="(item,index) in sortedDispatched" :key="item.id + '-' + item.pivot.warehouse_id + '-' + !(product_returns.includes(item))" v-if="!(product_returns.includes(item))">
                                <td>
                                    <button type="button" class="btn btn-primary btn-icon "  @click="returnProduct(item,index)">
                                        <log-out-icon size="1.5x" style="color: white"></log-out-icon>
                                    </button>
                                </td>
                                <td>
                                    {{item.sku}}
                                </td>
                                <td>
                                    {{item.name}}
                                </td>
                                <td>
                                    {{warehouses.find(warehouse => warehouse.id === item.pivot.warehouse_id) != null ? warehouses.find(warehouse => warehouse.id === item.pivot.warehouse_id).name : ''}}
                                </td>
                                <!--                                    <td>-->
                                <!--                                        {{item.location}}-->
                                <!--                                    </td>-->
                                <td>
                                    {{item.pivot.quantity_picked}}
                                </td>
                                <td v-if="item.use_serials" style="width: 50px">
                                    <!--                                    <p v-if="item.use_serials">{{item.returned_qty}}</p>-->
                                    <!--                                    <p v-if="!item.use_serials">-->
                                    <!--                                        <select-record name="serial_numbers" id="serial_numbers" :options="item.serial_numbers" ></select-record>-->
                                    <!--                                    </p>-->
                                    <!--                                    <select-record name="serial_numbers" id="serial_numbers" :options="item.serial_numbers" ></select-record>-->
                                    <div  class="form-group">

                                        <select-record name="serial_numbers" :options="dispatched_record.filter(record => record.product_id == item.id && (record.last_history.warehouse_id == null ? true : item.pivot.warehouse_id == record.last_history.warehouse_id))" v-model="item.serial_numbers"></select-record>
                                        <!--                                        {{dispatched_record.find(r => r.id == item).serial_number}}4-->
                                    </div>
                                    <!--                                    <p>Has Serial</p>-->

                                </td>
                                <td v-else style="width: 50px">
                                    <!--                                    <p v-if="item.use_serials">S/N</p>-->
                                    <p v-if="!item.use_serials && (item.pivot.quantity_picked != 0)">
                                        <input type="number" oninput="validity.valid||(value=\'\')" min="1" :max="item.pivot.quantity_picked" class="form-control" name="returned_qty" placeholder="##" v-model="item.returned_qty" @change="change_qty(item, item.id)">
                                    </p>


                                </td>
                                <!--                                <project-return-modal-->
                                <!--                                    :index="item.id"-->
                                <!--                                    :qualities="quality"-->
                                <!--                                    :product_returns="product_returns"-->
                                <!--                                    :dispatched_inventory="dispatched_inventory"-->
                                <!--                                    :dispatched_record="dispatched_record.filter(record => record.product_id == item.id)"-->
                                <!--                                    :warehouse_id="warehouses.find(warehouse => warehouse.id == item.pivot.warehouse_id).id"-->
                                <!--                                    :product="item"></project-return-modal>-->
                            </tr>
                            </tbody>
                        </table>
                        <nav style="float: right; margin-top: 10px">
                            <ul class="pagination">
                                <li class="page-item">
                                    <button type="button" @click="prevPage" class="page-link">Previous</button>
                                </li>
                                <p v-for="index in pages">
                                    <button v-if="currentPage == index" type="button" @click="alterCurrent(index)" class="page-link" style="background-color: orange; color: white"> {{index}} </button>
                                    <button v-else type="button" @click="alterCurrent(index)" class="page-link"> {{index}} </button>
                                </p>
                                <li class="page-item">
                                    <button type="button" @click="nextPage" class="page-link">Next</button>
                                </li>
                            </ul>
                        </nav>
                        Showing {{paginationCurrentFrom}} to {{paginationCurrentTo}} of {{filteredCats.length}} entries
                    </div>
                    <button type="button" class="btn btn-secondary mr-2 my-4" @click="return_all">Return All</button>
                    <button id ="add_returns_to_list" type="button" class="btn btn-secondary mr-2 my-4" @click="add_returns_to_list">Add Returns to List</button>
                    <br>
                    <h6 class="card-subtitle mb-2 text-muted">Returns</h6>
                    <div class="table-responsive pt-3">
                        <table class="table table-bordered table-hover">
                            <thead>
                            <tr>
                                <th>
                                    Remove/Edit
                                </th>
                                <th>
                                    Product ID
                                </th>
                                <th>
                                    Product
                                </th>
                                <th>
                                    Warehouse Name
                                </th>
                                <!--                                <th>-->
                                <!--                                    Zone Name-->
                                <!--                                </th>-->
                                <!--                                <th>-->
                                <!--                                    Location Name-->
                                <!--                                </th>-->
                                <th>
                                    Dispatched Qty.
                                </th>
                                <th>
                                    Returned Qty.
                                </th>
                                <th>
                                    Quality Check
                                </th>
                                <th>
                                    Reason
                                </th>
                                <th>
                                    Serial Numbers
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(item,index) in product_returns">
                                <td>
                                    <button type="button" class="btn btn-primary btn-icon "  v-on:click="removeFromIn(item.id,index)">
                                        <log-in-icon size="1.5x" style="color: white"></log-in-icon>
                                    </button>
                                    <edit-icon style="cursor: pointer" class="icon-color-primary" v-on:click="edit(item.id,index)"></edit-icon>
                                </td>
                                <td>
                                    {{item.sku}}
                                </td>
                                <td>
                                    {{item.name}}
                                </td>
                                <td>
                                    {{warehouses.find(warehouse => warehouse.id == item.warehouse_id) != null ? warehouses.find(warehouse => warehouse.id == item.warehouse_id).name : ''}}
                                </td>
                                <td>
                                    {{item.pivot.quantity_picked}}
                                </td>
                                <td>
                                    <!--                                    -->
                                    <!--                                    <p v-if="item.use_serials">{{item.returned_qty}}</p>-->
                                    <!--                                    <p v-if="!item.use_serials">-->
                                    <!--                                        <input type="number" oninput="validity.valid||(value='')" min="0" :max="item.pivot.quantity_picked" class="form-control" name="quantity"  placeholder="####" v-model="item.returned_qty">-->
                                    <!--                                    </p>-->

                                    <p>{{item.returned_qty}}</p>

                                    <!--                                    -->
                                </td>
                                <td>
                                    {{quality.find(q => q.id == item.quality_check).name}}
                                </td>
                                <td>
                                    {{item.return_reason}}
                                </td>
                                <td>
                                    <div v-for="(record,index) in item.serial_numbers" class="row">
                                        <p v-if="record.id == item.serial_numbers[0].id">{{dispatched_record.find(r => r.id == record).serial_number}}</p>
                                        <p v-else>{{', ' + dispatched_record.find(r => r.id == record).serial_number}}</p>
                                    </div>
                                </td>
                                <project-return-modal
                                    :index="item.id"
                                    :qualities="quality"
                                    :product_returns="product_returns"
                                    :dispatched_inventory="dispatched_inventory"
                                    :dispatched_record="dispatched_record.filter(record => record.product_id == item.id)"
                                    :warehouse_id1="warehouses.find(warehouse => warehouse.id == item.warehouse_id) != null ? warehouses.find(warehouse => warehouse.id == item.warehouse_id).id : ''"
                                    :zones="zones"
                                    :locations="locations"
                                    :product="item"></project-return-modal>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="mt-5">
                        <button :disabled="submit_disabled" type="button" class="btn btn-primary mr-2" @click="disableButton">Submit Inventory</button>
                        <div v-if="submit_disabled" class="spinner-border text-primary ml-4" role="status">
                            <span class="visually-hidden"></span>
                        </div>
                    </div>
                </div>

                <return-scan-item-modal
                    :index="this.index"
                    :products="$props.dispatched_inventory"
                    :flag="flag"
                    :product_returns="sortedDispatched"
                    :returned_list="this.product_returns"
                    :dispatched_record="dispatched_record"
                    :warehouses="warehouses"
                    :zones="zones"
                    :locations="locations"
                    :parent="this"
                    :quality="$props.quality">
                </return-scan-item-modal>

            </form>
        </div>
    </div>
</template>
<script>
import Swal from "sweetalert2";
import 'jquery-validation';
import {LogInIcon, LogOutIcon} from 'vue-feather-icons';
import datePicker from 'vue-bootstrap-datetimepicker';
import ReturnScanItemModal from "./ReturnScanItemModal";

export default {
    name: "ProjectReturnComponent",
    props: {
        dispatched_inventory: Array,
        dispatched_record: Array,
        project: Object,
        quality: Array,
        warehouses: Array,
        zones: Array,
        locations: Array,
    },
    components:{
        LogOutIcon, LogInIcon,
        datePicker,
        ReturnScanItemModal,
    },
    data(){
        return{
            product_returns: [],
            form:{
                products:[],
                check_in_date:'',
                project_cancelled: false
            },
            datePickerOptions:{
                format: 'DD/MM/YYYY'
            },
            returned_qty: '',
            elmIndex:[],
            newIndex:[],

            pageSize:10,
            currentPage:1,
            filter:'',
            currentSort:'sku',
            currentSortDir:'asc',
            paginationCurrentFrom: 1,
            paginationCurrentTo: 10,
            cc:'',
            pages:[],
            totalPages:0,
            flag:false,
            index:'return_component',
            upc_array:Array,
            submit_disabled: false,
        }
    },
    mounted() {
        this.totalPages = Math.ceil(this.filteredCats.length / this.pageSize);
        for(let i = 1; i <= this.totalPages; i++){
            this.pages.push(i);
        }

        for(let i = 0; i < this.$props.dispatched_inventory.length; i++){
            this.elmIndex.push(i);
        }

        this.$props.dispatched_inventory.forEach((inventory,index) => {
            this.$props.dispatched_inventory[index]['serial_numbers'] = [];
        })



        this.$props.dispatched_record = Object.values(this.$props.dispatched_record)

        $("form[name='product-return-form']").validate({
            rules: {
                check_in_date: {
                    required: true,
                },
            },
            errorPlacement: function(label, element) {
                if(['check_in_date'].includes(element[0].name)){
                    label.addClass('mt-2 text-danger');
                    label.insertAfter(element.next());
                }else{
                    label.addClass('mt-2 text-danger');
                    label.insertAfter(element);
                }
            },
            highlight: function(element, errorClass) {
                $(element).parent().addClass('has-danger')
                $(element).addClass('form-control-danger')
            },
            unhighlight: function(element, errorClass, validClass) {
                $(element).parent().removeClass('has-danger')
                $(element).removeClass('form-control-danger')
            },
            submitHandler: function(form){
                this.submit()
                // form.submit()
            }
        });
    },
    methods:{
        disableButton(){
            this.submit_disabled = true;
            this.submit();
        },
        sort:function(s) {
            console.log(s)
            if(s === this.currentSort) {
                this.currentSortDir = this.currentSortDir==='asc'?'desc':'asc';
            }
            this.currentSort = s;
        },
        alterCurrent(index){
            this.currentPage = index;
            if(this.currentPage === 1){
                this.paginationCurrentFrom = 1;
                this.paginationCurrentTo = 10;
            } else if(this.paginationCurrentTo > this.filteredCats.length - this.pageSize){
                this.paginationCurrentTo = this.filteredCats.length;
            } else{
                this.paginationCurrentFrom = this.pageSize*(this.currentPage-1)+1;
                this.paginationCurrentTo = this.pageSize*(this.currentPage);
            }
        },
        nextPage:function() {
            if((this.currentPage*this.pageSize) < this.filteredCats.length) {
                this.currentPage++;
                this.paginationCurrentFrom += this.pageSize;
                if(this.paginationCurrentTo > this.filteredCats.length - this.pageSize){
                    this.paginationCurrentTo = this.filteredCats.length;
                }else{
                    this.paginationCurrentTo = this.paginationCurrentTo + this.pageSize;
                }
            }
        },
        prevPage:function() {
            if(this.currentPage > 1){
                this.currentPage--;
                this.paginationCurrentFrom = this.paginationCurrentFrom - this.pageSize;
                if(this.paginationCurrentTo === this.filteredCats.length){
                    this.cc = 10 - ((10*(this.currentPage+1))-this.filteredCats.length);
                    console.log("d:",this.cc)
                    this.paginationCurrentTo = this.paginationCurrentTo - this.cc;
                }else{
                    this.paginationCurrentTo = this.paginationCurrentTo - this.pageSize;
                }
            }else if(this.currentPage === 1){
                this.paginationCurrentFrom = 1;
            }
        },
        submit(){
            let validation_flag = false;
            this.product_returns.forEach(product =>{
                console.log("Aqui", product.returned_qty)
                if(product.returned_qty <= 0){
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Returned quantity field should be greater than 0 for all the products to be returned...'
                    });
                    validation_flag = true;
                    this.submit_disabled = false;
                }
            })

            if(!validation_flag){
                this.form.products = this.product_returns;
                console.log("Form Products:",this.form.products);
                let attributes = this.form;

                axios.post('/projects/'+this.$props.project.id+'/return',attributes).then(response => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Success',
                        text: 'Products returned to inventory!'
                    });
                    this.submit_disabled = false;
                    location.reload();
                });
            }
        },
        // addModal(id,index){
        //     let return_reason = '';
        //     let quality_check = '';
        //     let returned_qty = '';
        //     let serial_number = '';
        //     let qualities = '';
        //
        //     this.$props.quality.forEach(quality => {
        //         qualities += '<option value="'+quality.name +'">' + quality.name +'</option>';
        //     })
        //
        //     let quantity_picked = this.$props.dispatched_inventory.find(tool=> tool.id == id).pivot.quantity_picked
        //     Swal.fire({
        //         title: 'Product to Return',
        //         html:
        //             '<label for="returned_qty">Returned Qty.<span class="required_asterisk">*</span></label>'+
        //             '<input type="number" oninput="validity.valid||(value=\'\')" min="1" max="'+ quantity_picked +'" id="returned_qty" class="swal2-input">'+
        //             '<label for="quality_check">Quality Check<span class="required_asterisk">*</span></label>'+
        //             '<select id="quality_check" class="swal2-input">'+
        //             qualities +
        //             '</select>'+
        //             '<label for="return_reason">Return Reason<span class="required_asterisk">*</span></label>'+
        //             '<input id="return_reason" class="swal2-input">'+
        //             '<label for="serial_number">Serial Number<span class="required_asterisk">*</span></label>'+
        //             '<input id="serial_number" class="swal2-input">'
        //
        //         ,
        //         focusConfirm: false,
        //         preConfirm: () => {
        //             return_reason = document.getElementById('return_reason').value;
        //             quality_check = document.getElementById('quality_check').value;
        //             returned_qty = document.getElementById('returned_qty').value;
        //             serial_number = document.getElementById('serial_number').value
        //             if(returned_qty > quantity_picked){
        //                 Swal.fire({
        //                     icon: 'error',
        //                     title: 'Oops...',
        //                     text: 'You cannot return more than what was dispatched, please try again.',
        //                 });
        //             }
        //             else if(returned_qty == 0){
        //                 Swal.fire({
        //                     icon: 'error',
        //                     title: 'Oops...',
        //                     text: 'You cannot return 0, try again.',
        //                 });
        //             }
        //             else {
        //                 this.addToIn(id, index, quality_check, return_reason, returned_qty, serial_number)
        //             }
        //         }
        //     });
        // },
        // addToIn(id,index,quality_check,return_reason,returned_qty,serial_number){
        //     let toolToIn = this.$props.dispatched_inventory.find(tool=> tool.id == id);
        //
        //     toolToIn.return_reason = return_reason;
        //     toolToIn.quality_check = quality_check;
        //     toolToIn.returned_qty = returned_qty;
        //     toolToIn.serial_number = serial_number;
        //     this.$props.dispatched_inventory.splice(index,1);
        //
        //     this.product_returns.push(toolToIn);
        // },
        removeFromIn(id,index){
            let toolToOut = this.product_returns.find(tool=> tool.id == id);

            this.product_returns.splice(index,1);
            toolToOut.return_reason = ''
            toolToOut.returned_qty = ''
            toolToOut.serial_number = ''
            toolToOut.quality_check = '';
            // this.$props.dispatched_inventory.push(toolToOut);
            // this.$forceUpdate();
        },
        loadTools(){
            this.product_returns = [];
            this.$props.dispatched_inventory = this.$props.supervisors.find(supervisor => supervisor.id == this.form.supervisor_id).tools.filter(tool => tool.in_use == 1);
        },
        //----------------
        add_returns_to_list(){

            let i;
            for(i = this.$props.dispatched_inventory.length - 1; i >=0; i--) {
                if(this.$props.dispatched_inventory[i].returned_qty > 0 || this.$props.dispatched_inventory[i].serial_numbers.length > 0 ){

                    let product =this.$props.dispatched_inventory[i];

                    product.return_reason = '';
                    product.quality_check = this.$props.quality.find(quality => quality.slug == 'good').id;
                    product.warehouse_id =  23; //product.pivot.warehouse_id;
                    product.zone_id = 11; //null;
                    product.location_id = 275; //null;
                    product.replacement = 'no';
                    if(this.$props.dispatched_inventory[i].use_serials){
                        product.returned_qty = this.$props.dispatched_inventory[i].serial_numbers.length;
                    }

                    if(!(this.product_returns.some(list_product => list_product.id === product.id && list_product.pivot.warehouse_id === product.pivot.warehouse_id))){
                        this.product_returns.push(product);
                    }

                    // this.$props.dispatched_inventory.splice(i, 1);

                    // this.elmIndex.push(i);
                    // console.log(this.elmIndex);
                    this.newIndex.push(this.elmIndex[i]);
                    console.log(this.newIndex);

                }

                // this.$forceUpdate();

            }
        },
        change_qty(item,id){
            let ret = this.$props.dispatched_inventory.find(tool=> tool.id == id).pivot.quantity;
            let product = item;
            product.returned_qty = item.returned_qty ;

            if(product.returned_qty > (this.$props.dispatched_inventory.find(tool=> tool.id == id).pivot.quantity + this.$props.dispatched_inventory.find(tool=> tool.id == id).pivot.additional_amount) ){
                document.getElementById("add_returns_to_list").disabled = true;
            } else{
                document.getElementById("add_returns_to_list").disabled = false;
            }

            this.$forceUpdate();

            // this.rows().invalidate().draw();
        },
        edit(id,index){
            this.$modal.show(id+'-return-modal')
            console.log(this.newIndex[index]);
        },
        //-----------------
        return_all(){
            const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                    confirmButton: 'btn btn-success',
                    cancelButton: 'btn btn-danger'
                },
                buttonsStyling: false,
            })

            swalWithBootstrapButtons.fire({
                title: 'Are you sure?',
                text: "This will return all the products to the inventory for this project.",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonClass: 'ml-2',
                confirmButtonText: 'Yes, delete it!',
                cancelButtonText: 'No, cancel!',
                reverseButtons: true
            }).then((result) => {

                let component = this;

                if (result.value) {

                    axios.post('/projects/'+this.$props.project.id+'/return_all').then(response => {
                        location = response.data.message;
                    });

                } else if (result.dismiss === Swal.DismissReason.cancel) {

                }
            })
        },
        returnProduct(item, index, is_return){
            console.log(item);
            // this.$forceUpdate();
            if(item.use_serials && (item.serial_numbers.length <= 0)) {

                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Must select a S/N'
                });
            }
            else if(!item.use_serials && (item.returned_qty <= 0 || item.returned_qty == '' || item.returned_qty == null)){

                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Must add a return quantity.'
                });

            }else if(!item.use_serials && (item.returned_qty > this.$props.dispatched_inventory.find(tool=> tool.id == item.id).pivot.quantity_picked)){
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Returned quantity must be less or equal to dispatched quantity.'
                });
            }else if(this.product_returns.includes(item)){
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Product Already added to the list of returns.'
                });
            }
            else{
                let product = item;
                let qlity_chck = item.quality_check;

                if(item.use_serials){
                    product.return_reason = '';
                    product.quality_check = this.$props.quality.find(quality => quality.slug == 'good').id;
                    product.returned_qty = item.serial_numbers.length;
                    if(is_return !== 'return_component'){
                        product.warehouse_id = 23;//item.pivot.warehouse_id;
                        product.zone_id = 11;//null;
                        product.location_id = 275;//null;
                    }else{
                        product.warehouse_id = 23; //item.warehouse_id_scan;
                        product.zone_id = 11; //item.zone_id_scan;
                        product.location_id = 275;//item.location_id_scan;
                        product.quality_check = qlity_chck
                    }
                    product.replacement = 'no';
                    this.$props.dispatched_inventory.splice(index, 1);
                    if(!(this.product_returns.some(list_product => list_product.id === product.id && list_product.pivot.warehouse_id === product.pivot.warehouse_id))){
                        this.product_returns.push(item);
                    }
                }else {
                    product.return_reason = '';
                    product.quality_check = this.$props.quality.find(quality => quality.slug == 'good').id;
                    if(is_return !== 'return_component'){
                        product.warehouse_id = 23; //item.pivot.warehouse_id;
                        product.zone_id = 11; //null;
                        product.location_id = 275; //null;
                    }else{
                        product.warehouse_id = 23;//item.warehouse_id_scan;
                        product.zone_id = 11; //item.zone_id_scan;
                        product.location_id = 275; //item.location_id_scan;
                        product.quality_check = qlity_chck;
                    }
                    product.replacement = 'no';
                    this.$props.dispatched_inventory.splice(index, 1);
                    this.product_returns.push(item);
                }
            }
        },
        scanProduct(){
            this.flag = !this.flag;
            this.filter = '';
            this.$modal.show('return-scan-item-modal-'+this.index);
        },
        getMatchingResults(upc_array) {
            return upc_array.filter(d => this.filter.split(',').every(v => d.toLowerCase() === v.toLowerCase()));
        },
    },
    watch: {
        filter() {
            console.log('reset to p1 due to filter');
            this.currentPage = 1;
        }
    },
    computed: {
        filteredCats() {
            return this.$props.dispatched_inventory.filter(c => {
                if (this.filter == '') return true;
                if(c.upc != null){
                    this.upc_array = c.upc.split(",");
                }
                return (c.sku.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
                || c.name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
                || this.warehouses.find(warehouse => warehouse.id === c.pivot.warehouse_id).name.toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
                || c.pivot.quantity_picked.toString().toLowerCase().indexOf(this.filter.toLowerCase()) >= 0
                || (c.upc != null && this.getMatchingResults(this.upc_array)[0]));
            })
        },
        sortedDispatched() {

            return this.filteredCats.sort((a, b) => {
                let modifier = 1;

                if(this.currentSort === 'warehouses'){
                    console.log('here', b[this.currentSort][0]['name'])
                    if (this.currentSortDir === 'desc') modifier = -1;
                    if (a[this.currentSort][0]['name'] < b[this.currentSort][0]['name']) return -1 * modifier;
                    if (a[this.currentSort][0]['name'] > b[this.currentSort][0]['name']) return 1 * modifier;
                    return 0;
                }else if(this.currentSort === 'quantity_picked'){
                    if (this.currentSortDir === 'desc') modifier = -1;
                    if (a['pivot'][this.currentSort] < b['pivot'][this.currentSort]) return -1 * modifier;
                    if (a['pivot'][this.currentSort] > b['pivot'][this.currentSort]) return 1 * modifier;
                    return 0;
                }else {
                    if (this.currentSortDir === 'desc') modifier = -1;
                    if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
                    if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
                    return 0;
                }
            }).filter((row, index) => {
                let start = (this.currentPage - 1) * this.pageSize;
                let end = this.currentPage * this.pageSize;
                if (index >= start && index < end) return true;
            });
        }
    }
}
</script>

<style scoped>

</style>
