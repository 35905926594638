<template>
    <div>
        <form @submit.prevent="submit" id="receiving-form" name="receiving-form" :ref="'receiving-form'">
            <div class="col-md-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h6 class="card-title">RMA Returns Details</h6>

                        <div class="mb-4 row">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-2">
                                        Project Number:
                                    </div>
                                    <div class="col-md-10">
                                        {{returned.project.project_number}}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-2">
                                        Deal Name:
                                    </div>
                                    <div class="col-md-10">
                                        {{returned.project.client_name}}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-2">
                                        Finance Company:
                                    </div>
                                    <div class="col-md-10">
                                        {{returned.project.financing_source_name}}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-5">
                            <div class="col-md-6">
                                <h6 class="card-title">Return Details</h6>
                            </div>
                            <div class="col-md-6 pl-0">
                                <h6 class="card-title">Replacement Details</h6>
                            </div>
                        </div>
                        <div v-for="(product,index) in form.products" class="row mb-3 border-bottom">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-xl-5">
                                        <p class="mr-4 mb-2"><b>Product: </b></p>
                                    </div>
                                    <div class="col-xl-7 ml-2 ml-xl-0">
                                        <p class="mb-2">{{product.name}}</p>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-xl-5">
                                        <p class="mr-4 mb-2"><b>Product ID: </b></p>
                                    </div>
                                    <div class=" col-xl-7 ml-2 ml-xl-0">
                                        <p class="mb-2">{{product.sku}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-5">
                                        <p class="mr-4 mb-2"><b>Description:  </b></p>
                                    </div>
                                    <div class="col-xl-7 ml-2 ml-xl-0">
                                        <p class="mb-2">{{product.description}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-5">
                                        <p class="mr-4 mb-2"><b>Returned Qty.:  </b></p>
                                    </div>
                                    <div class="col-xl-7 ml-2 ml-xl-0">
                                        <p class="mb-2">{{returned.returned_quantity}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-5">
                                        <p class="mr-4 mb-2"><b>Serial Number: </b></p>
                                    </div>
                                    <div class="col-xl-7 ml-2 ml-xl-0">
                                        <p class="mb-2">{{returned.serial_records.map(sr => sr['serial_number']).join()}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="form-group">
                                        <label for="order_qty">Qty Received<span class="required_asterisk">*</span></label>
                                        <input type="number" oninput="validity.valid||(value='')" min="0" class="form-control" name="product" id="order_qty" placeholder="####" v-model="product.qty_received">
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="form-check form-check-flat form-check-primary">
                                        <label class="form-check-label">
                                            <input type="checkbox" class="form-check-input" @change="updateComponent" v-model="product.same_warehouse" >
                                            Same Warehouse?
                                        </label>
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="form-check form-check-flat form-check-primary">
                                        <label class="form-check-label">
                                            <input type="checkbox" class="form-check-input" @change="updateComponent" v-model="product.scan_serials">
                                            Scan Serial Numbers?
                                        </label>
                                    </div>
                                </div>
                                <div class="row">
                                    <h6 class="card-title">Warehouse Allocation</h6>
                                </div>
                                <warehouse-allocation-component class="mb-4" ref="warehouse-component" :index="1" :parent="product" :type="'product'" :zones="zones" :locations="locations"></warehouse-allocation-component>
                                <div class="row">
                                    <div class="form-group">
                                        <label for="received_date">Date Received<span class="required_asterisk">*</span></label>
                                        <div class="input-group date datepicker dashboard-date mr-2 mb-2 mb-md-0 d-xl-flex">
                                            <span class="input-group-addon bg-transparent"><i data-feather="calendar" class=" text-primary"></i></span>
                                            <datePicker autocomplete="off" v-model="form.received_date" :config="datePickerOptions" name="received_date"></datePicker>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group w-100">
                                        <label for="comment">Comment</label>
                                        <textarea class="form-control" id="comment" name="comment" placeholder="Comments" rows="5" v-model="form.comment">Comment</textarea>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <button type="submit" class="btn btn-primary mr-2">Submit</button>
                        <a href="/accounting/rma" class="btn btn-light">Cancel</a>
                    </div>
                </div>
            </div>
        </form>

    </div>
</template>

<script>
    import 'bootstrap-datepicker';
    import datePicker from 'vue-bootstrap-datetimepicker';
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
    import Swal from 'sweetalert2';
    import { EditIcon} from 'vue-feather-icons';
    import 'dropify';
    import 'dropify/dist/css/dropify.min.css'
    import moment from "moment";

    export default {
        name: "RmaProductComponent",
        props:['returned','warehouses','zones','locations'],
        components:{
            EditIcon,
            datePicker
        },
        beforeMount() {

            let product = this.$props.returned.product;
            this.form.products.push({
                id: product.id,
                name: product.name,
                sku: product.sku,
                description: product.description,
                order_qty: "",
                qty_received: "",
                serial_codes: [],
                // warehouse_id: product.warehouse_id,
                // zone_id: product.zones.length > 0 ? product.zones[0].id : "",
                // location_id: product.locations.length > 0 ? product.locations[0].id:"",
                zones_options: [],
                locations_options: [],
                category: product.category,
                description_slug: product.description_slug,
                product_identifier: product.product_identifier,
                warehouses_allocation:[],
                warehouses: product.warehouses,
                warehouse_id:"",
                zone_id: "",
                location_id: "",
                scan_serials:true,
                same_warehouse:true
            })

        },
        data(){
            return{
                form:{
                    products: [],
                    received_date: moment().format('MM/DD/YYYY'),
                    invoice_number: "",
                    comment: "",
                    product: ''
                },
                location_options: [],
                zones_options: [],
                purchase_order: this.$props.order,
                datePickerOptions:{
                    format: 'MM/DD/YYYY',
                    useCurrent: false,
                    maxDate: new Date()
                },
                moment: moment
            }
        },
        methods:{
            submit(){
                let validSerials = true;
                let validQtyReceived = true;

                this.form.product = this.form.products[0]

                console.log(this.form.product);

                if(this.form.product.qty_received === '' || this.form.product.qty_received === null){
                    validQtyReceived = false;
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Input the quantity received for the product '+this.form.product.name + ' (' + this.form.product.sku + ').',
                    });
                } else if(this.form.product.serial_codes.length > 0 && this.form.product.qty_received !== this.form.product.serial_codes.length.toString()){
                    validSerials = false;
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'The product '+product.name + ' (' + product.sku + ') does not have enough serials.',
                    });
                }


                if(validSerials && validQtyReceived){
                    let attributes = this.form


                    axios.post('/accounting/products/' + this.$props.returned.id + '/rma',attributes).then(response => {
                        // let id = response.data.message;
                        location = response.data.message;
                        // axios.post('/orders/'+id+'/documents',formData).then(response =>{
                        //     location = response.data.message
                        // });
                    }).catch(error=>{
                        if(error.response.status === 403){
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'This RMA was already added o the inventory',
                            });
                        }
                    });
                }
            },
            loadZones(warehouse_id,product){
                product.zones_options = this.$props.warehouses.find(warehouse => warehouse.id == warehouse_id).zones
            },
            loadLocations(warehouse_id,zone_id,product){
                if(!!zone_id){
                    product.location_options = this.$props.warehouses.find(warehouse => warehouse.id == warehouse_id).zones.find(zone => zone.id == zone_id).locations
                }
            },
            updateComponent(){
                this.$forceUpdate();
                this.$refs['warehouse-component'][0].$forceUpdate();
            }
        }
    }
</script>

<style scoped>

</style>
