<template>
    <div>
        <button type="button" class="btn btn-primary mr-2" v-on:click="$modal.show('scan-item-bin-2-bin-modal'); changeFlag()">Scan Item</button>

        <scan-item-bin-2-bin-modal
            :products="$props.products"
            :flag="flag"
            :is_transfer="is_transfer"
            :transfer_inventory = '$props.transfer_inventory'
            :parent="parent">
        </scan-item-bin-2-bin-modal>
    </div>
</template>

<script>
import ScanItemB2BinModal from "./ScanItemBin2BinModal";

export default {
    name: "ScanItemBin2Bin",
    props: ['products', 'is_transfer', 'transfer_inventory', 'parent'],
    components:{
        ScanItemB2BinModal
    },
    data(){
        return {
            input_scan_sku:'',
            flag:false,
            transfer_inventory:[],
        }
    },
    methods:{
        changeFlag(){
            this.flag = !this.flag;
        }
    }
}
</script>

<style scoped>

</style>
