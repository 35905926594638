<template>
    <div>
        <form @submit.prevent="submit" id="receiving-form" name="receiving-form" :ref="'receiving-form'">
            <div class="col-md-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">
                        <h6 class="card-title">Warranty Claim Details</h6>

                        <div class="row mt-5">
                            <div class="col-md-6">
                                <h6 class="card-title">Tools Details</h6>
                            </div>
                            <div class="col-md-6 pl-0">
                                <h6 class="card-title">Replacement Details</h6>
                            </div>
                        </div>
                        <div class="row mb-3 border-bottom">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-xl-5">
                                        <p class="mr-4 mb-2"><b>Category: </b></p>
                                    </div>
                                    <div class="col-xl-7 ml-2 ml-xl-0">
                                        <p class="mb-2">{{form.tool.tool.category.name}}</p>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-xl-5">
                                        <p class="mr-4 mb-2"><b>Description: </b></p>
                                    </div>
                                    <div class=" col-xl-7 ml-2 ml-xl-0">
                                        <p class="mb-2">{{form.tool.tool.description}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-5">
                                        <p class="mr-4 mb-2"><b>Brand Name:  </b></p>
                                    </div>
                                    <div class="col-xl-7 ml-2 ml-xl-0">
                                        <p class="mb-2">{{form.tool.tool.brand.name}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-5">
                                        <p class="mr-4 mb-2"><b>Model:  </b></p>
                                    </div>
                                    <div class="col-xl-7 ml-2 ml-xl-0">
                                        <p class="mb-2">{{form.tool.tool.model}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-5">
                                        <p class="mr-4 mb-2"><b>Returned Qty. </b></p>
                                    </div>
                                    <div class="col-xl-7 ml-2 ml-xl-0">
                                        <p class="mb-2">{{1}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-5">
                                        <p class="mr-4 mb-2"><b>Tool Number:  </b></p>
                                    </div>
                                    <div class="col-xl-7 ml-2 ml-xl-0">
                                        <p class="mb-2">{{form.tool.tool_number}}</p>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-xl-5">
                                        <p class="mr-4 mb-2"><b>Serial Number:  </b></p>
                                    </div>
                                    <div class="col-xl-7 ml-2 ml-xl-0">
                                        <p class="mb-2">{{form.tool.serial_code}}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="form-group">
                                        <label for="order_qty">Qty Received<span class="required_asterisk">*</span></label>
                                        <p>1</p>
                                        <!--                                        <input type="number" disabled oninput="validity.valid||(value='')" min="0" class="form-control" name="tool" id="order_qty" placeholder="####" v-model="form.tool.qty_received">-->
                                    </div>
                                </div>
                                <div class="row mb-2">
                                    <div class="form-check form-check-flat form-check-primary">
                                        <label class="form-check-label">
                                            <input type="checkbox" class="form-check-input" name="remember" id="remember" v-model="form.tool.refurbished">
                                            Refurbished Tool
                                        </label>
                                    </div>
                                </div>
                                <div class="row" v-if="!form.tool.refurbished">
                                    <div class="form-group">
                                        <label for="order_qty">Serial Number</label>
                                        <input type="text" class="form-control" name="tool" id="serial_number" placeholder="####" v-model="form.tool.serial_number">
                                    </div>
                                </div>
                                <!--                                <div class="row">-->
                                <!--                                    <div class="form-group">-->
                                <!--                                        <label for="order_qty">Serial Numbers</label>-->
                                <!--                                        <p type="button" style="color: #007bff" @click="$modal.show(1+'-serial-codes-modal')">Add or Edit Codes</p>-->
                                <!--                                    </div>-->
                                <!--                                </div>-->
                                <div class="row">
                                    <div class="form-group col-md-12 ml-0 pl-0">
                                        <label for="warehouse_id">Warehouse Name<span class="required_asterisk">*</span></label>
                                        <dropbox :name="1 + 'warehouse_id'" :id="1 + 'warehouse_id'" :options="form.tool.tool.warehouses" v-model="form.tool.warehouse_id" @change="loadZones(form.tool.warehouse_id,form.tool)">
                                            <option></option>
                                        </dropbox>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-12 ml-0 pl-0">
                                        <label for="zone_id">Zone</label>
                                        <dropbox :name="1 + 'zone_id'" :id="1 + 'zone_id'" :options="zones_options" v-model="form.tool.zone_id" @change="loadLocations(tool.warehouse_id,tool.zone_id,tool)">
                                            <option></option>
                                        </dropbox>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group col-md-12 ml-0 pl-0">
                                        <label for="location_id">Location</label>
                                        <dropbox :name="1 + 'location_id'" :id="1 + 'location_id'" :options="location_options" v-model="form.tool.location_id" >
                                            <option></option>
                                        </dropbox>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group">
                                        <label for="received_date">Date Received<span class="required_asterisk">*</span></label>
                                        <div class="input-group date datepicker dashboard-date mr-2 mb-2 mb-md-0 d-xl-flex">
                                            <span class="input-group-addon bg-transparent"><i data-feather="calendar" class=" text-primary"></i></span>
                                            <datePicker autocomplete="off" v-model="form.received_date" :config="datePickerOptions" name="received_date"></datePicker>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group w-100">
                                        <label for="comment">Comment</label>
                                        <textarea class="form-control" id="comment" name="comment" placeholder="Comments" rows="5" v-model="form.comment">Comment</textarea>
                                    </div>
                                </div>
                            </div>
                            <!--                            <serial-codes-modal :product="form.tool" :index="1"></serial-codes-modal>-->
                        </div>

                        <button type="submit" class="btn btn-primary mr-2">Submit</button>
                        <a href="/accounting/warranty" class="btn btn-light">Cancel</a>
                    </div>
                </div>
            </div>
        </form>

    </div>
</template>

<script>
    import 'bootstrap-datepicker';
    import datePicker from 'vue-bootstrap-datetimepicker';
    import 'pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.css';
    import Swal from 'sweetalert2';
    import { EditIcon} from 'vue-feather-icons';
    import 'dropify';
    import 'dropify/dist/css/dropify.min.css'
    import moment from "moment";

    export default {
        name: "WarrantyToolComponent",
        props:['tool','warehouses'],
        components:{
            EditIcon,
            datePicker
        },
        beforeMount() {
            this.form.tool['name'] = this.form.tool.tool.model;
            this.form.tool['sku'] = this.form.tool.tool.brand.name;

            // if(this.form.tool.warehouse_id !== "" && this.form.tool.warehouse_id !== null){
            //
            //     this.zone_options = this.$props.warehouses.find(warehouse => warehouse.id == this.form.tool.warehouse_id).zones
            //
            //     if(this.form.tool.zone != null){
            //         this.location_options = this.$props.warehouses.find(warehouse => warehouse.id == this.form.tool.warehouse_id).zones.find(zone => zone.id == this.form.tool.zones[0].id).locations
            //     }
            // }

            this.form.tool.qty_received = 1;
            this.form.tool.warehouse_id = '';

        },
        data(){
            return{
                form:{
                    tool: this.$props.tool,
                    received_date: moment().format('MM/DD/YYYY'),
                    invoice_number: "",
                    comment: "",
                },
                location_options: [],
                zones_options: [],
                purchase_order: this.$props.order,
                datePickerOptions:{
                    format: 'MM/DD/YYYY',
                    useCurrent: false,
                    maxDate: new Date()
                },
                moment: moment
            }
        },
        methods:{
            submit(){
                let validSerials = true;
                let validQtyReceived = true;

                if(this.form.tool.qty_received === '' || this.form.tool.qty_received === null){
                    validQtyReceived = false;
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Input the quantity received for the tool '+this.form.tool.tool.model + ' (' + this.form.tool.tool.brand + ').',
                    });
                }


                if(validQtyReceived){
                    let attributes = this.form
                    let formData = new FormData();

                    axios.post('/accounting/toolinventory/' + this.form.tool.tool.id + '/warranty',attributes).then(response => {
                        // let id = response.data.message;
                        location = response.data.message;
                        // axios.post('/orders/'+id+'/documents',formData).then(response =>{
                        //     location = response.data.message
                        // });
                    }).catch(error=>{
                        if(error.response.status === 403){
                            Swal.fire({
                                icon: 'error',
                                title: 'Oops...',
                                text: 'This PO was already added o the inventory',
                            });
                        }
                    });
                }
            },
            loadZones(warehouse_id,tool){
                console.log(warehouse_id);
                this.zones_options = this.$props.warehouses.find(warehouse => warehouse.id == warehouse_id).zones
                this.form.tool.zone_id = '';
                this.form.tool.location_id = '';
                this.location_options = []
            },
            loadLocations(warehouse_id,zone_id,tool){
                if(!!zone_id){
                    this.location_options = this.$props.warehouses.find(warehouse => warehouse.id == warehouse_id).zones.find(zone => zone.id == zone_id).locations
                    this.form.tool.location_id = '';
                }
            }
        }
    }
</script>

<style scoped>

</style>
