<template>
    <div class="card">
        <div class="card-body">
            <h4 class="card-title">Transfer Order Picking</h4>
            <div>
                <p><b>Transfer order #: </b>{{transfer_order.transfer_order_number}}</p>
                <p><b>Date Ordered: </b>{{transfer_order.created_date}}</p>
                <p><b>Ordered By: </b>{{users.find(user => user.id == transfer_order.created_by).first_name + ' ' + users.find(user => user.id == transfer_order.created_by).last_name}}</p>
            </div>
            <div class="table-responsive pt-3 mb-12">
                <table class="table table-bordered table-hover data-table">
                    <thead>
                    <tr>
                        <th>
                            Product
                        </th>
                        <th>
                            SKU
                        </th>
                        <th>
                            From
                        </th>
                        <th>
                            To
                        </th>
                        <th>
                            Qty. On Hand
                        </th>
                        <th>
                            Qty. Ordered
                        </th>
                        <th>
                            Quantity
                        </th>
                        <th style="width: 50px">
                            Comments
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(transfer,index) in this.transfer_order_inventory">
                        <td>
                            {{products.find(prod => prod.id == transfer.product_id).name}}
                        </td>
                        <td>
                            {{products.find(prod => prod.id == transfer.product_id).sku}}
                        </td>
                        <td>
                            {{warehouses.find(wh => wh.id == transfer.old_warehouse_id) == null ? '' : warehouses.find(wh => wh.id == transfer.old_warehouse_id).name
                            + ' - ' + (zones.find(zn => zn.id == transfer.old_zone_id) == null ? '' : zones.find(zn => zn.id == transfer.old_zone_id).name)
                            + ' - ' + (locations.find(ln => ln.id == transfer.old_location_id) == null ? '' : locations.find(ln => ln.id == transfer.old_location_id).name)}}
                        </td>
                        <td>
                            {{warehouses.find(wh => wh.id == transfer.new_warehouse_id) == null ? '' : warehouses.find(wh => wh.id == transfer.new_warehouse_id).name
                            + ' - ' + (zones.find(zn => zn.id == transfer.new_zone_id) == null ? '' : zones.find(zn => zn.id == transfer.new_zone_id).name)
                            + ' - ' + (locations.find(ln => ln.id == transfer.new_location_id) == null ? '' : locations.find(ln => ln.id == transfer.new_location_id).name)}}
                        </td>
                        <td>
                            {{transfer.qty_on_hand}}
                        </td>
                        <td>
                            {{transfer.created_qty}}
                        </td>
                        <td v-if="transfer.use_serials" style="width: 50px">
                            <button type="button" class="btn btn-primary mr-2" v-on:click="startTransfer(-1, transfer)">Select S/N</button>
                        </td>
                        <td v-else style="width: 50px">
                            <input type="number" oninput="validity.valid||(value=\'\')" min="0" :max="transfer.created_qty" class="form-control" name="transfer_qty" placeholder="##" v-model="transfer.picked_qty">
                        </td>
                        <td style="width: 50px">
                            <textarea class="form-control" id="comments" name="comments" placeholder="Comments" v-model="transfer.comments"></textarea>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="mt-4">
                <button type="button" class="btn btn-primary mr-2" v-on:click="submitTransferPicking()">Submit Picking</button>
                <div v-if="loading" class="spinner-border text-primary" role="status">
                    <span class="visually-hidden"></span>
                </div>
            </div>
            <transfer-orders-serial-codes-modal
                :item_to_transfer="item_to_transfer"
                :serials="item_selected_serial_numbers"
                :product_id="item_to_transfer.product_id"
                :quantity="item_to_transfer.created_qty"
                :all_products="products"
                :list_of_serials="transfer_serials.filter(ts=> ts.inventory_transfer_order_id == item_to_transfer.id)"
                :parent="this"
            ></transfer-orders-serial-codes-modal>
        </div>
    </div>
</template>

<script>
import 'jquery-validation';
import {LogInIcon, LogOutIcon} from 'vue-feather-icons';
import SelectMultipleUser from "./SelectSerialRecordMultiple";
import Swal from "sweetalert2";

export default {
    name: "EditPickingTransferOrderComponent",
    props:['warehouses', 'zones', 'locations', 'products', 'is_transfer', 'users', 'transfer_order_inventory', 'transfer_order', 'transfer_serials'],
    components:{
        SelectMultipleUser,
        LogOutIcon, LogInIcon
    },
    data(){
        return{
            item_to_transfer:Object,
            item_selected_serial_numbers:[],
            form:{
                submit_pickings_transfer:[],
            },
            loading:false,
            is_error:false
        }
    },
    mounted() {
    },
    methods:{
        startTransfer(index, item) {
            this.item_to_transfer = item;
            this.flag = !this.flag;
            this.$modal.show('transfer-orders-serial-codes-modal');
        },
        submitTransferPicking(){
            let attributes = {
                transfer_order_inventory: this.$props.transfer_order_inventory,
                item_selected_serial_numbers: this.item_selected_serial_numbers,
            }
            this.loading = true;
            this.is_error = false;

            this.$props.transfer_order_inventory.forEach($toi =>{
                if($toi.use_serials == 0) {
                    if ($toi.picked_qty == '' || $toi.picked_qty == null) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Please, input the quantity for all the products (0 if none).',
                        });
                        this.loading = false;
                        this.is_error = true;
                    }
                }
            })
            if(this.is_error == false) {
                axios.post('/submit_picking_transfer_order/' + this.$props.transfer_order.id, attributes).then(response => {
                    if (response.data.error == 404) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: response.data.message,
                        });
                        this.loading = false;
                        this.flag_loading = false;
                        this.submit_disabled = false;
                    } else {
                        this.loading = false;
                        window.location = response.data.redirect;
                    }
                }).catch(error => {
                    if (error.response.status === 403) {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'There was a problem.',
                        });
                    }
                    this.loading = false;
                    this.flag_loading = false;
                    this.submit_disabled = false;
                });
            }
        }
    }
}
</script>

<style scoped>

</style>
