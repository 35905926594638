<template>
    <modal name="cycle-reconciliation-modal" height="auto" :scrollable="true">
        <div class="modal-content border-bottom-0 h-100">
            <div class="modal-header">
                <div class="row justify-content-between">
                    <h5 class="modal-title">Add Product from Inventory</h5>
                </div>
                <div style="float: right; text-align: right" class="mt-3 mr-3">
                    <button type="button" class="btn btn-primary" v-on:click="scanItem()"><h5>Scan Item</h5></button>
                </div>
            </div>
            <div class="modal-body">
                <div class="form-group">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-8">
                                <b>Select Product:</b>
                                <dropbox-reconciliation name="product_id" id="product_id" :options="inventory_products" v-model="form.new_product_id" v-on:change="changeProductToReconcile()">
                                    <option></option>
                                </dropbox-reconciliation>
                            </div>
                            <div class="col-md-4">
                                <b>Quantity:<span class="required_asterisk">*</span></b>
                                <input type="number" class="form-control" :min="0" name="reconciliation_number" placeholder="##" v-model="form.quantity_to_add">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-8">
                                <div class="row ml-2 mr-2 mt-2">
                                    <b>Select Warehouse:</b>
                                    <dropbox name="warehouse_id" id="warehouse_id" :options="warehouses" v-model="form.new_warehouse_id" v-on:change="resetZoneLocation(form.new_warehouse_id)">
                                        <option></option>
                                    </dropbox>
                                </div>
                                <div class="row ml-2 mr-2 mt-1" v-if="form.new_warehouse_id !==''">
                                    <b>Select Zone</b>
                                    <dropbox name="zone_id" id="zone_id" :options="zones" v-model="form.new_zone_id" v-on:change="changeLocation(form.new_zone_id)">
                                        <option></option>
                                    </dropbox>
                                </div>
                                <div class="row ml-2 mr-2 mt-1" v-if="(form.new_zone_id !=='') && (form.new_warehouse_id !=='')">
                                    <b>Select Location</b>
                                    <dropbox name="location_id" id="location_id" :options="locations" v-model="form.new_location_id" v-on:change="changeLocationProducts()">
                                        <option></option>
                                    </dropbox>
                                </div>
                            </div>
                            <div v-if="$props.is_admin" class="col-md-4">
                                <div class="row ml-2 mb-3 mt-3">
                                    <h6>In Stock:
                                        <b v-if="(form.new_product_id != '') && (form.new_warehouse_id != '')">
                                            <p>{{new_products_array != null ? new_products_array.quantity : 0}}</p>
                                        </b>
                                        <b v-else-if="form.new_product_id != ''">
                                            <p>{{$props.inventory_products.find(ip => ip.id == form.new_product_id).quantity}}</p>
                                        </b>
                                        <b v-else=""></b>
                                    </h6>
                                </div>
                                <div class="row ml-2">
                                    <h6>Variance:
                                        <b v-if="(form.new_product_id != '') && (form.new_warehouse_id != '')">
                                            <p v-if="(new_products_array != null && form.quantity_to_add != '') && form.quantity_to_add < new_products_array.quantity">
                                                {{'-'+Math.abs(new_products_array.quantity - form.quantity_to_add)}}
                                            </p>
                                            <p v-else-if="(new_products_array != null && form.quantity_to_add != '') && form.quantity_to_add >= new_products_array.quantity">
                                                {{'+'+Math.abs(new_products_array.quantity - form.quantity_to_add)}}
                                            </p>
                                            <p v-else-if="form.quantity_to_add != ''">
                                                {{'+'+form.quantity_to_add}}
                                            </p>
                                        </b>
                                        <p v-else>
                                        </p>
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-light" @click="$modal.hide('cycle-reconciliation-modal'), closeModal()">Close</button>
                <button type="button" class="btn btn-primary mr-2" @click="addToIn()">Add Product</button>
            </div>

            <scan-cycle-count-item-modal
                :locations = "locations"
                :zones="zones"
                :warehouses="warehouses"
                :new_warehouse_id="form.new_warehouse_id"
                :new_zone_id="form.new_zone_id"
                :new_location_id="form.new_location_id"
                :parent="this"
                :inventory_products="inventory_products"
                :product="form.new_product_id"
                :qty_to_add="form.quantity_to_add"

                :flagscan="flagscan"
            >
            </scan-cycle-count-item-modal>

        </div>
    </modal>
</template>

<script>
import Swal from "sweetalert2";
import axios from "axios";
import ScanCycleCountItemModal from "./ScanCycleCountItemModal";
export default {
    name: "CycleReconciliationModal",
    props: ['warehouses', 'zones', 'locations', 'products_to_reconcile', 'all_products', 'inventory_products', 'is_admin'],
    components:{
        ScanCycleCountItemModal
    },
    data() {
        return {
            form: {
                new_product_id:'',
                quantity_to_add:'',
                new_warehouse_id:'',
                new_zone_id:'',
                new_location_id:''
            },
            non_inv:[],
            flag:'',
            inner_flag:'',
            wh:'',
            new_products_array:Array,
            new_product_to_reconcile:'',
            flagscan:'',
        }
    },
    mounted() {
        this.wh = this.form.new_warehouse_id;
        // this.form.new_product_id = 1;
    },
    methods: {
        addToIn() {
            const Toast = Swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 2500
            });
            if( this.$props.products_to_reconcile.length > 0){
                if(this.form.new_warehouse_id == null || this.form.new_warehouse_id == ''){
                    Toast.fire({
                        icon: 'error',
                        title: 'At least warehouse must be selected.'
                    })
                }else if(this.form.new_product_id == ''){
                    Toast.fire({
                        icon: 'error',
                        title: 'Product and quantity must be selected.'
                    })
                }
                else if(this.form.quantity_to_add == "" || this.form.quantity_to_add == null){
                    Toast.fire({
                        icon: 'error',
                        title: 'Product and quantity must be selected.'
                    })
                }
                else {
                    this.$props.products_to_reconcile.forEach(elem => {
                        if ((parseInt(elem.product_id) === parseInt(this.form.new_product_id))
                            && (parseInt(elem.warehouse_id) === parseInt(this.form.new_warehouse_id))
                            && (parseInt(elem.zone_id) === parseInt(this.form.new_zone_id))
                            && (parseInt(elem.location_id) === parseInt(this.form.new_location_id))
                        ){
                            this.flag = true;
                            this.inner_flag = true;
                            if (this.form.quantity_to_add == null || this.form.quantity_to_add == "") {
                                Toast.fire({
                                    icon: 'error',
                                    title: 'Product and quantity must be selected.'
                                })
                            }
                            else {
                                if(this.form.new_zone_id === ''){
                                    this.form.new_zone_id = null;
                                }
                                if(this.form.new_location_id === ''){
                                    this.form.new_location_id = null;
                                }
                                elem.warehouse_id = parseInt(this.form.new_warehouse_id);
                                elem.zone_id = this.form.new_zone_id===null ? null : parseInt(this.form.new_zone_id);
                                elem.location_id = this.form.new_location_id===null ? null : parseInt(this.form.new_location_id);
                                elem.reconciliation_qty = this.form.quantity_to_add;
                                elem.reconciliation_qty_list = this.form.quantity_to_add;
                                Toast.fire({
                                    icon: 'success',
                                    title: 'Product quantity updated.'
                                })
                                this.$forceUpdate();
                                this.closeModal();
                                this.$modal.hide('scan-cycle-count-item-modal');
                            }
                        } else {
                            this.flag = false;
                            this.inner_flag = false;
                        }
                    })
                }
                if(this.flag === false && this.inner_flag !== true){
                    if(this.form.new_zone_id === ''){
                        this.form.new_zone_id = null;
                    }
                    if(this.form.new_location_id === ''){
                        this.form.new_location_id = null;
                    }
                    let prd = this.$props.all_products.filter(prd=> prd.id === parseInt(this.form.new_product_id));
                    let non_inv = {
                        warehouse_id: parseInt(this.form.new_warehouse_id),
                        zone_id: this.form.new_zone_id===null ? null : parseInt(this.form.new_zone_id),
                        location_id: this.form.new_location_id===null ? null : parseInt(this.form.new_location_id),
                        name: prd[0].name,
                        product_id:prd[0].id,
                        quantity:this.new_products_array == null ? 0: parseInt(this.new_products_array.quantity),
                        reconciliation_qty:this.form.quantity_to_add,
                        reconciliation_qty_list:this.form.quantity_to_add,
                        sku: prd[0].sku,
                        use_serials: prd[0].use_serials,
                        description: prd[0].description,
                    }
                    this.form.new_warehouse_id = '';
                    this.form.new_zone_id = '';
                    this.form.new_location_id = '';
                    this.$props.products_to_reconcile.push(non_inv);
                    this.$forceUpdate();
                    this.closeModal();
                }
            }else if(this.form.new_product_id == 0){
                Toast.fire({
                    icon: 'error',
                    title: 'Product and quantity must be selected.'
                })
            }else if(this.form.quantity_to_add == "" || this.form.quantity_to_add == null){
                Toast.fire({
                    icon: 'error',
                    title: 'Product and quantity must be selected.'
                })
            }
            else if(this.form.new_warehouse_id === null || this.form.new_warehouse_id === ''){
                Toast.fire({
                    icon: 'error',
                    title: 'At least warehouse must be selected.'
                })
            }else{
                if(this.form.new_zone_id === ''){
                    this.form.new_zone_id = null;
                }
                if(this.form.new_location_id === ''){
                    this.form.new_location_id = null;
                }
                let prd = this.$props.all_products.filter(prd=> prd.id === parseInt(this.form.new_product_id));
                let non_inv = {
                    warehouse_id: parseInt(this.form.new_warehouse_id),
                    zone_id: this.form.new_zone_id===null ? null : parseInt(this.form.new_zone_id),
                    location_id: this.form.new_location_id===null ? null : parseInt(this.form.new_location_id),
                    name: prd[0].name,
                    product_id:prd[0].id,
                    quantity:this.new_products_array == null ? 0: parseInt(this.new_products_array.quantity),//parseInt(this.new_products_array.quantity),
                    reconciliation_qty:this.form.quantity_to_add,
                    reconciliation_qty_list:this.form.quantity_to_add,
                    sku: prd[0].sku,
                    use_serials: prd[0].use_serials,
                    description: prd[0].description,
                }
                this.form.new_warehouse_id = '';
                this.form.new_zone_id = '';
                this.form.new_location_id = '';
                this.$props.products_to_reconcile.push(non_inv);
                this.$forceUpdate();
                this.closeModal();
            }
        },
        changeProductToReconcile(){
            this.form.new_warehouse_id = '';
            this.form.new_zone_id = '';
            this.form.new_location_id = '';
            this.form.quantity_to_add = '';
        },
        resetZoneLocation(wh_id){
            this.form.new_zone_id = '';
            this.form.new_location_id = '';
            let attributes = {
                'product_id': this.form.new_product_id,
                'warehouse_id':this.form.new_warehouse_id,
                'zone_id': null,
                'location_id': null,
            };
            if(wh_id != null && wh_id != '') {
                axios.get('/get_warehouses_zones/' + wh_id).then(response => {
                    this.$props.zones = response.data;
                })
                axios.post('/get_inventory_products_by_location', attributes).then(response => {
                    this.new_products_array = response.data.new_products;
                })
            }
        },
        changeLocation(zn_id){
            this.form.new_location_id = '';
            let attributes = {
                'product_id': this.form.new_product_id,
                'warehouse_id':this.form.new_warehouse_id,
                'zone_id': this.form.new_zone_id,
                'location_id': null,
            };
            if(zn_id != null && zn_id != '') {
                axios.get('/get_zones_locations/' + zn_id).then(response => {
                    this.$props.locations = response.data;
                })
                axios.post('/get_inventory_products_by_location', attributes).then(response => {
                    this.new_products_array = response.data.new_products;
                })
            }
        },
        changeLocationProducts(){
            let attributes = {
                'product_id': this.form.new_product_id,
                'warehouse_id':this.form.new_warehouse_id,
                'zone_id': this.form.new_zone_id,
                'location_id': this.form.new_location_id,
            };
            if(this.form.new_location_id != null && this.form.new_location_id != '') {
                axios.post('/get_inventory_products_by_location', attributes).then(response => {
                    this.new_products_array = response.data.new_products;
                })
            }
        },
        closeModal(){
            this.new_products_array = '';
            this.form.new_product_id = '';
            this.form.new_warehouse_id = '';
            this.form.new_zone_id = '';
            this.form.new_location_id = '';
            this.form.quantity_to_add = '';
        },
        scanItem(){
            this.flagscan = !this.flagscan;
            this.$modal.show('scan-cycle-count-item-modal');
        }
    }
}
</script>

<style scoped>

</style>
