<template>
    <modal :name="'inventory-transfer-bin-to-bin-modal-'+ index" height="auto" :scrollable="true">
        <div class="modal-content border-bottom-0" id="myModal" ref="vuemodal">
            <div class="modal-header">
                <div >
                    <div class="row justify-content-between mb-2">
                        <h4 class="modal-title">Transfer Inventory</h4>
                    </div>
                    <div>
                        <h6 class="mb-2">Product SKU: {{item.product_sku}}</h6>
                        <h6>Origin: {{item.warehouse_name}} / {{item.zone_name}} / {{item.location_name}}</h6>
                    </div>
                </div>
                <div class="mt-4">
                    <button v-if="item.use_serials" type="button" @click="$modal.show('bin-to-bin-serial-codes-modal')" class="btn btn-primary mr-2"><h3>Scan S/N</h3></button>
                </div>
            </div>
            <form v-on:submit.prevent @submit.prevent="submit" id="form" name="form" :ref="'form'">
                <div class="modal-body">
                    <div class="row col-md-12">
                        <div class="col-md-8">
                            <div v-if="index === 0" class="form-group col-md-12">
                                <p>Destination Warehouse:</p>
                                <dropbox name="warehouse_id" id="warehouse_id1" :options="warehouses" v-model="form.new_warehouse_id" v-on:change="resetZoneLocation(form.new_warehouse_id)">
                                    <option></option>
                                </dropbox>
                                <div class="mb-2 mt-2" v-if="form.new_warehouse_id !==''">
                                    <p>Destination Zone</p>
                                    <dropbox name="zone_id" id="zone_id1" :options="zones" v-model="form.new_zone_id" v-on:change="changeLocation(form.new_zone_id)">
                                        <option></option>
                                    </dropbox>
                                </div>
                                <div class="mb-2 mt-2" v-if="form.new_zone_id !=='' && form.new_warehouse_id !==''">
                                    <p>Destination Location</p>
                                    <dropbox name="location_id" id="location_id1" :options="locations" v-model="form.new_location_id">
                                        <option></option>
                                    </dropbox>
                                </div>
                                <h6 class="mb-2 mt-4">Select Quantity:</h6>
                                <input autocomplete="off" :max="item.quantity" :min="0" type="number" class="form-control mb-2" name="quantity" id="quantity" placeholder="###" v-model="form.quantity_to_transfer">
                            </div>
                            <div v-else class="form-group col-md-12">
                                <h6 class="mb-2">Destination Warehouse:</h6>
                                <dropbox name="warehouse_id" id="warehouse_id" :options="warehouses" v-model="form.new_warehouse_id" v-on:change="resetZoneLocation(form.new_warehouse_id)">
                                    <option></option>
                                </dropbox>
                                <div class="mb-2 mt-2" v-if="form.new_warehouse_id !==''">
                                    <p>Destination Zone</p>
                                    <dropbox name="zone_id" id="zone_id" :options="zones" v-model="form.new_zone_id" v-on:change="changeLocation(form.new_zone_id)">
                                        <option></option>
                                    </dropbox>
                                </div>
                                <div class="mb-2 mt-2" v-if="form.new_zone_id !=='' && form.new_warehouse_id !==''">
                                    <p>Destination Location</p>
                                    <dropbox name="location_id" id="location_id" :options="locations" v-model="form.new_location_id">
                                        <option></option>
                                    </dropbox>
                                </div>
                                <h6 class="mb-2 mt-4">Selected S/N: <span>{{form.selected_serial_numbers.length}}</span></h6>
                                <div class="col-12">
                                </div>
                                <div class="col-12">
                                    <div v-if="serials_loading" class="spinner-border" role="status">
                                        <span class="visually-hidden"></span>
                                    </div>
                                    <button type="button" class="btn btn-secondary mr-2 mt-2" @click="select_all()">Select All</button>
                                    <button type="button" class="btn btn-light mr-2 mt-2" @click="clearSerials()">Clear</button>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4" style="display: flex; align-items: center; justify-content: center ">
                            <button type="button" class="btn btn-primary ml-2" v-on:click="scanLocation()">Scan Location</button>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light" @click="$modal.hide('inventory-transfer-bin-to-bin-modal-'+index), clear_all()">Close</button>
                    <button :disabled="submit_disabled" type="button" class="btn btn-primary mr-2" @click="submit(item)">Submit</button>
                    <div v-if="loading" class="spinner-border" role="status">
                        <span class="visually-hidden"></span>
                    </div>
                </div>
                <bin-to-bin-serial-codes-modal
                    :product="item"
                    :index="1"
                    :serials="form.selected_serial_numbers"
                    :product_id="item.product_id"
                    :warehouse_id="item.warehouse_id"
                    :zone_id="item.zone_id"
                    :location_id="item.location_id"
                    :removeSerial="removeSerial"
                ></bin-to-bin-serial-codes-modal>
                <scan-location-modal
                    :index = "index1"
                    :product = "item"
                    :locations = "locations"
                    :zones="zones"
                    :warehouses="warehouses"
                    :flag1="flag1"
                    :new_warehouse_id="form.new_warehouse_id"
                    :new_zone_id="form.new_zone_id"
                    :new_location_id="form.new_location_id"
                    :parent="this"
                    :backup_locations="backup_locations"
                    :backup_warehouses="backup_warehouses"
                    :backup_zones="backup_zones"
                    :scan_flag="scan_flag"
                >
                </scan-location-modal>
            </form>
        </div>
    </modal>
</template>

<script>
import Swal from "sweetalert2";
import 'jquery-validation';
import axios from "axios";
import ScanLocationModal from "./ScanLocationModal";
import BinToBinSerialCodesModal from "./BinToBinSerialCodesModal";

export default {
    name: "InventoryTransferBinToBinModal",
    props: ['index', 'warehouses','zones','locations', 'item', 'flag', 'is_transfer', 'transfer_orders'],
    components:{
        BinToBinSerialCodesModal,
        ScanLocationModal
    },
    mounted(){
        this.backup_warehouses = this.$props.warehouses;
        this.backup_zones = this.$props.zones;
        this.backup_locations = this.$props.locations;
    },
    data(){
        return{
            form: {
                new_warehouse_id:'',
                new_zone_id:'',
                new_location_id:'',
                quantity_to_transfer:'',
                product_id:'',
                old_warehouse_id:'',
                old_zone_id:'',
                old_location_id:'',
                selected_serial_numbers:[],
                use_serials:''
            },
            submit_disabled: false,
            loading: false,
            serials_loading: false,
            flag1: false,
            scan_flag:false,
            index1:2,
            backup_warehouses:[],
            backup_zones:[],
            backup_locations:[],
            transfer_serial_codes:[]
        }
    },
    methods:{
        select_all(){
            this.serials_loading = true
            this.form.selected_serial_numbers.splice(0);
            this.form.selected_serial_numbers = [];
            let item = this.$props.item;
            axios.get('/bintobin/get_serials/'+ item.product_id + '/'+ item.warehouse_id + '/'+ item.zone_id + '/'+ item.location_id).then(response => {
                this.form.selected_serial_numbers = response.data;
                this.$forceUpdate();
                this.serials_loading = false;
            }).catch(error =>{
                if(error.response.status === 403){
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'There was a problem.',
                    });
                }
                this.serials_loading = false;
            });
        },
        removeSerial(item){
            this.form.selected_serial_numbers = this.form.selected_serial_numbers.filter(x => {
                if(x.id === item.id){
                    console.log(x.id, item.id);
                }
                return x.id !== item.id;
            });
        },
        clear_all(){
            console.log('Clear')
            this.form.selected_serial_numbers.splice(0);
            this.form.new_warehouse_id = '';
            this.form.new_zone_id = '';
            this.form.new_location_id = '';
            this.form.quantity_to_transfer = '';
            this.submit_disabled = false;
            this.loading = false;
        },
        clearSerials(){
            this.form.selected_serial_numbers.splice(0);
        },
        resetZoneLocation(wh_id){
            this.form.new_zone_id = "";
            this.form.new_location_id = "";
            axios.get('/get_warehouses_zones/'+ wh_id).then(response => {
                this.$props.zones = response.data;
            })
        },
        changeLocation(zn_id){
            this.form.new_location_id = "";
            axios.get('/get_zones_locations/'+ zn_id).then(response => {
                this.$props.locations = response.data;
            })
        },
        submit(){
            this.loading = true
            this.form.product_id = this.$props.item.product_id;
            this.form.old_warehouse_id = this.$props.item.warehouse_id;
            this.form.old_zone_id = this.$props.item.zone_id;
            this.form.old_location_id = this.$props.item.location_id;
            if (this.$props.item.use_serials) {
                this.form.use_serials = 1;
            } else {
                this.form.use_serials = 0;
            }
            let attributes = this.form;
            if (!this.$props.item.use_serials && ((this.form.quantity_to_transfer > this.$props.item.quantity) || (this.form.quantity_to_transfer <= 0))) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'The quantity to transfer must be less or equal to the quantity in stock.',
                });
                this.loading = false
            } else if (this.form.new_warehouse_id === '' || this.form.new_warehouse_id === null) { //|| this.form.new_zone_id === null || this.form.new_location_id === null || this.form.new_zone_id === '' || this.form.new_location_id === ''){
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'A destination warehouse must be selected.',
                });
                this.loading = false
            } else if (!this.$props.item.use_serials && (this.form.quantity_to_transfer === null || this.form.quantity_to_transfer === '')) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Please, input a quantity to transfer.',
                });
                this.loading = false
            } else if (this.$props.item.use_serials && (this.form.selected_serial_numbers.length === null || this.form.selected_serial_numbers.length === 0)) {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Please, select serial numbers to transfer.',
                });
                this.loading = false
            } else {
                if(this.$props.is_transfer) {
                    let item_number = 'item-' + Math.ceil(Math.random()*10000);
                    for(let i = 0; i < this.form.selected_serial_numbers.length; i++){
                        this.transfer_serial_codes.push({
                            'item': item_number,
                            'serial_code':this.form.selected_serial_numbers[i]['serial_code']
                        });
                    }
                    let transfer_product ={
                        'new_warehouse_id' : this.form.new_warehouse_id,
                        'new_zone_id' : this.form.new_zone_id,
                        'new_location_id' : this.form.new_location_id,
                        'quantity_to_transfer': this.form.use_serials == 1 ? this.form.selected_serial_numbers.length : this.form.quantity_to_transfer,
                        'product_id': this.form.product_id,
                        'old_warehouse_id':this.form.old_warehouse_id,
                        'old_zone_id':this.form.old_zone_id,
                        'old_location_id': this.form.old_location_id,
                        'selected_serial_numbers': this.transfer_serial_codes,
                        'use_serials':this.form.use_serials,
                        'qty_on_hand':this.$props.item.quantity,
                        'item_number': item_number
                    }
                    this.$props.transfer_orders.push(transfer_product);
                    this.$modal.hide('inventory-transfer-bin-to-bin-modal-'+this.$props.index);
                    this.clear_all();
                }else {
                    this.submit_disabled = true;
                    axios.post('/bintobin/transfer_inventory', attributes).then(response => {
                        location.reload();
                    });
                }
            }
        },
        scanLocation(){
            console.log('Scan Location');
            this.flag1 = !this.flag1;
            this.index1 = 2;
            this.$modal.show('scan-location-modal');
        }
    },
    watch: {
        flag: function(newVal, oldVal) {
            this.clear_all();
        }
    }
}
</script>

<style scoped>

</style>
