<template>
    <div class="card">
        <div class="card-body">
            <h6 class="card-title">Transfer Order Pickings</h6>
            <h6 class="card-subtitle mb-2 text-muted">Pending Pickings</h6>
            <div class="table-responsive pt-3 mb-12">
                <table class="table table-bordered table-hover data-table">
                    <thead>
                    <tr>
                        <th>
                            Transfer Order Number
                        </th>
                        <th>
                            Order By
                        </th>
                        <th>
                            Total Transfers
                        </th>
                        <th>
                            Order Date
                        </th>
                        <th>
                            Status
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(transfer,index) in this.pending_transfer_orders">
                        <td>
                            <p v-on:click="edit_picking(transfer.id)" style="color: blue; cursor: pointer">{{transfer.transfer_order_number}}</p>
                        </td>
                        <td>
                            {{users.find(user => user.id == transfer.created_by).first_name + ' ' + users.find(user => user.id == transfer.created_by).last_name}}
                        </td>
                        <td>
                            {{transfer_order_inventory.filter(toi => toi.transfer_order_id == transfer.id).length}}
                        </td>
                        <td>
                            {{transfer.created_date}}
                        </td>
                        <td>
                            <p style="color: orange">{{transfer.status}}</p>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
import 'jquery-validation';
import {LogInIcon, LogOutIcon} from 'vue-feather-icons';

export default {
    name: "PickingTransferOrderComponent",
    props:['warehouses', 'zones', 'locations', 'products', 'is_transfer', 'pending_transfer_orders', 'users', 'transfer_order_inventory'],
    components:{
        LogOutIcon, LogInIcon
    },
    data(){
        return{

        }
    },
    mounted() {
    },
    methods:{
        edit_picking(transfer_id){
            window.location = "/edit_picking_transfer_order/" + transfer_id;
        }
    }
}
</script>

<style scoped>

</style>
