<template>
    <div class="card">
        <div class="card-body">
            <h4 class="card-title">Transfer Order Receiving</h4>
            <p class="mb-4"><b>Transfer order #: </b>{{transfer_order.transfer_order_number}}</p>
            <div class="row col-md-12 mb-4">
                <div class="col-md-6">
                    <p><b>Date Ordered: </b>{{transfer_order.created_date}}</p>
                    <p><b>Date Picked: </b>{{transfer_order.picked_date}}</p>
                    <p><b>Date Received: </b>{{transfer_order.received_date}}</p>
                </div>
                <div class="col-md-6">
                    <p><b>Ordered By: </b>{{users.find(user => user.id == transfer_order.created_by).first_name + ' ' + users.find(user => user.id == transfer_order.created_by).last_name}}</p>
                    <p><b>Picked By: </b>{{users.find(user => user.id == transfer_order.picked_by).first_name + ' ' + users.find(user => user.id == transfer_order.picked_by).last_name}}</p>
                    <p><b>Received By: </b>{{users.find(user => user.id == transfer_order.received_by).first_name + ' ' + users.find(user => user.id == transfer_order.received_by).last_name}}</p>
                </div>
            </div>
            <div class="table-responsive pt-3 mb-12">
                <table class="table table-bordered table-hover data-table">
                    <thead>
                    <tr>
                        <th>
                            Product
                        </th>
                        <th>
                            SKU
                        </th>
                        <th>
                            From
                        </th>
                        <th>
                            To
                        </th>
                        <th>
                            Qty. On Hand
                        </th>
                        <th>
                            Qty. Received
                        </th>
                        <th style="width: 50px">
                            Comments
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(transfer,index) in this.transfer_order_inventory">
                        <td>
                            {{products.find(prod => prod.id == transfer.product_id).name}}
                        </td>
                        <td>
                            {{products.find(prod => prod.id == transfer.product_id).sku}}
                        </td>
                        <td>
                            {{warehouses.find(wh => wh.id == transfer.old_warehouse_id) == null ? '' : warehouses.find(wh => wh.id == transfer.old_warehouse_id).name
                            + ' - ' + (zones.find(zn => zn.id == transfer.old_zone_id) == null ? '' : zones.find(zn => zn.id == transfer.old_zone_id).name)
                            + ' - ' + (locations.find(ln => ln.id == transfer.old_location_id) == null ? '' : locations.find(ln => ln.id == transfer.old_location_id).name)}}
                        </td>
                        <td>
                            {{warehouses.find(wh => wh.id == transfer.new_warehouse_id) == null ? '' : warehouses.find(wh => wh.id == transfer.new_warehouse_id).name
                            + ' - ' + (zones.find(zn => zn.id == transfer.new_zone_id) == null ? '' : zones.find(zn => zn.id == transfer.new_zone_id).name)
                            + ' - ' + (locations.find(ln => ln.id == transfer.new_location_id) == null ? '' : locations.find(ln => ln.id == transfer.new_location_id).name)}}
                        </td>
                        <td>
                            {{transfer.qty_on_hand}}
                        </td>
                        <td>
                            {{transfer.received_qty}}
                        </td>
                        <td style="width: 50px">
                            {{transfer.comments}}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="mt-4">
            </div>
        </div>
        <div class="card-body">
            <h4 class="card-title">Transfer Order - Serial Numbers History</h4>
            <div class="table-responsive pt-3 mb-12">
                <table class="table table-bordered table-hover data-table">
                    <thead>
                    <tr>
                        <th>
                            Product
                        </th>
                        <th>
                            SKU
                        </th>
                        <th>
                            Serial Number
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(transfer,index) in this.transfer_serials">
                        <td>
                            {{products.find(prod => prod.id == (transfer_order_inventory.find(toi => toi.transfer_order_id == transfer_order.id).product_id)).name}}
                        </td>
                        <td>
                            {{products.find(prod => prod.id == (transfer_order_inventory.find(toi => toi.transfer_order_id == transfer_order.id).product_id)).sku}}
                        </td>
                        <td>
                            {{transfer.serial_number}}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="mt-4">
            </div>
        </div>
    </div>
</template>

<script>
import SelectMultipleUser from "./SelectSerialRecordMultiple";
import {LogInIcon, LogOutIcon} from "vue-feather-icons";

export default {
    name: "EditCompletedTransferOrderComponent",
    props:['warehouses', 'zones', 'locations', 'products', 'is_transfer', 'users', 'transfer_order_inventory', 'transfer_order', 'transfer_serials'],
    components:{
        SelectMultipleUser,
        LogOutIcon, LogInIcon
    },
    data(){
        return{
        }
    },
    mounted() {
    },
    methods:{

    }
}
</script>

<style scoped>

</style>
