<template>
    <modal :name="'scan-cycle-count-modal'" :scrollable="true" height="auto" width="400px">
        <div v-if="modal_type == 'scan_location'" class="modal-content border-bottom-0" id="myModal" ref="vuemodal">
            <div class="modal-header">
                <div>
                    <div class="row justify-content-between">
                        <h4 class="modal-title">Scan Location</h4>
                    </div>
                </div>
            </div>
            <form @submit.prevent="submit" id="form" name="form" :ref="'form'">
                <div class="modal-body">
                    <div>
                        <div style="text-align: center; text-align-all: center; align-self: center; align-content: center; align-items: center">
                            <input type="text" class="form-control-lg text-center" name="scan_location" id="scan_location" placeholder="Location" autofocus v-model="input_scan_location" v-on:keyup.enter="submit(input_scan_location, index)">
                            <p style="color: red" :hidden="!input_empty_location">Location not found in system.</p>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light" @click="$modal.hide('scan-cycle-count-modal'), clear_all()">Close</button>
                    <button type="button" class="btn btn-primary mr-2" @click="submit(input_scan_location, index)">Submit</button>
                </div>
            </form>
        </div>
    </modal>
</template>

<script>
import {trim} from "lodash/string";

export default {
    name: "ScanCycleCountModal",
    props: [
        'product',
        'warehouses',
        'zones',
        'locations',
        'index',
        'flag',
        'scan_flag',
        'new_warehouse_id',
        'new_zone_id',
        'new_location_id',
        'parent',
        'backup_warehouses',
        'backup_zones',
        'backup_locations',
        'return_flag',
        'path',
        'list_of_products',
        'scan_filtered_products'
    ],
    mounted(){
        this.modal_type = 'scan_location';
    },
    data(){
        return {
            input_scan_location:'',
            input_empty_location:false,
            scanned_location:'',
            modal_type:'',
            scanned_product:'',
            input_scan:'',
            input_empty:false,
        }
    },
    methods: {
        submit(input_scan_location, index){
            if(this.input_scan_location === ''){
                this.input_empty_location = false;
            }
            if(typeof(index) !== 'undefined') {
                if(index === 'cycle_count_component'){
                    for (let i = 0; i < this.$props.locations.length; i++) {
                        if (this.input_scan_location.toLowerCase() === this.$props.locations[i].name.toLowerCase()) {
                            for (let j = 0; j < this.$props.zones.length; j++) {
                                if (this.$props.locations[i].zone_id === this.$props.zones[j].id) {
                                    for (let k = 0; k < this.$props.warehouses.length; k++) {
                                        if (this.$props.zones[j].warehouse_id === this.$props.warehouses[k].id) {
                                            let warehouse_id = this.$props.warehouses[k].id;
                                            let zone_id = this.$props.zones[j].id;
                                            let location_id = this.$props.locations[i].id;
                                            this.$props.new_warehouse_id = warehouse_id;
                                            this.$props.parent.filter.new_warehouse_id = warehouse_id;
                                            this.cycle_select_zone(zone_id, location_id);
                                            this.input_empty_location = false;
                                            break;
                                        } else {
                                            this.input_empty_location = true;
                                        }
                                    }
                                    break;
                                } else {
                                    this.input_empty_location = true;
                                }
                            }
                            break;
                        } else {
                            this.input_empty_location = true;
                        }
                    }
                }
            }
        },
        clear_all() {
            this.input_scan_location = '';
            this.input_scan = '';
            this.modal_type = 'scan_location';
            setTimeout(function(){
                document.getElementById("scan_location").focus();
            },0)
        },
        cycle_select_zone(zone_id, location_id){
            setTimeout(() =>  {
                this.$props.parent.filter.new_zone_id = zone_id;
                this.cycle_select_location(location_id);
            }, 200);
        },
        cycle_select_location(location_id){
            setTimeout(() => {
                this.$props.parent.filter.new_location_id = location_id;
                this.cycle_count_search();
            }, 200);
        },
        cycle_count_search(){
            setTimeout(() => {
                this.$props.parent.getData(this.$props.path + '?' + this.$props.parent.getFilter());
                this.$props.parent.getScanProducts();
                this.$modal.hide('scan-cycle-count-modal');
            }, 200);
        },
        submit_scan_product(input_scan){
            this.input_scan = trim(this.input_scan);
            if (this.input_scan === '') {
                this.input_empty = true;
            }
            for (let i = 0; i < this.$props.scan_filtered_products.length; i++) {
                let match = null;
                if(this.$props.scan_filtered_products[i].upc != null){
                    this.upc_array = this.$props.scan_filtered_products[i].upc.split(",");
                    match = this.getMatchingResults(this.upc_array).length > 0;
                }else{
                    match = this.input_scan === this.$props.scan_filtered_products[i].upc;
                }
                if (this.input_scan.toLowerCase() === this.$props.scan_filtered_products[i].sku.toLowerCase() || (match)){
                    this.input_empty = false;
                    this.scanned_product = this.$props.scan_filtered_products[i];
                    this.serial_index = i;
                    this.modal_type = 'input_qty'
                    break;
                } else {
                    this.input_empty = true;
                }
            }
        },
        getMatchingResults(upc_array) {
            return upc_array.filter(d => this.input_scan.split(',').every(v => d.toLowerCase() === v.toLowerCase()));
        },
    },
    watch: {
        flag: function() {
            this.clear_all();
            this.input_empty_location = false;
        }
    }
}
</script>

<style scoped>

</style>
